import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { PropTypes } from 'prop-types';
import styled, { css, ThemeContext } from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { get, map, without } from 'lodash';
import { AiOutlineUpload } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import File from './File';
import ButtonRow from './ButtonRow';
import ModalError from '../modal-messages/ModalError';
import ConfirmUpload from './ConfirmUpload';
import { initializeDocumentsState } from '../../modules/applications/actions';

const Container = styled.div``;

const DropZone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: ${({ isMobile }) => (isMobile ? '128px' : '256px')};
  border: 1px dashed ${({ theme }) => theme.borderColor};
  border-radius: 2px;
  background: ${({ theme }) => theme.formBackground};
  transition: 1s;
  padding: 32px;

  cursor: pointer;

  ${({ isDragActive }) => isDragActive
    && css`
      border: 1px dotted black;
    `}
`;

const Heading = styled.h2`
  margin: 8px 0;
`;

const DropText = styled.p`
  text-align: center;
`;

const DropAreaContainer = styled.div`
  display: grid;
  gap: ${({ hasFiles }) => (hasFiles ? '32px' : '0px')};
  width: 100%;
  margin-bottom: ${({ hasFiles }) => (hasFiles ? '16px' : '0px')};
  grid-template-columns: ${({ hasFiles }) => (hasFiles ? '5fr 7fr' : '1fr')};
  transition: 250ms;

  ${({ isMobile, hasFiles }) => isMobile
    && css`
      grid-template-columns: 1fr;
      grid-template-rows: ${hasFiles ? '1fr auto' : '1fr'};
    `}
`;

const FilesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 48px;
  gap: 16px;
  margin: 8px 0;
`;

// const toBase64 = file =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = error => reject(error);
//   });

const Content = ({ modal }) => {
  const { boxShadowColor } = useContext(ThemeContext);
  const { sendDocumentCount } = useSelector((state) => state.app);
  const [files, setFiles] = useState([]);
  const [shouldConfirmUpload, setConfirmUpload] = useState({ status: 'not-required' });
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.browser.lessThan.medium);
  const { status, error } = useSelector((state) => state.applications.documents);

  useEffect(() => {
    if (sendDocumentCount > 0 && shouldConfirmUpload.status === 'not-required') {
      setConfirmUpload({ status: 'required' });
    }
  }, [sendDocumentCount]);

  useEffect(
    () => () => {
      dispatch(initializeDocumentsState());
    },
    [],
  );

  const onDrop = useCallback((acceptedFiles) => {
    map(acceptedFiles, async (file) => {
      // const b64 = await toBase64(file);
      const fileDetails = {
        fileName: file.name,
        size: file.size,
        category: '',
        description: '',
        fileType: file.type,
        file,
      };
      setFiles((prevFiles) => [...prevFiles, fileDetails]);
    });
  });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/pdf, image/jpeg',
  });

  const removeFile = useCallback((file) => {
    const newFiles = without(files, file);
    setFiles(newFiles);
  });

  const sapErrorMessage = get(error, 'errorData.SapErrorMessage');
  const errorKey = get(error, 'errorKey');
  const errorMessage = sapErrorMessage || errorKey;

  return (
    <Container>
      <Heading>
        <FormattedMessage id="Documents.Modal.Heading" />
      </Heading>
      {shouldConfirmUpload.status !== 'required' ? (
        <>
          <DropAreaContainer
            isMobile={isMobile}
            hasFiles={files.length > 0}
            animate={files.length > 0 ? 'hasFiles' : 'initial'}
          >
            <DropZone isMobile={isMobile} {...getRootProps()} isDragActive={isDragActive}>
              <AiOutlineUpload size={72} color={boxShadowColor} />
              <input {...getInputProps()} />
              <DropText>
                <FormattedMessage id="Documents.DropArea.Text" />
              </DropText>
            </DropZone>
            <FilesContainer>
              {map(files, (file, index) => (
                <File removeFile={() => removeFile(file)} file={file} />
              ))}
            </FilesContainer>
          </DropAreaContainer>
          {status === 'error' && (
            <ModalError
              message={!sapErrorMessage ? <FormattedMessage id={errorMessage} /> : sapErrorMessage}
            />
          )}
          <ButtonRow
            uploading={status === 'uploading'}
            onClose={modal.onClose}
            files={files}
            onSend={() => modal.onSend(files)}
          />
        </>
      ) : (
        <ConfirmUpload setConfirmUpload={setConfirmUpload} modal={modal} />
      )}
    </Container>
  );
};

Content.propTypes = {
  modal: PropTypes.shape({
    onClose: PropTypes.func,
    onSend: PropTypes.func,
  }).isRequired,
};

export default Content;
