import React from 'react';
import { PropTypes } from 'prop-types';
import styled, { css } from 'styled-components';
import ListArrow from './ListArrow';

const BulletWrapper = styled.div`
  line-height: 24px;
  margin-top: 8px;
  display: flex;
  margin-left: 16px;
  ${({ weight }) =>
    weight &&
    css`
      font-weight: ${weight};
    `}
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const Bullet = ({ children, weight }) => (
  <BulletWrapper weight={weight}>
    <Icon>
      <ListArrow />
    </Icon>
    {children}
  </BulletWrapper>
);

Bullet.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  weight: PropTypes.number,
};

Bullet.defaultProps = {
  weight: 400,
};

export default Bullet;
