import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';

import { Label } from '../grid/styled-components';
import TextInput from '../text-input/TextInput';
import Slider from '../slider/Slider';

const Container = styled.div`
  width: 100%;
  margin: 8px 0;
`;

const SliderWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 11px;
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 85px 3fr;
  grid-column-gap: 8px;
`;

const InterestChange = ({
  interestPercent,
  interestMin,
  interestMax,
  initialInterests,
  handleInterestCalculation,
  editable,
}) => {
  const afterChange = (val) => {
    const newInterest = val / 100;

    return handleInterestCalculation(newInterest);
  };

  return (
    <Container>
      <Label>
        <FormattedMessage id="Application.InterestPercent" />
      </Label>
      <Formik
        enableReinitialize
        initialValues={{ interestPercent }}
        render={({ values, setFieldValue }) => {
          const setInterestRate = (newInterestRate) => {
            const returnValue = (typeof newInterestRate === 'string' &&
            newInterestRate.includes(',')
              ? newInterestRate.replace(',', '.') / 100
              : newInterestRate / 100
            ).toFixed(5);

            if (parseFloat(returnValue) > parseFloat(interestMax)) {
              setFieldValue('interestPercent', interestMax);
              return interestMax;
            }
            setFieldValue('interestPercent', returnValue);
            return returnValue;
          };

          return (
            <InputContainer>
              <TextInput
                id="interestPercent"
                name="interestPercent"
                value={new Intl.NumberFormat('de-DE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(values.interestPercent * 100)}
                disabled={!editable}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    const newInterestRate = setInterestRate(e.target.value);
                    handleInterestCalculation(newInterestRate);
                  }
                }}
                onBlur={(e) => {
                  const newInterestRate = setInterestRate(e.target.value);
                  if (parseFloat(interestPercent) !== parseFloat(newInterestRate)) {
                    handleInterestCalculation(newInterestRate);
                  }
                }}
                rightSideText="%"
              />
              <SliderWrapper>
                <Slider
                  min={(interestMin * 1000000) / 10000}
                  max={(interestMax * 1000000) / 10000}
                  disabled={!editable}
                  defaultValue={initialInterests}
                  onChange={val => setFieldValue('interestPercent', val / 100)}
                  onAfterChange={afterChange}
                  value={parseFloat((values.interestPercent * 100).toFixed(5))}
                />
              </SliderWrapper>
            </InputContainer>
          );
        }}
      />
    </Container>
  );
};

InterestChange.propTypes = {
  interestPercent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  interestMin: PropTypes.number.isRequired,
  interestMax: PropTypes.number.isRequired,
  initialInterests: PropTypes.number.isRequired,
  handleInterestCalculation: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
};

export default InterestChange;
