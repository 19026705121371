import { useSelector } from 'react-redux';

export default () => {
  const { roles } = useSelector(state => state.auth.user);
  const sessionStorageRoles = sessionStorage.roles ? sessionStorage.roles.split(',') : [];

  const hasRole = (role) => {
    if (
      (typeof roles === 'object' && roles.indexOf(role) !== -1) ||
      sessionStorageRoles.indexOf(role) !== -1
    ) {
      return true;
    }

    return false;
  };

  return {
    hasRole,
    roles,
  };
};
