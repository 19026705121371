import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Switch from 'react-switch';

import { Label } from '../grid/styled-components';

const Container = styled.div`
  width: 100%;
  margin: 8px 0;
`;

const SwitchWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 4px;

  .react-switch-bg {
    border: 1px solid rgb(150, 150, 150);
  }
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: ${({ flexEnd }) => (flexEnd ? 'flex-end' : 'unset')};
`;

const CommitmentFeeChange = ({
  type, flexEnd, commitmentFeeFlag, onChange, editable,
}) => {
  let messageId = 'CommitmentFee.Loan';

  if (type === 2 || type === 8) {
    messageId = 'CommitmentFee.Lease';
  }

  return (
    <Container>
      <Label>
        <FormattedMessage id={messageId} />
      </Label>
      <InputContainer flexEnd={flexEnd}>
        <SwitchWrapper>
          <Switch
            checked={commitmentFeeFlag}
            onChange={onChange}
            onColor="#fdcb26"
            disabled={!editable}
            uncheckedIcon={false}
            checkedIcon={false}
            aria-label={commitmentFeeFlag ? 'Slökkva á stofngjaldi' : 'Kveikja á stofngjaldi'}
          />
        </SwitchWrapper>
      </InputContainer>
    </Container>
  );
};

CommitmentFeeChange.propTypes = {
  flexEnd: PropTypes.bool.isRequired,
  commitmentFeeFlag: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
};

export default CommitmentFeeChange;
