import ChangePassword from './ChangePassword';
import { NAME } from './constants';
import reducer from './reducer';
import * as actions from './actions';

export default {
  NAME,
  ChangePassword,
  reducer,
  actions,
};
