import React from 'react';
import PropTypes from 'prop-types';

import {
  EmailButton,
  EmailIcon,
  Card,
  CardImage,
  CardTitle,
  CardText,
  CardInfo,
} from './styled-components/Consultant';

const Consultant = ({ isConsultantInfo, consultant, openEmailTo }) => (
  <Card isConsultantInfo={isConsultantInfo} key={consultant.id}>
    <div>
      <CardImage src={consultant.photo} />
      <CardTitle>{consultant.name}</CardTitle>
    </div>
    <CardInfo>
      <CardText>{consultant.phone}</CardText>
      <CardText>{consultant.mobile}</CardText>
    </CardInfo>
    <EmailButton onClick={() => openEmailTo(consultant.email)}>
      <EmailIcon src="/images/icons/envelope.png" alt="mail-icon" />
    </EmailButton>
  </Card>
);

Consultant.propTypes = {
  consultant: PropTypes.object.isRequired,
  openEmailTo: PropTypes.func.isRequired,
  isConsultantInfo: PropTypes.bool,
};

Consultant.defaultProps = {
  isConsultantInfo: false,
};

export default Consultant;
