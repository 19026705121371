import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button } from '../button';

import { Container, Row, Heading, Text } from './styles';

const Logout = ({ handleLogout, cancelModal }) => (
  <Container>
    <Heading>
      <FormattedMessage id="LogoutModal.Heading" />
    </Heading>
    <Text>
      <FormattedMessage id="LogoutModal.Confirmation" />
    </Text>
    <Row>
      <Button onClick={handleLogout} content={<FormattedMessage id="LogoutModal.Yes" />} />
      <Button onClick={cancelModal} content={<FormattedMessage id="LogoutModal.No" />} />
    </Row>
  </Container>
);

Logout.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  cancelModal: PropTypes.func.isRequired,
};

export default Logout;
