import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import Contacts from './Contacts';
import { Row, ContactWrapper } from '../styled-components/Header';
import * as actionTypes from '../actions';

const Consultants = ({
  getConsultants,
  personConsultants,
  companyConsultants,
  isVisible,
  loanType,
  serialNumber,
  year,
  ssn,
}) => {
  useEffect(() => {
    getConsultants();
  }, []);

  return (
    <React.Fragment>
      <Row>
        <ContactWrapper isVisible={isVisible}>
          <Contacts
            loanType={loanType}
            serialNumber={serialNumber}
            year={year}
            ssn={ssn}
            personConsultants={personConsultants}
            companyConsultants={companyConsultants}
          />
        </ContactWrapper>
      </Row>
    </React.Fragment>
  );
};

Consultants.propTypes = {
  getConsultants: PropTypes.func.isRequired,
  personConsultants: PropTypes.array,
  companyConsultants: PropTypes.array,
  isVisible: PropTypes.bool,
  loanType: PropTypes.string,
  serialNumber: PropTypes.string,
  year: PropTypes.string,
  ssn: PropTypes.string,
};

Consultants.defaultProps = {
  personConsultants: [],
  companyConsultants: [],
  isVisible: '',
  loanType: '',
  serialNumber: '',
  year: '',
  ssn: '',
};

function mapStateToProps(state) {
  return {
    personConsultants: state.header.personConsultants,
    companyConsultants: state.header.companyConsultants,
    location: state.router.location,
    loanType: state.header.loanType,
    serialNumber: state.header.serialNumber,
    year: state.header.year,
    ssn: state.header.ssn,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getConsultants: () => dispatch(actionTypes.getConsultants()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(Consultants));
