import { handleActions } from 'redux-actions';

import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
} from './constants';

const initialState = {
  changePasswordSuccess: false,
  error: {},
  hasChangedPassword: false,
  hasStartedAuthCheck: false,
  isSubmitting: false,
};

export default handleActions(
  {
    [CHANGE_PASSWORD_REQUEST]: state => ({
      ...state,
      isSubmitting: true,
      hasChangedPassword: false,
    }),
    [CHANGE_PASSWORD_SUCCESS]: state => ({
      ...state,
      isSubmitting: false,
      hasChangedPassword: true,
    }),
    [CHANGE_PASSWORD_FAILURE]: (state, { error }) => ({
      ...state,
      error,
      isSubmitting: false,
      hasChangedPassword: false,
    }),
  },
  initialState,
);
