import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html, body, #root {
    margin: 0px;
    min-height: 100vh;
    font-family: 'roboto';
    background: ${({ theme }) => theme.backgroundGrey};
    color: ${({ theme }) => theme.primaryTextColor};
  }
  button, p, div, input, th, td, span {
    font-family: 'Arial';
  }
  h2, h2 > span {
    font-size: 35px;
    font-weight: bold;
    color: ${({ theme }) => theme.primaryColor};
    font-family: 'Arial';
  }
  h3, h3 > span {
    font-size: 24px;
    font-weight: 400;
    color: ${({ theme }) => theme.secondaryColor};
    font-family: 'Arial';

    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
  h4, h4 > span {
    font-size: 18px;
    font-family: 'Arial';
    font-weight: 400;
    margin: 0;
    color: ${({ theme }) => theme.secondaryColor};
  }
  #root {
    display: grid;
    grid-template-rows: auto 1fr auto;
  }
`;

export default GlobalStyles;
