import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { replace } from 'lodash';
import * as yup from 'yup';
import { TextInput } from '@origo_vl_banking/ibanking-react';
import kennitala from 'kennitala';

import { EqualColumns, TextName } from '../grid/styled-components';
import { checkSsnType, phoneRegExp } from '../../utils/helpers';
import { validate } from '../../utils/validate';

const validators = yup.object().shape({
  telephone: yup.string().matches(phoneRegExp, 'Form.Error.invalid-phone'),
  email: yup.string().email('Form.Error.invalid-email'),
});

const Applicant = ({
  app,
  applicant,
  handleApplicantChange,
  getApplicantName,
  validationError,
  disabled,
  ssnType,
  ssnInfo,
  restrictApplicant,
  getLeasingApplicantName,
}) => (
  <Formik
    enableReinitialize
    validationSchema={validators}
    initialValues={{
      ...applicant,
      ssn: applicant.ssn || '',
      telephone: applicant.telephone || '',
      email: applicant.email || '',
    }}
    validate={(values) => {
      const error = {};
      const fullSsn = values.ssn.includes('-') && values.ssn.length === 11;
      const shortSsn = !values.ssn.includes('-') && values.ssn.length === 10;
      if ((fullSsn || shortSsn) && !kennitala.isValid(values.ssn)) {
        error.ssn = 'Form.Error.invalid-ssn';
      }

      if (values.ssn && !kennitala.isValid(values.ssn)) {
        error.ssn = 'Form.Error.invalid-ssn';
      }

      if (!values.ssn) {
        error.ssn = 'Form.Error.required-field';
      }

      if (values.telephone.length < 7) {
        error.telephone = 'Form.Error.invalid-phone';
      }

      if (values.telephone && !validate.phoneNumber(values.telephone)) {
        error.telephone = 'Form.Error.invalid-phone';
      }

      if (!values.telephone) {
        error.telephone = 'Form.Error.invalid-phone';
      }

      return error;
    }}
    render={({
 touched, errors, handleChange, handleBlur, values, setFieldError,
}) => {
      const parseValue = (e) => {
        const { value } = e.target;
        if (!value) return null;
        const parsedValue = replace(value, '-', '');
        if (!parsedValue || !kennitala.isValid(parsedValue)) return null;
        return parsedValue;
      };
      const handleApplicantSsn = (e) => {
        const parsedSSN = parseValue(e);
        handleBlur(e);
        getApplicantName(e);

        if (app.type === 2 && parsedSSN) {
          getLeasingApplicantName(parsedSSN).then((res) => {
            if (!res.business) {
              return setFieldError('ssn', 'Form.Error.CompanyOnly');
            }
          });
        }
      };

      const handleApplicantField = (e) => {
        handleChange(e);
        handleApplicantChange(e.target.name, e);
      };

      const showSsnError = () => {
        const fullSsn = values.ssn.includes('-') && values.ssn.length === 11;
        const shortSsn = !values.ssn.includes('-') && values.ssn.length === 10;
        const COMPANY_ONLY_TEXT = 'Form.Error.CompanyOnly';
        const INVALID_SSN_TEXT = 'Form.Error.invalid-ssn';
        const REQUIRED_FIELD_TEXT = 'Form.Error.required-field';

        if (ssnInfo && errors.ssn) {
          return errors.ssn;
        }

        if (touched.ssn && errors.ssn) {
          return errors.ssn;
        }

        if ((fullSsn || shortSsn) && !validate.ssn(values.ssn)) {
          return INVALID_SSN_TEXT;
        }

        if (validationError && touched.ssn && !values.ssn) {
          return REQUIRED_FIELD_TEXT;
        }

        if (
          restrictApplicant === 'person' &&
          values.ssn.length > 1 &&
          ssnInfo.ssn === values.ssn &&
          !ssnInfo.business
        ) {
          return COMPANY_ONLY_TEXT;
        }

        if ((fullSsn || shortSsn) && !touched.ssn) {
          return null;
        }

        return null;
      };

      return (
        <EqualColumns style={{ paddingBottom: '20px' }}>
          <TextInput
            normalize="ssn"
            maxLength={11}
            label="Application.SSN"
            name="ssn"
            height="34px"
            onBlur={handleApplicantSsn}
            onChange={handleChange}
            value={applicant.ssn || values.ssn}
            error={
              app.type === 2
                ? showSsnError()
                : (touched.ssn && errors.ssn) || (!touched.ssn && validationError && !applicant.ssn)
            }
            disabled={disabled}
            star="red"
            width="100%"
            justifyErrorMessage="flex-end"
            errorInLabel
            tabIndex="4"
          />
          <TextName>{applicant.name}</TextName>
          {((values.ssn && checkSsnType(values.ssn) === 'company') || ssnType === 'company' || app.type === 2) && (
            <TextInput
              label="Application.ContactPerson"
              name="contactPerson"
              height="34px"
              onBlur={handleBlur}
              onChange={handleApplicantField}
              value={applicant.contactPerson || ''}
              disabled={disabled}
              tabIndex="5"
            />
          )}
          <TextInput
            label="Application.Telephone"
            name="telephone"
            height="34px"
            type="tel"
            onBlur={handleBlur}
            onChange={handleApplicantField}
            value={applicant.telephone || ''}
            error={
              (touched.telephone && errors.telephone) ||
              (!touched.telephone && validationError && !applicant.telephone)
            }
            disabled={disabled}
            star="red"
            maxLength={7}
            normalize="number"
            autoComplete="tel"
            errorInLabel
            tabIndex="5"
          />
          <TextInput
            label="Application.Email"
            name="email"
            height="34px"
            onBlur={handleBlur}
            onChange={handleApplicantField}
            value={applicant.email || ''}
            error={touched.email && errors.email}
            disabled={disabled}
            errorInLabel
            tabIndex="5"
          />
          {(values.ssn && checkSsnType(values.ssn) === 'company') ||
            ((ssnType !== 'company' && app.type !== 1 && app.type !== 2 && app.type !== 8) && (
              <>
                <TextInput
                  label="Application.Occupation"
                  name="occupation"
                  height="34px"
                  onChange={handleApplicantField}
                  value={applicant.occupation || ''}
                  disabled={disabled}
                  errorInLabel
                  tabIndex="5"
                />
                <TextInput
                  label="Application.Company"
                  name="companyName"
                  height="34px"
                  onChange={handleApplicantField}
                  value={applicant.companyName || ''}
                  disabled={disabled}
                  errorInLabel
                  tabIndex="5"
                />
              </>
            ))}
        </EqualColumns>
      );
    }}
  />
);

Applicant.propTypes = {
  app: PropTypes.object.isRequired,
  applicant: PropTypes.object,
  disabled: PropTypes.bool,
  getApplicantName: PropTypes.func.isRequired,
  getLeasingApplicantName: PropTypes.func,
  handleApplicantChange: PropTypes.func.isRequired,
  restrictApplicant: PropTypes.oneOf(['person', 'company', null]),
  ssnInfo: PropTypes.object,
  ssnType: PropTypes.string,
  validationError: PropTypes.string,
};

Applicant.defaultProps = {
  applicant: {},
  disabled: false,
  getLeasingApplicantName: () => null,
  restrictApplicant: null,
  ssnInfo: {},
  ssnType: '',
  validationError: '',
};

export default Applicant;
