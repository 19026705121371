import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { push } from 'connected-react-router';
import { FiCheck } from 'react-icons/fi';
import { get } from 'lodash';

import styled, { css } from 'styled-components';

import { Table } from '@origo_vl_banking/ibanking-react';
import { normalize } from '@origo_vl_banking/utils';

import { DatePickers } from './components';
import * as actions from './actions';
import * as appActions from '../app/actions';
import { Divider } from '../../components/shared/styled-components';
import AppLoader from '../../components/loader/AppLoader';
import SmartLoader from '../../components/loader/SmartLoader';
import { appType as T } from '../../utils/constants';
import ActionButtons from './components/table-buttons/ActionButtons';
import ApplicationButton from './components/table-buttons/ApplicationButton';
import useDigitalSignModal from '../../hooks/use-digital-sign-modal';
import useMergerMessageModal from '../../components/merger-message-modal/use-merger-message-modal';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;

  @media screen and (max-width: 768px) {
    height: 120px;
  }
`;

const TableContainer = styled.div`
  th {
    font-size: 14px;
    font-weight: 600;
    margin-top: 24px;
  }
  td {
    hyphens: auto;
  }
  th > span {
    color: ${({ theme }) => theme.secondaryColor} !important;
  }

  margin-bottom: 12px;
`;

const ConcatCell = styled.div`
  display: flex;
  flex-direction: column;
`;

const InlineCell = styled.div`
  display: flex;
  align-items: center;
`;

const InlineIcon = styled.div`
  margin-left: 4px;
`;

const Text = styled.span`
  ${({ secondary }) => secondary
    && css`
      padding-top: 4px;
      font-size: 13px;
    `}
`;

const DateAndInfoContainer = styled.section`
  display: flex;
  justify-content: space-between;
`;

const Applications = ({
  startDate,
  endDate,
  getApplications,
  pushTo,
  setPrintInfo,
  getContract,
  intl,
  tableData,
  isFetchingContract,
  isFetchingTableData,
  tableDataLoaded,
  setEndDate,
}) => {
  const { username } = useSelector((state) => state.auth.user);
  const { disposalModal } = useSelector((state) => state.applications);
  const { setShowMergerMessageModal, hasUserDismissedMessage } = useMergerMessageModal();
  useEffect(() => {
    getApplications(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
  }, []);
  useEffect(() => {
    setEndDate(moment());
  }, []);

  useEffect(() => {
    const { messages } = intl;

    const shouldDisplayMessage = get(messages, 'Merger.Notification.Heading');

    if (shouldDisplayMessage) {
      if (!hasUserDismissedMessage(username)) {
        setShowMergerMessageModal(true);
      }
    }
  }, [intl.messages]);

  const {
    modal: { onOpen },
  } = useDigitalSignModal();

  const chooseRoute = (typeCode) => {
    switch (typeCode) {
      case T.carLoan:
        return '/car-loan';
      case T.carLeasing:
        return '/car-leasing';
      case T.carRental:
        return '/car-rental';
      case T.leasing:
        return '/leasing';
      default:
        return '';
    }
  };

  const changeRoute = (item) => {
    let pathname = chooseRoute(item.typeCode);
    const printInfo = {
      printContract: item.printContract,
      printContractRegistry: item.printContractRegistry,
      serialNumber: item.serialNumber,
      year: item.year,
    };

    setPrintInfo(printInfo);
    if (item.isMortgageSwitch) {
      pathname = '/mortgage-switch';
    }

    pushTo({
      pathname,
      state: { serialNumber: item.serialNumber, year: item.year },
    });
  };

  const printPdf = (item, type) => getContract({
    year: item.year,
    serialNumber: item.serialNumber,
    type,
  });

  const signContract = (item) => onOpen(item);

  const translate = (text) => intl.formatMessage({ id: text });

  const data = tableData.map((item) => ({
    date: moment(item.lastChanged).format('DD.MM.YY'),
    statusText: (
      <InlineCell>
        {item.statusText}
        {/* {item.status === '50' && (
          <InlineIcon>
            <FiCheck color="#2ecc71" size={24} />
          </InlineIcon>
        )} */}
      </InlineCell>
    ),
    type: (
      <ConcatCell>
        <Text>{item.typeText}</Text>
        <Text secondary>{item.serialNumber}</Text>
      </ConcatCell>
    ),
    applicant: (
      <ConcatCell>
        <Text>{item.persons.applicant.name}</Text>
        <Text secondary>{normalize.ssn(item.persons.applicant.ssn)}</Text>
      </ConcatCell>
    ),
    ssn: normalize.ssn(item.persons.applicant.ssn),
    carNumber: item.car.number,
    carBrand: item.car.brand,
    sellerName: item.persons.seller.name,
    consultantName: item.persons.consultant.name,
    // actions: actionButtons(item),
    actions: (
      <ActionButtons
        item={item}
        printPdf={printPdf}
        translate={translate}
        signContract={signContract}
      />
    ),
    // not showing but needed for actionButton or search:
    applicantName: item.persons.applicant.name,
    typeText: item.typeText,
    changeLink: item.changeLink,
    typeCode: item.type,
    serialNumber: item.serialNumber,
    year: item.year,
    status: item.status,
    printContract: item.printContract,
    ssnRaw: item.persons.applicant.ssn,
    printContractRegistry: item.printContractRegistry,
    isMortgageSwitch: item.isMortgageSwitch,
    sendContract: item.sendContract,
  }));

  const isLoading = isFetchingContract;

  return (
    <div>
      <h3>
        <FormattedMessage id="sitemap.ApplicationList" />
      </h3>
      <Divider />
      <DateAndInfoContainer>
        <DatePickers startDate={startDate} endDate={endDate} />
        {/* <DigitalSignHelp /> */}
        {' '}
        {/* Hidden for now */}
      </DateAndInfoContainer>
      <SmartLoader loading={isLoading} hide={disposalModal.show} />
      {isFetchingTableData && (
        <LoaderWrapper>
          <AppLoader />
        </LoaderWrapper>
      )}
      {tableDataLoaded && (
        <TableContainer>
          <Table
            search
            sortable
            paging
            data={data}
            columns={[
              'date',
              'statusText',
              'type',
              'applicant',
              'carNumber',
              'carBrand',
              'sellerName',
              'consultantName',
              'actions',
            ]}
            columnsWidth={['6%', '20%', '11%', '18%', '5%', '14%', '14%', 'auto', '10%']}
            mobileColumns={['date', 'type', 'applicant']}
            mobileSize={1280}
            hideMobileDetailsHeader
            searchHeight="34px"
            searchPlaceholder="ApplicationList.SearchPlaceHolder"
            formattedPlaceholder
            rightAlign={['actions']}
            actionButton={(buttonProps) => (
              <ApplicationButton buttonProps={buttonProps} changeRoute={changeRoute} />
            )}
            whiteSpace="normal"
            expandedMobileItemStyle={{
              display: 'grid',
              gridTemplateColumns: '1fr auto',
              height: '100%',
              padding: 0,
            }}
            // conditionalRowStyling={(row) => {
            //   if (row.status === '50') {
            //     return {
            //       backgroundColor: '#2ecc71',
            //     };
            //   }

            //   return null;
            // }}
            // mobileWidth="1280px"
          />
        </TableContainer>
      )}
    </div>
  );
};

Applications.propTypes = {
  pushTo: PropTypes.func.isRequired,
  getApplications: PropTypes.func.isRequired,
  tableData: PropTypes.array.isRequired,
  setPrintInfo: PropTypes.func.isRequired,
  isFetchingTableData: PropTypes.bool.isRequired,
  tableDataLoaded: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  isFetchingContract: PropTypes.bool.isRequired,
  getContract: PropTypes.func.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  setEndDate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    tableData: state.applications.tableData,
    isFetchingTableData: state.applications.isFetchingTableData,
    tableDataLoaded: state.applications.tableDataLoaded,
    isFetchingContract: state.applications.isFetchingContract,
    mobile: state.browser.lessThan.medium,
    startDate: state.applications.startDate,
    endDate: state.applications.endDate,
    signingStatus: state.applications.signingStatus,
  };
}

const mapDispatchToProps = {
  getApplications: actions.getApplications,
  pushTo: (param) => push(param),
  setPrintInfo: appActions.setPrintInfo,
  getContract: actions.getContract,
  setEndDate: actions.setEndDate,
  sendToSigning: actions.sendToSigning,
  getApplication: appActions.getApplication,
  clearSigningStatus: actions.clearSigningStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Applications));
