import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { format } from '@origo_vl_banking/utils';
import { connect } from 'react-redux';

import { SpaceBetween, Label } from '../grid/styled-components';
import { Small } from './styled-components';
import { SmartMonthCalculator, SmartPdfButton } from './';

function SmartCalculator({ app, type }) {
  const { terms, calculation } = app;
  // const { interestChangeAllowed } = calculation;

  return (
    <React.Fragment>
      <SmartMonthCalculator type={type} />

      <SpaceBetween>
        <Label>
          <FormattedMessage id="Application.PlannedFirstPayment" />
        </Label>
        <Label>{format.amount(Math.round(calculation.plannedFirstPayment))} kr.</Label>
      </SpaceBetween>
      <SpaceBetween>
        <Label>
          <FormattedMessage id="Application.FirstDueDate" />
        </Label>
        <Label>{moment(terms.firstDueDate).format('DD.MM.YYYY')}</Label>
      </SpaceBetween>
      <SpaceBetween>
        <Label>
          <FormattedMessage id="Application.CostPercent" />
        </Label>
        <Label>{(calculation.costPercent * 100).toFixed(2)}%</Label>
      </SpaceBetween>
      <SpaceBetween>
        <Label>
          <FormattedMessage id="Application.InterestPercent" />
        </Label>
        {/* {interestChangeAllowed ? (
          <TextInput
            name=""
            width="100px"
            normalize="amount"
            height="34px"
            rightSideText="%"
            align="right"
            disabled={!app.editable}
          />
        ) : ( */}
        <Label>{(calculation.interestPercent * 100).toFixed(2)}%</Label>
        {/* )} */}
      </SpaceBetween>

      <Small>Útreikningur miðast við ISK og að fyrsti vaxtadagur sé dagurinn í dag</Small>

      <SmartPdfButton type={type} />
    </React.Fragment>
  );
}

SmartCalculator.propTypes = {
  app: PropTypes.object.isRequired,
  type: PropTypes.number.isRequired,
};

const mapStateToProps = ({ app }) => ({ app });

export default connect(mapStateToProps)(SmartCalculator);
