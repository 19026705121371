import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik } from 'formik';
import { TextInput } from '@origo_vl_banking/ibanking-react';
import { Label } from '../grid/styled-components';

import { validate } from '../../utils/validate';

const InputWrapper = styled.div`
  grid-area: ${({ gridArea }) => gridArea};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const NameWrapper = styled.div`
  margin-bottom: 10px;
`;


const Seller = ({
  app, seller, handleSeller, validationError, disabled, label,
}) => (
  <Formik
    enableReinitialize
    validate={(values) => {
      const error = {};

      if (values.ssn && !validate.ssn(values.ssn)) {
        error.ssn = 'Form.Error.invalid-ssn';
      }

      if (!values.ssn) {
        error.ssn = 'Form.Error.required-field';
      }

      return error;
    }}
    initialValues={seller}
    render={({
      touched, errors, handleBlur, handleChange, values,
    }) => {
      const handleSellerSsn = (e) => {
        handleBlur(e);
        handleSeller(e);
      };

      const { type } = app;

      return (
        <>
          <InputWrapper gridArea="seller">
            <TextInput
              label={label}
              name="ssn"
              normalize="ssn"
              maxLength={11}
              height="34px"
              value={seller.ssn || ''}
              onBlur={handleSellerSsn}
              onChange={handleChange}
              error={
                (values.ssn && touched.ssn && errors.ssn) ||
                (!touched.ssn && validationError && !seller.ssn)
              }
              disabled={type === 9 ? true : disabled}
              star="red"
              tabIndex="13"
            />
          </InputWrapper>
          <InputWrapper gridArea="sellerName">
            <TextWrapper>
              <Label />
              <NameWrapper>{seller.name}</NameWrapper>
            </TextWrapper>
          </InputWrapper>
        </>
      );
    }}
  />
);

Seller.propTypes = {
  seller: PropTypes.object,
  app: PropTypes.object,
  handleSeller: PropTypes.func,
  disabled: PropTypes.bool,
  validationError: PropTypes.string,
  label: PropTypes.string,
};

Seller.defaultProps = {
  app: {},
  seller: {},
  handleSeller: () => {},
  disabled: false,
  validationError: '',
  label: 'Application.SSN',
};

export default Seller;
