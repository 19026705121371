import styled from 'styled-components';

import BtnLink from './BtnLink';
import { darken } from 'polished';

const Button = styled.button`
  cursor: pointer;
  display: block;
  font-size: inherit;
  height: 40px;
  max-width: 100%;
  padding: 0 16px;
  width: ${({ width }) => width || 'fit-content'};

  &:disabled {
    background: #eee;
    border: 2px solid #eee;
    color: white;
    cursor: not-allowed;
  }
`;

const ButtonPrimary = styled(Button)`
  background-color: ${({ theme }) => theme.primaryColor};
  border: 2px solid ${({ theme }) => theme.primaryColor};
  color: inherit;

  margin-right: 8px;

  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.primaryColor)};
  }
`;

const ButtonSecondary = styled(Button)`
  background-color: ${({ theme }) => theme.borderColor};
  border: 2px solid ${({ theme }) => theme.borderColor};
  color: white;
`;

export { ButtonPrimary, ButtonSecondary, BtnLink };
