import { uniq } from 'lodash';
import * as actionTypes from './constants';
import { Api } from '../../utils';

const getCarTypeRequest = () => ({
  type: actionTypes.GET_CAR_TYPE_REQUEST,
});

const getCarTypeSuccess = (carList, carMainTypes, carSubTypes) => ({
  type: actionTypes.GET_CAR_TYPE_SUCCESS,
  carList,
  carMainTypes,
  carSubTypes,
});

const getCarTypeFailure = error => ({
  type: actionTypes.GET_CAR_TYPE_FAILURE,
  error,
});

export const getCarType = () => (dispatch) => {
  dispatch(getCarTypeRequest());
  Api.getCarType()
    .then((res) => {
      const carList = res.data;
      const types = uniq(carList.map(item => item.carMainType));
      const subTypes = uniq(carList.map(item => item.carSubType));
      const carMainTypes = types.map((item, index) => ({
        key: index,
        value: item,
      }));
      const carSubTypes = subTypes.map((item, index) => ({
        key: index,
        value: item,
      }));
      dispatch(getCarTypeSuccess(carList, carMainTypes, carSubTypes));
    })
    .catch(error => dispatch(getCarTypeFailure(error)));
};

const getCarTypeInfoRequest = () => ({
  type: actionTypes.GET_CAR_TYPE_INFO_REQUEST,
});

const getCarTypeInfoSuccess = data => ({
  type: actionTypes.GET_CAR_TYPE_INFO_SUCCESS,
  data,
});

const getCarTypeInfoFailure = error => ({
  type: actionTypes.GET_CAR_TYPE_INFO_FAILURE,
  error,
});

export const getCarTypeInfo = () => (dispatch, getState) => {
  const { typeId } = getState().app.car;
  dispatch(getCarTypeInfoRequest());
  Api.getCarTypeInfo(typeId.toString())
    .then((res) => {
      dispatch(getCarTypeInfoSuccess(res.data));
      dispatch(getCarType());
    })
    .catch(error => dispatch(getCarTypeInfoFailure(error)));
};

export const clearSelectedCarType = () => ({
  type: actionTypes.CLEAR_SELECTED_CAR_TYPE,
});
