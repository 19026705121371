/* eslint-disable consistent-return */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import GridView from '../../components/grid/GridView';
import SmartLoader from '../../components/loader/SmartLoader';
import {
  SmartHeader,
  SmartMessage,
  SmartApplicants,
  SmartCalculator,
} from '../../components/shared';
import { Terms, Mortgage } from './components';
import {
  getApplication,
  getPreConditions,
  setValidationError,
  postApplication,
  resetSecondApplicantField,
  resetGuarantorFields,
  resetSecondGuarantorFields,
  invalidCalculationMessage,
} from '../app/actions';
import { hasRequired, prepareData } from '../../utils/helpers';
import { appType, appStatus } from '../../utils/constants';

class CarLoan extends Component {
  componentDidMount() {
    const { state } = this.props.location;
    if (state) {
      this.loadApplicationHandler(state.year, state.serialNumber);
    } else {
      this.props.getPreconditions(appType.carLoan);
    }
  }

  loadApplicationHandler = (year, serialNumber) => {
    this.props.loadApplication(year, serialNumber);
  };

  handleApplication = (status) => {
    const {
      app, secondApplicant, guarantor, secondGuarantor, invalidMessage,
    } = this.props;

    if (!secondApplicant.ssn || !secondApplicant) {
      this.props.resetSecondApplicantField();
    }

    if (!guarantor.ssn || !guarantor) {
      this.props.resetGuarantorFields();
    }

    if (!secondGuarantor.ssn || !secondGuarantor) {
      this.props.resetSecondGuarantorFields();
    }
    if (app.terms.cashDeposit > app.terms.price) return;

    if (!app.isCalculationValid) {
      return invalidMessage();
    }

    const data = prepareData(app, status, appType.carLoan);

    if (!hasRequired(data) || app.applicant.telephone.length < 7) {
      return this.props.setValidationError('Form.Error.required-field');
    }

    if (app.secondApplicant.telephone && app.secondApplicant.telephone.length < 7) {
      return null;
    }
    if (app.guarantor.telephone && app.guarantor.telephone.length < 7) {
      return null;
    }
    if (app.secondGuarantor.telephone && app.secondGuarantor.telephone.length < 7) {
      return null;
    }

    return this.props.handlePostApplication(data, this.props.app.serialNumber);
  };

  render() {
    const { editable, statusText, message1, message2 } = this.props.app;
    return (
      <React.Fragment>
        <SmartLoader />
        <SmartHeader
          title="sitemap.CarLoan"
          subtitle={statusText || 'sitemap.Application'}
          message1={message1}
          message2={message2}
        />

        <GridView
          grid1={<Terms title="Application.Terms" />}
          grid2={<Mortgage title="Mortgage.LoanHeader" showInsuranceCompany />}
          grid3={<SmartApplicants title="Application.PersonsHeader" />}
          calculator={<SmartCalculator type={appType.carLoan} />}
          message={<SmartMessage />}
          displayButtons={editable}
          editable={editable}
          handleSaveApplication={() => this.handleApplication(appStatus.save)}
          handleSendApplication={() => this.handleApplication(appStatus.send)}
        />
      </React.Fragment>
    );
  }
}

CarLoan.propTypes = {
  app: PropTypes.object.isRequired,
  secondApplicant: PropTypes.object,
  guarantor: PropTypes.object,
  secondGuarantor: PropTypes.object,
  location: PropTypes.object.isRequired,
  getPreconditions: PropTypes.func.isRequired,
  handlePostApplication: PropTypes.func.isRequired,
  resetSecondApplicantField: PropTypes.func.isRequired,
  resetGuarantorFields: PropTypes.func.isRequired,
  resetSecondGuarantorFields: PropTypes.func.isRequired,
  loadApplication: PropTypes.func.isRequired,
  setValidationError: PropTypes.func.isRequired,
  invalidMessage: PropTypes.func.isRequired,
};

CarLoan.defaultProps = {
  secondApplicant: {},
  guarantor: {},
  secondGuarantor: {},
};

const mapStateToProps = ({ app }) => ({
  app,
  secondApplicant: app.secondApplicant,
  guarantor: app.guarantor,
  secondGuarantor: app.secondGuarantor,
});

const mapDispatchToProps = {
  setValidationError,
  handlePostApplication: postApplication,
  getPreconditions: getPreConditions,
  loadApplication: getApplication,
  resetSecondApplicantField,
  resetGuarantorFields,
  resetSecondGuarantorFields,
  invalidMessage: invalidCalculationMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(CarLoan);
