import { get, map, set } from 'lodash';
import { strip } from '@origo_vl_banking/utils';

export const mapPayload = (values, seller, user) => {
  const payments = [];
  const creditInstitutions = get(values, 'financeDetails.creditInstitutions', {});

  const payToSeller = {
    registryId: seller.ssn, // ??
    paymentCurr: 'ISK', // ?? ok to hardcode?
    paymentAmnt: strip.amount(values.payToSeller.amount),
    bankKey: values.payToSeller.bank,
    ctrlKey: values.payToSeller.ledger,
    bankAcct: values.payToSeller.account,
  };

  const payToDealer = {
    registryId: user.sellerSSN, // ??
    paymentCurr: 'ISK', // ?? ok to hardcode?
    paymentAmnt: strip.amount(values.payToDealer.amount),
    bankKey: values.payToDealer.bank,
    ctrlKey: values.payToDealer.ledger,
    bankAcct: values.payToDealer.account,
  };

  if (values.payToSeller.checked) {
    payments.push(payToSeller);
  }

  if (values.payToDealer.checked) {
    payments.push(payToDealer);
  }

  const payOffs = map(creditInstitutions, (payOff) => {
    const creditInstitution = get(payOff, 'creditInstitution', {
      text: null,
      value: null,
    });
    if (!creditInstitution.text || !creditInstitution.value) {
      return null;
    }
    return {
      partnerId: payOff.creditInstitution.value,
      paymentNote: payOff.licensePlate,
      paymentCurr: 'ISK',
      paymentAmnt: strip.amount(payOff.amount),
    };
  });

  return {
    payments,
    payOffs: payOffs.length > 0 && payOffs.filter((el) => el !== null),
  };
};

const checkPayToValues = (values) => Object.keys(values).filter((key) => {
  const val = values[key];
  if (val === '00' || val === '0000' || val === '000000') {
    return values[key];
  }
  return values[key] === null;
});

const getInsertedAmounts = (values, creditInstitutionsValues) => {
  let insertedAmount = 0;
  if (values.payToDealer.checked) {
    insertedAmount += strip.amount(values.payToDealer.amount);
  }

  if (values.payToSeller.checked) {
    insertedAmount += strip.amount(values.payToSeller.amount);
  }

  map(creditInstitutionsValues, (val) => {
    insertedAmount += strip.amount(val.amount);
  });

  return insertedAmount;
};

export const validateDisposal = (values, calculatedPayout) => {
  const errors = {};
  const creditInstitutionsValues = get(values, 'financeDetails.creditInstitutions', []);
  if (
    !values.payToSeller.checked
    && !values.financeDetails.checked
    && !values.payToDealer.checked
  ) {
    errors.required = 'Form.Error.required-field';
  }

  if (values.payToSeller.checked) {
    // map(checkPayToValues(values.payToSeller), (value) => {
    //   set(errors, `payToSeller.${value}`, 'Form.Error.required-field');
    // });
    if (!values.payToSeller.amount) {
      set(errors, 'payToSeller.amount', 'Form.Error.required-field');
    }
  }

  if (values.payToDealer.checked) {
    // map(checkPayToValues(values.payToDealer), (value) => {
    //   set(errors, `payToDealer.${value}`, 'Form.Error.required-field');
    // });
    if (!values.payToDealer.amount) {
      set(errors, 'payToDealer.amount', 'Form.Error.required-field');
    }
  }

  if (values.financeDetails.checked) {
    map(creditInstitutionsValues, (val, index) => {
      if (!val.creditInstitution.value) {
        // return null;
        set(
          errors,
          `financeDetails.creditInstitutions[${index}].value`,
          'Form.Error.required-field',
        );
      }
      if (!val.licensePlate) {
        set(
          errors,
          `financeDetails.creditInstitutions[${index}].licensePlate`,
          'Form.Error.required-field',
        );
      }
      if (!val.amount) {
        set(
          errors,
          `financeDetails.creditInstitutions[${index}].amount`,
          'Form.Error.required-field',
        );
      }
    });
  }

  const insertedAmount = getInsertedAmounts(values, creditInstitutionsValues);
  if (insertedAmount !== calculatedPayout) {
    errors.notEqual = 'not-equal';
  }

  return errors;
};
