import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaRegFileAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import { Borders } from '../styled-components';
import { Button } from '../../button';
import { ButtonGroup } from '../../shared/styled-components';
import { mdMin } from '../../../utils/constants';
import Fees from '../../fees/Fees';
import useDisposalModal from '../../../hooks/use-disposal-modal';
import useDocumentsModal from '../../../hooks/use-documents-modal';

const SideStyle = styled.div`
  grid-area: sidebar;
  @media (min-width: ${mdMin}) {
    max-width: 350px;
  }
`;

const IconWrapper = styled.div`
  margin-right: 4px;
  display: flex;
  align-items: center;
`;

const ModalButtons = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  margin: 16px 0;  
  justify-content: space-between;
`;

const Sidebar = (props) => {
  const { sendDisposalInformation, sendDocument } = useSelector((state) => state.app);
  const { modal: disposalModal } = useDisposalModal();
  const { modal: documentsModal } = useDocumentsModal();
  const renderModalButtons = sendDisposalInformation === 'C' || sendDisposalInformation === 'D' || sendDocument;
  // const renderDisposalButton = sendDisposalInformation === 'C' || sendDisposalInformation === 'D';
  const renderDisposalButton = sendDisposalInformation === 'C';
  const renderShowDisposalButton = sendDisposalInformation === 'D';

  return (
    <SideStyle>
      <Borders>{props.calculator}</Borders>

      <ModalButtons show={renderModalButtons}>
        {renderDisposalButton && (
          <Button
            primary={false}
            background="hsl(46, 98%, 57%)"
            color="#333"
            style={{ width: '100%', height: 'auto' }}
            border="1px solid transparent"
            onClick={() => disposalModal.onOpen()}
            content={<FormattedMessage id="Disposal.Modal.Button" />}
            contentStyle={{ padding: 8, height: 'auto', fontSize: '14px' }}
          />
        )}

        {sendDocument && (
          <Button
            border="1px solid transparent"
            background="hsl(46, 98%, 57%)"
            color="#333"
            onClick={() => documentsModal.onOpen()}
            content={(
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconWrapper>
                  <FaRegFileAlt size="18px" />
                </IconWrapper>
                <span>
                  <FormattedMessage id="Documents.Modal.Button" />
                </span>
              </div>
            )}
          />
        )}

        {renderShowDisposalButton && (
          <Button
            primary={false}
            background="hsl(46, 98%, 57%)"
            color="#333"
            border="1px solid transparent"
            onClick={() => disposalModal.onOpen()}
            content={<FormattedMessage id="Disposal.Modal.View" />}
            contentStyle={{ padding: 8, height: 'auto', fontSize: '14px' }}
          />
        )}

      </ModalButtons>
      <Fees editable={props.editable} />
      <ButtonGroup editable={props.displayButtons}>
        <Button
          // style={{ marginBottom: '8px' }}
          primary={false}
          background="#bfbfbf"
          color="white"
          border="1px solid transparent"
          hoverColor="#b3b3b3"
          onClick={props.handleSaveApplication}
          content={<FormattedMessage id="Application.CreateButton" />}
        />
        <Button
          border="1px solid transparent"
          onClick={props.handleSendApplication}
          content={<FormattedMessage id="Application.SendButton" />}
        />
      </ButtonGroup>
      {/* {props.message} */}
    </SideStyle>
  );
};

Sidebar.propTypes = {
  calculator: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  handleSaveApplication: PropTypes.func.isRequired,
  handleSendApplication: PropTypes.func.isRequired,
  displayButtons: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
};

Sidebar.defaultProps = {};

export default Sidebar;
