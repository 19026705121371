// responsive-breakpoints
export const smMin = '576px';
export const mdMin = '768px';
export const lgMin = '992px';
export const xlMin = '1280px';

export const appType = {
  carLoan: 1,
  leasing: 2,
  itemChange: 3,
  debtorChange: 4,
  unknown: 5,
  interestFreeCarLoan: 6,
  fixedInterestLoan: 7,
  carLeasing: 8,
  carRental: 9,
};

export const appStatus = {
  save: '05',
  send: '10',
};
