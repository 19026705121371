import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { EyeOpen } from '@origo_vl_banking/icons';
import { TiPencil } from 'react-icons/ti';

const ChangeButton = styled.div`
  width: 42px;
  display: flex;
  justify-content: center;
  @media (max-width: 1280px) {
    width: 35px;
  }

  @media (max-width: 580px) {
    width: 24px;
  }
`;

const OuterContainer = styled.div`
  display: table-cell;
  width: 32px;
  border-color: #f5f5f5;
  line-height: 100%;
  height: 48px;

  @media (max-width: 1280px) {
    height: 100%;
  }
  @supports (-moz-appearance: meterbar) {
    /* Firefox */
    height: 100%;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  background: #fdcd28;
  height: 100%;
  padding: 0 3px;
  @supports (-moz-appearance: meterbar) {
    /* Firefox */
    vertical-align: top;
  }
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'disabled')};
`;

const ApplicationActionButton = ({
  changeLink, disabled, changeRoute, buttonProps,
}) => (
  <OuterContainer onClick={() => !disabled && changeRoute(buttonProps)}>
    <InnerContainer>
      {changeLink ? (
        <ChangeButton>
          <TiPencil size="26px" color="#3f3f3f" />
        </ChangeButton>
      ) : (
        <EyeOpen color="#3f3f3f" />
      )}
    </InnerContainer>
  </OuterContainer>
);

ApplicationActionButton.propTypes = {
  changeLink: PropTypes.bool.isRequired,
};

export default ApplicationActionButton;
