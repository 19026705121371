import { get } from 'lodash';
import thunk from 'redux-thunk';
import { notifications } from '.';
import { initializeDisposalState, initializeDocumentsState } from '../modules/applications/actions';
import { logout, startCheckAuth } from '../modules/auth/actions';
// import { setEndDate } from '../modules/applications/actions';
// import moment from 'moment';

export const middleware = [];
middleware.push(thunk);

const checkAuth = store => next => (action) => {
  if (
    !action.error &&
    action.type !== 'auth/LOGIN_FAILURE' &&
    action.type !== 'auth/LOGOUT_FAILURE' &&
    action.type !== 'auth/START_CHECK_AUTH' &&
    action.type !== 'redux-responsive/CALCULATE_RESPONSIVE_STATE'
  ) {
    if (!store.getState().auth.hasStartedAuthCheck) {
      store.dispatch(startCheckAuth());
    }
  }

  return next(action);
};

const handleModalsOnRouting = store => next => (action) => {
  const { documentsModal, disposalModal } = store.getState().applications;
  if (action.type.includes('router/LOCATION_CHANGE')) {
    if (documentsModal.show) {
      store.dispatch(initializeDocumentsState());
    }
    if (disposalModal.show) {
      store.dispatch(initializeDisposalState());
    }
  }

  return next(action);
};

// const handleEndDate = store => next => (action) => {
//   if (action.type === '@@router/LOCATION_CHANGE') {
//     if (action.payload && action.payload.location) {
//       if (
//         action.payload.location.pathname === '/' ||
//         action.payload.location.pathname === '/overview'
//       ) {
//         store.dispatch(setEndDate(moment()));
//       }
//     }
//   }

//   return next(action);
// };

const handleApiError = store => next => (action) => {
  if (action.error) {
    const { isAuthenticated } = store.getState().auth;
    if (
      action.error.status &&
      action.error.status === 401 &&
      // action.type !== 'auth/LOGIN_FAILURE' &&
      !action.type.includes('auth')
    ) {
      if (isAuthenticated) {
        store.dispatch(logout());
      }
    } else if (
      action.error.errorKey === 'Error.User.AuthorizationError' &&
      !action.type.includes('auth')
    ) {
      if (isAuthenticated) {
        store.dispatch(logout());
      }
    } else if (action.error.severity && action.error.severity === 'E') {
      const displayNotification = get(action, 'displayNotification', true);
      if (displayNotification) {
        if (action.error.errorData.SapErrorMessage) {
          store.dispatch(notifications.errorNotification(action.error.errorData.SapErrorMessage));
        } else {
          store.dispatch(notifications.errorNotification(action.error.errorKey));
        }
      }
    }
  }
  return next(action);
};

middleware.push(handleApiError);
middleware.push(handleModalsOnRouting);
middleware.push(checkAuth);
// middleware.push(handleEndDate);
