import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Checkbox from './Checkbox';
import PayToForm from './PayToForm';
import AnimatedSection from './AnimatedSection';

const Container = styled.div``;

const PayTo = ({ formProps, formId, disabled }) => {
  const {
    seller,
    terms: { calculatedPayout },
  } = useSelector(state => state.app);
  const { user } = useSelector(state => state.auth);
  const { values, handleChange, handleBlur } = formProps;
  const value = values[formId];

  const recipientInfo = {
    name: formId === 'payToSeller' ? seller.name : user.dealershipName,
    ssn: formId === 'payToSeller' ? seller.ssn : user.sellerSSN,
  };

  return (
    <Container>
      <Checkbox
        type="checkbox"
        onChange={handleChange}
        onBlur={handleBlur}
        name={`${formId}.checked`}
        id={`${formId}.checked`}
        checked={value.checked}
        label={
          <FormattedMessage
            id="Disposal.Label.PayTo.Entity"
            values={{ name: recipientInfo.name }}
          />
        }
        formatted={false}
        disabled={disabled}
      />
      <AnimatedSection isOpen={value.checked}>
        <PayToForm
          disabled={disabled}
          recipientInfo={recipientInfo}
          formId={formId}
          formProps={formProps}
          calculatedPayout={calculatedPayout}
        />
      </AnimatedSection>
    </Container>
  );
};

PayTo.propTypes = {
  formProps: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  }).isRequired,
  formId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default PayTo;
