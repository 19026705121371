import React from 'react';

import Modal from '../modal/Modal';
import Content from './MergerMessageContent';
import useMergerMessageModal from './use-merger-message-modal';

const MergerMessageModal = () => {
  const { modal, showModal } = useMergerMessageModal();

  return (
    <Modal
      customContentStyle={{ overflow: 'inherit', maxWidth: 576 }}
      content={<Content modal={modal} />}
      showModal={showModal}
      onClose={modal.onClose}
      modalProps={{ shouldCloseOnOverlayClick: false, shouldCloseOnEsc: false }}
    />
  );
};

MergerMessageModal.propTypes = {};

export default MergerMessageModal;
