import React from 'react';
import { PropTypes } from 'prop-types';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

const Ring = styled.div`
  display: inline-block;
  position: relative;
  width: ${({ size }) => size};
  height: ${({ size }) => size};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 6px;
    border: ${({ borderSize }) => borderSize} solid #fdcd28;
    border-radius: 50%;
    animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fdcd28 transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

const AppLoader = ({ size, borderSize }) => (
  <Ring size={size} borderSize={borderSize}>
    <div />
    <div />
    <div />
    <div />
  </Ring>
);

AppLoader.propTypes = {
  size: PropTypes.string,
  borderSize: PropTypes.string,
};

AppLoader.defaultProps = {
  size: '256px',
  borderSize: '24px',
};

export default AppLoader;
