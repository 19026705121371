// Name of this module
export const NAME = 'applications';

export const TABLE_DATA_REQUEST = `${NAME}/TABLE_DATA_REQUEST`;
export const TABLE_DATA_SUCCESS = `${NAME}/TABLE_DATA_SUCCESS`;
export const TABLE_DATA_FAILURE = `${NAME}/TABLE_DATA_FAILURE`;

export const GET_CONTRACT_REQUEST = `${NAME}/GET_CONTRACT_REQUEST`;
export const GET_CONTRACT_SUCCESS = `${NAME}/GET_CONTRACT_SUCCESS`;
export const GET_CONTRACT_FAILURE = `${NAME}/GET_CONTRACT_FAILURE`;

export const SEND_TO_SIGNING_REQUEST = `${NAME}/SEND_TO_SIGNING_REQUEST`;
export const SEND_TO_SIGNING_SUCCESS = `${NAME}/SEND_TO_SIGNING_SUCCESS`;
export const SEND_TO_SIGNING_FAILURE = `${NAME}/SEND_TO_SIGNING_FAILURE`;

export const SET_START_DATE = `${NAME}/SET_START_DATE`;
export const SET_END_DATE = `${NAME}/SET_END_DATE`;
export const CLEAR_SIGNING_STATUS = `${NAME}/CLEAR_SIGNING_STATUS`;
export const SET_SHOW_DIGITAL_SIGN_MODAL = `${NAME}/SET_SHOW_DIGITAL_SIGN_MODAL`;
export const SET_SHOW_DISPOSAL_MODAL = `${NAME}/SET_SHOW_DISPOSAL_MODAL`;
export const SET_SHOW_DOCUMENTS_MODAL = `${NAME}/SET_SHOW_DOCUMENTS_MODAL`;
export const SET_APPLICATION_TO_SIGN = `${NAME}/SET_APPLICATION_TO_SIGN`;
export const SET_SHOW_MERGER_MESSAGE_MODAL = `${NAME}/SET_SHOW_MERGER_MESSAGE_MODAL`;

export const GET_CREDIT_INSTITUTIONS_REQUEST = `${NAME}/GET_CREDIT_INSTITUTIONS_REQUEST`;
export const GET_CREDIT_INSTITUTIONS_SUCCESS = `${NAME}/GET_CREDIT_INSTITUTIONS_SUCCESS`;
export const GET_CREDIT_INSTITUTIONS_FAILURE = `${NAME}/GET_CREDIT_INSTITUTIONS_FAILURE`;

export const GET_DISPOSAL_INFORMATION_REQUEST = `${NAME}/GET_DISPOSAL_INFORMATION_REQUEST`;
export const GET_DISPOSAL_INFORMATION_SUCCESS = `${NAME}/GET_DISPOSAL_INFORMATION_SUCCESS`;
export const GET_DISPOSAL_INFORMATION_FAILURE = `${NAME}/GET_DISPOSAL_INFORMATION_FAILURE`;

export const POST_DISPOSAL_INFORMATION_REQUEST = `${NAME}/POST_DISPOSAL_INFORMATION_REQUEST`;
export const POST_DISPOSAL_INFORMATION_SUCCESS = `${NAME}/POST_DISPOSAL_INFORMATION_SUCCESS`;
export const POST_DISPOSAL_INFORMATION_FAILURE = `${NAME}/POST_DISPOSAL_INFORMATION_FAILURE`;

export const UPLOAD_DOCUMENTS_REQUEST = `${NAME}/UPLOAD_DOCUMENTS_REQUEST`;
export const UPLOAD_DOCUMENTS_SUCCESS = `${NAME}/UPLOAD_DOCUMENTS_SUCCESS`;
export const UPLOAD_DOCUMENTS_FAILURE = `${NAME}/UPLOAD_DOCUMENTS_FAILURE`;

export const INITIALIZE_DISPOSAL_STATE = `${NAME}/INITIALIZE_DISPOSAL_STATE`;
export const INITIALIZE_DOCUMENTS_STATE = `${NAME}/INITIALIZE_DOCUMENTS_STATE`;
