import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { TextInput } from '@origo_vl_banking/ibanking-react';

import { EqualColumns, TextName } from '../grid/styled-components';
import { validate } from '../../utils/validate';

const validators = yup.object().shape({
  telephone: validate.formik.telephone,
  email: yup.string().email('Form.Error.invalid-email'),
});

const SecondGuarantor = ({
  app,
  secondGuarantor,
  handleSecondGuarantorName,
  handleSecondGuarantorChange,
  disabled,
  validationError,
}) => (
  <Formik
    enableReinitialize
    validationSchema={validators}
    initialValues={{
      ssn: secondGuarantor.ssn || '',
      telephone: secondGuarantor.telephone || '',
      email: secondGuarantor.email || '',
    }}
    validate={(values) => {
      const error = {};

      if (values.ssn && !validate.ssn(values.ssn)) {
        error.ssn = 'Form.Error.invalid-ssn';
      }

      if (values.ssn && values.telephone.length < 7) {
        error.telephone = 'Form.Error.invalid-phone';
      }

      if (values.ssn && !values.telephone) {
        error.telephone = 'Form.Error.required-field';
      }

      return error;
    }}
    render={({
 touched, errors, handleBlur, handleChange,
}) => {
      const handleSecondGuarantorSsn = (e) => {
        handleBlur(e);
        handleSecondGuarantorName(e);
      };

      const handleSecondGuarantorField = (e) => {
        handleChange(e);
        handleSecondGuarantorChange(e.target.name, e);
      };

      return (
        <EqualColumns style={{ paddingBottom: '20px' }}>
          <TextInput
            label="Application.SSN"
            normalize="ssn"
            maxLength={11}
            name="ssn"
            height="34px"
            onBlur={handleSecondGuarantorSsn}
            onChange={handleChange}
            value={secondGuarantor.ssn || ''}
            error={touched.ssn && errors.ssn}
            disabled={disabled}
            width="100%"
            tabIndex="8"
            justifyErrorMessage="flex-end"
            errorInLabel
          />
          <TextName>{secondGuarantor.name}</TextName>
          <TextInput
            type="tel"
            maxLength={7}
            normalize="number"
            label="Application.Telephone"
            name="telephone"
            height="34px"
            onBlur={handleBlur}
            onChange={handleSecondGuarantorField}
            value={secondGuarantor.telephone || ''}
            error={
              (touched.telephone && errors.telephone) ||
              (!touched.telephone &&
                secondGuarantor.ssn &&
                !secondGuarantor.telephone &&
                validationError)
            }
            disabled={disabled}
            star="red"
            pattern="\d*"
            tabIndex="9"
            justifyErrorMessage="flex-end"
            errorInLabel
          />
          <TextInput
            label="Application.Email"
            name="email"
            height="34px"
            onBlur={handleBlur}
            onChange={handleSecondGuarantorField}
            value={secondGuarantor.email || ''}
            error={touched.email && errors.email}
            disabled={disabled}
            tabIndex="9"
            justifyErrorMessage="flex-end"
            errorInLabel
          />
        </EqualColumns>
      );
    }}
  />
);

SecondGuarantor.propTypes = {
  secondGuarantor: PropTypes.object,
  handleSecondGuarantorName: PropTypes.func,
  handleSecondGuarantorChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  validationError: PropTypes.string,
};

SecondGuarantor.defaultProps = {
  secondGuarantor: {},
  handleSecondGuarantorName: () => {},
  disabled: false,
  validationError: '',
};

export default SecondGuarantor;
