import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';
import DocumentsModal from '../components/documents-modal/DocumentsModal';
import {
  initializeDocumentsState,
  setShowDocumentsModal,
  uploadDocuments,
} from '../modules/applications/actions';

export default () => {
  const { documentsModal, documents } = useSelector(state => state.applications);
  const {
    year,
    serialNumber,
    applicant: { ssn },
    sendDocumentCount,
  } = useSelector(state => state.app);
  const dispatch = useDispatch();
  useMemo(
    () => () => {
      if (documents.status !== 'initial') {
        dispatch(initializeDocumentsState());
      }
    },
    [documents.status],
  );
  const modal = {
    onClose: () => {
      dispatch(setShowDocumentsModal(false));
    },
    onCancel: () => {
      dispatch(setShowDocumentsModal(false));
    },
    onOpen: () => {
      dispatch(setShowDocumentsModal(true));
    },
    onSend: async (docs) => {
      const formData = new FormData();

      formData.append('serialNumber', serialNumber);
      formData.append('year', year);
      formData.append('ssn', ssn);

      map(docs, (file, index) => {
        formData.append(index, file.file, file.fileName);
        formData.append(`${index}category`, file.category);
        formData.append(`${index}description`, file.description);
      });

      // dispatch(uploadDocuments(formData));

      const upload = await dispatch(uploadDocuments(formData));
      if (!upload.errorData) {
        dispatch(setShowDocumentsModal(false));
      }
    },
  };

  return {
    modal,
    showModal: documentsModal.show,
    DocumentsModal,
    sendDocumentCount,
  };
};
