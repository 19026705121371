import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    &:nth-child(1) {
      flex-direction: column-reverse;
    }

    &:nth-child(2) {
      flex-direction: column;
    }
  }
`;

const Content = styled.div`
  width: 450px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ContentList = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding-left: 60px;
  padding-right: 20px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 16px;
  }
`;

const ListTitle = styled.h3`
  font-size: 24px;
  font-weight: 400;
  color: #51616f;
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const ListItem = styled.li`
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  font-size: 20px;
`;

const Text = styled.p`
  font-size: 20px;
`;

const ArrowIcon = styled.img`
  height: 20px;
  margin-right: 16px;
`;

const FormContent = styled.div`
  padding: 16px 0;
  margin: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ImageBackground = styled.div`
  background-image: ${({ image }) =>
    (image ? `url(${image})` : "url('/images/banner1.jpg')")};
  width: 100%;
  height: 550px;
  background-size: cover;

  @media (max-width: 768px) {
    height: 200px;
    padding-top: 36px;
  }
`;

const ImageText = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  text-align: center;
`;

const ImageTitle = styled.div`
  color: rgb(255, 255, 255);
  font-size: 80px;
  text-shadow: rgb(0, 0, 0) 3px 1px;

  @media (max-width: 768px) {
    font-size: 50px;
  }
`;

const ImageSubtitle = styled.div`
  color: rgb(255, 255, 255);
  font-size: 46px;
  margin-top: -5px;
  text-shadow: rgb(0, 0, 0) 3px 1px;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const FormHeader = styled.h3`
  color: #51616f;
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 8px;
`;

export {
  Row,
  Content,
  ContentList,
  FormHeader,
  FormContent,
  ImageBackground,
  ImageText,
  ImageTitle,
  ImageSubtitle,
  ListTitle,
  List,
  ListItem,
  Text,
  ArrowIcon,
};
