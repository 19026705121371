import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { FaRegFilePdf, FaRegFileAlt } from 'react-icons/fa';

const DocumentWrapper = styled.div`
  display: flex;
  padding: 16px 8px;

  /* max-width: 250px; */
  cursor: pointer;
  border-left: 3px solid transparent;
  &:hover {
    /* font-weight: 600; */

    background: ${({ theme }) => theme.backgroundGrey};
    border-left: 3px solid ${({ theme }) => theme.primaryColor};
  }
`;
const FileName = styled.div`
  /* margin-left: 8px; */
  font-size: 16px;
  height: 24px;
  line-height: 24px;
  color: ${({ theme }) => theme.secondaryColor};
`;

const IconWrapper = styled.div`
  padding-right: 12px;
`;

const Document = ({
  name, isPDF, link, onClick,
}) => (
  <DocumentWrapper onClick={onClick} href={link}>
    <IconWrapper>
      {isPDF ? (
        <FaRegFilePdf color="hsl(209, 21%, 32%)" size={20} />
      ) : (
        <FaRegFileAlt color="hsl(209, 21%, 32%)" size={20} />
      )}
    </IconWrapper>
    <FileName>{name}</FileName>
  </DocumentWrapper>
);

Document.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  isPDF: PropTypes.bool,
  onClick: PropTypes.func,
};

Document.defaultProps = {
  link: null,
  isPDF: false,
  onClick: () => null,
};

export default Document;
