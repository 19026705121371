import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { format } from '@origo_vl_banking/utils';

import {
  TwoColumns,
  RowWithBorders,
  TextRight,
  Line,
  Label,
} from '../../../components/grid/styled-components';
import { SmartTerms } from '../../../components/shared';
import { appType } from '../../../utils/constants';

const Terms = ({ app }) => {
  const { terms, fees } = app;

  const calculatePayout = () =>
    terms.price - terms.cashDeposit - (terms.priceDepositItem - terms.accruedDepositItem);

  const calculateTotal = () => {
    const { administrationFee, registrationFee, commitmentFee } = fees;
    return calculatePayout(terms) + administrationFee + registrationFee + commitmentFee;
  };

  const calculateTotalDeposit = () => {
    const totalDeposit = terms.cashDeposit + (terms.priceDepositItem - terms.accruedDepositItem);
    return totalDeposit;
  };

  const calcDepositPercentage = () => {
    const totalDepositPercent =
      ((terms.cashDeposit + (terms.priceDepositItem - terms.accruedDepositItem)) / terms.price) *
      100;
    if (totalDepositPercent < 0) return '';
    return `${totalDepositPercent.toFixed(2)}%`;
  };

  return (
    <>
      <SmartTerms type={appType.carLeasing} />

      <TwoColumns>
        <Line />
        <Label>
          <FormattedMessage id="Application.PaidWithMortgage" />
        </Label>
        <TextRight>{format.amount(app.mortgageAmount) || 0} kr.</TextRight>

        <Label>
          <FormattedMessage id="Application.TotalDeposit" />
          &nbsp;
          <span>{terms.cashDeposit ? calcDepositPercentage() : ''}</span>
        </Label>
        <TextRight>{format.amount(calculateTotalDeposit())} kr.</TextRight>

        <Label>
          <FormattedMessage id="Application.AdministrationFee" />
        </Label>
        <TextRight>{format.amount(fees.administrationFee)} kr.</TextRight>

        <Label>
          <FormattedMessage id="Application.StartingFee" />
          <span style={{ marginLeft: 4 }}>
            &nbsp;
            {fees.commitmentFeePercent
              ? `${parseFloat(fees.commitmentFeePercent * 100).toFixed(2)}%`
              : null}{' '}
          </span>
        </Label>
        <TextRight>{format.amount(fees.commitmentFee)} kr.</TextRight>

        <RowWithBorders>
          <TwoColumns style={{ fontWeight: 'bold', height: '34px' }}>
            <FormattedMessage id="Application.TotalContractAmount" />
            <TextRight>{format.amount(calculateTotal())} kr.</TextRight>
          </TwoColumns>
        </RowWithBorders>

        {/* {calculation.saleDiscount ? (
          <React.Fragment>
            <Label>Afoll</Label>
            <TextRight>
              {format.amount((calculateTotal()) - calculation.saleDiscount)} kr.
            </TextRight>
          </React.Fragment>
        ) : null} */}
        <Label>
          <FormattedMessage id="Application.ContractPayoutAmount" />
        </Label>
        <TextRight>{format.amount(calculatePayout())} kr.</TextRight>
      </TwoColumns>
    </>
  );
};

Terms.propTypes = {
  app: PropTypes.object.isRequired,
};

const mapStateToProps = ({ app }) => ({ app });

export default connect(mapStateToProps)(Terms);
