import styled from 'styled-components';
import { motion } from 'framer-motion';
import { mdMin, xlMin } from '../../../utils/constants';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  text-align: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 300px;
  justify-content: space-between;
`;

const Menu = styled.div`
  background-color: white;
  border-bottom: none;
  right: 0;
  overflow: hidden;
`;

const Head = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  height: 74px;
  padding: 16px;
  z-index: 99;

  @media (min-width: ${mdMin}) {
    /* width: 150px; */
  }
`;

const BurgerTitle = styled.span`
  font-size: 19px;
  line-height: 80px;
  color: #51616f;

  @media all and (max-width: ${xlMin}) {
    display: none;
  }
`;

const BurgerIcon = styled.span`
  color: #51616f;
  font-size: 34px;
  line-height: 0;

  @media all and (min-width: ${xlMin}) {
    display: none;
  }
`;

const Body = styled(motion.div)`
  /* height: ${props => (props.isClosed ? '0' : '390px')}; */
  /* transition: 0.2s ease-out; */
  /* visibility: ${props => (props.isClosed ? 'hidden' : 'visible')}; */
  padding: 0 8px 8px;
  position: absolute;
  background: white;
  border: 1px solid #ddd;
  overflow: hidden;
  top: 80px;
  right: 0;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  z-index: -1;
  @media (min-width: ${mdMin}) {
    width: 350px;
  }

  @media (min-width: ${xlMin}) {
    width: 260px;
  }
`;

const MenuTitle = styled.h3`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 12px;
  font-size: 16px;
  color: black;
  cursor: default;
  border-left: 2px solid transparent;
`;

const Item = styled.div`
  padding: 12px;
  font-size: 16px;
  cursor: pointer;
  border-left: 3px solid transparent;
  color: ${({ theme }) => theme.secondaryColor};
  &:hover {
    /* font-weight: 600; */
    border-left: 3px solid #fdcd28;
    background: ${({ theme }) => theme.backgroundGrey};
  }
`;

const Icon = styled.span`
  font-size: 28px;
  color: ${({ theme }) => theme.primaryColor};
`;

export { Container, Row, Menu, Head, BurgerTitle, BurgerIcon, Body, MenuTitle, Item, Icon };
