import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sanitize } from 'dompurify';

import { Label, Borders } from '../grid/styled-components';
import { setCommunication } from '../../modules/app/actions';

const SmartMessage = (props) => {
  const [communication, setCommunicationState] = useState(sanitize(props.app.communication));
  useEffect(() => {
    setCommunicationState(sanitize(props.app.communication));
  }, [props.app.communication]);
  return (
    <>
      {/* <p style={{ margin: '10px 0' }}>
        <h3>
          <FormattedMessage id="Messages" />
        </h3>
      </p> */}
      <Borders>
        <textarea
          style={{ width: '100%', height: '220px' }}
          value={sanitize(communication)}
          onChange={e => setCommunicationState(e.target.value)}
          onBlur={e => props.setCommunication(e.target.value)}
          disabled={!props.app.editable}
          tabIndex="17"
        />
      </Borders>
    </>
  );
};

SmartMessage.propTypes = {
  app: PropTypes.object.isRequired,
  setCommunication: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app }) => ({ app });

const mapDispatchToProps = { setCommunication };

export default connect(mapStateToProps, mapDispatchToProps)(SmartMessage);
