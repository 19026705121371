import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { Popover as TinyPopover } from 'react-tiny-popover';

const PopoverContainer = styled.div`
  background: #000a;
  color: white;
  padding: 12px;
  margin-bottom: 8px;
  font-size: 14px;
`;

const Popover = ({ isOpen, content, children }) => (
  <TinyPopover isOpen={isOpen} content={<PopoverContainer>{content}</PopoverContainer>}>
    {children}
  </TinyPopover>
);

Popover.propTypes = {};

export default Popover;
