import styled from 'styled-components';

import { mdMin, lgMin } from '../../../utils/constants';

const DatePickerContainer = styled.div`
  display: grid;
  align-items: end;
  gap: 16px;
  padding: 16px 0px 32px 0px;

  * > label > span {
    color: ${({ theme }) => theme.secondaryColor};
  }

  @media (min-width: ${mdMin}) {
    grid-template-columns: 115px 115px minmax(80px, 180px) auto auto;
  }

  @media (min-width: ${lgMin}) {
    grid-template-columns: 115px 115px minmax(80px, 180px) auto auto;
  }
`;

export default DatePickerContainer;
