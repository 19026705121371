import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { normalize } from '@origo_vl_banking/utils';
import TextInput from '../../text-input/TextInput';
import zeroPad from '../zeroPad';

const Container = styled.div`
  display: grid;
  grid-template-areas: 'name' 'bank' 'amount';
  margin: 16px 0;
  column-gap: 8px;
  row-gap: 16px;
`;

const Name = styled.h4`
  margin: 0;
  font-weight: 600;
`;

const IdentificationNumber = styled.h4`
  margin: 0;
`;

const PayerInfo = styled.div`
  display: flex;
  grid-area: name;
  flex-direction: column;
`;

const Bank = styled.div`
  grid-area: bank;

  display: grid;
  grid-template-columns: 2fr 1fr 3fr;
  gap: 8px;
`;

const Amount = styled.div`
  grid-area: amount;

  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr 3fr;
  gap: 8px;

  grid-template-areas: '. label input';
`;

const AmountLabel = styled.label`
  text-align: right;
  grid-area: label;
  font-size: 14px;
  color: ${({ theme }) => theme.secondaryColor};
  font-weight: 600;
`;

const AmountInput = styled.div`
  grid-area: input;
`;

const PayToForm = ({
  disabled,
  recipientInfo,
  formId,
  formProps: {
    handleChange, handleBlur, setFieldValue, values, errors, touched, setTouched,
  },
}) => (
  <Container>
    <PayerInfo>
      <Name>{get(recipientInfo, 'name', '')}</Name>
      <IdentificationNumber>{normalize.ssn(get(recipientInfo, 'ssn', ''))}</IdentificationNumber>
    </PayerInfo>
    <Bank>
      <TextInput
        disabled={disabled}
        label="Disposal.Label.Bank"
        name={`${formId}.bank`}
        id={`${formId}.bank`}
        onChange={handleChange}
        error={get(touched, `[${formId}.bank]`, false) && get(errors, `[${formId}.bank]`, false)}
        onBlur={e => zeroPad(e, 4, setFieldValue, setTouched, touched)}
        maxLength={4}
        value={values[formId].bank || ''}
        tabTo={`${formId}.ledger`}
        align="right"
      />
      <TextInput
        disabled={disabled}
        label="Disposal.Label.Ledger"
        name={`${formId}.ledger`}
        id={`${formId}.ledger`}
        onChange={handleChange}
        onBlur={e => zeroPad(e, 2, setFieldValue, setTouched, touched)}
        error={
          get(touched, `[${formId}.ledger]`, false) && get(errors, `[${formId}.ledger]`, false)
        }
        maxLength={2}
        tabTo={`${formId}.account`}
        value={values[formId].ledger || ''}
        align="right"
      />
      <TextInput
        disabled={disabled}
        label="Disposal.Label.Account"
        name={`${formId}.account`}
        id={`${formId}.account`}
        onChange={handleChange}
        error={
          get(touched, `[${formId}.account]`, false) && get(errors, `[${formId}.account]`, false)
        }
        onBlur={e => zeroPad(e, 6, setFieldValue, setTouched, touched)}
        maxLength={6}
        value={values[formId].account || ''}
        tabTo={`${formId}.amount`}
        align="right"
      />
    </Bank>
    <Amount>
      <AmountLabel>
        <FormattedMessage id="Disposal.Label.Amount" />
      </AmountLabel>
      <AmountInput>
        <TextInput
          disabled={disabled}
          name={`${formId}.amount`}
          id={`${formId}.amount`}
          type="amount"
          normalize="amount"
          error={get(touched, '[.amount]', false) && get(errors, '[.amount]', false)}
          onBlur={handleChange}
          rightSideText="kr."
          align="right"
          value={values[formId].amount || ''}
          onKeyPress={(e) => {
            if (e.charCode === 13) {
              handleChange(e);
            }
          }}
        />
      </AmountInput>
    </Amount>
  </Container>
);

PayToForm.propTypes = {
  recipientInfo: PropTypes.shape({
    name: PropTypes.string,
    ssn: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  formProps: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setTouched: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
  }).isRequired,
  formId: PropTypes.string.isRequired,
};

export default PayToForm;
