import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Button from '../button/Button';

const Container = styled.div`
  display: grid;
  grid-template-columns: 128px 128px;
  justify-content: space-between;
  gap: 8px;

  @media all and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

const ButtonRow = ({
  onClose, files, onSend, uploading,
}) => (
  <Container>
    <Button
      content={<FormattedMessage id="Documents.Button.Cancel" />}
      primary={false}
      background="#bfbfbf"
      color="white"
      border="1px solid transparent"
      hoverColor="#b3b3b3"
      onClick={() => onClose()}
      disabled={uploading}
    />
    <Button
      content={<FormattedMessage id="Documents.Button.Submit" />}
      disabled={files.length === 0 || uploading}
      onClick={onSend}
      loading={uploading}
    />
  </Container>
);

ButtonRow.propTypes = {
  files: PropTypes.array,
  onClose: PropTypes.func.isRequired,
};

ButtonRow.defaultProps = {
  files: [],
};

export default ButtonRow;
