import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal/Modal';
import Content from './Content';
import useDocumentsModal from '../../hooks/use-documents-modal';

const DocumentsModal = ({ show }) => {
  const { modal } = useDocumentsModal();

  return <Modal content={<Content modal={modal} />} showModal={show} onClose={modal.onClose} />;
};

DocumentsModal.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default DocumentsModal;
