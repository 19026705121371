import React, { Component } from 'react';
import { strip } from '@origo_vl_banking/utils';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get, replace } from 'lodash';
import { validate } from '../../../utils/validate';

import {
  getCarInfo,
  setMilage,
  setCarInsuranceCompany,
  setInsuranceCompanyName,
  getInsuranceCompanies,
  resetCarInfo,
  prepareAndCalculate,
  getSellerName,
  setSellerField,
} from '../../app/actions';
import { Mortgage } from '../../../components/shared';
import { appType } from '../../../utils/constants';

class MortgageContainer extends Component {
  getCarData = (e, handleBlur) => {
    handleBlur(e);
    const { value } = e.target;
    const { mortgage, resetCarInfoHandler } = this.props;
    if (!value) {
      return resetCarInfoHandler();
    }

    if (mortgage.mortgageItem === value) return;
    this.props.getCarInfo(value).then(() => this.props.prepareAndCalculate(appType.leasing));
  };

 
  handleMileageOnBlur = (e, handleBlur) => {
    handleBlur(e);
    const value = parseInt(strip.amount(e.target.value), 10);
    this.props.setMilage(value);
  };

  parseValue = (e) => {
    const { value } = e.target;
    if (!value) return null;
    const parsedValue = replace(value, '-', '');
    if (!parsedValue || !validate.ssn(parsedValue)) return null;
    return parsedValue;
  };

  handleSellerSsn = (e) => {
    const parsedValue = this.parseValue(e);
    if (!parsedValue || parsedValue === this.props.app.seller.ssn) return;
    this.props.getSellerName(parsedValue);
    this.props.setSellerField('ssn', parsedValue);
  };

  render() {
    return (
      <Mortgage
        editable={this.props.app.editable}
        mortgage={this.props.mortgage}
        companyName={this.props.mortgage.companyName}
        serialNumber={this.props.app.serialNumber}
        disabled={!this.props.app.editable}
        setCarInsuranceCompany={this.props.setCarInsuranceCompany}
        getInsuranceCompanies={this.props.getInsuranceCompanies}
        setInsuranceCompanyName={this.props.setInsuranceCompanyName}
        handleMileageOnBlur={this.handleMileageOnBlur}
        getCarData={this.getCarData}
        validationError={this.props.app.validationError}
        showInsuranceCompany={this.props.showInsuranceCompany}
        app={this.props.app}
        handleSeller={this.handleSellerSsn}
        isLeasing
      />
    );
  }
}

MortgageContainer.propTypes = {
  app: PropTypes.object.isRequired,
  mortgage: PropTypes.object.isRequired,
  getCarInfo: PropTypes.func.isRequired,
  resetCarInfoHandler: PropTypes.func.isRequired,
  getInsuranceCompanies: PropTypes.func.isRequired,
  setCarInsuranceCompany: PropTypes.func.isRequired,
  setInsuranceCompanyName: PropTypes.func.isRequired,
  showInsuranceCompany: PropTypes.bool.isRequired,
  setMilage: PropTypes.func.isRequired,
  getSellerName: PropTypes.func.isRequired,
  setSellerField: PropTypes.func.isRequired,
};

function mapStateToProps({ app }) {
  return { app, mortgage: app.mortgage };
}

const mapDispatchToProps = {
  getCarInfo,
  setMilage,
  resetCarInfoHandler: resetCarInfo,
  setCarInsuranceCompany,
  setInsuranceCompanyName,
  getInsuranceCompanies,
  prepareAndCalculate,
  getSellerName,
  setSellerField,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MortgageContainer);
