import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Loader } from '@origo_vl_banking/ibanking-react';

import StyledButton from './styled-components/StyledButton';

const ButtonText = styled.span`
  height: 24px;
  color: ${({ color }) => color || '#333'};
  font-size: inherit;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  ${({ contentStyle }) => contentStyle};
`;

const Button = ({
  background,
  border,
  color,
  content,
  customButton,
  disabled,
  fontSize,
  fullHeight,
  fullWidth,
  hoverColor,
  marginLeft,
  marginTop,
  noTopMargin,
  primary,
  underline,
  width,
  loading,
  loaderColor,
  contentStyle,
  ...props
}) => (
  <StyledButton
    background={background}
    border={border}
    color={color}
    customButton={customButton}
    disabled={disabled}
    fontSize={fontSize}
    fullHeight={fullHeight}
    fullWidth={fullWidth}
    hoverColor={hoverColor}
    marginLeft={marginLeft}
    marginTop={marginTop}
    noTopMargin={noTopMargin}
    primary={primary}
    type="button"
    underline={underline}
    width={width}
    {...props}
  >
    {loading ? (
      <Loader color={loaderColor || '#333'} />
    ) : (
      <ButtonText contentStyle={contentStyle} color={color}>
        {content}
      </ButtonText>
    )}
  </StyledButton>
);

Button.defaultProps = {
  background: null,
  border: null,
  color: null,
  customButton: false,
  disabled: false,
  fontSize: null,
  fullHeight: false,
  fullWidth: false,
  hoverColor: null,
  marginLeft: null,
  marginTop: null,
  noTopMargin: false,
  primary: true,
  underline: false,
  width: null,
  loading: false,
  loaderColor: null,
};

Button.propTypes = {
  background: PropTypes.string,
  border: PropTypes.string,
  color: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  customButton: PropTypes.bool,
  disabled: PropTypes.bool,
  fontSize: PropTypes.string,
  fullHeight: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hoverColor: PropTypes.string,
  marginLeft: PropTypes.string,
  marginTop: PropTypes.string,
  noTopMargin: PropTypes.bool,
  primary: PropTypes.bool,
  underline: PropTypes.bool,
  width: PropTypes.string,
  loading: PropTypes.bool,
  loaderColor: PropTypes.string,
};

export default Button;
