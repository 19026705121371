import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import GridView from '../../components/grid/GridView';
import { SmartHeader, SmartMessage, SmartApplicants } from '../../components/shared';
import { Terms, Mortgage, Calculator } from './components';
import { getCarType } from './actions';
import {
  getApplication,
  getPreConditions,
  setValidationError,
  postApplication,
  getSellerName,
  resetGuarantorFields,
  resetSecondGuarantorFields,
} from '../app/actions';
import SmartLoader from '../../components/loader/SmartLoader';
import { appType, appStatus } from '../../utils/constants';
import { hasRequiredCarRental, prepareData } from '../../utils/helpers';

class CarRental extends Component {
  componentDidMount() {
    const { state } = this.props.location;
    if (state) {
      this.props.getApplication(state.year, state.serialNumber, true);
    } else {
      this.props.getPreConditions(appType.carRental);
      this.props.getCarType();
      // this.props.handleSellerSSN(sessionStorage.sellerSSN);
    }
  }

  handleApplication = (status) => {
    const { app, guarantor, secondGuarantor } = this.props;

    if (!guarantor.ssn || !guarantor) {
      this.props.resetGuarantorFields();
    }

    if (!secondGuarantor.ssn || !secondGuarantor) {
      this.props.resetSecondGuarantorFields();
    }

    const data = prepareData(app, status, appType.carRental);

    if (!hasRequiredCarRental(data) || app.applicant.telephone.length < 7) {
      return this.props.setValidationError('Form.Error.required-field');
    }

    if (app.guarantor.telephone && app.guarantor.telephone.length < 7) {
      return null;
    }
    if (app.secondGuarantor.telephone && app.secondGuarantor.telephone.length < 7) {
      return null;
    }

    this.props.postApplication(data, this.props.app.serialNumber);
  };

  render() {
    const { editable, statusText, message1, message2 } = this.props.app;
    return (
      <div>
        <SmartLoader />
        <SmartHeader
          title="sitemap.CarRental"
          subtitle={statusText || 'sitemap.Application'}
          message1={message1}
          message2={message2}
        />

        <GridView
          grid1={<Terms title="Application.Terms" />}
          grid2={(
            <Mortgage
              hideInsurance
              title="Application.CarRental.CarTypeHeader"
              showInsuranceCompany={false}
            />
          )}
          grid3={(
            <SmartApplicants
              title="Application.PersonsHeader"
              showSecondApplicant={false}
              showGuarantor
            />
          )}
          calculator={<Calculator />}
          message={<SmartMessage />}
          editable={editable}
          displayButtons={editable}
          handleSaveApplication={() => this.handleApplication(appStatus.save)}
          handleSendApplication={() => this.handleApplication(appStatus.send)}
        />
      </div>
    );
  }
}

CarRental.propTypes = {
  app: PropTypes.object.isRequired,
  guarantor: PropTypes.object,
  secondGuarantor: PropTypes.object,
  location: PropTypes.object.isRequired,
  getPreConditions: PropTypes.func.isRequired,
  getCarType: PropTypes.func.isRequired,
  postApplication: PropTypes.func.isRequired,
  getApplication: PropTypes.func.isRequired,
  resetGuarantorFields: PropTypes.func.isRequired,
  resetSecondGuarantorFields: PropTypes.func.isRequired,
  setValidationError: PropTypes.func.isRequired,
};

CarRental.defaultProps = {
  guarantor: {},
  secondGuarantor: {},
};

const mapStateToProps = ({ app }) => ({
  app,
  guarantor: app.guarantor,
  secondGuarantor: app.secondGuarantor,
});

const mapDispatchToProps = {
  resetGuarantorFields,
  resetSecondGuarantorFields,
  postApplication,
  getPreConditions,
  getApplication,
  getCarType,
  setValidationError,
  handleSellerSSN: getSellerName,
};

export default connect(mapStateToProps, mapDispatchToProps)(CarRental);
