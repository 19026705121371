import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NavItem } from '../styled-components/Navbar';

const NavButton = ({
  onClick, navlink, route, messageId,
}) => (
  <NavItem onClick={onClick} navlink={navlink} route={route}>
    <FormattedMessage id={messageId} />
  </NavItem>
);

NavButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  navlink: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  messageId: PropTypes.string.isRequired,
};

export default NavButton;
