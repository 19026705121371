import React from 'react';
import { PropTypes } from 'prop-types';
import { SelectDropdown } from '@origo_vl_banking/ibanking-react';
import { FormattedMessage } from 'react-intl';
import { Label } from '../grid/styled-components';

const InsuranceCompany = ({
  editable,
  showInsuranceCompany,
  app,
  handleSelect,
  companyName,
  disabled,
  error,
}) => {
  if (editable && showInsuranceCompany) {
    return (
      <SelectDropdown
        label="Mortgage.InsuranceCompany"
        name="insuranceCompany"
        headerHeight="34px"
        width="100%"
        data={app.companies}
        onClicked={handleSelect}
        displayValue={companyName}
        disabled={disabled}
        tabIndex="14"
        error={error}
      />
    );
  } else if (showInsuranceCompany) {
    return (
      <div>
        <Label style={{ marginBottom: 16, display: 'block' }}>
          <FormattedMessage id="Mortgage.InsuranceCompany" />
        </Label>
        <span>{companyName}</span>
      </div>
    );
  }

  return null;
};

InsuranceCompany.propTypes = {
  app: PropTypes.shape({
    companies: PropTypes.array.isRequired,
  }).isRequired,
  companyName: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
  showInsuranceCompany: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

InsuranceCompany.defaultProps = {
  error: '',
};

export default InsuranceCompany;
