import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';

import { login, electronicLogin, startElectronicLogin } from '../actions';
import LoginForm from './LoginForm';
import EletronicLoginForm from './ElectronicLoginForm';
import EletronicAppLoginForm from './ElectroniAppLoginForm';
import { ErrorMessage } from '../../../components';
import { Row, Content, FormHeader, FormContent } from '../styled-components/Login';
import { validate } from '../../../utils/validate';
import AuthModal from '../../../components/auth-modal/Modal';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    // this.SMSInput = React.createRef();
  }

  // handleSMSToken = (values) => {
  //   const { dispatch } = this.props;
  //   const { username, password } = values;
  //   const payload = {
  //     username,
  //     password,
  //   };
  //   dispatch(sendSMSToken(payload));
  // };

  handleLogin = (values) => {
    const { dispatch } = this.props;
    const { username, password } = values;
    const payload = {
      username,
      password,
    };
    dispatch(login(payload));
  };

  handleElectronicLogin = (values) => {
    const { dispatch } = this.props;
    const { mobile } = values;
    // const payload = { mobile };
    // dispatch(electronicLogin(payload));
    dispatch(startElectronicLogin({ identifier: mobile.toString(), authType: 0 }));
  };

  handleAppElectronicLogin = (values) => {
    const { dispatch } = this.props;
    const { ssn } = values;
    dispatch(startElectronicLogin({ identifier: ssn.toString(), authType: 2 }));
  };

  handleForgotPassword = () => {
    const { dispatch } = this.props;
    dispatch(push('/auth/reset-password'));
  };

  render() {
    const {
      isSubmitting,
      submissionError,
      isAuthenticated,
      isWatingForDigitalLogin,
      verificationCode,
      authType,
    } = this.props;

    if (isAuthenticated) {
      return <Redirect to="/" />;
    }

    return (
      <>
        <Row>
          <Content>
            <FormContent>
              <FormHeader>
                <FormattedMessage id="WebClient.Login.DigitalLogin.MainText" />
              </FormHeader>
              {!isEmpty(submissionError) && <ErrorMessage errorKey={submissionError.errorKey} />}
              <EletronicLoginForm
                handleLogin={this.handleElectronicLogin}
                validators={{
                  mobile: validate.formik.phone,
                }}
                formSubmitting={isSubmitting}
                submissionError={submissionError}
              />
              <br />
              <FormHeader>
                <FormattedMessage id="WebClient.Login.DigitalAppLogin.MainText" />
              </FormHeader>
              <EletronicAppLoginForm
                handleLogin={this.handleAppElectronicLogin}
                validators={{
                  ssn: validate.formik.username,
                }}
                formSubmitting={isSubmitting}
                submissionError={submissionError}
              />
              <FormHeader>
                {/* {' '} */}
                <FormattedMessage id="Login.Login" />
              </FormHeader>
              <LoginForm
                handleLogin={this.handleLogin}
                validators={{
                  username: validate.formik.username,
                  password: validate.formik.loginPassword,
                }}
                formSubmitting={isSubmitting}
                submissionError={submissionError}
                handleForgotPassword={this.handleForgotPassword}
              />
            </FormContent>
          </Content>
        </Row>
        <AuthModal
          show={isWatingForDigitalLogin && verificationCode}
          verificationCode={verificationCode}
          authType={authType}
        />
      </>
    );
  }
}

Login.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  submissionError: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  isWatingForDigitalLogin: PropTypes.bool.isRequired,
  verificationCode: PropTypes.string.isRequired,
  authType: PropTypes.string.isRequired,
};

Login.defaultProps = {
  submissionError: {},
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isSubmitting: state.auth.isSubmitting,
    submissionError: state.auth.error,
    isWatingForDigitalLogin: state.auth.isWatingForDigitalLogin,
    verificationCode: state.auth.verificationCode,
    authType: state.auth.authType,
  };
}

export default connect(mapStateToProps)(Login);
