import React from 'react';
import { PropTypes } from 'prop-types';
import { withTheme } from 'styled-components';
import RcSlider from 'rc-slider';
import 'rc-slider/assets/index.css';

const TrackStyle = (theme, disabled) => ({
  backgroundColor: `${!disabled ? theme.primaryColor : theme.disabledInputBackground}`,
  height: 14,
  borderRadius: 0,
  border: '1px solid hsl(0, 0%, 59%)',
});

const RailStyle = {
  backgroundColor: 'white',
  height: 14,
  borderRadius: 0,
  border: '1px solid hsl(0, 0%, 59%)',
};

const Thumb = {
  border: 'none',
  background: 'transparent',
  borderRadius: '0px',
  cursor: 'pointer',
  marginTop: '-6px',
  width: 0,
  height: 0,
  fontSize: 0,
  lineHeight: 0,

  borderStyle: 'solid',
  borderWidth: '20px 5.5px 0',
  borderColor: '#444 transparent transparent transparent',
  boxShadow: 'none',
};

const Slider = ({
  min, max, defaultValue, onChange, value, theme, onAfterChange, disabled,
}) => (
  <RcSlider
    min={disabled ? 0 : min}
    disabled={disabled}
    max={max}
    defaultValue={defaultValue}
    trackStyle={TrackStyle(theme, disabled)}
    onChange={onChange}
    value={value}
    railStyle={RailStyle}
    step={0.05}
    handleStyle={Thumb}
    onAfterChange={onAfterChange}
  />
);

Slider.propTypes = {
  onChange: PropTypes.func,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  defaultValue: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
  onAfterChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Slider.defaultProps = {
  onChange: () => null,
  disabled: false,
};

export default withTheme(Slider);
