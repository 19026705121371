import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Consultant } from '../../../components/shared';
import { openEmail } from '../../../utils/helpers';

import { Row, Heading } from '../styled-components/Contacts';

const Contacts = ({
  personConsultants, companyConsultants, loanType, serialNumber, year, ssn,
}) => {
  const openEmailTo = (email) => {
    openEmail(email, serialNumber, loanType, year, ssn);
  };

  const renderPeople = persons =>
    persons
      .filter(person => person.id !== ' ') // filter bad server data
      .map(person => <Consultant key={person.id} consultant={person} openEmailTo={openEmailTo} />);

  return (
    <React.Fragment>
      <Heading>
        <FormattedMessage id="Consultants.Private" />
      </Heading>
      <Row>{renderPeople(personConsultants)}</Row>

      <Heading>
        <FormattedMessage id="Consultants.Company" />
      </Heading>
      <Row>{renderPeople(companyConsultants)}</Row>
    </React.Fragment>
  );
};

Contacts.propTypes = {
  personConsultants: PropTypes.array,
  companyConsultants: PropTypes.array,
  loanType: PropTypes.string,
  serialNumber: PropTypes.string,
  year: PropTypes.string,
  ssn: PropTypes.string,
};

Contacts.defaultProps = {
  personConsultants: [],
  companyConsultants: [],
  loanType: '',
  serialNumber: '',
  year: '',
  ssn: '',
};

export default Contacts;
