import React from 'react';
import { PropTypes } from 'prop-types';

// const ElectronicSignature = ({ title, size, color }) => (
//   <svg width={size} height={size} viewBox="0 0 23 23">
//     <title>{title}</title>
//     <g
//       stroke={color}
//       strokeWidth={2}
//       fill="none"
//       fillRule="evenodd"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//     >
//       <path d="M15 10.5V20a2 2 0 01-2 2H3a2 2 0 01-2-2V4a2 2 0 012-2h10.082C14.142 2 15 2.859 15 3.918M8 18h.01" />
//       <path d="M17.5 1.621a2.121 2.121 0 013 3l-9.5 9.5-4 1 1-4 9.5-9.5z" />
//     </g>
//   </svg>
// );

const ElectronicSignature = ({ title, size, color }) => (
  <svg width={size} height={size} viewBox="0 0 20 22">
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M11 7l2-4v-.5A2.5 2.5 0 0010.5 0h-8A2.5 2.5 0 000 2.5v17A2.5 2.5 0 002.5 22h8a2.5 2.5 0 002.5-2.5V11 9l-2 2M6.5 21c-.83 0-1.5-.67-1.5-1.5S5.67 18 6.5 18s1.5.67 1.5 1.5S7.33 21 6.5 21zM11 11v6H2V2h9v5"
        fill={color}
        fillRule="nonzero"
      />
      <path
        d="M15.41 1.616a2.103 2.103 0 012.974 2.974L8.966 14.01 5 15l.991-3.966 9.419-9.418z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

ElectronicSignature.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
};

ElectronicSignature.defaultProps = {
  title: 'Rafræn undirritun',
  size: '24px',
  color: '#3f3f3f',
};

export default ElectronicSignature;
