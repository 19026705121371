import styled from 'styled-components';

const Content = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: transparent;
  top: 0;
  z-index: 99;
  display: ${({ isClosed }) => (isClosed ? 'block' : 'none')}
`;

export { Content };
