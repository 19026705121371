import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';

import useRoles from '../../hooks/use-roles';

const AuthRoute = ({
  component: Component, isAuthenticated, userRole, ...rest
}) => {
  const { hasRole } = useRoles();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && (!userRole || hasRole(userRole))) {
          const newProps = { ...props, ...rest.props };
          return <Component {...newProps} />;
        } else if (isAuthenticated && userRole && !hasRole(userRole)) {
          return (
            <Redirect
              push
              to={{
                pathname: '/',
              }}
            />
          );
        }

        return (
          <Redirect
            push
            to={{
              pathname: '/auth/login',
            }}
          />
        );
      }}
    />
  );
};

AuthRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  userRole: PropTypes.string,
};

AuthRoute.defaultProps = {
  userRole: null,
};

export default connect(({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
  gotSMSToken: auth.gotSMSToken,
}))(AuthRoute);
