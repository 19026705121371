import { handleActions } from 'redux-actions';

import {
  MORTGAGE_INFO_REQUEST,
  MORTGAGE_INFO_SUCCESS,
  MORTGAGE_INFO_FAILURE,
  SET_MORTGAGE_ITEM,
  SET_SSN,
  CAR_INFO_REQUEST,
  CAR_INFO_SUCCESS,
  CAR_INFO_FAILURE,
  SET_MORTGAGE_AMOUNT,
  SET_MILAGE,
  SET_CAR_INSURANCE_COMPANY,
  SET_CAR_INSURANCE_COMPANIES,
  SAVE_MORTGAGE_REQUEST,
  SAVE_MORTGAGE_SUCCESS,
  SAVE_MORTGAGE_FAILURE,
  SEND_MORTGAGE_REQUEST,
  SEND_MORTGAGE_SUCCESS,
  SEND_MORTGAGE_FAILURE,
  RESET_MORTGAGE_STATE,
  GET_MORTGAGE_DETAILS_REQUEST,
  GET_MORTGAGE_DETAILS_SUCCESS,
  GET_MORTGAGE_DETAILS_FAILURE,
  SET_COMMUNICATION,
  SET_VALIDATION_ERROR,
} from './constants';

const initialState = {
  isFetchingMortgageInfo: false,
  hasFetchedMortgageInfo: false,
  isFetchingData: false,
  hasFetchedMortgageData: false,
  isFetchingCarInfo: false,
  hasFetchedCareInfo: false,
  isFetchingMortgageDetails: false,
  hasFetchedMortgageDetails: false,
  ssn: '',
  mortgageItem: '',
  newMortgage: {},
  carInsuranceCompanies: [],
  communication: null,
  editable: true,
  validationError: '',
};

export default handleActions(
  {
    [RESET_MORTGAGE_STATE]: () => ({
      ...initialState,
    }),
    [MORTGAGE_INFO_REQUEST]: state => ({
      ...state,
      isFetchingMortgageInfo: true,
      hasFetchedMortgageInfo: false,
    }),
    [MORTGAGE_INFO_SUCCESS]: (state, action) => ({
      ...state,
      ...action.data,
      isFetchingMortgageInfo: false,
      hasFetchedMortgageInfo: true,
    }),
    [MORTGAGE_INFO_FAILURE]: (state, { error }) => ({
      ...state,
      isFetchingMortgageInfo: false,
      hasFetchedMortgageInfo: false,
      error,
    }),
    [SET_MORTGAGE_ITEM]: (state, action) => ({
      ...state,
      mortgageItem: action.mortgageItem,
    }),
    [SET_SSN]: (state, action) => ({
      ...state,
      ssn: action.ssn,
    }),
    [SET_MORTGAGE_AMOUNT]: (state, action) => ({
      ...state,
      newMortgage: {
        ...state.newMortgage,
        mortgageAmount: action.amount,
      },
    }),
    [SET_MILAGE]: (state, { mortgageMilage }) => ({
      ...state,
      newMortgage: { ...state.newMortgage, mortgageMilage },
    }),
    [CAR_INFO_REQUEST]: state => ({
      ...state,
      isFetchingCarInfo: true,
      hasFetchedCarInfo: false,
    }),
    [CAR_INFO_SUCCESS]: (state, { car }) => ({
      ...state,
      isFetchingCarInfo: false,
      hasFetchedCarInfo: true,
      newMortgage: {
        ...state.newMortgage,
        mortgageItem: car.number,
        mortgageType: car.type,
        mortgageSubType: car.subType,
      },
      car,
    }),
    [CAR_INFO_FAILURE]: (state, { error }) => ({
      ...state,
      isFetchingCarInfo: false,
      hasFetchedCarInfo: false,
      error,
    }),
    [SET_CAR_INSURANCE_COMPANY]: (state, { insuranceCompanyId }) => ({
      ...state,
      newMortgage: { ...state.newMortgage, insuranceCompanyId },
    }),
    [SET_CAR_INSURANCE_COMPANIES]: (state, { carInsuranceCompanies }) => ({
      ...state,
      carInsuranceCompanies,
    }),
    [SAVE_MORTGAGE_REQUEST]: state => ({
      ...state,
      isFetchingData: true,
      hasFetchedMortgageData: false,
    }),
    [SAVE_MORTGAGE_SUCCESS]: (state, action) => ({
      ...state,
      ...action.data,
      isFetchingData: false,
      hasFetchedMortgageData: true,
    }),
    [SAVE_MORTGAGE_FAILURE]: (state, { error }) => ({
      ...state,
      isFetchingData: false,
      hasFetchedMortgageData: false,
      error,
    }),
    [SEND_MORTGAGE_REQUEST]: state => ({
      ...state,
      isFetchingData: true,
      hasFetchedMortgageData: false,
    }),
    [SEND_MORTGAGE_SUCCESS]: (state, action) => ({
      ...state,
      ...action.data,
      isFetchingData: false,
      hasFetchedMortgageData: true,
    }),
    [SEND_MORTGAGE_FAILURE]: (state, { error }) => ({
      ...state,
      isFetchingData: false,
      hasFetchedMortgageData: false,
      error,
    }),
    [GET_MORTGAGE_DETAILS_REQUEST]: state => ({
      ...state,
      isFetchingMortgageDetails: true,
      hasFetchedMortgageDetails: false,
    }),
    [GET_MORTGAGE_DETAILS_SUCCESS]: (state, { mortgageDetails }) => ({
      ...state,
      ...mortgageDetails,
      isFetchingMortgageDetails: false,
      hasFetchedMortgageDetails: true,
    }),
    [GET_MORTGAGE_DETAILS_FAILURE]: (state, { error }) => ({
      ...state,
      error,
      isFetchingMortgageDetails: false,
      hasFetchedMortgageDetails: false,
    }),
    [SET_COMMUNICATION]: (state, { communication }) => ({
      ...state,
      communication,
    }),
    [SET_VALIDATION_ERROR]: (state, { validationError }) => ({
      ...state,
      validationError,
    }),
  },
  initialState,
);
