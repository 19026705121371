import styled from 'styled-components';

import { mdMin } from '../../../utils/constants';

export const StyledFooter = styled.div`
  background-color: ${({ theme }) => theme.secondaryColor};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  padding-bottom: 10px;
  padding-top: 10px;

  @media(min-width: ${mdMin}) {
    align-items: center;
    flex-direction: row;
  }
`;

export const Column = styled.div`
  @media(min-width: ${mdMin}) {
    margin-right: 40px;
  }
`;

export const InnerColumn = styled.div`
  margin-bottom: 5px;
  @media(min-width: ${mdMin}) {
    display: flex;
    margin-right: 40px;
  }
`;

export const Item = styled.div`
  font-size: 14px;
  color: #fff;
  margin-bottom: 5px;
`;

export const ItemTitle = styled.div`
  font-size: 24px;
  color: #fff;
`;

export const ItemColumnTitle = styled.div`
  margin-top: 12px;
`;

export const ItemImage = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
`;

export const Image = styled.img`
  height: 22px;
  margin-right: 5px;
`;

export const ItemTextMobile = styled.span`
  color: rgb(253, 205, 40);
  display: inline-block;
  position: relative;
  font-size: 24px;
`;

export const ItemTextEnvelope = styled.span`
  color: #fff;
  display: inline-block;
  position: relative;
  font-size: 24px;
`;

export const RightImage = styled.img`
  height: 80px;
  width: 180px;
  float: right;
`;
