import styled from 'styled-components';

import { mdMin } from '../../../utils/constants';

const Text = styled.p`
  margin-bottom: 8px;
  margin-top: 20px;
  @media (min-width: ${mdMin}) {
    margin-bottom: 0;
  }
`;

const TextField = styled.div`
  margin-bottom: 8px;
  margin-top: 8px;
  text-align: right;
  @media (min-width: ${mdMin}) {
    margin-bottom: 0;
  }
`;

const Width = styled.div`
  width: 100%;
  @media (min-width: ${mdMin}) {
    width: 244px;
  }
`;

const Label = styled.div`
  width: 100%;
  color: #5b6d7a;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const SelectButton = styled.div`
  border: 1px solid #aaa;
  padding-left: 8px;
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  padding-right: 8px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
  background: ${({ disabled, theme }) => (disabled ? theme.disabledInputBackground : 'white')};
  color: ${({ disabled }) => disabled && '#aaa'};
  position: relative;
  outline: 0;

  &:active {
    border: 1px solid ${({ theme }) => theme.primaryColor};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.primaryColor};
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 8px;
  display: flex;
  align-items: center;
`;

const DropdownContent = styled.div`
  background: #ddd;
  position: absolute;
  width: 500px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-spacing: 5px;
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  font-size: 14px;
  font-family: roboto;
`;

const Header = styled.tr`
  border-collapse: collapse;
  color: #5b6d7a;
`;

const Tr = styled.tr`
  border-collapse: collapse;
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;

const Th = styled.th`
  border-bottom: 1px solid #ddd;
  border-collapse: collapse;
  padding: 10px 6px;
  text-align: left;
`;

const Td = styled.td`
  border-bottom: 1px solid #ddd;
  border-collapse: collapse;
  padding: 6px;
`;

export {
  DropdownContent,
  Label,
  SelectButton,
  Header,
  Table,
  Tr,
  Th,
  Td,
  Text,
  TextField,
  Width,
  IconWrapper,
};
