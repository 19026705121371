import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const ErrorContainer = styled.div`
  width: 100%;
  height: 55px;
  margin: 0 auto;
  background-color: ${props => props.theme.dangerLight};
  border: 1px solid ${props => props.theme.danger};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-bottom: 25px;
`;

const ErrorMessage = ({ errorKey }) => (
  <ErrorContainer>
    <FormattedMessage id={errorKey || 'Error'} />
  </ErrorContainer>
);

ErrorMessage.propTypes = {
  errorKey: PropTypes.string.isRequired,
};

export default ErrorMessage;
