import React, { useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { format, strip } from '@origo_vl_banking/utils';
import { get, map } from 'lodash';

const Heading = styled.h4`
  margin: 0px 0px 8px 0;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.primaryTextColor};
  border-bottom: 1px solid ${({ theme }) => theme.primaryTextColor};
  margin-bottom: 16px;
  min-width: 512px;

  @media screen and (max-width: 512px) {
    min-width: auto;
  }
`;

const Details = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 8px;
`;

const DetailsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
`;

const Amount = styled.span`
  text-align: right;
  font-weight: ${({ heavy }) => heavy && 'bold'};
  font-size: ${({ heavy }) => heavy && '18px'};
`;

const RowLabel = styled.span`
  font-weight: ${({ heavy }) => heavy && 'bold'};
  font-size: ${({ heavy }) => heavy && '18px'};
`;

const getAllocatedFunds = ({ payToDealer, payToSeller, financeDetails }) => {
  const creditInstitutionsValues = get(financeDetails, 'creditInstitutions', []);
  let insertedAmount =
    0 +
    (payToDealer.checked && strip.amount(payToDealer.amount)) +
    (payToSeller.checked && strip.amount(payToSeller.amount));

  map(creditInstitutionsValues, (val) => {
    insertedAmount += strip.amount(val.amount);
  });
  return insertedAmount;
};

const DisposalDetails = ({
  formProps: {
    values: { payToDealer, payToSeller, financeDetails },
  },
}) => {
  const [allocatedFunds, setAllocatedFunds] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const {
    terms: { calculatedPayout },
  } = useSelector(state => state.app);
  useMemo(() => {
    const allocated = getAllocatedFunds({ payToDealer, payToSeller, financeDetails });
    setAllocatedFunds(allocated);
    setRemaining(calculatedPayout - allocated);
  }, [payToDealer, payToSeller, financeDetails]);

  return (
    <DetailsContainer>
      <Details>
        <DetailsRow>
          <RowLabel>
            <FormattedMessage id="Disposal.Details.Payout" />
          </RowLabel>
          <Amount>{format.amount(calculatedPayout)} kr.</Amount>
        </DetailsRow>
        <DetailsRow>
          <RowLabel>
            <FormattedMessage id="Disposal.Details.Allocated" />
          </RowLabel>
          <Amount>{format.amount(allocatedFunds)} kr.</Amount>
        </DetailsRow>
        <DetailsRow>
          <RowLabel heavy>
            <FormattedMessage id="Disposal.Details.Remaining" />
          </RowLabel>
          <Amount heavy>{format.amount(remaining)} kr.</Amount>
        </DetailsRow>
      </Details>
    </DetailsContainer>
  );
};

DisposalDetails.propTypes = {
  formProps: PropTypes.shape({
    values: PropTypes.shape({
      payToDealer: PropTypes.shape({
        amount: PropTypes.number,
      }).isRequired,
      payToSeller: PropTypes.shape({
        amount: PropTypes.number,
      }).isRequired,
      financeDetails: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
};

export default DisposalDetails;
