import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { format } from '@origo_vl_banking/utils';

import { FormGroup } from '../../../components/shared/styled-components';

import { Label } from '../../../components/grid/styled-components';

const DepsoitToText = styled.p`
  color: ${({ theme }) => theme.secondaryColor};
  margin-bottom: 0px;
  font-size: 14px;
  white-space: pre-line;
`;

const Container = styled.div`
  min-width: 290px;
`;

const Cost = ({ changeFee, registrationFee, totalFee }) => (
  <Container>
    <FormGroup>
      <Label>
        <FormattedMessage id="Application.ChangeFee" />
      </Label>
      <Label>{format.amount(changeFee)} kr.</Label>
    </FormGroup>
    <FormGroup>
      <Label>
        <b>
          <FormattedMessage id="Application.RegistrationFee" />
        </b>
      </Label>
      <Label>{format.amount(registrationFee)} kr.</Label>
    </FormGroup>
    <hr />
    <FormGroup>
      <Label color="black">
        <b>
          <FormattedMessage id="Application.TotalCost" />
        </b>
      </Label>
      <Label color="black">{format.amount(totalFee)} kr.</Label>
    </FormGroup>
    <hr />
    <FormGroup>
      <DepsoitToText>
        <FormattedMessage id="Fees.DepositTo" values={{ newline: '\n' }} />
      </DepsoitToText>
    </FormGroup>
  </Container>
);

Cost.propTypes = {
  changeFee: PropTypes.number,
  registrationFee: PropTypes.number,
  totalFee: PropTypes.number,
};

Cost.defaultProps = {
  changeFee: 0,
  registrationFee: 0,
  totalFee: 0,
};

const mapStateToProps = state => ({
  changeFee: state.mortgage.changeFee,
  registrationFee: state.mortgage.registrationFee,
  totalFee: state.mortgage.totalFee,
});

export default connect(mapStateToProps)(Cost);
