const PrimaryTheme = {
  primaryColor: 'hsl(46, 98%, 57%)',
  secondaryColor: 'hsl(205, 15%, 42%)',
  headerColor: 'hsl(209, 21%, 32%)',
  disabledColor: '#CCC',
  backgroundColor: '#FFFFFF',
  backgroundGrey: 'hsl(200, 13%, 95%)',
  borderColor: '#AAA',
  boxShadowColor: '#AAA',
  disabledInputBackground: '#EEE',
  buttonBorder: 'hsl(46, 98%, 57%)',
  buttonPrimary: 'hsl(46, 98%, 57%)',
  danger: '#e74c3c',
  dangerLight: '#f4ada6',
  evenTableRow: '#F5F5F5',
  form: {
    formErrorColor: '#fccbc7',
    formErrorBorder: '1px solid red',
    isVideoBackground: false,
  },
  formBackground: '#fefefe',
  iconColor: 'hsl(46, 98%, 57%)',
  loginButtonPrimary: '#2196F3',
  loginButtonSecondary: 'white',
  logoSource: 'lykill.png',
  pieChartColors: ['#A28352', '#592D48', '#24405B', '#A8BAC6', '#B52D3D', '#0E777A'],
  primaryTextColor: '#232323',
  secondaryTextColor: '#d0d0d0',
  sensetiveText: {
    color: 'transparent',
    textShadow: '0 0 10px #AAA',
  },
  submitButton: '#1DA6CC',
  submitButtonActive: '#0a69b5',
  submitButtonHover: '#0c85e6',
  success: '#2ecc71',
  successLight: '#8CE5B2',
  textColor: 'black',
};

export { PrimaryTheme };
