import { Api } from '../../utils';

import {
  FETCH_CONSULTANTS_REQUEST,
  FETCH_CONSULTANTS_SUCCESS,
  FETCH_CONSULTANTS_FAILURE,
  TOGGLE_CONSULTANTS,
} from './constants';

export const getConsultantsRequest = () => ({
  type: FETCH_CONSULTANTS_REQUEST,
});

export const toggleConsultants = isVisible => ({
  type: TOGGLE_CONSULTANTS,
  isVisible,
});

export const getConsultantsSuccess = (personConsultants, companyConsultants) => ({
  type: FETCH_CONSULTANTS_SUCCESS,
  personConsultants,
  companyConsultants,
});

export const getConsultantsFailure = error => ({
  type: FETCH_CONSULTANTS_FAILURE,
  error,
});

export const getConsultants = () => (dispatch) => {
  dispatch(getConsultantsRequest);
  return Api.getConsultants()
    .then((res) => {
      const consultants = res.data;
      const personConsultants = consultants.filter(consultant => consultant.type === 'Person');
      const companyConsultants = consultants.filter(consultant => consultant.type === 'Company');
      dispatch(getConsultantsSuccess(personConsultants, companyConsultants));
    })
    .catch((err) => {
      dispatch(getConsultantsFailure(err));
    });
};
