import moment from 'moment';

import * as A from './constants';
import { Api, notifications } from '../../utils';

let timer = null;
const MAX_INACTIVITY = 17950; // 7170 seconds | 1h 59m 30s

export const setAuth = isAuthenticated => ({
  type: A.SET_AUTH,
  isAuthenticated,
});

const clearState = () => ({
  type: 'CLEAR_STATE',
});

const logoutRequest = () => ({ type: A.LOGOUT_REQUEST });

const logoutSuccess = () => ({ type: A.LOGOUT_SUCCESS });

const logoutFailure = error => ({
  type: A.LOGOUT_FAILURE,
  error,
});

export const logout = () => (dispatch) => {
  dispatch(logoutRequest());
  dispatch(setAuth(false));

  dispatch(clearState());
  dispatch(logoutSuccess());
  sessionStorage.clear();
  clearInterval(timer);

  return Api.logout().catch((error) => {
    dispatch(logoutFailure(error));
  });
};

export const checkAuth = () => (dispatch, getState) => {
  const { auth } = getState();
  const missingActivity = !auth || !auth.lastActivity;
  const lastActivity = moment(auth.lastActivity);
  const bootOutAfter = moment().subtract(MAX_INACTIVITY, 'seconds');

  const isUserInactive = lastActivity.isBefore(bootOutAfter);

  if (missingActivity || isUserInactive) {
    clearInterval(timer);
    dispatch(logout(auth));
  }
};

const hasStartedCheckAuth = () => ({
  type: A.START_CHECK_AUTH,
});

export const startCheckAuth = () => (dispatch) => {
  dispatch(hasStartedCheckAuth(timer));
  clearInterval(timer);
  timer = setInterval(() => dispatch(checkAuth()), 29000 /* 29 seconds */);
};

export const setUser = user => ({
  type: A.SET_USER,
  user,
});

const loginRequest = () => ({
  type: A.LOGIN_REQUEST,
});

const loginSuccess = data => ({
  type: A.LOGIN_SUCCESS,
  data,
});

const loginFailure = error => ({
  type: A.LOGIN_FAILURE,
  error,
});

const startDigitalLoginRequest = () => ({
  type: A.START_DIGITAL_LOGIN_REQUEST,
});

const startDigitalLoginSuccess = ({ verificationCode, authType }) => ({
  type: A.START_DIGITAL_LOGIN_SUCCESS,
  verificationCode,
  authType,
});

const startDigitalLoginFailure = (error) => ({
  type: A.START_DIGITAL_LOGIN_FAILURE,
  error,
});

const finishDigitalLoginRequest = () => ({
  type: A.FINISH_DIGITAL_LOGIN_REQUEST,
});

const finishDigitalLoginSuccess = (data) => ({
  type: A.FINISH_DIGITAL_LOGIN_SUCCESS,
  data,
});

const finishDigitalLoginFailure = (error) => ({
  type: A.FINISH_DIGITAL_LOGIN_FAILURE,
  error,
});

const getDealershipNameRequest = () => ({
  type: A.GET_DEALERSHIP_NAME_REQUEST,
});

const getDealershipNameSuccess = dealershipName => ({
  type: A.GET_DEALERSHIP_NAME_SUCCESS,
  dealershipName,
});

const getDealershipNameFailure = () => ({
  type: A.GET_DEALERSHIP_NAME_FAILURE,
});

export const getDealershipName = id => (dispatch) => {
  dispatch(getDealershipNameRequest(id));
  return Api.getPersonName(id)
    .then((res) => {
      sessionStorage.dealershipName = res.data;
      dispatch(getDealershipNameSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getDealershipNameFailure(err));
    });
};

const resetPasswordRequest = () => ({
  type: A.RESET_PASSWORD_REQUEST,
});

const resetPasswordSuccess = data => ({
  type: A.RESET_PASSWORD_SUCCESS,
  data,
});

const resetPasswordFailure = error => ({
  type: A.RESET_PASSWORD_FAILURE,
  error,
});

const getUserProperties = () => (dispatch) => {
  Api.userProperties()
    .then((res) => {
      dispatch(loginSuccess(res));
      const responseData = res.data;
      const roles = responseData.roles.split(',');

      if (roles.includes('Loan')) {
        if (!roles.includes('CarLeasing')) {
          roles.push('CarLeasing');
        }
        if (!roles.includes('MortgageSwitch')) {
          roles.push('MortgageSwitch');
        }
      }

      sessionStorage.username = responseData.username;
      sessionStorage.name = responseData.name;
      sessionStorage.sellerSSN = responseData.sellerSSN;
      sessionStorage.login = true;
      sessionStorage.roles = roles;

      dispatch(getDealershipName(responseData.sellerSSN));
      dispatch(setAuth(true));
      const userData = {
        name: responseData.name,
        username: responseData.username,
        sellerSSN: responseData.sellerSSN,
        lastLogin: responseData.lastLogin,
        roles,
      };
      dispatch(setUser(userData));
    })
    .catch((err) => {
      if (!err.errorKey) {
        err.errorKey = 'Error.Audkenni.UnexpectedError';
      }
      dispatch(loginFailure(err));
    });
};

export const login = data => (dispatch) => {
  dispatch(loginRequest());
  Api.usernameLogin(data)
    .then(() => {
      dispatch(startCheckAuth());
      dispatch(getUserProperties());
    })
    .catch((err) => {
      dispatch(loginFailure(err));
    });
};

export const electronicLogin = data => (dispatch) => {
  dispatch(loginRequest());
  const mobile = data.mobile.toString();

  Api.electronicLogin({ mobile })
    .then(() => {
      dispatch(getUserProperties());
    })
    .catch(err => dispatch(loginFailure(err)));
};

export const finishElectronicLogin = ({ data }) => (dispatch) => {
  dispatch(finishDigitalLoginRequest());
  Api.finishElectronicLogin(data)
    .then((response) => {
      if (response.data === false) {
        setTimeout(() => {
          dispatch(finishElectronicLogin({ data }));
        }, 5000);
      } else {
        dispatch(getUserProperties());
      }
    })
    .catch((err) => dispatch(finishDigitalLoginFailure(err)));
};

export const startElectronicLogin = (data) => (dispatch) => {
  dispatch(startDigitalLoginRequest());
  Api.startElectronicLogin(data)
    .then((response) => {
      dispatch(startDigitalLoginSuccess(response.data));
      setTimeout(() => {
        dispatch(finishElectronicLogin(response));
      }, 5000);
    })
    .catch((err) => dispatch(startDigitalLoginFailure(err)));
};

export const resetPassword = data => (dispatch) => {
  dispatch(resetPasswordRequest());
  Api.resetPassword(data)
    .then(() => {
      dispatch(resetPasswordSuccess());
    })
    .catch((err) => {
      dispatch(resetPasswordFailure(err));
    });
};

export const changePasswordRequest = () => ({
  type: A.CHANGE_PASSWORD_REQUEST,
});

export const changePasswordSuccess = data => ({
  type: A.CHANGE_PASSWORD_SUCCESS,
  data,
});

export const changePasswordFailure = error => ({
  type: A.CHANGE_PASSWORD_FAILURE,
  error,
});

export const changePassword = data => (dispatch) => {
  dispatch(changePasswordRequest());
  Api.ChangePasswordToken(data)
    .then(() => {
      dispatch(notifications.successNotification('PasswordChanged'));
      dispatch(changePasswordSuccess());
    })
    .catch((err) => {
      dispatch(changePasswordFailure(err));
    });
};
