import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { FormattedMessage } from 'react-intl';

import ResetPasswordForm from './ResetPasswordForm';
import { Content } from '../styled-components/Login';
import { validate } from '../../../utils/validate';

class ResetPassword extends React.Component {
  handleRoute = () => {
    const { dispatch } = this.props;
    dispatch(push('/'));
  };

  render() {
    return (
      <Content>
        <h3>
          <FormattedMessage id="Login.ResetPassword" />
        </h3>
        <p>
          <FormattedMessage id="Login.ResetPassword.MainText" />
        </p>

        <ResetPasswordForm
          handleLogin={this.handleLogin}
          validators={{
            username: validate.formik.username,
            password: validate.formik.loginPassword,
          }}
        />
      </Content>
    );
  }
}

ResetPassword.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
}

export default connect(mapStateToProps)(ResetPassword);
