import { handleActions } from 'redux-actions';

import {
  FETCH_CONSULTANTS_REQUEST,
  FETCH_CONSULTANTS_SUCCESS,
  FETCH_CONSULTANTS_FAILURE,
  TOGGLE_CONSULTANTS,
} from './constants';

const initialState = {
  personConsultants: [],
  companyConsultants: [],
  consultantsDataLoaded: false,
  isFetchingConsultantsData: false,
  isVisible: false,
};

export default handleActions(
  {
    [FETCH_CONSULTANTS_REQUEST]: (state, action) => ({
      ...state,
      ...action,
      consultantsDataLoaded: false,
      isFetchingConsultantsData: true,
    }),
    [FETCH_CONSULTANTS_SUCCESS]: (state, action) => ({
      ...state,
      personConsultants: action.personConsultants,
      companyConsultants: action.companyConsultants,
      consultantsDataLoaded: true,
      isFetchingConsultantsData: false,
    }),
    [FETCH_CONSULTANTS_FAILURE]: (state, action) => ({
      ...state,
      error: action.error,
      consultantsDataLoaded: false,
      isFetchingConsultantsData: false,
    }),
    [TOGGLE_CONSULTANTS]: (state, { isVisible }) => ({
      ...state,
      isVisible,
    }),
  },
  initialState,
);
