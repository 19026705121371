import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Heading = styled.h3`
  font-family: roboto;
  font-size: 18px;
  text-align: right;
  padding-left: 4px;
  padding-right: 4px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export {
  Row,
  Heading,
};
