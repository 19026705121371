import styled from 'styled-components';
import { Field } from 'formik';

const FormRow = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ColumnWrapper = styled.div`
  margin-top: 24px;
  flex: 1 1 0%;
`;
const ColumnWrapperButton = styled.div`
  margin-top: 24px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const Input = styled(Field)`
  // background-color: rgb(244, 244, 244);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0px;
  box-shadow: none;
  color: rgb(62, 62, 62);
  font-size: 16px;
  height: 40px;
  padding-left: 10px;
  width: 100%;
  margin-bottom: 2px;

  &:focus {
    background-color: #fff;
  }

  ${(props) => {
    if (props.error && props.touched) {
      return `
        background-color: ${props.theme.form.formErrorColor};
        border: ${props.theme.form.formErrorBorder};
    `;
    }
    return '';
  }};
`;

const FormErrorMessage = styled.span`
  color: red;
  float: left;

  &.hide {
    display: none;
  }
`;

const LabelWrapper = styled.div``;
const Label = styled.div`
  margin-bottom: 5px;
`;

export {
  FormRow,
  ColumnWrapper,
  ColumnWrapperButton,
  Input,
  FormErrorMessage,
  LabelWrapper,
  Label,
};
