import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { find, get, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import PayTo from './components/PayTo';
import FinanceDetails from './components/FinanceDetails';
import ButtonRow from './components/ButtonRow';

import AppLoader from '../loader/AppLoader';
import ModalError from '../modal-messages/ModalError';
import { mapPayload, validateDisposal } from './utils';
import ViewDisposal from './components/ViewDisposal';
import DisposalDetails from './components/DisposalDetails';
import { initializeDisposalState } from '../../modules/applications/actions';
import { resetState } from '../../modules/app/actions';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 512px;
`;

const Heading = styled.h2`
  margin: 8px 0;
`;
const Subheading = styled.h3`
  margin: 6px 0;
`;

const LoaderHeading = styled.h4`
  margin-top: 32px;
`;

const Sections = styled.section`
  display: grid;
  row-gap: 8px;
  /* overflow: scroll; */
  margin-top: 16px;
`;

const StyledForm = styled(Form)`
  display: grid;
  grid-template-rows: repeat(3, auto);
  row-gap: 16px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 16px;
`;

const PAY_TO_INITIAL_VALUES = {
  checked: false,
  bank: null,
  ledger: null,
  account: null,
  amount: null,
};

export const CREDIT_INSTITUTION_INITIAL_VALUES = {
  licensePlate: '',
  amount: null,
  creditInstitution: { text: '', value: null },
};

function mapInitialPaymentValues(payment) {
  return (
    payment && {
      bank: payment.bankKey,
      ledger: payment.ctrlKey,
      account: payment.bankAcct,
      amount: payment.paymentAmnt,
      checked:
        (!!payment.bankKey && !!payment.ctrlKey && !!payment.bankAcct && !!payment.paymentAmnt)
        || payment.paymentAmnt > 0,
    }
  );
}

function getCreditInstitution(partnerId, creditInstitutions) {
  if (!partnerId) {
    return {
      text: '',
      value: null,
    };
  }
  return find(creditInstitutions, { value: partnerId });
}

function mapInitialPayOffsValues(payOffs = [], creditInstitutions) {
  if (!payOffs || !payOffs.length) {
    return [CREDIT_INSTITUTION_INITIAL_VALUES];
  }
  return map(payOffs, (payOff) => ({
    licensePlate: payOff.paymentNote,
    amount: payOff.paymentAmnt,
    creditInstitution: getCreditInstitution(payOff.partnerId, creditInstitutions),
  }));
}

const getInitialValues = (values, seller, user, creditInstitutions) => {
  const isSame = user.sellerSSN === seller.ssn;
  const payToDealer = (!isSame && mapInitialPaymentValues(find(values.payments, { registryId: user.sellerSSN })))
    || PAY_TO_INITIAL_VALUES;
  const payToSeller = mapInitialPaymentValues(find(values.payments, { registryId: seller.ssn }))
    || PAY_TO_INITIAL_VALUES;
  const financeDetails = {
    checked: values.payoffs && !!values.payoffs.length,
    creditInstitutions: mapInitialPayOffsValues(values.payoffs, creditInstitutions),
  };

  return { payToDealer, payToSeller, financeDetails };
};

const DisposalModalContent = ({ modal }) => {
  const {
    seller,
    sendDisposalInformation,
    terms: {
      calculatedPayout,
      price,
      cashDeposit,
      priceDepositItem,
      accruedDepositItem,
    },
    mortgage,
  } = useSelector((state) => state.app);

  const loanPayout = price - cashDeposit - (priceDepositItem - accruedDepositItem);

  const { location } = useSelector((state) => state.router);
  const { disposalInformation, creditInstitutions } = useSelector((state) => state.applications);
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(initializeDisposalState());
      if (location.pathname === '/') {
        // Reset the details state whenever we use the modal outside an application
        dispatch(resetState());
      }
    },
    [],
  );

  const { user } = useSelector((state) => state.auth);
  const sapErrorMessage = get(disposalInformation, 'error.errorData.SapErrorMessage');
  const errorKey = get(disposalInformation, 'error.errorKey');
  const errorMessage = sapErrorMessage || errorKey || 'Villa';
  const disabled = !sendDisposalInformation || disposalInformation.status === 'error';
  const isSame = user.sellerSSN === seller.ssn;
  const loading = disposalInformation.status === 'loading'
    || creditInstitutions.status === 'loading'
    || !sendDisposalInformation;
  const isViewMode = sendDisposalInformation === 'D';

  const initialValues = getInitialValues(
    disposalInformation.data,
    // MOCK,
    seller,
    user,
    creditInstitutions.data,
  );

  return (
    <Container>
      {!loading && (
        <>
          <Heading>
            <FormattedMessage id="Disposal.Heading" />
          </Heading>
        </>
      )}
      <Sections>
        {loading && (
          <LoaderWrapper>
            <AppLoader size="192px" borderSize="24px" />
            <LoaderHeading>
              <FormattedMessage id="Disposal.SubheadingLoading" />
            </LoaderHeading>
          </LoaderWrapper>
        )}
        {disposalInformation.status === 'error' && disposalInformation.error && (
          <ModalError
            message={!sapErrorMessage ? <FormattedMessage id={errorMessage} /> : sapErrorMessage}
          />
        )}

        {!loading && isViewMode && (
          <ViewDisposal
            disposalInformation={disposalInformation.data}
            seller={seller}
            user={user}
            creditInstitutions={creditInstitutions.data}
            closeModal={() => modal.onClose()}
            sendToSigning={() => modal.sendToSigning()}
            mortgage={mortgage}
            loanPayout={loanPayout}
          />
        )}
        {!loading && !isViewMode && (
          <Formik
            onSubmit={(values) => {
              const payload = mapPayload(values, seller, user);
              modal.onSign(payload);
            }}
            validate={(values) => validateDisposal(values, calculatedPayout)}
            initialValues={initialValues}
            enableReinitialize
            render={(formProps) => (
              <StyledForm>
                <DisposalDetails formProps={formProps} />
                <PayTo disabled={disabled} formId="payToSeller" formProps={formProps} />
                {!isSame && (
                  <PayTo disabled={disabled} formId="payToDealer" formProps={formProps} />
                )}
                <FinanceDetails disabled={disabled} formId="financeDetails" formProps={formProps} />
                <ButtonRow
                  status={disposalInformation.status}
                  disabled={disabled}
                  formProps={formProps}
                  closeModal={modal.onClose}
                />
              </StyledForm>
            )}
          />
        )}
      </Sections>
    </Container>
  );
};

DisposalModalContent.propTypes = {
  modal: PropTypes.object.isRequired,
};

export default DisposalModalContent;
