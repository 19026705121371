import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { DetailsButton } from '@origo_vl_banking/ibanking-react';
import { map } from 'lodash';
import styled from 'styled-components';
import { injectIntl, FormattedMessage } from 'react-intl';
import { FaRegFileAlt } from 'react-icons/fa';
import Document from './Document';
import { smMin } from '../../utils/constants';

const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-end;

  &:nth-child(1) {
    padding-top: 0px !important;
  }
`;

const DocumentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background: white;
  min-width: 400px;
  top: 33px;
  border: 1px solid ${({ visible }) => (visible ? '#e8e8e8' : 'transparent')};
  /* transition: max-height 0.2s ease-out; */
  max-height: ${({ visible }) => (visible ? '350px' : '0')};
  background: ${({ visible }) => (visible ? 'white' : 'transparent')};
  visibility: ${({ visible }) => (visible ? 'unset' : 'unset')};
  z-index: 5;

  box-shadow: ${({ visible }) => (visible ? '0 10px 20px rgba(0,0,0,0.23), 0 6px 6px rgba(0,0,0,0.27)' : '0')};

  @media all and (max-width: ${smMin}) {
    min-width: 280px;
  }
`;

const Documents = ({
  showDefault, intl, printInfo, appInfo, getPDF,
}) => {
  const [expanded, setExpanded] = useState(false);
  const defaultDocuments = [];
  if (intl) {
    defaultDocuments.push(
      {
        name: intl.formatMessage({ id: 'Application.Authorization.Information' }),
        link: intl.formatMessage({ id: 'Application.Authorization.Information.Link' }),
        id: 'document0',
      },
      {
        name: intl.formatMessage({ id: 'Application.Authorization.Information.Company' }),
        link: intl.formatMessage({ id: 'Application.Authorization.Information.Link.Company' }),
        id: 'document1',
      },
    );
  }

  return (
    <DocumentsContainer>
      <DetailsButton
        onClick={() => setExpanded(!expanded)}
        color="#5b6d7a"
        showDetails={expanded}
        content={(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaRegFileAlt style={{ paddingRight: 8 }} color="hsl(209, 21%, 32%)" size={22} />
            <FormattedMessage id="ApplicationList.Docs" />
          </div>
        )}
      />
      <DocumentsWrapper visible={expanded}>
        {expanded && printInfo.printContract && (
          <Document
            name={intl.formatMessage({ id: 'ApplicationList.Docs.Tooltip' })}
            isPDF
            onClick={() => {
              getPDF(appInfo, '01');
              setExpanded(false);
            }}
          />
        )}
        {expanded && printInfo.printContractRegistry && (
          <Document
            isPDF
            name={intl.formatMessage({ id: 'ApplicationList.RegistrationDoc.Tooltip' })}
            onClick={() => {
              getPDF(appInfo, '02');
              setExpanded(false);
            }}
          />
        )}
        {expanded
          && defaultDocuments.length
          && map(defaultDocuments, (doc) => (
            <Document
              key={doc.id}
              name={doc.name}
              isPDF
              onClick={() => {
                window.open(doc.link, 'mywindow');
                setExpanded(false);
              }}
            />
          ))}
        {expanded && showDefault && (
          <Document
            name="Eyðublöð"
            onClick={() => {
              window.open('https://www.lykill.is/um-lykil/eydublod/', 'mywindow');
              setExpanded(false);
            }}
          />
        )}
      </DocumentsWrapper>
    </DocumentsContainer>
  );
};

Documents.propTypes = {
  showDefault: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  printInfo: PropTypes.object,
  appInfo: PropTypes.object,
  getPDF: PropTypes.func,
};

Documents.defaultProps = {
  showDefault: true,
  printInfo: {},
  appInfo: {},
  getPDF: () => null,
};

export default injectIntl(Documents);
