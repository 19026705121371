import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.dangerLight};
  border: ${({ theme }) => `1px solid ${theme.danger}`};
  margin-bottom: 16px;
`;

const Error = styled.p``;

const ModalError = ({ message }) => (
  <ErrorWrapper>
    <Error>{message}</Error>
  </ErrorWrapper>
);

ModalError.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default ModalError;
