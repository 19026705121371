import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Message = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  margin-bottom: 16px;
`;
export const Text = styled.p`
  margin-bottom: 0;
  margin-top: 8px;
  ${({ inner }) =>
    inner &&
    css`
      margin: 0;
      padding: 0;
    `}

  ${({ weight }) =>
    weight &&
    css`
      font-weight: ${weight};
    `}
`;
export const Heading = styled.h3`
  font-size: 24px;
  line-height: 1.5;
  margin: 8px 0;

  > span {
    font-size: 24px;
  }
`;
