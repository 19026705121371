import React from 'react';
import { PropTypes } from 'prop-types';
import SignContract from './SignContract';
import CreateButton from './CreateButton';
import useDisposalModal from '../../../../hooks/use-disposal-modal';

const ActionButtons = ({ item, printPdf, translate }) => {
  const {
    modal: { onOpen },
  } = useDisposalModal({ year: item.year, serialNumber: item.serialNumber });
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {item.sendContract && <SignContract onClick={() => onOpen(item)} />}
      {item.printContract && (
        <CreateButton
          onClick={() => printPdf(item, '01')}
          title={translate('ApplicationList.Docs.Tooltip')}
        />
      )}
      {item.printContractRegistry && (
        <CreateButton
          onClick={() => printPdf(item, '02')}
          title={translate('ApplicationList.RegistrationDoc.Tooltip')}
        />
      )}
    </div>
  );
};

ActionButtons.propTypes = {
  item: PropTypes.shape({
    printContract: PropTypes.string,
    printContractRegistry: PropTypes.string,
    sendContract: PropTypes.string,
  }).isRequired,
  printPdf: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

export default ActionButtons;
