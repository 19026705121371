import CarRental from './CarRental';
import { NAME } from './constants';
import reducer from './reducer';
import * as actions from './actions';

export default {
  CarRental,
  NAME,
  reducer,
  actions,
};
