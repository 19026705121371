import styled from 'styled-components';
import { xlMin } from '../../../utils/constants';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  text-align: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 300px;
  justify-content: space-between;
`;

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  @media (min-width: ${xlMin}) {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

const NavItem = styled.li`
  background: #fff;
  border-bottom: ${({ navlink, route }) =>
    (navlink === route || route === true ? '3px solid #fdcd28' : 'none')};
  border-right: 1px solid #d1d1d1;
  color: #51616f;
  cursor: pointer;
  display: none;
  font-size: 19px;
  height: 80px;
  justify-content: center;
  line-height: 80px;
  margin-bottom: 0;
  padding: 0px 18px;
  text-align: center;
  width: 155px;

  &:first-child {
    border-left: 1px solid #d1d1d1;
  }

  @media (min-width: ${xlMin}) {
    min-width: 100px;
    display: flex;
  }
`;

export { NavList, NavItem, Container, Row };
