const messages = {
  'is-IS': {
    // 'Merger.Notification.Heading':
    //   'Vegna samruna Lykils, Kviku og TM hefur Lykill fengið nýja kennitölu og nýtt reikningsnúmer.',
    // 'Merger.Notification.Paragraph.1.Heavy':
    //   'Lykill fjármögnun{br}kt. 690121-2200{br}Nýtt reikningsnúmer Lykils er 0358-26-152',
    // 'Merger.Notification.Paragraph.2':
    //   'Stíla þarf reikninga, afsöl og eigendaskráningar á nýju kennitöluna',
    // 'Merger.Notification.Paragraph.3':
    //   'Starfsemin verður áfram að Síðumúla 24. Sama góða starfsfólkið veitir áfram framúrskarandi þjónustu og er með óbreytt netföng og símanúmer',
    // 'Merger.Notification.Paragraph.3.Bullet':
    // 'Í stöðu Samþykkt - Skrá ráðstöfun og senda í undirritun',
    // 'Application.Handbook': 'Handbók',
    // 'Applications.Popover.Sign': 'Senda í rafræna undirritun',
    // 'Form.Error.password-too-short': 'Lykilorðið er of stutt',
    // 'Form.Error.required-field': 'Þetta svæði þarf að fylla út',
    // 'Reset.Password.ChangeRequested.Sent': 'Í vinnslu...',
    // 'Reset.Password.MessageNew': 'Veldu nýtt lykilorð',
  },
  en: {
    // custom translations
    'Table.TH.date': 'Date',
    'Table.TH.status': 'Status',
    'Table.TH.type': 'Funding',
    'Table.TH.applicant': 'Applicant',
    'Table.TH.ssn': 'SSN',
    'Table.TH.licensePlate': 'License Plate',
    'Table.TH.carMake': 'Make',
    'Table.TH.salesMan': 'Salesman',
    'Table.TH.businessManager': 'Business Manager',
    'Table.TH.application': 'Application',
    'Table.TH.documents': 'Documents',
    'Table.TH.registration': 'Registration',
    'Dropdown.Menu': 'Menu',
    'Dropdown.MyContracts': 'My contracts',
    'Dropdown.MyBills': 'My bills',
    'Dropdown.MyDocuments': 'My documents',
    'Dropdown.Applications': 'Applications',
    'Dropdown.MyApplications': 'My applications',
    'Dropdown.ApplyForLykilfe': 'Apply for Lykilfé',
    'Dropdown.ApplyForLykillan': 'Apply for Lykillán',
    'Dropdown.ApplyForLykilsamning': 'Apply for Lykilsamning',
    'Dropdown.ContactUs': 'Contact us',
    'Dropdown.LoggedIn': 'Logged in',
    'Dropdown.Settings': 'Settings',
    'Dropdown.LogOut': 'Log out',
    Login_Username: 'Username',
    'Reset.Password.Heading': 'Reset password',
    'Reset.Password.Submit': 'Send',
    'ApplicationList.SearchPlaceHolder': 'Enter search string',
  },
};

export default messages;
