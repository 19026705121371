import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { find, map, sumBy } from 'lodash';
import { format } from '@origo_vl_banking/utils';
import { FormattedMessage } from 'react-intl';
import { formatSsn } from '../../../utils/format';
import { Button } from '../../button';

const ViewContainer = styled.div``;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;
`;

const PaymentHeading = styled.h5`
  margin-bottom: 8px;
`;
const PaymentWrapper = styled.div``;

const PaymentText = styled.p`
  margin: 0;
  line-height: 1.5;
`;

const HeadingsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const InfoField = styled.div``;
const Recipient = styled(InfoField)``;
const Amount = styled(InfoField)``;
const Bank = styled(InfoField)``;
const Vehicle = styled(InfoField)``;

const Details = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const getName = (ssn, seller, user) => {
  const inSeller = seller.ssn === ssn ? seller.name : false;
  const inDealer = user.sellerSSN === ssn ? user.dealershipName : false;

  return inSeller || inDealer || null;
};

const getCreditInstitutionName = (partnerId, creditInstitutions) => {
  const creditInstitution = find(creditInstitutions, { value: partnerId });
  if (!creditInstitution) return null;
  return creditInstitution.text;
};

const ViewDisposal = ({
  disposalInformation,
  creditInstitutions,
  seller,
  user,
  closeModal,
  sendToSigning,
  mortgage,
  loanPayout,
}) => (
  <ViewContainer>
    <h4><FormattedMessage id="Disposal.Heading.Total" /> {mortgage.mortgageItem} kr. {format.amount(loanPayout)}</h4>
    {map(disposalInformation.payments, (payment) => {
      const name = getName(payment.registryId, seller, user);
      return (
        <PaymentWrapper>
          <HeadingsContainer>
            <PaymentHeading>
              <FormattedMessage id="Disposal.Heading.PayTo" />
            </PaymentHeading>
            <PaymentHeading>
              <FormattedMessage id="Disposal.Heading.Amount" />
            </PaymentHeading>
            <PaymentHeading>
              <FormattedMessage id="Disposal.Heading.BankInfo" />
            </PaymentHeading>
          </HeadingsContainer>
          <Details>
            <Recipient>
              <PaymentText>{name}</PaymentText>
              <PaymentText>{formatSsn(payment.registryId)}</PaymentText>
            </Recipient>
            <Amount>
              <PaymentText>
                {format.amount(payment.paymentAmnt)}
                {' '}
                {payment.paymentCurr}
              </PaymentText>
            </Amount>
            <Bank>
              <PaymentText>
                {payment.bankKey}
                -
                {payment.ctrlKey}
                -
                {payment.bankAcct}
              </PaymentText>
            </Bank>
          </Details>
        </PaymentWrapper>
      );
    })}
    {map(disposalInformation.payoffs, (payOff) => {
      const name = getCreditInstitutionName(payOff.partnerId, creditInstitutions);
      if (!name) return null;
      return (
        <PaymentWrapper>
          <HeadingsContainer>
            <PaymentHeading>
              <FormattedMessage id="Disposal.Heading.Settle" />
            </PaymentHeading>
            <PaymentHeading>
              <FormattedMessage id="Disposal.Heading.Amount" />
            </PaymentHeading>
            <PaymentHeading>
              <FormattedMessage id="Disposal.Heading.LicensePlate" />
            </PaymentHeading>
          </HeadingsContainer>
          <Details>
            <Recipient>{name}</Recipient>
            <Amount>
              {format.amount(payOff.paymentAmnt)}
              {' '}
              {payOff.paymentCurr}
            </Amount>
            <Vehicle>{payOff.paymentNote}</Vehicle>
          </Details>
        </PaymentWrapper>
      );
    })}
    <ButtonsContainer>
      <Button
        background="white"
        border="3px solid hsl(46, 98%, 57%)"
        onClick={closeModal}
        content="Loka"
        primary={false}
      />
      {/* <Button onClick={sendToSigning} content="Senda í rafræna undirritun" /> */}
    </ButtonsContainer>
  </ViewContainer>
);

ViewDisposal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  creditInstitutions: PropTypes.array.isRequired,
  disposalInformation: PropTypes.shape({
    payoffs: PropTypes.array,
    payments: PropTypes.array,
  }).isRequired,
  seller: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  mortgage: PropTypes.object.isRequired,
  loanPayout: PropTypes.number.isRequired,
};

export default ViewDisposal;
