import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { map } from 'lodash';

import { Button } from '../button';
import useMergerMessageModal from './use-merger-message-modal';
import { Text, Heading, Container, Message } from './MergerMessageContent-styles';
import Bullet from './components/Bullet';

const getFormat = (key) => {
  const format = {
    weight: 400,
    Component: Text,
  };

  if (key.includes('Heading')) {
    format.Component = Heading;
  }

  if (key.includes('Heavy')) {
    format.weight = 600;
  }

  if (key.includes('Bullet')) {
    format.Component = Bullet;
  }

  return format;
};

const MergerMessageContent = () => {
  const {
    modal: { onClose },
  } = useMergerMessageModal();
  const { messages } = useSelector(state => state.intl);
  const notifications = Object.keys(messages)
    .filter(key => key.startsWith('Merger.Notification'))
    .sort();

  return (
    <Container>
      <Message>
        {map(notifications, (key, index) => {
          const { weight, Component } = getFormat(key);
          const text = messages[key];
          if (!text) {
            return null;
          }
          return (
            <Component key={`${key}-${index}`} weight={weight}>
              <FormattedMessage id={key} values={{ br: <br /> }} />
            </Component>
          );
        })}
      </Message>
      <Button
        width="128px"
        content={<FormattedMessage id="Merger.Modal.Button.Close" />}
        onClick={onClose}
        style={{ alignSelf: 'center' }}
      />
    </Container>
  );
};

MergerMessageContent.propTypes = {};

export default MergerMessageContent;
