import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { ErrorMessage } from '../../components';
import ChangePasswordForm from './components/ChangePasswordForm';
import { changePassword } from './actions';
import { validate } from '../../utils/validate';

const Content = styled.div`
  width: 400px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
    padding: 16px;
  }
`;

class ChangePassword extends React.Component {
  handleChangePassword = (values) => {
    const { dispatch } = this.props;
    const { oldPassword, newPassword, confirmPassword } = values;

    const payload = {
      oldPassword,
      newPassword,
      confirmPassword,
    };
    dispatch(changePassword(payload));
  };

  render() {
    const { isSubmitting, submissionError, hasChangedPassword } = this.props;
    const isSubmissionError = !isEmpty(submissionError);

    return (
      <Content>
        {isSubmissionError && <ErrorMessage errorKey={submissionError.errorKey} />}
        <h3>
          <FormattedMessage id="Login.ChangePassword" />
        </h3>
        <h5>
          <FormattedMessage id="Login.ChangePassword.MainText" />
        </h5>
        <ChangePasswordForm
          handleChangePassword={this.handleChangePassword}
          formSubmitting={isSubmitting}
          submissionError={submissionError}
          hasChangedPassword={hasChangedPassword}
          validators={{
            oldPassword: validate.formik.oldPassword,
            newPassword: validate.formik.newPassword,
            confirmPassword: validate.formik.confirmPassword,
          }}
        />
      </Content>
    );
  }
}

ChangePassword.propTypes = {
  submissionError: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  hasChangedPassword: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool,
};

ChangePassword.defaultProps = {
  submissionError: {},
  isSubmitting: false,
};

function mapStateToProps(state) {
  return {
    isSubmitting: state.settings.isSubmitting,
    hasChangedPassword: state.settings.hasChangedPassword,
  };
}

export default connect(mapStateToProps)(ChangePassword);
