import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { TransparentButton } from '../../../../components/shared/styled-components';
import ManualSignature from '../../../../components/icons/ManualSignature';
import Popover from '../../../../components/popover/Popover';

const CreateButton = ({ title, onClick }) => {
  const [showPopover, setShowPopover] = useState(false);
  return (
    <Popover isOpen={showPopover} content={title}>
      <TransparentButton
        onMouseEnter={() => setShowPopover(true)}
        onMouseLeave={() => setShowPopover(false)}
        onClick={onClick}
      >
        <ManualSignature title={title} color="#3f3f3f" size="26px" />
      </TransparentButton>
    </Popover>
  );
};

CreateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default CreateButton;
