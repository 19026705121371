import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { AiFillCloseCircle } from 'react-icons/ai';
import { get } from 'lodash';

import { formatBytes } from '../../utils/helpers';
import FileType from './FileType';

const FileWrapper = styled.div`
  align-items: center;

  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 8px;
  height: 48px;
  width: 100%;
  min-width: 304px;
  color: ${({ theme: { primaryTextColor } }) => primaryTextColor};
`;

const Delete = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Description = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;

  row-gap: 4px;
`;

const Name = styled.span`
  font-weight: 600;
  font-family: Roboto;
`;
const Type = styled.div`
  display: flex;
  align-items: center;
`;
const Size = styled.span`
  color: ${({ theme: { secondaryTextColor } }) => secondaryTextColor};
  font-weight: 100;
  font-family: Roboto;
`;

const File = ({ file, removeFile }) => {
  const { danger } = useContext(ThemeContext);
  if (!file) {
    return null;
  }

  const fileName = get(file, 'fileName', 'Skjal')
    .split('.')
    .slice(0, -1)
    .join('.');
  const truncatedName = fileName.length > 24 ? `${fileName.substring(0, 24)}...` : fileName;
  return (
    <FileWrapper>
      <Type>
        <FileType file={file} />
      </Type>
      <Description>
        <Name>{truncatedName}</Name>
        <Size>{formatBytes(file.size)}</Size>
      </Description>
      <Delete role="button" aria-label="Eyða skjali" onClick={removeFile}>
        <AiFillCloseCircle color={danger} />
      </Delete>
    </FileWrapper>
  );
};

File.propTypes = {
  file: PropTypes.shape({
    fileName: PropTypes.shape({
      split: PropTypes.func,
    }),
    size: PropTypes.number,
    category: PropTypes.string,
  }).isRequired,
  removeFile: PropTypes.func.isRequired,
};

export default File;
