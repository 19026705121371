import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { GridContainer, Borders, Title } from './styled-components';
import Sidebar from './components/Sidebar';

function GridView(props) {
  return (
    <GridContainer>
      {/* Grid 1 */}
      <Title style={{ gridArea: 'title1' }}>
        <FormattedMessage id={props.grid1.props.title} />
      </Title>
      <Borders style={{ gridArea: 'grid1' }}>{props.grid1}</Borders>

      {/* Grid 2 */}
      <Title style={{ gridArea: 'title2' }}>
        <FormattedMessage id={props.grid2.props.title} />
      </Title>
      <Borders style={{ gridArea: 'grid2' }}>{props.grid2}</Borders>

      {/* Sidebar */}
      <Title style={{ gridArea: 'titlebar' }}>
        <FormattedMessage
          id={props.title === 'Application.Cost' ? 'Application.Cost' : 'Application.Calculations'}
        />
      </Title>
      <Sidebar
        calculator={props.calculator}
        message={props.message}
        displayButtons={props.displayButtons}
        handleSaveApplication={props.handleSaveApplication}
        handleSendApplication={props.handleSendApplication}
        ssnInfo={props.ssnInfo}
        type={props.type}
        editable={props.editable}
      />

      {/* Grid 3 */}
      {props.grid3 && (
        <Title style={{ gridArea: 'title3' }}>
          <FormattedMessage id={props.grid3.props.title} />
        </Title>
      )}
      {props.grid3 && <Borders style={{ gridArea: 'grid3' }}>{props.grid3}</Borders>}

      <Title style={{ gridArea: 'textboxtitle' }}>
        <FormattedMessage id="Messages" />
      </Title>
      <div style={{ gridArea: 'textboxarea' }}>{props.message} </div>
    </GridContainer>
  );
}

GridView.propTypes = {
  grid1: PropTypes.object.isRequired,
  grid2: PropTypes.object.isRequired,
  grid3: PropTypes.object,
  calculator: PropTypes.object.isRequired,
  title: PropTypes.string,
  message: PropTypes.object,
  handleSaveApplication: PropTypes.func,
  handleSendApplication: PropTypes.func,
  displayButtons: PropTypes.bool.isRequired,
  ssnInfo: PropTypes.object,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editable: PropTypes.bool.isRequired,
};

GridView.defaultProps = {
  grid3: null,
  message: null,
  title: '',
  handleSaveApplication: () => {},
  handleSendApplication: () => {},
  ssnInfo: {},
  type: '',
};

export default GridView;
