import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DisposalModal from '../components/disposal-modal/DisposalModal';
import { getApplication } from '../modules/app/actions';
import {
  getCreditInstitution,
  getDisposalInformation,
  initializeDisposalState,
  postDisposalInformation,
  setApplicationToSign,
  setShowDigitalSignModal,
  setShowDisposalModal,
} from '../modules/applications/actions';

export default (applicationInfo = {}) => {
  const { disposalModal, disposalInformation, creditInstitutions } = useSelector(
    (state) => state.applications,
  );
  const app = useSelector((state) => state.app);
  const { year, serialNumber, sendDisposalInformation } = app;

  const application = {
    year: applicationInfo.year || year,
    serialNumber: applicationInfo.serialNumber || serialNumber,
    sendDisposalInformation: applicationInfo.sendDisposalInformation || sendDisposalInformation,
  };

  const dispatch = useDispatch();
  useMemo(() => {
    if (disposalModal.show && creditInstitutions.status === 'initial') {
      dispatch(getCreditInstitution());
    }
  }, [disposalModal.show, creditInstitutions.status]);

  useMemo(
    () => () => {
      if (disposalInformation.status !== 'initial') {
        dispatch(initializeDisposalState());
      }
    },
    [disposalInformation.status],
  );
  const modal = {
    onClose: () => {
      dispatch(setShowDisposalModal(false));
    },
    onCancel: () => {
      dispatch(setShowDisposalModal(false));
    },
    onOpen: () => {
      if (app.year !== application.year || app.serialNumber !== application.serialNumber) {
        // We want to determine if the application deteails have been retrieved
        dispatch(getApplication(application.year, application.serialNumber));
      }
      if (creditInstitutions.status === 'initial') {
        dispatch(getCreditInstitution());
      }
      if (application.year && application.serialNumber) {
        dispatch(
          getDisposalInformation(
            { year: application.year, serialNumber: application.serialNumber },
            application.sendDisposalInformation === 'D',
          ),
        );
      }
      dispatch(setShowDisposalModal(true));
    },
    onSign: (payload) => {
      dispatch(
        postDisposalInformation({
          year: application.year,
          serialNumber: application.serialNumber,
          ...payload,
        }),
      ).then(() => {
        dispatch(getApplication(application.year, application.serialNumber)).then((res) => {
          dispatch(setShowDigitalSignModal(true));
          return dispatch(setApplicationToSign(res));
        });
        return dispatch(setShowDisposalModal(false));
      });
    },
    sendToSigning: () => {
      dispatch(setApplicationToSign(app));
      dispatch(setShowDigitalSignModal(true));
      return dispatch(setShowDisposalModal(false));
    },
  };

  return {
    modal,
    showModal: disposalModal.show,
    setShowDisposalModal: (show) => dispatch(setShowDisposalModal(show)),
    DisposalModal,
  };
};
