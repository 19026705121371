import React from 'react';

const Info = props => (
  <svg
    width={24}
    height={24}
    fill="white"
    stroke="hsl(46, 98%, 57%)"
    strokeWidth={2.5}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="prefix__feather prefix__feather-info"
    {...props}
  >
    <circle cx={12} cy={12} r={10} />
    <path d="M12 16v-4M12 8h.01" />
  </svg>
);

export default Info;
