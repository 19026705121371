import styled from 'styled-components';

const Card = styled.div`
  text-align: center;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px solid #ddd;
  padding-bottom: 8px;
  margin-right: 5px;
  margin-bottom: 16px;
  margin-left: 12px;
  width: 162px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: ${({ isConsultantInfo }) => isConsultantInfo && 'absolute'};
  top: ${({ isConsultantInfo }) => isConsultantInfo && '32px'};

  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
`;

const CardImage = styled.img`
  height: auto;
  width: 96px;

  padding-top: 8px;
`;

const CardTitle = styled.h3`
  font-size: 13px;
  font-family: roboto;
  margin-top: 0;
  margin-bottom: 0;
`;

const CardText = styled.h5`
  font-size: 12px;
  font-family: roboto;
  margin: 6px 0 0 0;
  font-weight: normal;
`;

const CardInfo = styled.div`
  font-size: 12px;
  margin: 8px;
`;

const EmailButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: none;
    border: none;
  }
`;

const EmailIcon = styled.img`
  height: 22px;
`;

export { EmailButton, EmailIcon, Card, CardImage, CardTitle, CardText, CardInfo };
