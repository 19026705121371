// name of the module
export const NAME = 'app';

export const TEXT_KEYS_REQUEST = `${NAME}/TEXT_KEYS_REQUEST`;
export const TEXT_KEYS_SUCCESS = `${NAME}/TEXT_KEYS_SUCCESS`;
export const TEXT_KEYS_FAILURE = `${NAME}/TEXT_KEYS_FAILURE`;

export const GET_PDF_REQUEST = `${NAME}/GET_PDF_REQUEST`;
export const GET_PDF_SUCCESS = `${NAME}/GET_PDF_SUCCESS`;
export const GET_PDF_FAILURE = `${NAME}/GET_PDF_FAILURE`;

// APPLICATION ACTIONS
export const RESET_STATE = `${NAME}/RESET_STATE`;
export const SET_VALIDATION_ERROR = `${NAME}/SET_VALIDATION_ERROR`;
export const SET_TOUCHED = `${NAME}/SET_TOUCHED`;
export const SET_COMMUNICATION = `${NAME}/SET_COMMUNICATION`;
export const SET_PRINT_INFO = `${NAME}/SET_PRINT_INFO`;

export const GET_APPLICATION_REQUEST = `${NAME}/GET_APPLICATION_REQUEST`;
export const GET_APPLICATION_SUCCESS = `${NAME}/GET_APPLICATION_SUCCESS`;
export const GET_APPLICATION_FAILURE = `${NAME}/GET_APPLICATION_FAILURE`;
export const SAVE_APPLICATION_REQUEST = `${NAME}/SAVE_APPLICATION_REQUEST`;
export const SAVE_APPLICATION_SUCCESS = `${NAME}/SAVE_APPLICATION_SUCCESS`;
export const SAVE_APPLICATION_FAILURE = `${NAME}/SAVE_APPLICATION_FAILURE`;

// CALCULATION ACTIONS
export const CALCULATION_REQUEST = `${NAME}/CALCULATION_REQUEST`;
export const CALCULATION_SUCCESS = `${NAME}/CALCULATION_SUCCESS`;
export const CALCULATION_FAILURE = `${NAME}/CALCULATION_FAILURE`;
export const SET_INTEREST_CHANGED_ALLOWED = `${NAME}/SET_INTEREST_CHANGED_ALLOWED`;
export const SET_INTEREST_PERCENT = `${NAME}/SET_INTEREST_PERCENT`;
export const SET_COMMITMENT_FEE_FLAG = `${NAME}/SET_COMMITMENT_FEE_FLAG`;

// MORTGAGE ACTIONS
export const CAR_INFO_REQUEST = `${NAME}/CAR_INFO_REQUEST`;
export const CAR_INFO_SUCCESS = `${NAME}/CAR_INFO_SUCCESS`;
export const CAR_INFO_FAILURE = `${NAME}/CAR_INFO_FAILURE`;

export const SET_MILEAGE = `${NAME}/SET_MILEAGE`;
export const SET_CAR_INSURANCE_COMPANY = `${NAME}/SET_CAR_INSURANCE_COMPANY`;
export const SET_INSURANCE_COMPANY_NAME = `${NAME}/SET_INSURANCE_COMPANY_NAME`;
export const SET_MORTGAGE_AMOUNT = `${NAME}/SET_MORTGAGE_AMOUNT`;

export const RESET_CAR_INFO = `${NAME}/RESET_CAR_INFO`;

// PERSON ACTIONS
export const GET_PERSON_NAME_REQUEST = `${NAME}/GET_PERSON_NAME_REQUEST`;
export const GET_PERSON_NAME_FAILURE = `${NAME}/GET_PERSON_NAME_FAILURE`;
export const GET_CONSULTANT_SUCCESS = `${NAME}/GET_CONSULTANT_SUCCESS`;
export const GET_APPLICANT_NAME_SUCCESS = `${NAME}/GET_APPLICANT_NAME_SUCCESS`;
export const GET_APPLICANT2_NAME_SUCCESS = `${NAME}/GET_APPLICANT2_NAME_SUCCESS`;
export const GET_GUARANTOR_NAME_SUCCESS = `${NAME}/GET_GUARANTOR_NAME_SUCCESS`;
export const GET_SECOND_GUARANTOR_NAME_SUCCESS = `${NAME}/GET_SECOND_GUARANTOR_NAME_SUCCESS`;
export const GET_SELLER_NAME_SUCCESS = `${NAME}/GET_SELLER_NAME_SUCCESS`;

export const RESET_APPLICANT = `${NAME}/RESET_APPLICANT`;
export const RESET_SECOND_APPLICANT = `${NAME}/RESET_SECOND_APPLICANT`;
export const RESET_SECOND_APPLICANT_FIELDS = `${NAME}/RESET_SECOND_APPLICANT_FIELDS`;
export const RESET_GUARANTOR = `${NAME}/RESET_GUARANTOR`;
export const RESET_GUARANTOR_FIELDS = `${NAME}/RESET_GUARANTOR_FIELDS`;
export const RESET_SECOND_GUARANTOR = `${NAME}/RESET_SECOND_GUARANTOR`;
export const RESET_SECOND_GUARANTOR_FIELDS = `${NAME}/RESET_SECOND_GUARANTOR_FIELDS`;

export const SET_APPLICANT_FIELD = `${NAME}/SET_APPLICANT_FIELD`;
export const SET_SECOND_APPLICANT_FIELD = `${NAME}/SET_SECOND_APPLICANT_FIELD`;
export const SET_GUARANTOR_FIELD = `${NAME}/SET_GUARANTOR_FIELD`;
export const SET_SECOND_GUARANTOR_FIELD = `${NAME}/SET_SECOND_GUARANTOR_FIELD`;
export const SET_SELLER_FIELD = `${NAME}/SET_SELLER_FIELD`;

export const SET_SSN_TYPE = `${NAME}/SET_SSN_TYPE`;
export const SET_PERSON_SSN_TYPE = `${NAME}/SET_PERSON_SSN_TYPE`;

// TERMS ACTIONS
export const SET_TERMS_PRICE = `${NAME}/SET_TERMS_PRICE`;
export const SET_TERMS_CASH_DEPOSIT = `${NAME}/SET_TERMS_CASH_DEPOSIT`;
export const SET_PRICE_DEPOSIT_ITEM = `${NAME}/SET_PRICE_DEPOSIT_ITEM`;
export const SET_DEPOSIT_ITEM = `${NAME}/SET_DEPOSIT_ITEM`;
export const SET_ACCRUED_DEPOSIT_ITEM = `${NAME}/SET_ACCRUED_DEPOSIT_ITEM`;
export const SET_TERMS_DUE_DATE_COUNT = `${NAME}/SET_TERMS_DUE_DATE_COUNT`;
export const SET_CASH_DEPOSIT = `${NAME}/SET_CASH_DEPOSIT`;

export const SET_CAR_TYPE_ID = `${NAME}/SET_CAR_TYPE_ID`;
export const SET_ACCESSORY = `${NAME}/SET_ACCESSORY`;
export const SET_MAX_MILEAGE = `${NAME}/SET_MAX_MILEAGE`;

export const RESET_PRICE_DEPOSIT_ITEM = `${NAME}/RESET_PRICE_DEPOSIT_ITEM`;
export const RESET_ACCRUED_DEPOSIT_ITEM = `${NAME}/RESET_ACCRUED_DEPOSIT_ITEM`;

export const GET_INSURANCE_COMPANIES_REQUEST = `${NAME}/GET_INSURANCE_COMPANIES_REQUEST`;
export const GET_INSURANCE_COMPANIES_SUCCESS = `${NAME}/GET_INSURANCE_COMPANIES_SUCCESS`;
export const GET_INSURANCE_COMPANIES_FAILURE = `${NAME}/GET_INSURANCE_COMPANIES_FAILURE`;
