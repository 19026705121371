import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Modal from '../modal/Modal';

const Container = styled.section`
  min-width: 310px;
  min-height: 120px;
`;

const AuthModal = ({ show, verificationCode, authType }) => {
  const textId = authType === 0 ? 'WebClient.Authenticate.Login.Sim' : 'WebClient.Authenticate.Login.App'; 
  const content = (
    <Container>
      <h3 style={{ marginTop: 8, marginBottom: 8 }}>
        <FormattedMessage id="DigitalSignature.Heading" />
      </h3>
      <div>
        <FormattedMessage id={textId} />:{' '}{verificationCode}
      </div>
    </Container>
  );

  return <Modal content={content} showModal={show} />;
};

AuthModal.propTypes = {
  show: PropTypes.bool.isRequired,
  verificationCode: PropTypes.string.isRequired,
  authType: PropTypes.string.isRequired,
};

export default AuthModal;
