import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal/Modal';
import Content from './Content';
import useDigitalSignModal from '../../hooks/use-digital-sign-modal';

const DigitalSignatureModal = ({ show }) => {
  const { modal } = useDigitalSignModal();

  return <Modal content={<Content />} showModal={show} onClose={modal.onClose} />;
};

DigitalSignatureModal.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default DigitalSignatureModal;
