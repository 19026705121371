import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import DetailsButton from '../button/DetailsButton';
import InterestChange from './InterestChange';
import {
  getCalculate,
  prepareAndCalculate,
  setInterestPercent,
  setCommitmentFeeFlag,
} from '../../modules/app/actions';
import CommitmentFeeChange from './CommitmentFeeChange';
import Discount from './Discount';
import { Borders } from '../grid/styled-components';

const DetailsButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  * > button {
    padding-left: 0px;
  }
`;

const FeesContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 3fr) minmax(0px, 1fr);
  grid-column-gap: 8px;

  height: ${({ show }) => (show ? 'auto' : '0px')};
  overflow: ${({ show }) => (show ? 'unset' : 'hidden')};
`;

const FeeWrapper = styled.div`
  display: flex;
  width: 100%;

  justify-content: ${({ justifyContent }) => justifyContent || 'unset'};

  * > .rc-slider-disabled {
    background-color: transparent;
  }
`;

const Fees = ({
  interestChangeAllowed,
  interestPercent,
  interestMax,
  interestMin,
  commitmentFeeFlag,
  commitmentFeeChangeAllowed,
  calculate,
  setInterest,
  setCommitmentFee,
  discountPct,
  discountAmount,
  paymentToSales,
  initialInterests,
  type,
  editable,
}) => {
  const [expanded, setExpanded] = useState(false);
  const toggle = () => {
    setExpanded(!expanded);
  };
  const handleInterestCalculation = (newInterestPercent) => {
    const returnPercentage = Number(newInterestPercent).toPrecision(5);

    setInterest(returnPercentage);
    calculate();
  };

  const handleCommitmentFee = (commitmentFee) => {
    setCommitmentFee(commitmentFee);
    calculate();
  };

  if (!interestChangeAllowed && !commitmentFeeChangeAllowed) {
    return null;
  }

  if (!commitmentFeeChangeAllowed && interestMin === 0 && interestMax === 0) {
    return null;
  }

  return (
    <Borders style={{ marginTop: 8 }}>
      <DetailsButtonWrapper>
        <DetailsButton
          color="#5b6d7a"
          content={<FormattedMessage id="Fees.Button" />}
          showDetails={expanded}
          onClick={toggle}
        />
        {expanded && (discountAmount > 0 || discountPct > 0 || paymentToSales > 0) && (
          <Discount
            discountPct={discountPct}
            paymentToSales={paymentToSales}
            discountAmount={discountAmount}
          />
        )}
      </DetailsButtonWrapper>
      <FeesContainer show={expanded}>
        {interestChangeAllowed && interestMax > 0 && (
          <FeeWrapper gridArea="interest">
            <InterestChange
              interestMax={interestMax}
              interestMin={interestMin}
              editable={editable}
              initialInterests={initialInterests}
              interestPercent={interestPercent}
              handleInterestCalculation={handleInterestCalculation}
            />
          </FeeWrapper>
        )}
        {commitmentFeeChangeAllowed && (
          <FeeWrapper gridArea="commitmentFee">
            <CommitmentFeeChange
              flexEnd={true && interestMax > 0}
              type={type}
              editable={editable}
              onChange={handleCommitmentFee}
              commitmentFeeFlag={commitmentFeeFlag}
              commitmentFeeChangeAllowed={commitmentFeeChangeAllowed}
            />
          </FeeWrapper>
        )}
      </FeesContainer>
    </Borders>
  );
};

Fees.propTypes = {
  interestChangeAllowed: PropTypes.bool.isRequired,
  interestPercent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  interestMax: PropTypes.number.isRequired,
  interestMin: PropTypes.number.isRequired,
  commitmentFeeFlag: PropTypes.bool.isRequired,
  commitmentFeeChangeAllowed: PropTypes.bool.isRequired,
  calculate: PropTypes.func.isRequired,
  setInterest: PropTypes.func.isRequired,
  setCommitmentFee: PropTypes.func.isRequired,
  discountPct: PropTypes.number,
  discountAmount: PropTypes.number,
  paymentToSales: PropTypes.number,
  initialInterests: PropTypes.number,
  type: PropTypes.number,
  editable: PropTypes.bool.isRequired,
};

Fees.defaultProps = {
  discountPct: null,
  discountAmount: null,
  paymentToSales: null,
  initialInterests: 0,
  type: null,
};

const mapStateToProps = (state) => ({
  interestChangeAllowed: state.app.calculation.interestChangeAllowed,
  interestPercent: state.app.calculation.interestPercent,
  interestMax: state.app.calculation.interestMax,
  interestMin: state.app.calculation.interestMin,
  initialInterests: state.app.calculation.initialInterests,
  discountPct: state.app.calculation.discountPct,
  discountAmount: state.app.calculation.discountAmount,
  paymentToSales: state.app.calculation.paymentToSales,
  commitmentFeeFlag: state.app.fees.commitmentFeeFlag,
  commitmentFeeChangeAllowed: state.app.fees.commitmentFeeChangeAllowed,
  type: state.app.type,
});

const mapDispatchToProps = (dispatch) => ({
  handleCalculation: (data, value) => dispatch(getCalculate(data, value)),
  calculate: (type) => dispatch(prepareAndCalculate(type)),
  setInterest: (interestPercent) => dispatch(setInterestPercent(interestPercent)),
  setCommitmentFee: (commitmentFeeFlag) => dispatch(setCommitmentFeeFlag(commitmentFeeFlag)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fees);
