import React from 'react';
import { push } from 'connected-react-router';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { map } from 'lodash';
import { Modal } from '@origo_vl_banking/ibanking-react';

import { resetState, loadPrecondition } from '../../../modules/app/actions';
import { NavList } from '../styled-components/Navbar';
import { RoutingModal } from '../../../components/modal-content';
import NavButton from './NavButton';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      route: this.props.location.pathname,
      showModal: false,
      currentRoute: '',
    };
    this.dispatch = this.props.dispatch;
  }

  componentDidUpdate(prevProps) {
    // if select route from dropdown
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.dispatch(resetState());
      this.handleRouteInitialSetup();
      this.props.dispatch(push(this.props.location.pathname));
    }
  }

  handleRouteInitialSetup = () => {
    this.setState({ route: this.props.location.pathname });
  };

  handleRoute = (route) => {
    if (this.props.touched) {
      this.setState({ showModal: true, currentRoute: route });
    } else {
      if (route === this.state.route) {
        this.dispatch(loadPrecondition(route));
      }
      this.props.dispatch(push(route));
      this.setState({ route });
    }
  };

  continue = () => {
    const { currentRoute } = this.state;
    this.dispatch(loadPrecondition(currentRoute));
    this.setState({
      route: currentRoute,
      showModal: false,
    });
    this.props.dispatch(push(currentRoute));
  };

  cancel = () => {
    this.setState({ showModal: false });
  };

  hasRole = (role) => {
    const roles = sessionStorage.roles && sessionStorage.roles.split(',');
    if (typeof roles === 'object' && roles.indexOf(role) !== -1) {
      return true;
    }

    return false;
  };

  LOAN_ITEMS = [
    {
      onClick: () => this.handleRoute('/car-loan'),
      route: '/car-loan',
      role: 'Loan',
      messageId: 'sitemap.Loan',
    },
    {
      onClick: () => this.handleRoute('/car-leasing'),
      route: '/car-leasing',
      role: 'CarLeasing',
      messageId: 'sitemap.CarLeasing',
    },
    {
      onClick: () => this.handleRoute('/mortgage-switch'),
      route: '/mortgage-switch',
      role: 'MortgageSwitch',
      messageId: 'sitemap.MortgageSwitch',
    },
    {
      onClick: () => this.handleRoute('/car-rental'),
      route: '/car-rental',
      role: 'CarRental',
      messageId: 'sitemap.CarRental',
    },
    {
      onClick: () => this.handleRoute('/leasing'),
      route: '/leasing',
      role: 'Leasing',
      messageId: 'sitemap.Lease',
    },
  ];

  render() {
    const { route, showModal } = this.state;
    const { handleHome } = this.props;

    return (
      <React.Fragment>
        <Modal
          hideLaunchButton
          hideButton
          content={<RoutingModal continueTo={this.continue} cancelModal={this.cancel} />}
          showModal={showModal}
          customOverlayStyle={{ background: '#000A' }}
          onClose={this.cancel}
        />
        <NavList>
          {map(
            this.LOAN_ITEMS,
            (loan, index) =>
              this.hasRole(loan.role) && (
                <NavButton
                  key={`${loan.role}-${index}`}
                  onClick={loan.onClick}
                  route={route}
                  navlink={loan.route}
                  messageId={loan.messageId}
                />
              ),
          )}
          <NavButton
            onClick={handleHome}
            navlink="/"
            route={route === '/' || route === '/overview'}
            messageId="sitemap.ApplicationList"
          />
        </NavList>
      </React.Fragment>
    );
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  touched: PropTypes.bool.isRequired,
  handleHome: PropTypes.func.isRequired,
};

Navbar.defaultProps = {
  location: {},
};

const mapStateToProps = state => ({
  location: state.router.location,
  touched: state.app.touched,
  type: state.app.type,
  roles: state.auth.user.roles,
});

export default connect(mapStateToProps)(Navbar);
