import { useEffect, useState } from 'react';

export default () => {
  const [showModal, setShowDigitalSignModal] = useState(false);

  useEffect(() => {
    setShowDigitalSignModal(showModal);
  }, [showModal]);

  const toggleModal = state => setShowDigitalSignModal(state || !showModal);

  return {
    toggleModal,
    showModal,
  };
};
