import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { Button } from '../../../components/button';
import { BtnLink } from '../../../components/button/styled-components';

import {
  RowWrapper,
  Input,
  FormErrorMessage,
  Label,
  LabelWrapper,
} from '../styled-components/LoginForm';

const LoginForm = ({
  formSubmitting,
  handleLogin,
  // handleSMSToken,
  validators,
  handleForgotPassword,
}) => (
  <React.Fragment>
    <Formik
      validationSchema={yup.object().shape(validators)}
      initialValues={{ username: '', password: '' }}
      onSubmit={(values) => {
        handleLogin(values);
        // if (!gotSMSToken) {
        //   handleSMSToken(values);
        // } else {
        //   handleLogin(values);
        // }
      }}
      render={({
 handleSubmit, handleChange, handleBlur, touched, errors,
}) => (
  <Form onSubmit={handleSubmit}>
    {/* {gotSMSToken && !isSubmissionError && (
            <PrimaryMessage messageKey="Notification.SMSToken" />
          )} */}
    <React.Fragment>
      <div>
        <LabelWrapper>
          <Label htmlFor="username">
            <FormattedMessage id="Login_Username" />
          </Label>
        </LabelWrapper>
        <Input
          type="text"
          id="username"
          label="username"
          name="username"
          autoComplete="username"
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={formSubmitting}
        />
        {touched.username && errors.username && (
        <FormErrorMessage>
          <FormattedMessage id={errors.username} />
        </FormErrorMessage>
              )}
      </div>

      <RowWrapper>
        <LabelWrapper>
          <Label htmlFor="password">
            <FormattedMessage id="Login_Password" />
          </Label>
        </LabelWrapper>
        <Input
          type="password"
          id="password"
          label="password"
          name="password"
          autoComplete="current-password"
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={formSubmitting}
        />
        {touched.password && errors.password && (
        <FormErrorMessage>
          <FormattedMessage id={errors.password} />
        </FormErrorMessage>
              )}
      </RowWrapper>

      {/* {gotSMSToken && (
              <React.Fragment>
                <LabelWrapper>
                  <Label htmlFor="smstoken">
                    <FormattedMessage id="Field.smstoken" />
                  </Label>
                  {touched.smstoken && errors.smstoken && (
                    <FormErrorMessage>{errors.smstoken}</FormErrorMessage>
                  )}
                </LabelWrapper>
                <Input
                  type="text"
                  id="smstoken"
                  label="smstoken"
                  name="smstoken"
                  placeholder="Token"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </React.Fragment>
            )} */}
    </React.Fragment>
    <Button
      marginTop="24px"
      type="submit"
      loading={formSubmitting}
      content={<FormattedMessage id="Login.LoginButton" />}
    />
    <p>
      <FormattedMessage id="Login.Login.ForgottenPassword" />
    </p>
    <BtnLink onClick={handleForgotPassword}>
      <FormattedMessage id="Login.ForgottenPasswordLink" />
    </BtnLink>
  </Form>
      )}
    />
  </React.Fragment>
);

LoginForm.defaultProps = {
  formSubmitting: false,
};

LoginForm.propTypes = {
  // handleSMSToken: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  formSubmitting: PropTypes.bool,
  validators: PropTypes.object.isRequired,
  handleForgotPassword: PropTypes.func.isRequired,
};

export default LoginForm;
