import React, { Component } from 'react';
import { strip } from '@origo_vl_banking/utils';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  getCarInfo,
  setMilage,
  setCarInsuranceCompany,
  setInsuranceCompanyName,
  getInsuranceCompanies,
  resetCarInfo,
} from '../../app/actions';
import { Mortgage } from '../../../components/shared';

class MortgageContainer extends Component {
  getCarData = (e, handleBlur) => {
    handleBlur(e);
    const { value } = e.target;
    const { mortgage, resetCarInfoHandler } = this.props;
    if (!value) {
      return resetCarInfoHandler();
    }
    if (mortgage.mortgageItem === value) return;
    this.props.getCarInfo(value);
  };

  handleMileageOnBlur = (e, handleBlur) => {
    handleBlur(e);
    const value = parseInt(strip.amount(e.target.value), 10);
    this.props.setMilage(value);
  };

  render() {
    const { app, mortgage } = this.props;
    return (
      <Mortgage
        serialNumber={app.serialNumber}
        editable={app.editable}
        mortgage={mortgage}
        companyName={mortgage.companyName}
        disabled={!app.editable}
        validationError={app.validationError}
        getCarData={this.getCarData}
        handleMileageOnBlur={this.handleMileageOnBlur}
        setCarInsuranceCompany={this.props.setCarInsuranceCompany}
        getInsuranceCompanies={this.props.getInsuranceCompanies}
        setInsuranceCompanyName={this.props.setInsuranceCompanyName}
        showInsuranceCompany={this.props.showInsuranceCompany}
        showDropwdown={false}
        app={app}
        hideInsurance
      />
    );
  }
}

MortgageContainer.propTypes = {
  app: PropTypes.object.isRequired,
  mortgage: PropTypes.object.isRequired,
  getCarInfo: PropTypes.func.isRequired,
  setMilage: PropTypes.func.isRequired,
  resetCarInfoHandler: PropTypes.func.isRequired,
  getInsuranceCompanies: PropTypes.func.isRequired,
  setCarInsuranceCompany: PropTypes.func.isRequired,
  setInsuranceCompanyName: PropTypes.func.isRequired,
  showInsuranceCompany: PropTypes.bool,
};

MortgageContainer.defaultProps = {
  showInsuranceCompany: true,
};

function mapStateToProps({ app }) {
  return { app, mortgage: app.mortgage };
}

const mapDispatchToProps = dispatch => ({
  getCarInfo: value => dispatch(getCarInfo(value)),
  setMilage: value => dispatch(setMilage(value)),
  setCarInsuranceCompany: value => dispatch(setCarInsuranceCompany(value)),
  setInsuranceCompanyName: value => dispatch(setInsuranceCompanyName(value)),
  getInsuranceCompanies: () => dispatch(getInsuranceCompanies()),
  resetCarInfoHandler: () => dispatch(resetCarInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MortgageContainer);
