import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withTheme } from 'styled-components';
import NotificationsSystem from 'reapop';
import notificationTheme from 'reapop-theme-wybo';
import { Power } from '@origo_vl_banking/icons';
import moment from 'moment';

import { logout } from '../auth/actions';
import Container from '../../components/container';
import Navbar from './components/Navbar';
import Consultants from './components/Consultants';
import { Dropdown } from '../../components/dropdown';
import { Backdrop } from '../../components/backdrop';
import Logo from './components/Logo';
import Modal from '../../components/modal/Modal';
import useModal from '../../components/modal/use-modal';
import { LogoutModal } from '../../components/modal-content';

import {
  HeaderContainer,
  Nav,
  Column,
  Image,
  MenuContent,
  LogOutIconWrapper,
  BackdropContacts,
} from './styled-components/Header';
import { toggleConsultants } from './actions';
import {
  getApplications as getApplicationsAction,
  setEndDate as setEndDateAction,
} from '../applications/actions';

const Header = ({
  handleLogout,
  name,
  isAuthenticated,
  location,
  handleHome,
  toggleIsVisible,
  isVisible,
  getApplications,
  startDate,
  endDate,
  setEndDate,
}) => {
  const logoutModal = useModal();
  const [isClosed, setClosed] = useState(true);

  const handleClose = () => {
    setClosed(!isClosed);
    toggleIsVisible(false);
  };

  const handleVisible = () => {
    toggleIsVisible(!isVisible);
  };

  useEffect(() => {
    toggleIsVisible(false);
  }, [location.pathname]);

  const navigateToHome = () => {
    setEndDate();
    if (location.pathname === '/' || location.pathname === '/overview') {
      return getApplications(startDate.toISOString(), moment().toISOString());
    }
    handleHome();
  };

  const cancelLogoutModal = () => {
    logoutModal.toggleModal(false);
  };

  return (
    <HeaderContainer>
      <Container>
        <Modal
          content={
            <LogoutModal
              handleLogout={() => {
                handleLogout();
                cancelLogoutModal();
              }}
              cancelModal={cancelLogoutModal}
            />
          }
          showModal={logoutModal.showModal}
          onClose={cancelLogoutModal}
        />
        <NotificationsSystem theme={notificationTheme} />
        <Nav>
          <Image onClick={navigateToHome}>
            <Logo height="64" isAuthenticated={isAuthenticated} />
          </Image>
          {isAuthenticated && (
            <Column>
              <Navbar handleVisible={handleVisible} handleHome={navigateToHome} />
              <MenuContent>
                <Dropdown
                  handleClose={handleClose}
                  isClosed={isClosed}
                  handleLogout={handleLogout}
                  handleHome={navigateToHome}
                  userFullName={name}
                />
              </MenuContent>
              <LogOutIconWrapper onClick={() => logoutModal.toggleModal()}>
                <Power color="#5b6d7a" />
              </LogOutIconWrapper>
            </Column>
          )}
        </Nav>
        {isAuthenticated && (
          <React.Fragment>
            <Consultants isVisible={isVisible} />
          </React.Fragment>
        )}
      </Container>
      <Backdrop handleClose={handleClose} isClosed={isClosed} />
      <BackdropContacts onClick={() => toggleIsVisible(!isVisible)} isVisible={isVisible} />
    </HeaderContainer>
  );
};

Header.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  name: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  location: PropTypes.object.isRequired,
  handleHome: PropTypes.func.isRequired,
  toggleIsVisible: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  getApplications: PropTypes.func.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  setEndDate: PropTypes.func.isRequired,
};

Header.defaultProps = {
  name: '',
  isAuthenticated: false,
};

function mapStateToProps(state) {
  return {
    name: state.auth.user.name,
    lastLogin: state.auth.user.lastLogin,
    isAuthenticated: state.auth.isAuthenticated,
    location: state.router.location,
    isVisible: state.header.isVisible,
    startDate: state.applications.startDate,
    endDate: state.applications.endDate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleLogout: () => dispatch(logout()),
    handleHome: () => dispatch(push('/')),
    toggleIsVisible: isVisible => dispatch(toggleConsultants(isVisible)),
    getApplications: (startDate, endDate) => dispatch(getApplicationsAction(startDate, endDate)),
    setEndDate: () => dispatch(setEndDateAction(moment())),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Header));
