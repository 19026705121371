import styled from 'styled-components';

import { mdMin, lgMin, xlMin, smMin } from '../../../utils/constants';

export const GridContainer = styled.div`
  margin-bottom: 2%;
  @media (min-width: ${mdMin}) {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas:
    'title1 titlebar'
    'grid1 sidebar'
    'title2 title2'
    'grid2 grid2'
    'title3 title3'
    'grid3 grid3'
    'textboxtitle textboxtitle'
    'textboxarea textboxarea';
    grid-gap: 10px;
  }
  @media (min-width: ${lgMin}) {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    grid-template-areas:
      'title1 title2 titlebar'
      'grid1  grid2  sidebar'
      'title3 title3 textboxtitle'
      'grid3  grid3  textboxarea';
    grid-gap: 10px;
  }

  @media (max-width: ${smMin}) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'title1'
      'grid1'
      'title2'
      'grid2'
      'title3'
      'grid3'
      'titlebar'
      'sidebar'
      'textboxtitle'
      'textboxarea';
  }
`;

export const GridRows = styled.div`
  display: grid;
  grid-row-gap: 12px;
`;

export const TwoColumns = styled.div`
  white-space: nowrap;
  display: grid;
  @media (min-width: ${xlMin}) {
    align-items: center;
    grid-column-gap: 40px;
    grid-row-gap: 8px;
    grid-template-columns: 1fr 1fr;
  }
`;

export const TwoColumnsLg = styled(TwoColumns)`
  grid-row-gap: 12px;
  transition: 250ms;
`;

export const EqualColumns = styled(TwoColumns)`
  @media (min-width: ${xlMin}) {
    grid-column-gap: 20px;
  }
`;

export const Label = styled.span`
  color: ${({ color, theme }) => color || theme.secondaryColor};
  font-size: 14px;
  font-weight: 600;
`;

export const Row = styled.div`
  grid-column: 1 / -1;
`;

export const Hr = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid lightgray;
  margin: 1em 0;
  padding: 0;
`;

export const Line = styled(Row)`
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid lightgray;
`;

export const RowWithBorders = styled(Row)`
  border-bottom: 1px solid lightgray;
  border-top: 1px solid lightgray;
`;

export const TextRight = styled.span`
  font-size: 14px;

  @media (min-width: ${mdMin}) {
    text-align: right;
  }
`;

export const Borders = styled.div`
  border: 1px solid lightgray;
  padding: 20px;
  background: white;
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`;

export const TextName = styled.span`
  padding-top: 20px;
`;

export const Title = styled.h3`
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 20px;
`;

export const RowWrapper = styled.div`
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
