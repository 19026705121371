const formatSsn = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.toString().replace(/[^\d]/g, '');
  if (onlyNums.length <= 6) {
    return onlyNums;
  }
  return `${onlyNums.slice(0, 6)}-${onlyNums.slice(6)}`;
};

export { formatSsn };
