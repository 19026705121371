import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ArrowDown, ArrowUp } from '@origo_vl_banking/icons';

const Wrapper = styled.div`
  padding-top: 6px;
`;

const Button = styled.button`
  cursor: pointer;
  border: 0;
  margin: 0;
  background: 0;
  text-decoration: none;
  outline: 0;
  display: flex;
  align-items: center;
`;

const ButtonText = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.secondaryColor};
  margin-right: 10px;
  text-decoration-color: ${({ theme }) => theme.secondaryColor};
`;

const DetailsButton = ({
  showDetails, onClick, buttonStyle, content,
}) => (
  <Wrapper>
    <Button style={buttonStyle} onClick={onClick}>
      <ButtonText>{content}</ButtonText>
      {showDetails ? (
        <ArrowUp color="hsl(46, 98%, 57%)" size={24} />
      ) : (
        <ArrowDown color="hsl(46, 98%, 57%)" size={24} />
      )}
    </Button>
  </Wrapper>
);

DetailsButton.propTypes = {
  showDetails: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  buttonStyle: PropTypes.object,
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

DetailsButton.defaultProps = {
  onClick: () => null,
  buttonStyle: null,
  content: 'Show details',
};

export default DetailsButton;
