import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from './constants';
import { Api, notifications } from '../../utils';


const changePasswordRequest = () => ({
  type: CHANGE_PASSWORD_REQUEST,
});

const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

const changePasswordFailure = () => ({
  type: CHANGE_PASSWORD_FAILURE,
});

export const changePassword = data => (dispatch) => {
  dispatch(changePasswordRequest(data));
  return new Promise((resolve, reject) => {
    Api.changePassword(data)
      .then((res) => {
        resolve(res.data);
        dispatch(notifications.successNotification('PasswordChanged'));
        dispatch(changePasswordSuccess(res.data));
      })
      .catch((err) => {
        reject(err);
        dispatch(changePasswordFailure(err));
      });
  });
};

