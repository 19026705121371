import React from 'react';
import { PropTypes } from 'prop-types';
import { Modal as IBankingModal } from '@origo_vl_banking/ibanking-react';

const Modal = ({
  content, onClose, showModal, customContentStyle, modalProps,
}) => (
  <IBankingModal
    hideLaunchButton
    hideButton
    content={content}
    showModal={showModal}
    customOverlayStyle={{ background: '#000A' }}
    customContentStyle={customContentStyle}
    onClose={onClose}
    modalProps={modalProps}
  />
);

Modal.propTypes = {
  content: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  customContentStyle: PropTypes.object,
  modalProps: PropTypes.object,
};

Modal.defaultProps = {
  customContentStyle: {},
  modalProps: {},
};

export default Modal;
