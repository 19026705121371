import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import StandardLoader from '../loader/StandardLoader';
import { Label, Borders } from '../grid/styled-components';

const Message = ({
  communication, handleChange, disabled, isFetchingData,
}) => (
  <React.Fragment>
    {isFetchingData && <StandardLoader />}
    <Borders>
      <textarea
        style={{ width: '100%', height: '200px' }}
        value={communication || ''}
        onChange={e => handleChange(e.target.value)}
        disabled={disabled}
      />
    </Borders>
  </React.Fragment>
);

Message.propTypes = {
  communication: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  isFetchingData: PropTypes.bool,
};

Message.defaultProps = {
  communication: '',
  handleChange: () => {},
  disabled: false,
  isFetchingData: false,
};

export default Message;
