import React, { Component } from 'react';
import { strip } from '@origo_vl_banking/utils';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get, replace } from 'lodash';
import { validate } from '../../../utils/validate';
import {
  setMilage,
  setCarInsuranceCompany,
  setInsuranceCompanyName,
  getCarInfo,
  prepareAndCalculate,
  getInsuranceCompanies,
  resetCarInfo,
  getSellerName,
  setSellerField,
} from '../../app/actions';
import { Mortgage } from '../../../components/shared';
import { appType } from '../../../utils/constants';


class MortgageContainer extends Component {
  getCarData = (e, handleBlur) => {
    handleBlur(e);
    const { value } = e.target;
    const { mortgage, resetCarInfoHandler } = this.props;
    if (!value) {
      return resetCarInfoHandler();
    }
    if (!value || get(mortgage, 'mortgageItem') === value) return;

    this.props.getCarInfo(value).then(() => this.props.prepareAndCalculate(appType.carLeasing));
  };

  handleMileageOnBlur = (e, handleBlur) => {
    handleBlur(e);
    const value = parseInt(strip.amount(e.target.value), 10);
    this.props.setMilage(value);
  };


  parseValue = (e) => {
    const { value } = e.target;
    if (!value) return null;
    const parsedValue = replace(value, '-', '');
    if (!parsedValue || !validate.ssn(parsedValue)) return null;
    return parsedValue;
  };

  handleSellerSsn = (e) => {
    const parsedValue = this.parseValue(e);

    if (parsedValue === null) {
      this.props.setSellerField('ssn', '');
      this.props.setSellerField('name', '');
      return;
    }

    if (parsedValue === this.props.app.seller.ssn) {
      return;
    }

    this.props.getSellerName(parsedValue);
    this.props.setSellerField('ssn', parsedValue);
  };

  render() {
    const {
      app,
      mortgage,
      disabled,
      validationError,
      setCarInsuranceCompany,
      getInsuranceCompanies,
      setInsuranceCompanyName,
      showInsuranceCompany,
    } = this.props;

    return (
      <Mortgage
        editable={app.editable}
        mortgage={mortgage || {}}
        companyName={get(mortgage, 'companyName', '')}
        disabled={disabled}
        serialNumber={app.serialNumber}
        validationError={validationError}
        setCarInsuranceCompany={setCarInsuranceCompany}
        getInsuranceCompanies={getInsuranceCompanies}
        setInsuranceCompanyName={setInsuranceCompanyName}
        getCarData={this.getCarData}
        handleMileageOnBlur={this.handleMileageOnBlur}
        isFetchingData={get(mortgage, 'isFetchingData', false)}
        showInsuranceCompany={showInsuranceCompany}
        app={app}
        handleSeller={this.handleSellerSsn}
      />
    );
  }
}

MortgageContainer.propTypes = {
  mortgage: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  getCarInfo: PropTypes.func.isRequired,
  prepareAndCalculate: PropTypes.func.isRequired,
  setCarInsuranceCompany: PropTypes.func.isRequired,
  resetCarInfoHandler: PropTypes.func.isRequired,
  getInsuranceCompanies: PropTypes.func.isRequired,
  setInsuranceCompanyName: PropTypes.func.isRequired,
  setMilage: PropTypes.func.isRequired,
  showInsuranceCompany: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validationError: PropTypes.string.isRequired,
  getSellerName: PropTypes.func.isRequired,
  setSellerField: PropTypes.func.isRequired,
};

function mapStateToProps({ app }) {
  return {
    app,
    mortgage: app.mortgage,
    disabled: !app.editable,
    validationError: app.validationError,
  };
}

const mapDispatchToProps = {
  getCarInfo,
  setMilage,
  resetCarInfoHandler: resetCarInfo,
  setCarInsuranceCompany,
  setInsuranceCompanyName,
  prepareAndCalculate,
  getInsuranceCompanies,
  getSellerName,
  setSellerField,
};

export default connect(mapStateToProps, mapDispatchToProps)(MortgageContainer);
