import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import GridView from '../../components/grid/GridView';
import { CurrentInfo, Cost, NewMortgage } from './components';
import { Message, Header } from '../../components/shared';
import SmartLoader from '../../components/loader/SmartLoader';
import {
  saveMortgageSwitch,
  sendMortgageSwitch,
  getMortgageDetails,
  setCommunication,
  resetMortageState,
} from './actions';
import { getInsuranceCompanies } from '../app/actions';
import { HeaderWrapper } from '../../components/shared/styled-components';

class Mortgage extends Component {
  state = {};

  componentDidMount() {
    const { handleGetInsuranceCompanies, handleGetMortgageDetails, location } = this.props;
    const { state } = location;

    if (state) {
      handleGetMortgageDetails({ year: state.year, serialNumber: state.serialNumber });
    }

    handleGetInsuranceCompanies();
  }

  componentWillUnmount() {
    const { handleUnmount } = this.props;

    handleUnmount();
  }

  handleSaveMortgage = () => {
    const {
      oldMortgage,
      newMortgage,
      applicants,
      guarantors,
      contractNumber,
      statusText,
      type,
      typeText,
      seller,
      handleSaveMortgageSwitch,
      year,
      serialNumber,
      communication,
    } = this.props;

    handleSaveMortgageSwitch({
      status: '5',
      communication,
      oldMortgage,
      newMortgage,
      applicants,
      guarantors,
      contractNumber,
      statusText,
      type,
      typeText,
      changeType: 'LEI',
      currency: 'ISK',
      seller,
      year,
      serialNumber,
    });
  };

  handleSendMortgage = () => {
    const {
      oldMortgage,
      newMortgage,
      applicants,
      guarantors,
      contractNumber,
      statusText,
      type,
      typeText,
      seller,
      handleSendMortgageSwitch,
      year,
      serialNumber,
      communication,
    } = this.props;

    handleSendMortgageSwitch({
      status: '10',
      communication,
      oldMortgage,
      newMortgage,
      applicants,
      guarantors,
      contractNumber,
      statusText,
      type,
      typeText,
      currency: 'ISK',
      seller,
      year,
      serialNumber,
    });
  };

  render() {
    const {
      loading, communication, handleSetCommunication, disabled,
    } = this.props;
    
    return (
      <React.Fragment>
        <HeaderWrapper>
          <Header title="sitemap.MortgageSwitch" />
        </HeaderWrapper>
        <SmartLoader loading={loading} />

        <GridView
          grid1={<CurrentInfo title="Application.CurrentInfo" />}
          grid2={<NewMortgage title="Application.NewMortgage" />}
          // message={<SmartMessage />}
          message={
            <Message
              disabled={disabled}
              handleChange={handleSetCommunication}
              communication={communication}
            />
          }
          calculator={<Cost />}
          editable={!disabled}
          displayButtons={!disabled}
          handleSaveApplication={this.handleSaveMortgage}
          handleSendApplication={this.handleSendMortgage}
          title="Application.Cost"
        />
      </React.Fragment>
    );
  }
}

Mortgage.propTypes = {
  applicants: PropTypes.array,
  communication: PropTypes.string,
  contractNumber: PropTypes.string,
  guarantors: PropTypes.array,
  handleGetInsuranceCompanies: PropTypes.func.isRequired,
  handleGetMortgageDetails: PropTypes.func.isRequired,
  handleSaveMortgageSwitch: PropTypes.func.isRequired,
  handleSendMortgageSwitch: PropTypes.func.isRequired,
  handleSetCommunication: PropTypes.func.isRequired,
  handleUnmount: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  location: PropTypes.object,
  newMortgage: PropTypes.object,
  oldMortgage: PropTypes.object,
  seller: PropTypes.string,
  serialNumber: PropTypes.string,
  statusText: PropTypes.string,
  type: PropTypes.string,
  typeText: PropTypes.string,
  year: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

Mortgage.defaultProps = {
  communication: null,
  applicants: [],
  guarantors: [],
  newMortgage: {},
  oldMortgage: {},
  contractNumber: '',
  loading: false,
  location: {},
  statusText: null,
  type: null,
  typeText: null,
  seller: null,
  year: '0000',
  serialNumber: '00000000',
};

const mapStateToProps = ({ app, mortgage }) => ({
  applicants: mortgage.applicants,
  guarantors: mortgage.guarantors,
  newMortgage: mortgage.newMortgage,
  oldMortgage: mortgage.oldMortgage,
  contractNumber: mortgage.contractNumber,
  statusText: mortgage.statusText,
  type: mortgage.type,
  typeText: mortgage.typeText,
  currency: mortgage.currency,
  seller: mortgage.seller,
  mortgage,
  year: mortgage.year,
  serialNumber: mortgage.serialNumber,
  communication: mortgage.communication,
  loading:
    mortgage.isFetchingMortgageInfo ||
    mortgage.isFetchingCarInfo ||
    mortgage.isFetchingMortgageDetails ||
    app.isFetchingData ||
    mortgage.isFetchingData,
  disabled: !mortgage.editable,
});

const mapDispatchToProps = dispatch => ({
  handleSaveMortgageSwitch: data => dispatch(saveMortgageSwitch(data)),
  handleSendMortgageSwitch: data => dispatch(sendMortgageSwitch(data)),
  handleGetInsuranceCompanies: () => dispatch(getInsuranceCompanies()),
  handleGetMortgageDetails: data => dispatch(getMortgageDetails(data)),
  handleSetCommunication: communication => dispatch(setCommunication(communication)),
  handleUnmount: () => dispatch(resetMortageState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Mortgage);
