import { handleActions } from 'redux-actions';

import * as A from './constants';

const initialState = {
  locale: 'is-IS',
  textKeys: {},
  textKeysLoaded: false,
  isFetchingTextKeys: false,
  dataLoaded: false,
  isFetchingData: false,
  isSavingApplication: false, // both for save and send
  hasSavedApplication: false,
  editable: true,
  touched: false,
  validationError: '',
  communication: '',
  error: {},
  calculation: {
    payoutRate: 0,
    actualPayoutRate: 0,
    // firstInterestDate: Date.now(),
    interestPercent: 0,
    costPercent: 0,
    plannedFirstPayment: 0,
    plannedAveragePayment: 0,
    inflationPercent: 0,
    cashFlow: null,
    funding: 0,
    saleDiscount: 0,
    interestChangeAllowed: false,
    interestMax: 0,
    interestMin: 0,
  },
  fees: {
    administrationFee: 0,
    registrationFee: 0,
    commitmentFee: 0,
    commitmentFeeFlag: false,
    commitmentFeeChangeAllowed: false,
  },
  mortgage: {
    mortgageAmount: 0,
    insuranceCompanyId: '',
  },
  terms: {
    price: 0,
    cashDeposit: 0,
    priceDepositItem: 0,
    accruedDepositItem: 0,
    depositItem: '',
    dueDateCount: 0,
    calculatedPayout: 0,
  },
  seller: {
    cip: '',
    companyName: '',
    contactPerson: '',
    email: '',
    name: '',
    occupation: '',
    ssn: '',
    telephone: '',
  },
  applicant: {
    ssn: '',
    name: '',
    telephone: '',
    email: '',
    occupation: '',
    companyName: '',
    contactPerson: '',
    cip: '',
  },
  secondApplicant: {
    ssn: '',
    name: '',
    telephone: '',
    email: '',
    occupation: '',
    companyName: '',
    contactPerson: '',
    cip: '',
  },
  car: {},
  guarantor: {},
  secondGuarantor: {},
  consultant: {},
  companies: [],
  printInfo: {},
  isFetchingContract: false,
  hasFetchedContract: false,
  isCalculationValid: true,
  SapErrorMessage: '',
  sendDocuemnt: false,
  sendDocumentCount: 0,
  sendDisposalInformation: false,
};

export default handleActions(
  {
    [A.RESET_STATE]: state => ({
      ...initialState,
      textKeys: state.textKeys,
      textKeysLoaded: state.textKeysLoaded,
      printInfo: state.printInfo,
    }),
    [A.TEXT_KEYS_REQUEST]: (state, action) => ({
      ...state,
      ...action,
      textKeysLoaded: false,
      isFetchingTextKeys: true,
    }),
    [A.TEXT_KEYS_SUCCESS]: (state, { textKeys, intlSelector }) => ({
      ...state,
      textKeys,
      intlSelector,
      textKeysLoaded: true,
      isFetchingTextKeys: false,
    }),
    [A.TEXT_KEYS_FAILURE]: (state, error) => ({
      ...state,
      error,
      textKeysLoaded: false,
      isFetchingTextKeys: false,
    }),
    [A.GET_APPLICATION_REQUEST]: state => ({
      ...state,
      dataLoaded: false,
      isFetchingData: true,
    }),
    [A.GET_APPLICATION_SUCCESS]: (state, { data }) => {
      const newData = {
        year: data.year,
        type: data.type,
        startDate: data.startDate,
        typeText: data.typeText,
        status: data.status,
        statusText: data.statusText,
        serialNumber: data.serialNumber,
        editable: data.editable,
        lastChanged: data.lastChanged,
        contract: data.contract,
        communication: data.communication || '',
        calculation: data.calculation,
        mortgage: data.mortgage,
        car: data.car,
        fees: data.fees,
        terms: data.terms,
        seller: data.persons.seller || initialState.seller,
        applicant: data.persons.applicant || initialState.applicant,
        secondApplicant: data.persons.secondApplicant || initialState.secondApplicant,
        guarantor: data.persons.guarantor || initialState.guarantor,
        secondGuarantor: data.persons.secondGuarantor || initialState.secondGuarantor,
        consultant: data.persons.consultant || initialState.consultant,
        sendContract: data.sendContract,
        sendDisposalInformation: data.sendDisposalInformation,
        sendDocumentCount: data.sendDocumentCount,
        sendDocument: data.sendDocument,
        message1: data.message1,
        message2: data.message2,
      };

      const { applicant, powerOfAttorney, guarantor } = data.persons;

      if (
        applicant
        && applicant.ssn
        && applicant.ssn.length === 10
        && parseInt(applicant.ssn.slice(0, 2), 10) > 31
      ) {
        newData.guarantor = powerOfAttorney;
        newData.secondGuarantor = guarantor;
      }

      if (
        state.printInfo.year === data.year &&
        state.printInfo.serialNumber === data.serialNumber
      ) {
        newData.printInfo = state.printInfo;
      }

      return {
        ...initialState,
        dataLoaded: true,
        textKeys: state.textKeys,
        textKeysLoaded: state.textKeysLoaded,
        ...newData,
      };
    },
    [A.GET_APPLICATION_FAILURE]: (state, { error }) => ({
      ...state,
      error,
      dataLoaded: false,
      isFetchingData: false,
    }),
    [A.CALCULATION_REQUEST]: state => ({
      ...state,
      dataLoaded: false,
      isFetchingData: true,
    }),
    [A.CALCULATION_SUCCESS]: (state, { data }) => ({
      ...state,
      calculation: data.calculation,
      fees: data.fees,
      terms: data.terms,
      car: data.car,
      dataLoaded: true,
      isFetchingData: false,
      isCalculationValid: true,
      SapErrorMessage: '',
    }),
    [A.CALCULATION_FAILURE]: (state, { calculation, error, SapErrorMessage }) => ({
      ...state,
      ...calculation,
      error,
      dataLoaded: false,
      isFetchingData: false,
      isCalculationValid: false,
      SapErrorMessage,
    }),
    [A.SAVE_APPLICATION_REQUEST]: state => ({
      ...state,
      touched: false,
      isSavingApplication: true,
      hasSavedApplication: false,
    }),
    [A.SAVE_APPLICATION_SUCCESS]: (state, { data }) => ({
      ...state,
      year: data.year || state.year, // if save from data, if update from state
      serialNumber: data.serialNumber || state.serialNumber,
      isSavingApplication: false,
      hasSavedApplication: true,
    }),
    [A.SAVE_APPLICATION_FAILURE]: (state, { error }) => ({
      ...state,
      error,
      isSavingApplication: false,
      hasSavedApplication: false,
    }),
    [A.SET_VALIDATION_ERROR]: (state, { validationError }) => ({
      ...state,
      validationError,
    }),
    [A.SET_TOUCHED]: (state, { touched }) => ({
      ...state,
      touched,
    }),
    [A.GET_PERSON_NAME_REQUEST]: state => ({
      ...state,
      touched: false,
      isFetchingData: true,
      dataLoaded: false,
    }),
    [A.GET_PERSON_NAME_FAILURE]: (state, { error }) => ({
      ...state,
      isFetchingData: false,
      dataLoaded: false,
      error,
    }),
    [A.GET_CONSULTANT_SUCCESS]: (state, { data }) => ({
      ...state,
      isFetchingData: false,
      dataLoaded: true,
      consultant: {
        ...state.consultant,
        ...data,
      },
    }),
    [A.GET_APPLICANT_NAME_SUCCESS]: (state, { name }) => ({
      ...state,
      isFetchingData: false,
      dataLoaded: true,
      applicant: {
        ...state.applicant,
        name,
      },
    }),
    [A.GET_GUARANTOR_NAME_SUCCESS]: (state, { name }) => ({
      ...state,
      isFetchingData: false,
      dataLoaded: true,
      guarantor: {
        ...state.guarantor,
        name,
      },
    }),
    [A.GET_SECOND_GUARANTOR_NAME_SUCCESS]: (state, { name }) => ({
      ...state,
      isFetchingData: false,
      dataLoaded: true,
      secondGuarantor: {
        ...state.secondGuarantor,
        name,
      },
    }),
    [A.GET_APPLICANT2_NAME_SUCCESS]: (state, { name }) => ({
      ...state,
      isFetchingData: false,
      dataLoaded: true,
      secondApplicant: {
        ...state.secondApplicant,
        name,
      },
    }),
    [A.RESET_APPLICANT]: state => ({
      ...state,
      applicant: {
        ...state.applicant,
        ssn: '',
        name: '',
      },
    }),
    [A.RESET_SECOND_APPLICANT]: state => ({
      ...state,
      secondApplicant: {
        ...state.secondApplicant,
        ssn: '',
        name: '',
      },
    }),
    [A.RESET_SECOND_APPLICANT_FIELDS]: state => ({
      ...state,
      secondApplicant: {},
    }),
    [A.GET_SELLER_NAME_SUCCESS]: (state, { name }) => ({
      ...state,
      isFetchingData: false,
      dataLoaded: true,
      seller: {
        ...state.seller,
        name,
      },
    }),
    [A.CAR_INFO_REQUEST]: state => ({
      ...state,
      isFetchingData: true,
      dataLoaded: false,
    }),
    // MORTGAGE_INFO
    [A.CAR_INFO_SUCCESS]: (state, { car }) => ({
      ...state,
      isFetchingData: false,
      dataLoaded: true,
      mortgage: {
        ...state.mortgage,
        mortgageItem: car.number,
        mortgageType: car.type,
        mortgageSubType: car.subType,
        mortgageModelYear: car.firstRegistration,
        mortgageGreen: car.green,
      },
    }),
    [A.CAR_INFO_FAILURE]: (state, { error }) => ({
      ...state,
      isFetchingData: false,
      dataLoaded: false,
      error,
    }),
    [A.SET_COMMUNICATION]: (state, { communication }) => ({
      ...state,
      communication,
    }),
    [A.SET_MILEAGE]: (state, { mortgageMilage }) => ({
      ...state,
      mortgage: {
        ...state.mortgage,
        mortgageMilage,
      },
    }),
    [A.RESET_CAR_INFO]: state => ({
      ...state,
      mortgage: {
        ...state.mortgage,
        mortgageItem: '',
        mortgageType: '',
        mortgageSubType: '',
        mortgageYear: '',
      },
    }),
    [A.SET_MORTGAGE_AMOUNT]: (state, { mortgageAmount }) => ({
      ...state,
      mortgageAmount,
    }),
    [A.SET_CAR_INSURANCE_COMPANY]: (state, { insuranceCompanyId }) => ({
      ...state,
      mortgage: {
        ...state.mortgage,
        insuranceCompanyId,
      },
    }),
    [A.GET_INSURANCE_COMPANIES_SUCCESS]: (state, { companies }) => ({
      ...state,
      companies,
    }),
    [A.SET_INSURANCE_COMPANY_NAME]: (state, { companyName }) => ({
      ...state,
      mortgage: {
        ...state.mortgage,
        companyName,
      },
    }),
    [A.SET_INTEREST_CHANGED_ALLOWED]: (state, { commitmentFeeFlag }) => ({
      ...state,
      fees: {
        ...state.fees,
        commitmentFeeFlag,
      },
    }),
    [A.SET_TERMS_PRICE]: (state, { price }) => ({
      ...state,
      terms: {
        ...state.terms,
        price,
      },
    }),
    [A.SET_TERMS_CASH_DEPOSIT]: (state, { cashDeposit }) => ({
      ...state,
      terms: {
        ...state.terms,
        cashDeposit,
      },
    }),
    [A.SET_PRICE_DEPOSIT_ITEM]: (state, { priceDepositItem }) => ({
      ...state,
      terms: {
        ...state.terms,
        priceDepositItem,
      },
    }),
    [A.SET_ACCRUED_DEPOSIT_ITEM]: (state, { accruedDepositItem }) => ({
      ...state,
      terms: {
        ...state.terms,
        accruedDepositItem,
      },
    }),
    [A.SET_DEPOSIT_ITEM]: (state, { depositItem }) => ({
      ...state,
      terms: {
        ...state.terms,
        depositItem,
      },
    }),
    [A.RESET_PRICE_DEPOSIT_ITEM]: state => ({
      ...state,
      terms: {
        ...state.terms,
        priceDepositItem: 0,
      },
    }),
    [A.RESET_ACCRUED_DEPOSIT_ITEM]: state => ({
      ...state,
      terms: {
        ...state.terms,
        accruedDepositItem: 0,
      },
    }),
    [A.SET_TERMS_DUE_DATE_COUNT]: (state, { dueDateCount }) => ({
      ...state,
      terms: {
        ...state.terms,
        dueDateCount,
      },
    }),
    [A.SET_APPLICANT_FIELD]: (state, { field, value }) => ({
      ...state,
      applicant: {
        ...state.applicant,
        [field]: value,
      },
    }),
    [A.SET_SECOND_APPLICANT_FIELD]: (state, { field, value }) => ({
      ...state,
      secondApplicant: {
        ...state.secondApplicant,
        [field]: value,
      },
    }),
    [A.SET_GUARANTOR_FIELD]: (state, { field, value }) => ({
      ...state,
      guarantor: {
        ...state.guarantor,
        [field]: value,
      },
    }),
    [A.RESET_GUARANTOR]: state => ({
      ...state,
      guarantor: {
        ...state.guarantor,
        ssn: '',
        name: '',
      },
    }),
    [A.RESET_GUARANTOR_FIELDS]: state => ({
      ...state,
      guarantor: {},
    }),
    [A.SET_SECOND_GUARANTOR_FIELD]: (state, { field, value }) => ({
      ...state,
      secondGuarantor: {
        ...state.secondGuarantor,
        [field]: value,
      },
    }),
    [A.RESET_SECOND_GUARANTOR]: state => ({
      ...state,
      secondGuarantor: {
        ...state.secondGuarantor,
        ssn: '',
        name: '',
      },
    }),
    [A.RESET_SECOND_GUARANTOR_FIELDS]: state => ({
      ...state,
      secondGuarantor: {},
    }),
    [A.SET_SELLER_FIELD]: (state, { field, value }) => ({
      ...state,
      seller: {
        ...state.seller,
        [field]: value,
      },
    }),
    [A.SET_SSN_TYPE]: (state, { value }) => ({
      ...state,
      ssnType: value,
    }),
    [A.SET_PERSON_SSN_TYPE]: (state, { data, ssn }) => ({
      ...state,
      ssnInfo: {
        ...state.ssnInfo,
        ...data,
        ssn,
      },
    }),
    [A.SET_CAR_TYPE_ID]: (state, { typeId }) => ({
      ...state,
      car: {
        ...state.car,
        typeId,
      },
    }),
    [A.SET_ACCESSORY]: (state, { accessory }) => ({
      ...state,
      car: {
        ...state.car,
        accessory,
      },
    }),
    [A.SET_MAX_MILEAGE]: (state, { maxMilage }) => ({
      ...state,
      car: {
        ...state.car,
        maxMilage,
      },
    }),
    [A.SET_PRINT_INFO]: (state, { printInfo }) => ({
      ...state,
      printInfo,
    }),
    [A.SET_CASH_DEPOSIT]: (state, { cashDeposit }) => ({
      ...state,
      terms: {
        ...state.terms,
        cashDeposit,
      },
    }),
    [A.GET_PDF_REQUEST]: state => ({
      ...state,
      isFetchingContract: true,
      hasFetchedContract: false,
    }),
    [A.GET_PDF_SUCCESS]: state => ({
      ...state,
      isFetchingContract: false,
      hasFetchedContract: true,
    }),
    [A.GET_PDF_FAILURE]: (state, { error }) => ({
      ...state,
      error,
      isFetchingContract: false,
      hasFetchedContract: false,
    }),
    [A.SET_INTEREST_PERCENT]: (state, { interestPercent }) => ({
      ...state,
      calculation: {
        ...state.calculation,
        interestPercent,
      },
    }),
    [A.SET_COMMITMENT_FEE_FLAG]: (state, { commitmentFeeFlag }) => ({
      ...state,
      fees: {
        ...state.fees,
        commitmentFeeFlag,
      },
    }),
  },
  initialState,
);
