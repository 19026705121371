import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Formik } from 'formik';
import { strip } from '@origo_vl_banking/utils';
import { DetailsButton } from '@origo_vl_banking/ibanking-react';
import * as yup from 'yup';

import TextInput from '../text-input/TextInput';

import { validate } from '../../utils/validate';

import {
  setDepositItem,
  setTermsPrice,
  setTermsCashDeposit,
  setPriceDepositItem,
  setAccruedDepositItem,
  setMortgageAmount,
  prepareAndCalculate,
  resetPriceDepositItem,
  resetAccruedDepositItem,
} from '../../modules/app/actions';
import { TwoColumnsLg, Row } from '../grid/styled-components';

import { preventNumError, normalizeMortgageItem } from '../../utils/helpers';

const validators = {
  price: validate.formik.amount,
  priceDepositItem: validate.formik.amount,
  cashDeposit: validate.formik.amount,
  accruedDepositItem: validate.formik.amount,
};
const depositPercent = 0.2;

// const MortgageHeading = styled.h4``;

// const SwitchWrapper = styled.div` // ! Keep for posterity, as it will be reused here later
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   padding-top: 4px;
//   padding-left: 16px;

//   .react-switch-bg {
//     border: 1px solid rgb(150, 150, 150);
//   }
// `;

const DetailsButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  * {
    /* padding-left: 0px; */
    padding-top: 0px;
  }
`;

const SmartTerms = ({
  app,
  type,
  setItemNumber,
  setPrice,
  setCashDeposit,
  setDepositItemPrice,
  setDepositItemAccrued,
  setMortgagePrice,
  prepareAndCalc,
  resetPriceDepositItemHandler,
  // resetAccruedDepositItemHandler,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [depositError, setDepositError] = useState();
  const [depositItemError, setDepositItemError] = useState();
  const [depositItemAccruedError, setDepositItemAccruedError] = useState();
  const [cashDepositPercent, setCashDepositPercent] = useState();

  const { terms, validationError } = app;
  const disabled = !app.editable;

  useEffect(() => {
    setMortgagePrice(terms.priceDepositItem - terms.accruedDepositItem);
  }, [terms.priceDepositItem]);

  useEffect(() => {
    setCashDepositPercent(Math.round((terms.cashDeposit / terms.price) * 100));
  }, [
    terms.price,
    terms.cashDeposit,
    terms.priceDepositItem,
    terms.accruedDepositItem,
    terms.depositItem,
    terms.dueDateCount,
  ]);

  const handlePrice = (e) => {
    const price = parseInt(strip.amount(e.target.value), 10);
    if (price === terms.price) return;
    setDepositError('');
    setPrice(price);
    setCashDeposit(Math.round(price * depositPercent));
    prepareAndCalc(type);
  };

  const setDeposit = (value) => {
    const deposit = Math.round(parseInt(strip.amount(value), 10));
    if (deposit === terms.cashDeposit) return;
    setCashDeposit(deposit);
    prepareAndCalc(type);
  };

  const handleBlurDeposit = (e) => {
    setDeposit(e.target.value);
    // const deposit = Math.round(parseInt(strip.amount(e.target.value), 10));
    // if (deposit === terms.cashDeposit) return;
    // setCashDeposit(deposit);
    // prepareAndCalc(type);
  };

  const handleCashDepositPercentOnBlur = (e) => {
    if (e.target.value && terms.price) {
      const newCashDepositValue = (e.target.value / 100) * terms.price;
      setDeposit(newCashDepositValue);
    }
  };

  const handleMortgage = (e, firstField) => {
    const value = parseInt(strip.amount(e.target.value), 10);

    let diff;
    if (firstField) {
      if (!value) {
        resetPriceDepositItemHandler();
        setDepositItemError('');
      }
      setDepositItemPrice(value);
      setDepositItemError('');
      setDepositItemAccruedError('');
      if (!value) {
        diff = 0;
      } else {
        diff = value - terms.accruedDepositItem;
      }
    } else {
      if (!value) {
        resetAccruedDepositItem();
        setDepositItemAccruedError('');
      }
      setDepositItemAccrued(value);
      setDepositItemAccruedError('');
      setDepositItemError('');
      diff = terms.priceDepositItem - value;
    }

    setMortgagePrice(diff);
    prepareAndCalc(type);
  };

  const toggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={yup.object().shape(validators)}
      initialValues={{
        price: terms.price || '',
        cashDeposit: terms.cashDeposit || '',
        priceDepositItem: terms.priceDepositItem || '',
        accruedDepositItem: terms.accruedDepositItem || '',
        depositItem: terms.depositItem || '',
      }}
      render={({
 touched, errors, handleBlur, handleChange, setFieldValue, values,
}) => {
        const handleBlurPrice = (e) => {
          handleBlur(e);
          handlePrice(e);
        };

        const handlePriceDepositItem = (e) => {
          handleBlur(e);
          handleMortgage(e, true);
        };

        const handleAccruedDepositItem = (e) => {
          handleBlur(e);
          handleMortgage(e);
        };

        const handleItemNumber = (e) => {
          handleChange(e);
          setItemNumber(e.target.value);
        };

        return (
          <>
            <TwoColumnsLg>
              <TextInput
                label="Application.Price"
                normalize="amount"
                height="34px"
                name="price"
                onBlur={handleBlurPrice}
                onChange={e => preventNumError(e, handleChange)}
                error={
                  (touched.price && errors.price) ||
                  (!touched.price && validationError && !terms.price)
                }
                value={terms.price || ''}
                rightSideText="kr."
                align="right"
                disabled={disabled}
                star="red"
                autoFocus
                tabIndex="1"
              />
            </TwoColumnsLg>
            <div style={{ paddingTop: 10 }}>
              <TwoColumnsLg>
                <TextInput
                  label="Application.PaidDepositItem"
                  name="cashDeposit"
                  normalize="amount"
                  height="34px"
                  error={depositError}
                  value={terms.cashDeposit || ''}
                  onBlur={handleBlurDeposit}
                  rightSideText="kr."
                  align="right"
                  disabled={disabled}
                  tabIndex="2"
                />
                <div style={{ paddingTop: 24, width: 80 }}>
                  <TextInput
                    height="34px"
                    name="cashDepositPercent"
                    onBlur={handleCashDepositPercentOnBlur}
                    value={cashDepositPercent || ''}
                    rightSideText="%"
                    align="right"
                    disabled={disabled}
                    tabIndex="1"
                  />
                </div>
                <Row style={{ padding: '10px 0' }}>
                  <DetailsButtonWrapper>
                    <DetailsButton
                      color="#5b6d7a"
                      tabIndex="15"
                      content={<FormattedMessage id="Mortgage.Label" />}
                      showDetails={expanded}
                      onClick={toggle}
                    />
                  </DetailsButtonWrapper>
                </Row>
              </TwoColumnsLg>
            </div>
            {expanded && (
              <TwoColumnsLg
                style={{
                  marginBottom: '8px',
                  gridRowGap: depositItemError || depositItemAccruedError ? 36 : 12,
                }}
              >
                <TextInput
                  label="Application.PriceDepositItem"
                  name="priceDepositItem"
                  normalize="amount"
                  height="34px"
                  onBlur={handlePriceDepositItem}
                  onChange={e => preventNumError(e, handleChange)}
                  error={depositItemError}
                  value={terms.priceDepositItem || ''}
                  rightSideText="kr."
                  align="right"
                  disabled={disabled}
                  tabIndex="16"
                />
                <TextInput
                  label="Application.AccruedDepositItem"
                  name="accruedDepositItem"
                  height="34px"
                  normalize="amount"
                  onBlur={handleAccruedDepositItem}
                  onChange={e => preventNumError(e, handleChange)}
                  error={depositItemAccruedError}
                  value={terms.accruedDepositItem || ''}
                  rightSideText="kr."
                  align="right"
                  // disabled={!values.priceDepositItem ? true : disabled}
                  disabled={disabled}
                  tabIndex="16"
                />
                <TextInput
                  label="AccruedDeposit.ItemNumber"
                  height="34px"
                  name="depositItem"
                  onChange={e =>
                    normalizeMortgageItem(e.target.value, setFieldValue, 'depositItem')
                  }
                  onBlur={handleItemNumber}
                  value={values.depositItem.toUpperCase() || ''}
                  disabled={disabled}
                  tabIndex="16"
                  maxLength={6}
                  align="right"
                />
              </TwoColumnsLg>
            )}
          </>
        );
      }}
    />
  );
};

SmartTerms.propTypes = {
  app: PropTypes.object.isRequired,
  type: PropTypes.number.isRequired,
  setItemNumber: PropTypes.func.isRequired,
  setPrice: PropTypes.func.isRequired,
  setCashDeposit: PropTypes.func.isRequired,
  setDepositItemPrice: PropTypes.func.isRequired,
  setDepositItemAccrued: PropTypes.func.isRequired,
  setMortgagePrice: PropTypes.func.isRequired,
  prepareAndCalc: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app }) => ({ app });

const mapDispatchToProps = {
  setItemNumber: setDepositItem,
  setPrice: setTermsPrice,
  setCashDeposit: setTermsCashDeposit,
  setDepositItemPrice: setPriceDepositItem,
  setDepositItemAccrued: setAccruedDepositItem,
  setMortgagePrice: setMortgageAmount,
  prepareAndCalc: prepareAndCalculate,
  resetPriceDepositItemHandler: resetPriceDepositItem,
  resetAccruedDepositItemHandler: resetAccruedDepositItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartTerms);
