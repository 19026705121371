import styled from 'styled-components';

export default styled.button`
  background: transparent;
  border: 0;
  color: #5b6d7a;
  cursor: pointer;
  font-size: inherit;
  text-decoration: underline;
  padding: 0;

  &:hover {
    font-weight: 600;
  }
`;
