import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button } from '../button';

import { Container, Row } from './styles';

const RoutingModal = ({ continueTo, cancelModal }) => (
  <Container>
    <span style={{ textaLign: 'center', marginBottom: 24 }}>
      <FormattedMessage id="Application.ConfirmationText" />
    </span>
    <Row>
      <Button onClick={continueTo} content={<FormattedMessage id="Application.Ok" />} />
      <Button onClick={cancelModal} content={<FormattedMessage id="Application.Cancel" />} />
    </Row>
  </Container>
);

RoutingModal.propTypes = {
  continueTo: PropTypes.func.isRequired,
  cancelModal: PropTypes.func.isRequired,
};

export default RoutingModal;
