import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { DetailsButton } from '@origo_vl_banking/ibanking-react';
import { ClearIcon } from './styled-components';

import { Row, Label, RowWrapper } from '../grid/styled-components';
import Applicant from './Applicant';
import SecondApplicant from './SecondApplicant';
import Seller from './Seller';
import Guarantor from './Guarantor';
import SecondGuarantor from './SecondGuarantor';

const Applicants = ({
  app,
  applicant,
  secondApplicant,
  guarantor,
  secondGuarantor,
  seller,
  getApplicantName,
  handleApplicantChange,
  getSecondApplicantName,
  handleGuarantorName,
  handleGuarantorChange,
  handleSecondGuarantorName,
  handleSecondGuarantorChange,
  getLeasingApplicantName,
  handleSecondApplicantChange,
  handleSeller,
  disabled,
  validationError,
  showSecondApplicant,
  toggle,
  expanded,
  ssnType,
  restrictApplicant,
  resetSecondApplicantField,
  resetGuarantorFields,
  resetSecondGuarantorFields,
  setApplicantField,
  ssnInfo,
}) => {
  const icon = '×';
  const isEmpty = obj => Object.values(obj).every(value => value === null || value === '');


  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        {app.type !== 2 ? (
          <FormattedMessage id="Loan.Borrower" />
        ) : (
          <FormattedMessage id="Application.Leaser" />
        )}
      </div>

      {/* main applicant - visible on all applications */}
      <Applicant
        app={app}
        ssnInfo={ssnInfo}
        applicant={applicant}
        getLeasingApplicantName={getLeasingApplicantName}
        setApplicantField={setApplicantField}
        handleApplicantChange={handleApplicantChange}
        getApplicantName={getApplicantName}
        validationError={validationError}
        disabled={disabled}
        ssnType={ssnType}
        restrictApplicant={restrictApplicant}
      />

      {/* show Second Applicant or Gurantor label if Car Loan or Car Leasing */}
      {(showSecondApplicant || app.type === 8 || app.type === 1) && (
        <RowWrapper style={{ paddingBottom: '20px' }}>
          <DetailsButton
            tabIndex="6"
            color="#5b6d7a"
            content={
              ssnType !== 'company' ? (
                <FormattedMessage id="Loan.SecondBorrower" />
              ) : (
                <FormattedMessage id="Application.POA" />
              )
            }
            showDetails={expanded}
            onClick={toggle}
          />

          {!isEmpty(secondApplicant) && !disabled ? (
            <ClearIcon onClick={resetSecondApplicantField}>{icon}</ClearIcon>
          ) : null}
          {!isEmpty(guarantor) && !disabled ? (
            <ClearIcon onClick={resetGuarantorFields}>{icon}</ClearIcon>
          ) : null}
        </RowWrapper>
      )}

      {/* if ssn is Company's show Guarantor Label on Car Rental */}
      {app.type === 9 && ssnType === 'company' && (
        <RowWrapper style={{ paddingBottom: '20px' }}>
          <DetailsButton
            color="#5b6d7a"
            content={<FormattedMessage id="Application.Guarantor" />}
            showDetails={expanded}
            onClick={toggle}
            style={{ background: 'red' }}
            tabIndex="6"
          />
          {!isEmpty(guarantor) && !disabled ? (
            <ClearIcon onClick={resetGuarantorFields}>{icon}</ClearIcon>
          ) : null}
        </RowWrapper>
      )}

      {/* show Guarantor Label on Leasing */}
      {app.type === 2 && (
        <RowWrapper style={{ paddingBottom: '20px' }}>
          <DetailsButton
            color="#5b6d7a"
            content={<FormattedMessage id="Application.POA" />}
            showDetails={expanded}
            onClick={toggle}
            tabIndex="6"
          />
          {!isEmpty(guarantor) && !disabled ? (
            <ClearIcon onClick={resetGuarantorFields}>{icon}</ClearIcon>
          ) : null}
        </RowWrapper>
      )}

      {/* show Second Applicant on Car Loan and Car Leasing */}
      {expanded && ssnType !== 'company' && app.type !== 2 && (
        <Row style={{ paddingBottom: '20px' }}>
          <SecondApplicant
            app={app}
            secondApplicant={secondApplicant}
            getSecondApplicantName={getSecondApplicantName}
            handleSecondApplicantChange={handleSecondApplicantChange}
            disabled={disabled}
            validationError={validationError}
          />
        </Row>
      )}

      {/* show Gurantors on Leasing - default */}
      {expanded && app.type === 2 && (
        <>
          <RowWrapper>
            <Label>
              <FormattedMessage id="Application.POA3" />
            </Label>
          </RowWrapper>
          <Guarantor
            app={app}
            guarantor={guarantor}
            handleGuarantorName={handleGuarantorName}
            handleGuarantorChange={handleGuarantorChange}
            disabled={disabled}
            validationError={validationError}
          />
          <RowWrapper>
            <Label>
              <FormattedMessage id="Application.POA2" />
            </Label>
            {!isEmpty(secondGuarantor) ? (
              <ClearIcon onClick={resetSecondGuarantorFields}>{icon}</ClearIcon>
            ) : null}
          </RowWrapper>

          <SecondGuarantor
            app={app}
            secondGuarantor={secondGuarantor}
            handleSecondGuarantorName={handleSecondGuarantorName}
            handleSecondGuarantorChange={handleSecondGuarantorChange}
            disabled={disabled}
            validationError={validationError}
          />
        </>
      )}

      {/* show Gurantors if ssn is company's - Car Loan, Car Leasing and Car Rentals */}
      {expanded && ssnType === 'company' && app.type !== 2 && (
        <>
          <RowWrapper>
            <Label>
              <FormattedMessage id="Application.POA3" />
            </Label>
          </RowWrapper>
          <Guarantor
            guarantor={guarantor}
            handleGuarantorName={handleGuarantorName}
            handleGuarantorChange={handleGuarantorChange}
            disabled={disabled}
            validationError={validationError}
          />
          <RowWrapper>
            <Label>
              <FormattedMessage id="Application.POA2" />
            </Label>
            {!isEmpty(secondGuarantor) ? (
              <ClearIcon onClick={resetSecondGuarantorFields}>{icon}</ClearIcon>
            ) : null}
          </RowWrapper>

          <SecondGuarantor
            app={app}
            secondGuarantor={secondGuarantor}
            handleSecondGuarantorName={handleSecondGuarantorName}
            handleSecondGuarantorChange={handleSecondGuarantorChange}
            disabled={disabled}
            validationError={validationError}
          />
        </>
      )}
    </>
  );
};

Applicants.propTypes = {
  app: PropTypes.object.isRequired,
  applicant: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  expanded: PropTypes.bool.isRequired,
  getApplicantName: PropTypes.func.isRequired,
  getLeasingApplicantName: PropTypes.func,
  getSecondApplicantName: PropTypes.func,
  guarantor: PropTypes.object,
  handleApplicantChange: PropTypes.func.isRequired,
  handleGuarantorChange: PropTypes.func.isRequired,
  handleGuarantorName: PropTypes.func,
  handleSecondApplicantChange: PropTypes.func,
  handleSecondGuarantorChange: PropTypes.func,
  handleSecondGuarantorName: PropTypes.func,
  handleSeller: PropTypes.func.isRequired,
  resetGuarantorFields: PropTypes.func.isRequired,
  resetSecondApplicantField: PropTypes.func.isRequired,
  resetSecondGuarantorFields: PropTypes.func.isRequired,
  restrictApplicant: PropTypes.oneOf(['person', 'company', null]),
  secondApplicant: PropTypes.object,
  secondGuarantor: PropTypes.object,
  seller: PropTypes.object.isRequired,
  setApplicantField: PropTypes.func,
  showSecondApplicant: PropTypes.bool.isRequired,
  ssnInfo: PropTypes.object,
  ssnType: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  validationError: PropTypes.string,
};

Applicants.defaultProps = {
  disabled: false,
  getLeasingApplicantName: () => null,
  getSecondApplicantName: () => {},
  guarantor: {},
  handleGuarantorName: () => {},
  handleSecondApplicantChange: () => {},
  handleSecondGuarantorChange: () => null,
  handleSecondGuarantorName: () => null,
  restrictApplicant: null,
  secondApplicant: {},
  secondGuarantor: {},
  setApplicantField: () => null,
  ssnInfo: PropTypes.object,
  validationError: '',
};

export default Applicants;
