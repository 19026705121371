import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { get } from 'lodash';

import { Header, ConsultantInfo } from '.';
import { HeaderWrapper } from './styled-components';
import { getPDF as getPDFAction } from '../../modules/app/actions';
import { openEmail } from '../../utils/helpers';
import Documents from '../documents/Documents';
import SmartLoader from '../loader/SmartLoader';

const AlignRight = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  align-self: flex-end;

  margin-bottom: 8px;
`;

const SmartHeader = ({
  app,
  title,
  subtitle,
  getPDF,
  message1,
  message2,
}) => {
  const applicantSsn = get(app, 'applicant.ssn');
  const openEmailTo = (email) => {
    openEmail(email, app.serialNumber, app.typeText, app.year, applicantSsn);
  };

  return (
    <HeaderWrapper>
      <SmartLoader loading={app.isFetchingContract} />
      <Header
        title={title}
        subtitle={subtitle}
        message1={message1}
        message2={message2}
      />
      <AlignRight>
        <Documents
          printInfo={app.printInfo}
          getPDF={getPDF}
          appInfo={{ year: app.year, serialNumber: app.serialNumber, type: app.type }}
        />
        <ConsultantInfo consultant={app.consultant} openEmailTo={openEmailTo} />
      </AlignRight>
    </HeaderWrapper>
  );
};

SmartHeader.propTypes = {
  app: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  getPDF: PropTypes.func.isRequired,
  message1: PropTypes.string,
  message2: PropTypes.string,
};

SmartHeader.defaultProps = {
  message1: '',
  message2: '',
};

const mapStateToProps = ({ app }) => ({ app });

export default connect(mapStateToProps, { getPDF: getPDFAction })(SmartHeader);
