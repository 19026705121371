import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { replace } from 'lodash';
import { connect } from 'react-redux';

import { validate } from '../../utils/validate';

import {
  getApplicantName,
  getLeasingApplicantName,
  getSecondApplicantName,
  getSellerName,
  getGuarantorName,
  setApplicantField,
  setSecondApplicantField,
  setGuarantorField,
  setSellerField,
  resetSecondApplicant,
  resetGuarantorFields,
  resetGuarantor,
  resetSecondApplicantField,
  getSecondGuarantorName,
  setSecondGuarantorField,
  resetSecondGuarantorFields,
  resetSecondGuarantor,
  resetApplicant,
  setSsnType,
} from '../../modules/app/actions';
import { Applicants } from './';

const SmartApplicants = (props) => {
  const [expanded, setExpanded] = useState(false);
  const isEmpty = obj => Object.values(obj).every(value => value === null || value === '');
  const toggle = () => {
    setExpanded(!expanded);
  };

  const checkSsnType = (value) => {
    const str = value.slice(0, 2);
    const parsedValue = parseInt(str, 10);
    if (parsedValue > 31) {
      props.setSsnType('company');
      props.resetSecondApplicantField();
      setExpanded(false);
    } else {
      props.resetGuarantorFields();
      setExpanded(false);
      props.setSsnType('person');
    }
  };

  useEffect(() => {
    checkSsnType(props.applicant.ssn);
    if (
      !isEmpty(props.secondApplicant) ||
      !isEmpty(props.guarantor) ||
      !isEmpty(props.secondGuarantor)
    ) {
      setExpanded(true);
    }
  }, [props.applicant]);

  const handleApplicantChange = (field, e) => {
    props.setApplicantField(field, e.target.value);
  };

  const handleSecondApplicantChange = (field, e) => {
    props.setSecondApplicantField(field, e.target.value);
  };

  const handleGuarantorChange = (field, e) => {
    props.setGuarantorField(field, e.target.value);
  };

  const handleSecondGuarantorChange = (field, e) => {
    props.setSecondGuarantorField(field, e.target.value);
  };

  const parseValue = (e) => {
    const { value } = e.target;
    if (!value) return null;
    const parsedValue = replace(value, '-', '');
    if (!parsedValue || !validate.ssn(parsedValue)) return null;
    return parsedValue;
  };

  const handleApplicantSsn = (e) => {
    const parsedValue = parseValue(e);
    if (parsedValue === props.applicant.ssn) return;
    if (!parsedValue) {
      props.resetApplicant();
    } else if (props.app.type === 2) {
      props.setApplicantField('ssn', parsedValue);
      props.getLeasingApplicantName(parsedValue);
    } else {
      checkSsnType(parsedValue);
      props.getApplicantName(parsedValue);
      props.setApplicantField('ssn', parsedValue);
    }
  };

  const handleGuarantorName = (e) => {
    const parsedValue = parseValue(e);
    if (parsedValue === props.guarantor.ssn) return;
    if (!parsedValue) {
      props.resetGuarantor();
    } else {
      props.getGuarantorName(parsedValue);
      props.setGuarantorField('ssn', parsedValue);
    }
  };

  const handleSecondGuarantorName = (e) => {
    const parsedValue = parseValue(e);
    if (parsedValue === props.secondGuarantor.ssn) return;
    if (!parsedValue) {
      props.resetSecondGuarantor();
    } else {
      props.getSecondGuarantorName(parsedValue);
      props.setSecondGuarantorField('ssn', parsedValue);
    }
  };

  const handleSecondApplicantSsn = (e) => {
    const parsedValue = parseValue(e);
    if (parsedValue === props.secondApplicant.ssn) return;
    if (!parsedValue) {
      props.resetSecondApplicant();
    } else {
      props.getSecondApplicantName(parsedValue);
      props.setSecondApplicantField('ssn', parsedValue);
    }
  };

  const handleSellerSsn = (e) => {
    const parsedValue = parseValue(e);
    if (!parsedValue || parsedValue === props.seller.ssn) return;
    props.getSellerName(parsedValue);
    props.setSellerField('ssn', parsedValue);
  };


  return (
    <Applicants
      app={props.app}
      applicant={props.applicant}
      disabled={props.disabled}
      expanded={expanded}
      getApplicantName={handleApplicantSsn}
      getLeasingApplicantName={props.getLeasingApplicantName}
      getSecondApplicantName={handleSecondApplicantSsn}
      guarantor={props.guarantor}
      handleApplicantChange={handleApplicantChange}
      handleGuarantorChange={handleGuarantorChange}
      handleGuarantorName={handleGuarantorName}
      handleSecondApplicantChange={handleSecondApplicantChange}
      handleSecondGuarantorChange={handleSecondGuarantorChange}
      handleSecondGuarantorName={handleSecondGuarantorName}
      handleSeller={handleSellerSsn}
      resetApplicant={props.resetApplicant}
      resetGuarantorFields={props.resetGuarantorFields}
      resetSecondApplicantField={props.resetSecondApplicantField}
      resetSecondGuarantorFields={props.resetSecondGuarantorFields}
      restrictApplicant={props.restrictApplicant}
      secondApplicant={props.secondApplicant}
      secondGuarantor={props.secondGuarantor}
      seller={props.seller}
      setApplicantField={props.setApplicantField}
      showGuarantor={props.showGuarantor}
      showSecondApplicant={props.showSecondApplicant}
      ssnInfo={props.ssnInfo}
      ssnType={props.ssnType}
      toggle={toggle}
      validationError={props.validationError}
    />
  );
};

SmartApplicants.propTypes = {
  app: PropTypes.object.isRequired,
  applicant: PropTypes.object.isRequired,
  secondApplicant: PropTypes.object.isRequired,
  guarantor: PropTypes.object.isRequired,
  secondGuarantor: PropTypes.object.isRequired,
  seller: PropTypes.object.isRequired,
  ssnType: PropTypes.string,
  setApplicantField: PropTypes.func.isRequired,
  setSecondApplicantField: PropTypes.func.isRequired,
  setSellerField: PropTypes.func.isRequired,
  getApplicantName: PropTypes.func.isRequired,
  resetSecondApplicant: PropTypes.func.isRequired,
  getSecondApplicantName: PropTypes.func.isRequired,
  resetApplicant: PropTypes.func.isRequired,
  getSecondGuarantorName: PropTypes.func.isRequired,
  resetGuarantorFields: PropTypes.func.isRequired,
  resetSecondGuarantorFields: PropTypes.func.isRequired,
  resetGuarantor: PropTypes.func.isRequired,
  resetSecondGuarantor: PropTypes.func.isRequired,
  getGuarantorName: PropTypes.func.isRequired,
  setGuarantorField: PropTypes.func.isRequired,
  setSecondGuarantorField: PropTypes.func.isRequired,
  getSellerName: PropTypes.func.isRequired,
  resetSecondApplicantField: PropTypes.func.isRequired,
  setSsnType: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  validationError: PropTypes.string.isRequired,
  showSecondApplicant: PropTypes.bool,
  showGuarantor: PropTypes.bool,
  ssnInfo: PropTypes.object,
  restrictApplicant: PropTypes.oneOf(['person', 'company', null]),
  getLeasingApplicantName: PropTypes.func.isRequired,
};

SmartApplicants.defaultProps = {
  showSecondApplicant: true,
  showGuarantor: false,
  ssnType: '',
  restrictApplicant: null,
  ssnInfo: {},
};

const mapStateToProps = ({ app }) => ({
  applicant: app.applicant,
  ssnType: app.ssnType,
  secondApplicant: app.secondApplicant,
  guarantor: app.guarantor,
  secondGuarantor: app.secondGuarantor,
  seller: app.seller,
  disabled: !app.editable,
  validationError: app.validationError,
  app,
  ssnInfo: app.ssnInfo,
});

const mapDispatchToProps = {
  setApplicantField,
  setSecondApplicantField,
  setSellerField,
  getApplicantName,
  getLeasingApplicantName,
  getSecondApplicantName,
  getGuarantorName,
  setGuarantorField,
  getSecondGuarantorName,
  setSecondGuarantorField,
  getSellerName,
  resetSecondApplicant,
  resetSecondApplicantField,
  resetGuarantorFields,
  resetGuarantor,
  resetApplicant,
  resetSecondGuarantorFields,
  resetSecondGuarantor,
  setSsnType,
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartApplicants);
