import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const MessageContainer = styled.div`
  width: 100%;
  height: 55px;
  margin: 0 auto;
  background-color: ${props => props.theme.successLight};
  border: 1px solid ${props => props.theme.success};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-bottom: 25px;
`;


const PrimaryMessage = ({ messageKey }) => (
  <MessageContainer>
    <FormattedMessage id={messageKey} />
  </MessageContainer>
);

PrimaryMessage.propTypes = {
  messageKey: PropTypes.string.isRequired,
};

export default PrimaryMessage;
