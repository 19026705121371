import React from 'react';
import { connect, ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Router, Switch } from 'react-router';
import PropTypes from 'prop-types';
// import DigitalSignatureModal from './components/digital-sign-confirmation-modal/Modal';

import { Footer } from './components';

import {
  auth,
  header,
  settings,
  applications,
  mortgage,
  carloan,
  carleasing,
  carrental,
  leasing,
} from './modules';
import Container from './components/container';
import useDigitalSignModal from './hooks/use-digital-sign-modal';
import useDisposalModal from './hooks/use-disposal-modal';
import useDocumentsModal from './hooks/use-documents-modal';
import useMergerMessageModal from './components/merger-message-modal/use-merger-message-modal';

const Routes = ({ history, isAuthenticated }) => {
  const { DigitalSignatureModal, showModal: showDigitalSignModal } = useDigitalSignModal();
  const { DisposalModal, showModal: showDisposalModal } = useDisposalModal();
  const { DocumentsModal, showModal: showDocumentsModal } = useDocumentsModal();
  const { MergerMessageModal, showModal: showMergerMessageModal } = useMergerMessageModal();
  return (
    <ConnectedRouter history={history} context={ReactReduxContext}>
      <Router history={history}>
        <DigitalSignatureModal show={showDigitalSignModal} />
        <DisposalModal show={showDisposalModal} />
        <DocumentsModal show={showDocumentsModal} />
        <MergerMessageModal show={showMergerMessageModal} />
        <>
          <Route path="/" component={header.Header} />
          <Switch>
            <Container>
              <Route path="/auth/login" component={auth.Login} />
              <auth.Route
                exact
                path="/"
                component={isAuthenticated ? applications.Applications : auth.Login}
              />
              <auth.Route userRole="Loan" path="/car-loan" component={carloan.CarLoan} />
              <auth.Route
                userRole="CarLeasing"
                path="/car-leasing"
                component={carleasing.CarLeasing}
              />
              <auth.Route userRole="Leasing" path="/leasing" component={leasing.Leasing} />
              <auth.Route userRole="CarRental" path="/car-rental" component={carrental.CarRental} />
              <auth.Route path="/overview" component={applications.Applications} />
              <auth.Route
                userRole="MortgageSwitch"
                path="/mortgage-switch"
                component={mortgage.Mortgage}
              />
              <auth.Route path="/settings/change-password" component={settings.ChangePassword} />
              <Route path="/auth/reset-password" component={auth.ResetPassword} />
              <Route path="/auth/resetpassword/:token" component={auth.ChangePassword} />
              <Route
                path="/error"
                component={() => (
                  <div>
                    <h1>Óþekkt villa</h1>
                    <p>
                      Ekki tókst að klára beiðni. Vinsamlegast hafðu samband við okkur eða reyndu
                      aftur síðar.
                    </p>
                  </div>
                )}
              />
            </Container>
          </Switch>
          <Route path="/" component={Footer} />
        </>
      </Router>
    </ConnectedRouter>
  );
};

Routes.propTypes = {
  history: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    location: state.router.location,
    showModal: state.applications.showModal,
  };
}

export default connect(mapStateToProps)(Routes);
