import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import { format } from '@origo_vl_banking/utils';
import { TextInput } from '@origo_vl_banking/ibanking-react';
import * as yup from 'yup';
import { connect } from 'react-redux';

import { Hr, SpaceBetween } from '../grid/styled-components';
import { InputWrapper, AmountWrapper } from './styled-components';
import { setTermsDueDateCount, getCalculate } from '../../modules/app/actions';

import { validate } from '../../utils/validate';

const validators = {
  dueDateCount: validate.formik.required,
};

function SmartMonthCalculator({
  app, type, setMonths, calculate,
}) {
  const { terms, calculation, fees, car, mortgage } = app; // eslint-disable-line
  const { plannedAveragePayment } = calculation;

  const handleMonthsBlur = (e) => {
    const dueDateCount = e.target.value;
    if (terms.dueDateCount === dueDateCount) return;
    if (!terms.price) return; //
    const data = {
      type,
      terms: {
        ...terms,
        dueDateCount,
      },
      fees,
      car,
      calculation,
      mortgage,
    };
    calculate(data, false);
  };

  return (
    <Formik
      validationSchema={yup.object().shape(validators)}
      initialValues={{ dueDateCount: terms.dueDateCount }}
      render={({
 touched, errors, handleBlur, handleChange,
}) => {
        const handleDateBlur = (e) => {
          handleBlur(e);
          setMonths(Number(e.target.value));
          handleMonthsBlur(e);
        };

        return (
          <React.Fragment>
            <SpaceBetween>
              <InputWrapper>
                <TextInput
                  label="Application.DueDateCount"
                  name="dueDateCount"
                  normalize="amount"
                  height="34px"
                  value={terms.dueDateCount}
                  error={
                    (touched.dueDateCount && errors.dueDateCount) ||
                    (!touched.dueDateCount && !terms.dueDateCount && app.validationError)
                  }
                  onBlur={handleDateBlur}
                  onChange={handleChange}
                  disabled={!app.editable}
                  star="red"
                  tabIndex="3"
                />
              </InputWrapper>
              <AmountWrapper>
                <b>
                  <FormattedMessage id="Application.PlannedAveragePayment" />
                </b>
                <h4 style={{ marginTop: '8px' }}>
                  {format.amount(Math.round(plannedAveragePayment))} kr.
                </h4>
              </AmountWrapper>
            </SpaceBetween>
            <Hr />
          </React.Fragment>
        );
      }}
    />
  );
}

SmartMonthCalculator.propTypes = {
  app: PropTypes.object.isRequired,
  setMonths: PropTypes.func.isRequired,
  calculate: PropTypes.func.isRequired,
  type: PropTypes.number.isRequired,
};

const mapStateToProps = ({ app }) => ({ app });

const mapDispatchToProps = {
  setMonths: setTermsDueDateCount,
  calculate: getCalculate,
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartMonthCalculator);
