import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { DatePicker } from '@origo_vl_banking/ibanking-react';

import { DatePickerContainer } from './';
import * as actions from '../actions';
import { Button } from '../../../components/button';

const DatePickers = ({
  getApplications, setStartDate, setEndDate, startDate, endDate,
}) => {
  const reload = () => {
    getApplications(startDate.toISOString(), endDate.toISOString());
  };

  return (
    <DatePickerContainer>
      <DatePicker
        name="startDate"
        label="ApplicationList.DateFrom"
        showDateInput={false}
        disableDates={false}
        onDateChange={date => setStartDate(date)}
        initialDate={moment(startDate)}
        value={startDate}
        flexDirection="column"
        width={85}
        labelWidth="105px"
        dateFormat="DD.MM.YYYY"
        displayFormat="DD.MM.YY"
      />
      <DatePicker
        name="endDate"
        label="ApplicationList.DateTo"
        showDateInput={false}
        disableDates={false}
        onDateChange={date => setEndDate(date)}
        initialDate={moment(endDate)}
        value={endDate}
        flexDirection="column"
        width={85}
        labelWidth="105px"
        dateFormat="DD.MM.YYYY"
        displayFormat="DD.MM.YY"
      />
      <Button
        onClick={reload}
        disabled={!startDate || !endDate}
        content={<FormattedMessage id="Applications.Fetch" />}
        primary
        width="120px"
      />
      {/* <ButtonPrimary onClick={reload} disabled={!startDate || !endDate}>
        <FormattedMessage id="Applications.Fetch" />
      </ButtonPrimary> */}
    </DatePickerContainer>
  );
};

DatePickers.propTypes = {
  getApplications: PropTypes.func.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
};

function mapStateToProps({ applications }) {
  return {
    endDate: applications.endDate,
    startDate: applications.startDate,
  };
}

export default connect(mapStateToProps, {
  getApplications: actions.getApplications,
  setEndDate: actions.setEndDate,
  setStartDate: actions.setStartDate,
})(DatePickers);
