import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
// import { TextInput } from '@origo_vl_banking/ibanking-react';
import { Formik } from 'formik';
import { format, strip } from '@origo_vl_banking/utils';
import { getCalculate } from '../../app/actions';
import {
  FormGroup,
  Row,
  TermsFormContainer,
  TermsRow,
  TermsTripleRow,
} from '../../../components/shared/styled-components';
import { Label } from '../../../components/grid/styled-components';
import TextInput from '../../../components/text-input/TextInput';

const Terms = ({
  app, handleCalculation, disabled, validationError,
}) => {
  const [error, setError] = useState();
  const [isChecked, setChecked] = useState(true);
  const [vskPaidAmount, setVskPaid] = useState(0);
  const [basePaymentAmount, setBasePaymentAmount] = useState(0);
  const [paidOnSigningAmount, setPaidOnSigningAmount] = useState(0);
  const [cashDepositPercent, setCashDepositPercent] = useState(0);
  const [total, setTotal] = useState(0);

  const {
    terms, fees, calculation, mortgage,
  } = app;

  const updateFields = () => {
    const vskPaid = Math.round(terms.price * 0.24);
    const totalAmount = terms.price + vskPaid;
    setVskPaid(vskPaid);
    setTotal(totalAmount);
    const basePayment = terms.price - terms.cashDeposit;
    const paidOnSigning = vskPaid + terms.cashDeposit;
    const depositFeePercent = Math.round((terms.cashDeposit / terms.price) * 100);
    setBasePaymentAmount(basePayment);
    setPaidOnSigningAmount(paidOnSigning);
    setCashDepositPercent(depositFeePercent);
    setChecked(terms.vat);
  };

  useEffect(() => {
    updateFields();
  }, [
    terms.price,
    terms.cashDeposit,
    terms.vat,
    vskPaidAmount,
    basePaymentAmount,
    paidOnSigningAmount,
    cashDepositPercent,
    total,
  ]);

  const handleCheckBoxChange = (e) => {
    const value = e.target.checked;
    setChecked(value);
    if (terms.price === 0) return;
    const vskPaid = terms.price * 0.24;
    if (value) {
      const totalAmount = terms.price + vskPaid;
      setVskPaid(vskPaid);
      setTotal(totalAmount);
      const prepareData = (type) => ({
        terms: {
          ...terms,
          vat: value,
          vatAmount: vskPaid,
          vatPercentage: 24,
        },
        type,
        fees,
        calculation,
        mortgage,
      });

      const data = prepareData(2);
      handleCalculation(data, false);
    } else {
      setPaidOnSigningAmount(terms.cashDeposit);
      const prepareData = (type) => ({
        terms: {
          ...terms,
          vat: value,
          vatAmount: vskPaid,
          vatPercentage: 24,
        },
        type,
        fees,
        calculation,
        mortgage,
      });

      const data = prepareData(2);
      handleCalculation(data, false);
    }
  };

  const handlePriceOnBlur = (e, handleBlur) => {
    handleBlur(e);
    if (isEmpty(e.target.value)) return;

    const price = parseInt(strip.amount(e.target.value), 10);
    const parsedPrice = price;
    if (parsedPrice < 0 || parsedPrice === terms.price) return;

    const vskPaid = parsedPrice * 0.24;
    const depositValue = parsedPrice * 0.2;
    const parsedDepositValue = Math.round(depositValue);
    const depositFeePercent = (parsedDepositValue / parsedPrice) * 100;
    const basePayment = parsedPrice - parsedDepositValue;
    const paidOnSigning = vskPaid + parsedDepositValue;
    setCashDepositPercent(depositFeePercent);
    setBasePaymentAmount(basePayment);
    setPaidOnSigningAmount(paidOnSigning);

    if (isChecked) {
      const totalAmount = parsedPrice + vskPaid;
      setVskPaid(vskPaid);
      setTotal(totalAmount);
    }

    const prepareData = (type) => ({
      terms: {
        ...terms,
        price: parsedPrice,
        cashDeposit: parsedDepositValue,
        vat: isChecked,
        vatAmount: vskPaid,
        vatPercentage: 24,
      },
      type,
      fees,
      calculation,
      mortgage,
    });

    const data = prepareData(2);
    handleCalculation(data, false);
  };

  const handleCashDepositOnBlur = (e) => {
    if (isEmpty(e.target.value)) return;

    const newDepositValue = Math.round(parseInt(strip.amount(e.target.value), 10));
    if (newDepositValue > terms.price) {
      const errorField = 'Application.Deposit.TooHigh';
      setError(errorField);
      return;
    }

    const vskPaid = terms.price * 0.24;
    const depositFeePercent = (newDepositValue / terms.price) * 100;
    const basePayment = terms.price - newDepositValue;
    const paidOnSigning = vskPaid + newDepositValue;
    setCashDepositPercent(depositFeePercent);
    setPaidOnSigningAmount(paidOnSigning);
    setBasePaymentAmount(basePayment);

    if (isChecked) {
      const totalAmount = terms.price + vskPaid;
      setVskPaid(vskPaid);
      setTotal(totalAmount);
    }

    setError('');

    if (newDepositValue === terms.cashDeposit) return;

    const prepareData = (type) => ({
      terms: {
        ...terms,
        cashDeposit: newDepositValue,
        vat: isChecked,
        vatAmount: vskPaidAmount,
        vatPercentage: 24,
      },
      type,
      fees,
      calculation,
      mortgage,
    });

    const data = prepareData(2);
    handleCalculation(data, false);
  };

  const handleCashDepositPercentOnBlur = (e) => {
    if (!e.target.value) return;
    const cashDepositFeePercent = Math.round(parseInt(strip.amount(e.target.value), 10));
    if (cashDepositFeePercent === cashDepositPercent) return;

    if (terms.cashDeposit > terms.price) {
      const errorField = 'Application.Deposit.TooHigh';
      setError(errorField);
      return;
    }

    const vskPaid = terms.price * 0.24;
    const newCashDepositValue = (cashDepositFeePercent / 100) * terms.price;
    const basePayment = terms.price - newCashDepositValue;
    const paidOnSigning = vskPaid + newCashDepositValue;
    setPaidOnSigningAmount(paidOnSigning);
    setBasePaymentAmount(basePayment);

    if (isChecked) {
      const totalAmount = terms.price + vskPaid;
      setVskPaid(vskPaid);
      setTotal(totalAmount);
    }
    setError('');

    const prepareData = (type) => ({
      terms: {
        ...terms,
        cashDeposit: newCashDepositValue,
        vat: isChecked,
        vatAmount: vskPaidAmount,
        vatPercentage: 24,
      },
      type,
      fees,
      calculation,
      mortgage,
    });

    const data = prepareData(2);
    handleCalculation(data, false);
  };

  return (
    <>
      <Formik
        // validationSchema={yup.object().shape(validators)}
        validate={(values) => {
          const errors = {};
          if (!values.price) {
            errors.price = 'Form.Error.required-field';
          }

          return errors;
        }}
        initialValues={{
          price: terms.price || '',
          cashDeposit: '',
        }}
        render={({
          touched, errors, handleBlur, handleChange,
        }) => (
          <TermsFormContainer>
            {/* Kaupverd an vsk */}
            <TermsTripleRow>
              <Label>
                <FormattedMessage id={isChecked ? 'Application.Leasing.Price' : 'Application.Leasing.PurchasePrice'} />
                {' '}
                <span style={{ color: 'red' }}>*</span>
              </Label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label htmlFor="isGoing" style={{ fontSize: 14, fontWeight: 600 }}>
                  VSK
                </label>
                <input
                  disabled={disabled}
                  style={{ marginRight: 16 }}
                  name="isGoing"
                  id="isGoing"
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckBoxChange}
                />
              </div>
              <TextInput
                height="34px"
                name="price"
                normalize="amount"
                onBlur={(e) => handlePriceOnBlur(e, handleBlur)}
                onChange={handleChange}
                value={terms.price || ''}
                rightSideText="kr."
                align="right"
                error={
                  (touched.price && errors.price)
                  || (!touched.price && validationError && !terms.price)
                }
                disabled={disabled}
                star="red"
                width="156px"
                tabIndex="1"
              />
            </TermsTripleRow>

            {/* Innborgun */}
            <TermsTripleRow>
              <Label>
                <FormattedMessage id="Application.Leasing.DownPayment" />
              </Label>

              <TextInput
                height="34px"
                name="cashDepositPercent"
                onBlur={handleCashDepositPercentOnBlur}
                value={Math.round(cashDepositPercent) || ''}
                rightSideText="%"
                align="right"
                disabled={disabled}
                tabIndex="1"
              />
              <TextInput
                height="34px"
                name="cashDeposit"
                normalize="amount"
                onBlur={handleCashDepositOnBlur}
                value={terms.cashDeposit || ''}
                rightSideText="kr."
                align="right"
                error={error}
                disabled={disabled}
                tabIndex="1"
              />
            </TermsTripleRow>

            {/* Leigugrunnur */}
            <TermsRow>
              <b>
                <FormattedMessage id="Application.Leasing.BasePayment" />
              </b>
              <b>
                <span>{`${format.amount(basePaymentAmount) || 0} kr.`}</span>
              </b>
            </TermsRow>

            {/* Vsk gr. vid undirritun */}
            {isChecked
              && (
              <TermsRow>
                <Label>
                  <FormattedMessage id="Application.Leasing.VskPaid" />
                </Label>
                <span>{`${isChecked ? format.amount(vskPaidAmount) : 0} kr.`}</span>
              </TermsRow>
              )}

            {/* Greitt vid undirritun */}
            <TermsRow>
              <b>
                <FormattedMessage id="Application.PaidOnSigning" />
              </b>
              <b>
                <span>
                  {`${format.amount(isChecked ? paidOnSigningAmount : terms.cashDeposit) || 0} kr.`}
                </span>
              </b>
            </TermsRow>

            {/* Samtals m / an vsk */}
            <TermsRow>
              <Label>
                <FormattedMessage
                  id={isChecked ? 'Application.Leasing.Sum' : 'Application.Leasing.PurchasePrice'}
                />
              </Label>
              <span>{`${format.amount(isChecked ? total : terms.price) || 0} kr.`}</span>
            </TermsRow>
          </TermsFormContainer>
        )}
      />
    </>
  );
};

Terms.propTypes = {
  app: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  validationError: PropTypes.string.isRequired,
  handleCalculation: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app }) => ({
  app,
  disabled: !app.editable,
  validationError: app.validationError,
});

const mapDispatchToProps = (dispatch) => ({
  handleCalculation: (data, value) => dispatch(getCalculate(data, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
