import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import StandardLoader from './StandardLoader';

const SmartLoader = ({
  loading, isFetchingData, isSavingApplication, hide,
}) => {
  const showLoader = !hide && (loading || isFetchingData || isSavingApplication);
  return showLoader ? <StandardLoader /> : null;
};

SmartLoader.propTypes = {
  loading: PropTypes.bool,
  hide: PropTypes.bool,
  isFetchingData: PropTypes.bool.isRequired,
  isSavingApplication: PropTypes.bool.isRequired,
};

SmartLoader.defaultProps = {
  loading: false,
  hide: false,
};

function mapStateToProps(state) {
  return {
    isFetchingData: state.app.isFetchingData,
    isSavingApplication: state.app.isSavingApplication,
  };
}

export default connect(mapStateToProps)(SmartLoader);
