import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Checkbox from './Checkbox';
import AnimatedSection from './AnimatedSection';
import FinanceDetailsForm from './FinanceDetailsForm';

const Container = styled.div``;

const FinanceDetails = ({ formProps, formId, disabled }) => {
  const { values, handleChange, handleBlur } = formProps;
  const value = values[formId];
  return (
    <Container>
      <Checkbox
        type="checkbox"
        onChange={handleChange}
        onBlur={handleBlur}
        name={`${formId}.checked`}
        id={`${formId}.checked`}
        checked={value.checked}
        disabled={disabled}
        label={`Disposal.${formId}`}
      />
      <AnimatedSection isOpen={value.checked}>
        <FinanceDetailsForm disabled={disabled} formId={formId} formProps={formProps} />
      </AnimatedSection>
    </Container>
  );
};

FinanceDetails.propTypes = {
  formProps: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  }).isRequired,
  formId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default FinanceDetails;
