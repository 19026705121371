import { addNotification as notify } from 'reapop';
import { IntlProvider } from 'react-intl';

const DEFAULT_DISMISS = 3000;

/* DEFAULT_POSITION dictates the notification origin location.
 * Can be top (t) / bottom (r) & right, center and left of t/b
 * e.g. top-right: "tr", bottom-left: "bl"
 */

const DEFAULT_POSITION = 'tr';

export const simpleNotification = title =>
  notify({
    title,
    status: 'success',
    dismissible: true,
    dismissAfter: DEFAULT_DISMISS,
    position: DEFAULT_POSITION,
  });

const notification = (message, status, dismissAfter = DEFAULT_DISMISS, messageVal = {}) => (
  dispatch,
  getState,
) => {
  const { locale, messages } = getState().intl;
  const intlSelector = new IntlProvider({ locale, messages }, {}).getChildContext().intl;
  dispatch(notify({
    message: intlSelector.formatMessage({ id: message }, messageVal),
    status,
    dismissible: true,
    dismissAfter,
    position: DEFAULT_POSITION,
  }));
};

export const infoNotification = message => (dispatch) => {
  dispatch(notification(message, 'info'));
};

export const successNotification = (message, dismissAfter = DEFAULT_DISMISS) => (dispatch) => {
  dispatch(notification(message, 'success', dismissAfter));
};

export const errorNotification = (message, messageVal) => (dispatch) => {
  dispatch(notification(message, 'error', 0, messageVal));
};

/*

status: Can be default, info, success, warning or error
message: What it says

*/
