import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Section = styled(motion.section)``;

const VARIANTS = {
  closed: {
    height: 0,
    opacity: 0,
    y: -100,
    transitionEnd: {
      display: 'none',
    },
  },
  open: {
    height: 'auto',
    display: 'block',
    opacity: 1,
    y: 0,
    transitionEnd: {
      zIndex: 500,
    },
  },
};

const TRANSITION = { type: 'tween', duration: 0.1, ease: [0.17, 0.67, 0.83, 0.67] };

const AnimatedSection = ({ children, isOpen }) => (
  <Section
    variants={VARIANTS}
    animate={isOpen ? 'open' : 'closed'}
    initial={false}
    transition={TRANSITION}
  >
    {children}
  </Section>
);

AnimatedSection.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default AnimatedSection;
