import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import is from 'react-intl/locale-data/is';

import { fetchTextKeysIfNeeded, setTouched } from './actions';
import { messages } from '../../messages';
import AppLoaderWrapper from '../../components/loader/AppLoaderWrapper';

class App extends Component {
  componentDidMount() {
    addLocaleData([...en, ...is]);
    this.props.fetchTextKeysIfNeeded();

    document.body.addEventListener('change', () => {
      if (!this.props.app.dataLoaded) return; // prevent setTouched on login
      if (!this.props.app.touched) this.props.setTouched(true);
    });
  }

  render() {
    const { children, textKeysLoaded } = this.props;
    if (textKeysLoaded) {
      return children;
    }
    return <AppLoaderWrapper />;
  }
}

App.propTypes = {
  app: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  fetchTextKeysIfNeeded: PropTypes.func.isRequired,
  textKeysLoaded: PropTypes.bool.isRequired,
  setTouched: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app }) => ({
  app,
  messages,
  textKeysLoaded: app.textKeysLoaded,
});

const mapDispatchToProps = {
  fetchTextKeysIfNeeded,
  setTouched,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
