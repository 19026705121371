import styled from 'styled-components';

const HeaderContainer = styled.div`
  position: sticky;
  top: 0px;
  z-index: 1;
  width: 100%;

  border-bottom: 1px solid #d1d1d1;
  background: white;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  position: absolute;
  z-index: 100;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Column = styled.div`
  display: flex;
`;

const ContactWrapper = styled.div`
  max-height: ${props => (props.isVisible ? '1500px' : '0px')};
  z-index: ${props => (props.isVisible ? '0' : '-10')};
  margin-bottom: ${props => (props.isVisible ? '20px' : 0)};
  background: #fff;
  transition: 0.3s linear;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
  border: ${({ isVisible }) => (isVisible ? '1px solid #ddd' : '0px solid #ddd')};
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
`;

const Nav = styled.div`
  height: 80px;
  box-sizing: content-box;
  /* border-bottom: 1px solid #d1d1d1; */
  /* border-top: 1px solid #d1d1d1; */
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const Image = styled.div`
  margin-top: 4px;
  cursor: pointer;
`;

const MenuContent = styled.div`
  display: flex;
  align-items: center;
  /* z-index: 100; */
`;

export const LogOutIconWrapper = styled.div`
  padding-right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  cursor: pointer;
`;

export const BackdropContacts = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  background: transparent;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

export { HeaderContainer, ContactWrapper, Row, Column, Nav, Image, MenuContent };
