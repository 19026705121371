import React from 'react';
import { PropTypes } from 'prop-types';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Loader } from '@origo_vl_banking/ibanking-react';
import { connect } from 'react-redux';

import { Button } from '../../../components/button';
import { ErrorMessage } from '../../../components';
import {
  Input, FormErrorMessage, Label, LabelWrapper,
} from '../styled-components/LoginForm';
import { resetPassword } from '../actions';

const NotificationContainer = styled.div`
  width: 100%;
  height: 55px;
  margin: 0 auto;
  background-color: ${(props) => props.theme.successLight};
  border: 1px solid ${(props) => props.theme.success};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-bottom: 25px;
`;

const FormWrapper = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const ResetPasswordForm = ({
  isSubmitting,
  submissionError,
  validators,
  dispatch,
  resetLinkSent,
}) => {
  const isSubmissionError = !isEmpty(submissionError);
  return (
    <>
      <Formik
        validationSchema={yup.object().shape(validators)}
        initialValues={{ username: '' }}
        render={({
          // handleSubmit,
          handleChange,
          handleBlur,
          touched,
          errors,
          values,
        }) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(resetPassword(values));
            }}
          >
            {isSubmissionError && <ErrorMessage errorKey={submissionError.errorKey} />}
            {resetLinkSent && (
              <NotificationContainer>
                <FormattedMessage id="Login.ResetPasswordSuccess" />
              </NotificationContainer>
            )}

            {!resetLinkSent && (
              <FormWrapper>
                <LabelWrapper>
                  <Label htmlFor="username">
                    <FormattedMessage id="Login_Username" />
                  </Label>
                </LabelWrapper>
                <Input
                  type="text"
                  name="username"
                  onBlur={handleBlur}
                  value={values.username}
                  onChange={handleChange}
                />
                {touched.username && errors.username && (
                  <FormErrorMessage>
                    <FormattedMessage id={errors.username} />
                  </FormErrorMessage>
                )}
                <Button
                  // width="150px"
                  marginTop="24px"
                  type="submit"
                  content={
                    isSubmitting ? <Loader /> : <FormattedMessage id="Login.ResetPasswordButton" />
                  }
                />
              </FormWrapper>
            )}
          </Form>
        )}
      />
    </>
  );
};

ResetPasswordForm.defaultProps = {
  submissionError: {},
};

ResetPasswordForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  submissionError: PropTypes.object,
  validators: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  resetLinkSent: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isSubmitting: state.auth.isSubmitting,
    submissionError: state.auth.error,
    resetLinkSent: state.auth.passwordResetLinkSent,
  };
}

export default connect(mapStateToProps)(ResetPasswordForm);
