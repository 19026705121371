import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MainHeader = styled.h3`
  margin-bottom: 4px;
  > span {
    color: ${({ theme }) => theme.headerColor};
    font-weight: 600;
    font-size: 28px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubtitleHeader = styled.h4`
  margin-bottom: 4px;
  padding-top: 6px;
`;

const Header = ({
  title,
  subtitle,
  message1,
  message2,
}) => {
  const showSubtitle = message1 || message2;

  
  return (
    <HeaderContainer>
      <MainHeader>
        <span><FormattedMessage id={title} /></span>
        {subtitle &&
          <>
        <span style={{ paddingLeft: 8, paddingRight: 8 }}>-</span>
        <span style={{ fontWeight: 400, fontSize: 24 }}><FormattedMessage id={subtitle} /></span>
          </>
        }
      </MainHeader>
      {showSubtitle && (
        <SubtitleHeader>
          {message1 && (<div style={{ paddingTop: 4 }}>{message1}</div>)}
          {message2 && (<div style={{ paddingTop: 4 }}>{message2}</div>)}
        </SubtitleHeader>
      )}
    </HeaderContainer>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  message1: PropTypes.string,
  message2: PropTypes.string,
};

Header.defaultProps = {
  subtitle: '',
  message1: '',
  message2: '',
};

export default Header;
