import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { push } from 'connected-react-router';

import DigitalSignatureModal from '../components/digital-sign-confirmation-modal/Modal';
import {
  setShowDigitalSignModal,
  sendToSigning,
  clearSigningStatus,
  setApplicationToSign,
} from '../modules/applications/actions';
import * as appActions from '../modules/app/actions';

const validateApplication = (application) => {
  const year = get(application, 'year', null);
  const serialNumber = get(application, 'serialNumber', null);

  if (year && serialNumber) {
    return true;
  }

  return false;
};

export default () => {
  const {
    digitalSignModal, isSigning, hasSigned, applicationToSign, signingStatus,
  } = useSelector(state => state.applications);
  const dispatch = useDispatch();

  useEffect(() => {
    setApplicationToSign(applicationToSign);
  }, [applicationToSign]);

  const modal = {
    onClose: () => {
      dispatch(appActions.resetState());
      dispatch(setShowDigitalSignModal(false));
      dispatch(setApplicationToSign({}));
      dispatch(clearSigningStatus());
      dispatch(push('/'));
    },
    onCancel: () => {
      dispatch(setShowDigitalSignModal(false));
      dispatch(setApplicationToSign({}));
      dispatch(clearSigningStatus());
    },
    onOpen: (application) => {
      if (validateApplication(application)) {
        dispatch(setApplicationToSign(application));
        dispatch(setShowDigitalSignModal(true));
      }
    },
    onSign: () => {
      if (validateApplication(applicationToSign)) {
        dispatch(sendToSigning({
          year: applicationToSign.year,
          serialNumber: applicationToSign.serialNumber,
        }));
      }
    },
  };

  return {
    applicationToSign,
    setApplicationToSign: app => dispatch(setApplicationToSign(app)),
    DigitalSignatureModal,
    showModal: digitalSignModal.show,
    application: {
      hasSigned,
      isSigning,
      signingStatus,
    },
    modal,
    setShowDigitalSignModal: show => dispatch(setShowDigitalSignModal(show)),
  };
};
