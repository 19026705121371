/* eslint-disable no-restricted-properties */
export function openEmail(email, serialNumber, typeText, year, ssn) {
  if (serialNumber) {
    window.location.href = `mailto:${email}?subject=${typeText} Umsókn nr. ${year} - ${serialNumber} á kt. ${ssn}`;
  } else {
    window.location.href = `mailto:${email}`;
  }
}

function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
}

export function createAndShowBlobFile(body) {
  const arrayBuffer = base64ToArrayBuffer(body);
  const file = new Blob([arrayBuffer], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL, '_blank', 'width=700,height=800');
}

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// prevent Formik's strange number error
export const preventNumError = (e, handleChange) => {
  if (isNaN(e.target.value)) return; // eslint-disable-line no-restricted-globals
  handleChange(e);
};

export const hasRequired = (data) => {
  const { terms, persons, mortgage } = data;
  const guarantorObject = persons.powerOfAttorney ? persons.powerOfAttorney : persons.guarantor;

  if (persons.secondApplicant.ssn && !persons.secondApplicant.telephone) {
    return (
      mortgage.insuranceCompanyId
      && mortgage.mortgageItem
      && terms.price
      && terms.dueDateCount
      && persons.applicant.ssn
      && persons.applicant.telephone
      && persons.seller.ssn
      && persons.secondApplicant.telephone
    );
  }
  if (guarantorObject.ssn && !guarantorObject.telephone) {
    return (
      mortgage.insuranceCompanyId
      && mortgage.mortgageItem
      && terms.price
      && terms.dueDateCount
      && persons.applicant.ssn
      && persons.applicant.telephone
      && persons.seller.ssn
      && guarantorObject.telephone
    );
  }
  if (persons.secondGuarantor.ssn && !persons.secondGuarantor.telephone) {
    return (
      mortgage.insuranceCompanyId
      && mortgage.mortgageItem
      && terms.price
      && terms.dueDateCount
      && persons.applicant.ssn
      && persons.applicant.telephone
      && persons.seller.ssn
      && persons.secondGuarantor.telephone
    );
  }
  return (
    mortgage.insuranceCompanyId
    && mortgage.mortgageItem
    && terms.price
    && terms.dueDateCount
    && persons.applicant.ssn
    && persons.applicant.telephone
    && persons.seller.ssn
  );
};

export const hasRequiredCarRental = (data) => {
  const { terms, persons } = data;
  if (persons.guarantor.ssn && !persons.guarantor.telephone) {
    return (
      terms.price
      && terms.dueDateCount
      && persons.applicant.ssn
      && persons.applicant.telephone
      && persons.seller.ssn
      && persons.guarantor.telephone
    );
  }
  return (
    terms.price
    && terms.dueDateCount
    && persons.applicant.ssn
    && persons.applicant.telephone
    && persons.seller.ssn
  );
};

export const prepareData = (app, status, type) => {
  const isCompany = app.ssnType === 'company';
  let { secondApplicant, guarantor, secondGuarantor } = app;
  if (!app.secondApplicant.ssn) {
    secondApplicant = {};
  }

  if (!app.guarantor.ssn) {
    guarantor = {};
  }

  if (!app.secondGuarantor.ssn) {
    secondGuarantor = {};
  }
  const data = {
    type,
    status,
    year: app.year,
    serialNumber: app.serialNumber,
    terms: app.terms,
    fees: app.fees,
    calculation: app.calculation,
    communication: app.communication,
    mortgage: app.mortgage,
    car: app.car,
    persons: {
      applicant: app.applicant,
      secondApplicant,
      guarantor: isCompany ? null : guarantor,
      powerOfAttorney: isCompany ? guarantor : null,
      seller: app.seller,
      secondGuarantor,
      consultant: app.consultant,
    },
  };
  return data;
};

export const checkSsnType = (ssn) => {
  const str = ssn.slice(0, 2);
  const parsedValue = parseInt(str, 10);
  if (parsedValue > 31) {
    return 'company';
  }
  return 'person';
};

export const normalizeMortgageItem = (value, setFieldValue, name) => {
  if (!value) {
    setFieldValue(name, '');
    return value;
  }

  if (value.length < 2) {
    return setFieldValue(name, value);
  }
  const firstTwo = value.slice(0, 2);
  const rest = value.slice(2).replace(/\s/g, '');

  return setFieldValue(name, `${firstTwo} ${rest}`);
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line prefer-template
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
