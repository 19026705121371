import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Button } from '../button';
import { prepareAndCalculate } from '../../modules/app/actions';

const SmartPdfButton = ({ app, type, calculate }) => {
  const { calculation, editable, terms } = app; // eslint-disable-line
  if (!editable || !terms.price) return null;

  return (
    <Button
      style={{ marginTop: '18px' }}
      content={<FormattedMessage id="Application.GetContractDraft" />}
      width="175px"
      onClick={() => calculate(type, true)}
    />
  );
};

SmartPdfButton.propTypes = {
  app: PropTypes.object.isRequired,
  type: PropTypes.number.isRequired,
  calculate: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app }) => ({ app });

const mapDispatchToProps = {
  calculate: prepareAndCalculate,
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartPdfButton);
