import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ArrowDown, ArrowUp } from '@origo_vl_banking/icons';
import { isEmpty } from 'lodash';

import {
  DropdownContent,
  IconWrapper,
  SelectButton,
  Header,
  Table,
  Tr,
  Th,
  Td,
} from '../styled-components';

const CarTypeDropdown = ({
  carModelSelected, modelData, handleSelectCarModel, disabled,
}) => {
  const [expand, setExpand] = useState(false);
  const handleExpand = () => {
    if (isEmpty(modelData) || disabled) return;
    setExpand(!expand);
  };
  return (
    <React.Fragment>
      <SelectButton disabled={disabled} tabIndex="1" onClick={() => handleExpand()}>
        {carModelSelected ? carModelSelected.carModel : ''}
        <IconWrapper>{expand ? <ArrowUp size={16} /> : <ArrowDown size={16} />}</IconWrapper>
      </SelectButton>
      {expand ? (
        <DropdownContent>
          <Table>
            <thead>
              <Header>
                <Th>
                  <FormattedMessage id="Application.CarModel" />
                </Th>
                <Th>
                  <FormattedMessage id="Application.CarTransmission" />
                </Th>
                <Th>
                  <FormattedMessage id="Application.CarFuel" />
                </Th>
                <Th>
                  <FormattedMessage id="Application.CarDoors" />
                </Th>
              </Header>
            </thead>
            <tbody>
              {modelData
                ? modelData.map(item => (
                  <Tr
                    key={item.typeId}
                    onClick={() => handleSelectCarModel(item, setExpand, expand)}
                  >
                    <Td>{item.carModel}</Td>
                    <Td>{item.carTransmission}</Td>
                    <Td>{item.carFuel}</Td>
                    <Td>{item.carDoors}</Td>
                  </Tr>
                  ))
                : null}
            </tbody>
          </Table>
        </DropdownContent>
      ) : null}
    </React.Fragment>
  );
};

CarTypeDropdown.propTypes = {
  carModelSelected: PropTypes.object,
  modelData: PropTypes.array,
  handleSelectCarModel: PropTypes.func.isRequired,
};

CarTypeDropdown.defaultProps = {
  modelData: undefined,
  carModelSelected: undefined,
};

export default CarTypeDropdown;
