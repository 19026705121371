import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';

const Container = styled.div`
  background-color: hsl(46, 98%, 98%);
  border: 1px solid hsl(46, 98%, 57%);
  padding: 8px;
  align-items: center;
  margin: 16px 0;
  color: #232323;
  display: flex;
`;

const Icon = styled.div`
  padding-right: 8px;
  display: flex;
  align-items: center;
`;

const Message = ({ message, isError }) => (
  <Container>
    <Icon>
      {isError ? (
        <AiOutlineClose color="#e74c3c" size={28} />
      ) : (
        <AiOutlineCheck color="#2ecc71" size={28} />
      )}
    </Icon>
    {message}
  </Container>
);

Message.propTypes = {
  isError: PropTypes.bool,
  message: PropTypes.string.isRequired,
};

Message.defaultProps = {
  isError: false,
};

export default Message;
