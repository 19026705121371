import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button } from '../button';
import AppLoader from '../loader/AppLoader';
import Message from './Success';
import useDigitalSignModal from '../../hooks/use-digital-sign-modal';

const Container = styled.section`
  min-width: 310px;
`;
const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ alignEnd }) => (alignEnd ? '1fr' : '1fr 1fr')};
  grid-column-gap: 8px;
  margin-top: 16px;
  justify-items: ${({ alignEnd }) => alignEnd && 'end'};
`;

const Content = () => {
  const {
    application: { hasSigned, isSigning, signingStatus },
    modal: { onSign, onClose, onCancel },
  } = useDigitalSignModal();
  return (
    <Container>
      <h3 style={{ marginTop: 8, marginBottom: 8 }}>
        <FormattedMessage id="DigitalSignature.Heading" />
      </h3>
      {!isSigning ? (
        <div>
          <>
            {signingStatus && !hasSigned && (
              <Message message={signingStatus} isError={signingStatus && !hasSigned} />
            )}
            {signingStatus && hasSigned && <Message message={signingStatus} />}
            {!signingStatus && <FormattedMessage id="DigitalSignature.SignContent" />}
            <ButtonContainer alignEnd={signingStatus}>
              <Button
                content={(
                  <FormattedMessage
                    id={`DigitalSignature.Button.${signingStatus ? 'Close' : 'Cancel'}`}
                  />
                )}
                primary={false}
                background="#bfbfbf"
                color="white"
                border="1px solid transparent"
                hoverColor="#b3b3b3"
                onClick={() => (signingStatus ? onClose() : onCancel())}
              />
              {!signingStatus && (
                <Button
                  content={<FormattedMessage id="DigitalSignature.Button.Sign" />}
                  primary
                  disabled={signingStatus}
                  onClick={() => onSign()}
                />
              )}
            </ButtonContainer>
          </>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
          }}
        >
          <div style={{ marginRight: 16 }}>
            <AppLoader size="32px" borderSize="4px" />
          </div>
          <p>
            <FormattedMessage id="DigitalSignature.Sending" />
          </p>
        </div>
      )}
    </Container>
  );
};

Content.propTypes = {};

export default Content;
