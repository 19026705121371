import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl-redux';
import { ThemeProvider } from 'styled-components';
import moment from 'moment';
import { createBrowserHistory } from 'history';
import 'moment/locale/is';

import configureStore from './configureStore';
import { PrimaryTheme } from './utils/theme';
import GlobalStyles from './utils/GlobalStyles';
import { messages } from './messages';
import Routes from './routes';
import { app } from './modules';

const history = createBrowserHistory();

const store = configureStore({}, history);

// injectGlobalStyles(PrimaryTheme);

moment.locale('is');

const Index = () => (
  <Provider store={store}>
    <IntlProvider locale="is" messages={messages}>
      <ThemeProvider theme={PrimaryTheme}>
        <GlobalStyles />
        <app.App>
          <Routes history={history} />
        </app.App>
      </ThemeProvider>
    </IntlProvider>
  </Provider>
);

ReactDOM.render(<Index />, document.getElementById('root'));
