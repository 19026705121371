import { IntlProvider } from 'react-intl';
import { updateIntl } from 'react-intl-redux';
import { push } from 'connected-react-router';
import { get } from 'lodash';

import { messages as localMessages } from '../../messages';
import { Api, notifications } from '../../utils';
import * as A from './constants';
import { getCarType, getCarTypeInfo } from '../car-rental/actions';
import { appType, appStatus } from '../../utils/constants';
import { createAndShowBlobFile } from '../../utils/helpers';

// import devData from '../../messages/dev.json';
// import testData from '../../messages/test.json';

export const resetState = () => ({
  type: A.RESET_STATE,
});

export const fetchTextKeysRequest = (url) => ({
  type: A.TEXT_KEYS_REQUEST,
  url,
});

export const fetchTextKeysSuccess = (textKeys, intlSelector) => ({
  type: A.TEXT_KEYS_SUCCESS,
  textKeys,
  intlSelector,
});

export const fetchTextKeysFailure = (error) => ({
  type: A.TEXT_KEYS_FAILURE,
  error,
});

export const fetchTextKeys = () => (dispatch, getState) => {
  const { locale } = getState().app;
  dispatch(fetchTextKeysRequest);
  Api.getLanguage(locale)
    .then((response) => {
      const messages = { ...localMessages[locale], ...response.data };
      dispatch(updateIntl({ locale, messages }));
      const intlSelector = new IntlProvider({
        locale,
        messages,
      }).getChildContext().intl;
      //
      // const newObj = {
      //   ...testData,
      //   ...devData,
      // };
      //
      dispatch(fetchTextKeysSuccess(messages, intlSelector));
    })
    .catch((error) => {
      dispatch(fetchTextKeysFailure(error));
    });
};

export const fetchTextKeysIfNeeded = () => (dispatch, getState) => {
  const { locale } = getState().app;
  if (!getState().app.textKeysLoaded && !getState().app.isFetchingTextKeys) {
    dispatch(fetchTextKeys(locale));
  } else if (getState().intl.locale !== locale) {
    const { textKeys } = getState().app;
    dispatch(updateIntl({ locale, messages: textKeys }));
  }
};

export const setValidationError = (validationError) => ({
  type: A.SET_VALIDATION_ERROR,
  validationError,
});

// export const setSecondApplicantValidationError = validationError => ({
//   type: A.SET_SECOND_APPLICANT_VALIDATION_ERROR,
//   validationError,
// });

export const setTouched = (touched) => ({
  type: A.SET_TOUCHED,
  touched,
});

export const getApplicationRequest = () => ({
  type: A.GET_APPLICATION_REQUEST,
});

export const getApplicationSuccess = (data) => ({
  type: A.GET_APPLICATION_SUCCESS,
  data,
});

export const getApplicationFailure = (error) => ({
  type: A.GET_APPLICATION_FAILURE,
  error,
});

export const setInsuranceCompanyName = (companyName) => ({
  type: A.SET_INSURANCE_COMPANY_NAME,
  companyName,
});

const getInsuranceCompaniesRequest = () => ({
  type: A.GET_INSURANCE_COMPANIES_REQUEST,
});

const getInsuranceCompaniesSuccess = (companies) => ({
  type: A.GET_INSURANCE_COMPANIES_SUCCESS,
  companies,
});

const getInsuranceCompaniesFailure = () => ({
  type: A.GET_INSURANCE_COMPANIES_FAILURE,
});

export const getInsuranceCompanies = (id) => (dispatch) => {
  dispatch(getInsuranceCompaniesRequest());
  return Api.getInsuranceCompanies()
    .then((res) => {
      const companies = res.data.map((x) => ({
        key: x.insuranceCompanyId,
        value: x.name,
      }));
      dispatch(getInsuranceCompaniesSuccess(companies));
      if (id) {
        const selected = companies.find((item) => item.key === id);
        if (selected.value) {
          dispatch(setInsuranceCompanyName(selected.value));
        }
      }
    })
    .catch((err) => dispatch(getInsuranceCompaniesFailure(err)));
};

export const saveApplicationRequest = (request) => ({
  type: A.SAVE_APPLICATION_REQUEST,
  request,
});

export const saveApplicationSuccess = (data) => ({
  type: A.SAVE_APPLICATION_SUCCESS,
  data,
});

export const saveApplicationFailure = (error) => ({
  type: A.SAVE_APPLICATION_FAILURE,
  error,
});

export const calcRequest = (data) => ({
  type: A.CALCULATION_REQUEST,
  data,
});

export const calcSuccess = (data) => ({
  type: A.CALCULATION_SUCCESS,
  data,
});

export const calcFailure = (error, calculation, SapErrorMessage) => ({
  type: A.CALCULATION_FAILURE,
  error,
  calculation,
  SapErrorMessage,
});

export const carInfoRequest = () => ({
  type: A.CAR_INFO_REQUEST,
});

export const carInfoSuccess = (car) => ({
  type: A.CAR_INFO_SUCCESS,
  car,
});

export const carInfoFailure = (error) => ({
  type: A.CAR_INFO_FAILURE,
  error,
});

export const getCarInfo = (id) => (dispatch, getState) => {
  dispatch(carInfoRequest());
  const {
    type,
    seller,
    year,
    serialNumber,
  } = getState().app;

  return Api.getVehicle(id, year, serialNumber)
    .then((res) => {
      dispatch(carInfoSuccess(res.data));
      if (!seller || !seller.ssn) {
        if (type === 1 || type === 8) {
          if (res.data && res.data.ownerRegistryId) {
            dispatch(setSellerField('ssn', res.data.ownerRegistryId));
            dispatch(getSellerName(res.data.ownerRegistryId));
          }
        }
      }
    })
    .catch((err) => {
      dispatch(carInfoFailure(err));
    });
};

export const getCalculate = (data, createPdf = false) => (dispatch, getState) => {
  dispatch(calcRequest(data));

  Api.getCalculation(data, createPdf)
    .then((res) => {
      if (createPdf) {
        createAndShowBlobFile(res.data.cashFlow);
      }

      dispatch(calcSuccess(res.data));
    })
    .catch((error) => {
      const SapErrorMessage = get(error, 'errorData.SapErrorMessage', null);
      return dispatch(calcFailure(error, data, SapErrorMessage));
    });
};

export const prepareAndCalculate = (type, createPdf = false) => (dispatch, getState) => {
  const { app } = getState();
  if (!app.terms.price) return;
  const data = {
    type: type || app.type,
    terms: app.terms,
    fees: app.fees,
    car: app.car,
    calculation: app.calculation,
    mortgage: app.mortgage,
  };

  dispatch(getCalculate(data, createPdf));
};

export const getPersonNameRequest = () => ({
  type: A.GET_PERSON_NAME_REQUEST,
});

export const getPersonNameFailure = (error) => ({
  type: A.GET_PERSON_NAME_FAILURE,
  error,
});

const getConsultantSuccess = (data) => ({
  type: A.GET_CONSULTANT_SUCCESS,
  data,
});

export const getConsultant = (id) => (dispatch) => {
  dispatch(getPersonNameRequest());
  Api.getConsultantInfo(id)
    .then((res) => {
      dispatch(getConsultantSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getPersonNameFailure(err));
    });
};

export const getApplicantNameSuccess = (name) => ({
  type: A.GET_APPLICANT_NAME_SUCCESS,
  name,
});

export const resetApplicant = () => ({
  type: A.RESET_APPLICANT,
});

export const resetSecondApplicant = () => ({
  type: A.RESET_SECOND_APPLICANT,
});

export const resetSecondApplicantField = () => ({
  type: A.RESET_SECOND_APPLICANT_FIELDS,
});

export const resetGuarantor = () => ({
  type: A.RESET_GUARANTOR,
});

export const resetGuarantorFields = () => ({
  type: A.RESET_GUARANTOR_FIELDS,
});

export const resetSecondGuarantor = () => ({
  type: A.RESET_SECOND_GUARANTOR,
});

export const resetSecondGuarantorFields = () => ({
  type: A.RESET_SECOND_GUARANTOR_FIELDS,
});

export const getApplicant2NameSuccess = (name) => ({
  type: A.GET_APPLICANT2_NAME_SUCCESS,
  name,
});

export const getSellerNameSuccess = (name) => ({
  type: A.GET_SELLER_NAME_SUCCESS,
  name,
});

export const getApplicantName = (id) => (dispatch) => {
  dispatch(getPersonNameRequest());
  Api.getPersonName(id)
    .then((res) => {
      dispatch(getApplicantNameSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getPersonNameFailure(err));
    });
};

export const setPersonType = (data, ssn) => ({
  type: A.SET_PERSON_SSN_TYPE,
  data,
  ssn,
});

export const setPrintInfo = (printInfo) => ({
  type: A.SET_PRINT_INFO,
  printInfo,
});

export const getLeasingApplicantName = (id) => (dispatch) => {
  dispatch(getPersonNameRequest());
  return new Promise((resolve, reject) => Api.getLeasingPersonName(id)
    .then((res) => {
      const { name } = res.data;
      dispatch(getApplicantNameSuccess(name));
      dispatch(setPersonType(res.data, id));
      resolve(res.data);
    })
    .catch((err) => {
      reject(err);
      dispatch(getPersonNameFailure(err));
    }));
};

export const getGuarantorNameSuccess = (name) => ({
  type: A.GET_GUARANTOR_NAME_SUCCESS,
  name,
});

export const getGuarantorName = (id) => (dispatch) => {
  dispatch(getPersonNameRequest());
  Api.getPersonName(id)
    .then((res) => {
      dispatch(getGuarantorNameSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getPersonNameFailure(err));
    });
};

export const getSecondGuarantorNameSuccess = (name) => ({
  type: A.GET_SECOND_GUARANTOR_NAME_SUCCESS,
  name,
});

export const getSecondGuarantorName = (id) => (dispatch) => {
  dispatch(getPersonNameRequest());
  Api.getPersonName(id)
    .then((res) => {
      dispatch(getSecondGuarantorNameSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getPersonNameFailure(err));
    });
};

export const getSecondApplicantName = (id) => (dispatch) => {
  if (id === '') {
    dispatch(getApplicant2NameSuccess(''));
  }
  dispatch(getPersonNameRequest());
  Api.getPersonName(id)
    .then((res) => {
      dispatch(getApplicant2NameSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getPersonNameFailure(err));
    });
};

export const getSellerName = (id) => (dispatch) => {
  dispatch(getPersonNameRequest());
  Api.getPersonName(id)
    .then((res) => {
      dispatch(getSellerNameSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getPersonNameFailure(err));
    });
};

export const getApplication = (year, serialNumber, shouldGetInfo) => (dispatch) => {
  dispatch(resetState());
  dispatch(getApplicationRequest());
  return new Promise((resolve, reject) => {
    Api.getApplicationDetails(year, serialNumber)
      .then((res) => {
        const { data } = res;
        const { insuranceCompanyId } = data.mortgage;
        const { terms } = data;
        const applicationDetails = {
          ...data,
          terms: {
            ...terms,
            calculatedPayout:
              get(terms, 'price', 0)
              - get(terms, 'cashDeposit', 0)
              - (get(terms, 'priceDepositItem') - get(terms, 'accruedDepositItem', 0)),
          },
        };

        dispatch(getApplicationSuccess(applicationDetails));
        dispatch(getInsuranceCompanies(insuranceCompanyId));
        if (shouldGetInfo) dispatch(getCarTypeInfo());
        const { ssn } = res.data.persons.consultant;
        if (ssn) dispatch(getConsultant(ssn));

        if (data.mortgage.mortgageItem) {
          dispatch(getCarInfo(data.mortgage.mortgageItem));
        }

        resolve(data);
      })
      .catch((err) => {
        dispatch(getApplicationFailure(err));
        reject(err);
      });
  });
};

export const postApplication = (data, serialNumber) => (dispatch) => {
  dispatch(saveApplicationRequest(data));
  const apiCall = serialNumber ? Api.updateApplication : Api.saveApplication;
  apiCall(data)
    .then((res) => {
      dispatch(saveApplicationSuccess(res.data));
      if (data.status === appStatus.save) {
        dispatch(notifications.successNotification('Application.Saved'));
      }
      if (data.status === appStatus.send) {
        dispatch(notifications.successNotification('Application.Sent', 15000));
        dispatch(push('/'));
      }
    })
    .catch((err) => {
      dispatch(saveApplicationFailure(err));
    });
};

export const setCommunication = (communication) => ({
  type: A.SET_COMMUNICATION,
  communication,
});

export const setMilage = (mortgageMilage) => ({
  type: A.SET_MILEAGE,
  mortgageMilage,
});

export const resetCarInfo = () => ({
  type: A.RESET_CAR_INFO,
});

export const setMortgageAmount = (mortgageAmount) => ({
  type: A.SET_MORTGAGE_AMOUNT,
  mortgageAmount,
});

export const setCarInsuranceCompany = (insuranceCompanyId) => ({
  type: A.SET_CAR_INSURANCE_COMPANY,
  insuranceCompanyId,
});

export const setInterestChangeAllowed = (commitmentFeeFlag) => ({
  type: A.SET_INTEREST_CHANGED_ALLOWED,
  commitmentFeeFlag,
});

export const setDepositItem = (depositItem) => ({
  type: A.SET_DEPOSIT_ITEM,
  depositItem,
});

export const resetPriceDepositItem = () => ({
  type: A.RESET_PRICE_DEPOSIT_ITEM,
});

export const resetAccruedDepositItem = () => ({
  type: A.RESET_ACCRUED_DEPOSIT_ITEM,
});

export const setTermsPrice = (price) => ({
  type: A.SET_TERMS_PRICE,
  price,
});

export const setTermsDueDateCount = (dueDateCount) => ({
  type: A.SET_TERMS_DUE_DATE_COUNT,
  dueDateCount,
});

export const setTermsCashDeposit = (cashDeposit) => ({
  type: A.SET_TERMS_CASH_DEPOSIT,
  cashDeposit,
});

export const setAccruedDepositItem = (accruedDepositItem) => ({
  type: A.SET_ACCRUED_DEPOSIT_ITEM,
  accruedDepositItem,
});

export const setPriceDepositItem = (priceDepositItem) => ({
  type: A.SET_PRICE_DEPOSIT_ITEM,
  priceDepositItem,
});

export const setApplicantField = (field, value) => ({
  type: A.SET_APPLICANT_FIELD,
  field,
  value,
});

export const setSecondApplicantField = (field, value) => ({
  type: A.SET_SECOND_APPLICANT_FIELD,
  field,
  value,
});

export const setGuarantorField = (field, value) => ({
  type: A.SET_GUARANTOR_FIELD,
  field,
  value,
});

export const setSecondGuarantorField = (field, value) => ({
  type: A.SET_SECOND_GUARANTOR_FIELD,
  field,
  value,
});

export const setSellerField = (field, value) => ({
  type: A.SET_SELLER_FIELD,
  field,
  value,
});

export const setSsnType = (value) => ({
  type: A.SET_SSN_TYPE,
  value,
});

export const setCarTypeId = (typeId) => ({
  type: A.SET_CAR_TYPE_ID,
  typeId,
});

export const setAccessory = (accessory) => ({
  type: A.SET_ACCESSORY,
  accessory,
});

export const setMaxMilage = (maxMilage) => ({
  type: A.SET_MAX_MILEAGE,
  maxMilage,
});

export const setCashDeposit = (cashDeposit) => ({
  type: A.SET_CASH_DEPOSIT,
  cashDeposit,
});

export const getPreConditions = (type) => (dispatch) => {
  dispatch(getApplicationRequest());
  Api.getPreConditions(type)
    .then((res) => {
      dispatch(getApplicationSuccess(res.data));
      dispatch(getInsuranceCompanies());
      if (res.data.type === 9 || res.data.type === 2) {
        dispatch(getSellerName(sessionStorage.sellerSSN));
        dispatch(setSellerField('ssn', sessionStorage.sellerSSN));
      }
    })
    .catch((error) => dispatch(getApplicationFailure(error)));
};

export const loadPrecondition = (route) => (dispatch) => {
  dispatch(resetState());
  if (route === '/car-loan') {
    dispatch(getPreConditions(appType.carLoan));
  }
  if (route === '/car-leasing') {
    dispatch(getPreConditions(appType.carLeasing));
  }
  if (route === '/car-rental') {
    dispatch(getPreConditions(appType.carRental));
    dispatch(getCarType());
  }
  if (route === '/leasing') {
    dispatch(getPreConditions(appType.leasing));
  }
};

const getPDFRequest = (item, documentType) => ({
  type: A.GET_PDF_REQUEST,
  item,
  documentType,
});

const getPDFSuccess = () => ({
  type: A.GET_PDF_SUCCESS,
});

const getPDFFailure = () => ({
  type: A.GET_PDF_FAILURE,
});

export const getPDF = (item, type) => async (dispatch) => {
  dispatch(getPDFRequest(item, type));
  try {
    const res = await Api.contract({ ...item, type });
    dispatch(getPDFSuccess());
    createAndShowBlobFile(res.data.contractPDF);
  } catch (err) {
    return dispatch(getPDFFailure(err));
  }
};

export const setInterestPercent = (interestPercent) => ({
  type: A.SET_INTEREST_PERCENT,
  interestPercent,
});

export const setCommitmentFeeFlag = (commitmentFeeFlag) => ({
  type: A.SET_COMMITMENT_FEE_FLAG,
  commitmentFeeFlag,
});

export const invalidCalculationMessage = () => (dispatch, getState) => {
  const { SapErrorMessage } = getState().app;
  return dispatch(
    notifications.errorNotification('Application.InvalidCalculation', { reason: SapErrorMessage }),
  );
};
