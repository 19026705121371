import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DetailsButton } from '@origo_vl_banking/ibanking-react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Consultant } from './';

const ConsultantWrapper = styled.div`
  display: flex;
  position: relative;
`;

export default function ConsultantInfo(props) {
  const [expanded, setExpanded] = useState(false);
  const { consultant, openEmailTo } = props;

  if (!consultant.name) return null;
  return (
    <ConsultantWrapper>
      <DetailsButton
        color="#5b6d7a"
        content={<FormattedMessage id="Application.Consultant" />}
        showDetails={expanded}
        onClick={() => setExpanded(!expanded)}
      />
      <br />
      {expanded && (
        <Consultant isConsultantInfo consultant={consultant} openEmailTo={openEmailTo} />
      )}
    </ConsultantWrapper>
  );
}

ConsultantInfo.propTypes = {
  consultant: PropTypes.object.isRequired,
  openEmailTo: PropTypes.func.isRequired,
};
