/* eslint-disable no-underscore-dangle */
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { intlReducer } from 'react-intl-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as notificationsReducer } from 'reapop';
import { responsiveStoreEnhancer, responsiveStateReducer } from 'redux-responsive';

import { app, auth, applications, header, settings, carrental, mortgage } from './modules';
import { initialState as authState } from './modules/auth/reducer';
import { middleware } from './utils';

export default ({ initialState = { auth: authState } }, history) => {
  const composeEnhancers =
    process.env.NODE_ENV === 'development'
      ? composeWithDevTools({
        trace: true,
        name: 'Lykill',
      })
      : compose;

  const rootReducer = (state, action) => {
    const reducer = combineReducers({
      [app.NAME]: app.reducer,
      [auth.NAME]: auth.reducer,
      [applications.NAME]: applications.reducer,
      [header.NAME]: header.reducer,
      [settings.NAME]: settings.reducer,
      [carrental.NAME]: carrental.reducer,
      [mortgage.NAME]: mortgage.reducer,
      notifications: notificationsReducer(),
      router: connectRouter(history),
      intl: intlReducer,
      browser: responsiveStateReducer,
    });

    if (action.type === 'CLEAR_STATE') {
      sessionStorage.clear();
      return reducer(
        {
          app: state.app,
          browser: state.browser,
          intl: state.intl,
          router: state.router,
        },
        action,
      );
    }

    return reducer(
      {
        ...state,
        auth: { ...state.auth, lastActivity: Date.now() }, // update every time reducer is called
      },
      action,
    );
  };

  const routerMware = routerMiddleware(history);

  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(responsiveStoreEnhancer, applyMiddleware(...middleware, routerMware)),
  );
};
