import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { push } from 'connected-react-router';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { map } from 'lodash';

import Modal from '../modal/Modal';
import useModal from '../modal/use-modal';
import { RoutingModal, LogoutModal } from '../../components/modal-content';
import { loadPrecondition } from '../../modules/app/actions';
import { ButtonPrimary, ButtonSecondary } from '../button/styled-components';
import { Menu, Head, BurgerTitle, BurgerIcon, Body, MenuTitle } from './styled-component/Dropdown';
import Burger from './components/Burger';
import { resetMortageState } from '../../modules/mortgage/actions';
import { toggleConsultants } from '../../modules/header/actions';
import MenuItem from './components/MenuItem';
import useRoles from '../../hooks/use-roles';

const variants = {
  closed: { height: 0, y: -25, zIndex: -1 },
  open: {
    height: 'auto',
    y: 0,
    transitionEnd: {
      zIndex: 500,
    },
  },
};

const Dropdown = ({
  dispatch,
  handleLogout,
  handleClose,
  isClosed,
  userFullName,
  touched,
  isVisible,
  handleHome,
}) => {
  const routingModal = useModal();
  const logoutModal = useModal();
  const { hasRole } = useRoles();
  const [currentRoute, setCurrentRoute] = useState('');
  const icon = isClosed ? <Burger /> : '×';

  const goChangePassword = () => {
    dispatch(push('/settings/change-password'));
    handleClose();
  };

  const handleRoute = (route) => {
    if (touched) {
      routingModal.toggleModal(true);
      setCurrentRoute(route);
    } else {
      dispatch(loadPrecondition(route));
      dispatch(resetMortageState());
      dispatch(push(route));
    }
    handleClose();
  };

  const handleLogoutClick = () => {
    logoutModal.toggleModal(true);
    handleClose(false);
  };

  const continueTo = () => {
    dispatch(loadPrecondition(currentRoute));
    dispatch(resetMortageState());
    routingModal.toggleModal(false);
    setCurrentRoute(currentRoute);
    dispatch(push(currentRoute));
  };

  const cancelRoutingModal = () => {
    routingModal.toggleModal(false);
  };

  const cancelLogoutModal = () => {
    logoutModal.toggleModal(false);
  };

  const LOAN_ITEMS = [
    {
      onClick: () => handleRoute('/car-loan'),
      role: 'Loan',
      messageId: 'sitemap.Loan',
    },
    {
      onClick: () => handleRoute('/car-leasing'),
      role: 'CarLeasing',
      messageId: 'sitemap.CarLeasing',
    },
    {
      onClick: () => handleRoute('/mortgage-switch'),
      role: 'MortgageSwitch',
      messageId: 'sitemap.MortgageSwitch',
    },
    {
      onClick: () => handleRoute('/car-rental'),
      role: 'CarRental',
      messageId: 'sitemap.CarRental',
    },
    {
      onClick: () => handleRoute('/leasing'),
      role: 'Leasing',
      messageId: 'sitemap.Lease',
    },
  ];

  return (
    <Menu>
      <Modal
        content={<RoutingModal continueTo={continueTo} cancelModal={cancelRoutingModal} />}
        showModal={routingModal.showModal}
        onClose={() => routingModal.toggleModal(false)}
      />
      <Modal
        content={<LogoutModal handleLogout={handleLogout} cancelModal={cancelLogoutModal} />}
        showModal={logoutModal.showModal}
        onClose={() => logoutModal.toggleModal(false)}
      />
      <Head onClick={handleClose}>
        <BurgerTitle>
          <FormattedMessage id="Dropdown.Menu" />
        </BurgerTitle>
        <BurgerIcon>{icon}</BurgerIcon>
      </Head>
      <Body
        variants={variants}
        animate={isClosed ? 'closed' : 'open'}
        transition={{ type: 'tween', duration: 0.2, ease: [0.17, 0.67, 0.83, 0.67] }}
      >
        {map(
          LOAN_ITEMS,
          (loan, index) =>
            hasRole(loan.role) && (
              <MenuItem
                key={`${loan.role}-${index}`}
                onClick={loan.onClick}
                messageId={loan.messageId}
              />
            ),
        )}
        <MenuItem onClick={handleHome} messageId="sitemap.ApplicationList" />
        <MenuItem
          onClick={() => dispatch(toggleConsultants(!isVisible))}
          messageId="Application.Consultants"
        />
        <MenuItem
          messageId="Application.Forms"
          onClick={() => window.open('https://www.lykill.is/um-lykil/eydublod/', '_blank')}
        />
        <MenuItem
          messageId="Application.Handbook"
          onClick={() =>
            window.open(
              'https://www.lykill.is/wp-content/skrar/Notendahandbok_Rafraent_undirritadir_Lykilsamningar.pdf',
              '_blank',
            )
          }
        />

        <MenuTitle>{userFullName}</MenuTitle>
        <div
          style={{
            display: 'flex',
            marginTop: '20px',
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
        >
          <ButtonPrimary width="50%" onClick={goChangePassword}>
            <FormattedMessage id="Dropdown.Settings" />
          </ButtonPrimary>
          <ButtonSecondary width="50%" onClick={handleLogoutClick}>
            <FormattedMessage id="Dropdown.LogOut" />
          </ButtonSecondary>
        </div>
      </Body>
    </Menu>
  );
};

Dropdown.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  isClosed: PropTypes.bool,
  userFullName: PropTypes.string.isRequired,
  touched: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

Dropdown.defaultProps = {
  isClosed: false,
};

function mapStateToProps({ app, header }) {
  return {
    touched: app.touched,
    isVisible: header.isVisible,
  };
}

export default connect(mapStateToProps)(Dropdown);
