import styled from 'styled-components';

import { mdMin, lgMin } from '../../../utils/constants';

const Row = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
  @media (min-width: ${mdMin}) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 12px;
`;

const FormGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-flow: column;

  @media (min-width: ${mdMin}) {
    flex-flow: ${(props) => props.flow || 'row'};
    align-items: ${(props) => props.align || 'center'};
  }
`;

const ButtonGroup = styled.div`
  margin-top: 16px;
  @media (min-width: ${mdMin}) {
    display: ${({ editable }) => (editable ? 'flex' : 'none')};
    justify-content: space-between;
  }
`;

const TextLabel = styled.div`
  width: 250px;
`;

const Divider = styled.div`
  height: 2px;
  background-color: #ddd;
  @media (min-width: ${mdMin}) {
    width: 350px;
  }
`;

const TransparentButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
  margin-left: 8px;
`;

const SwitchButton = styled.button`
  display: block;
  height: 30px;
  min-width: 121px;
  width: 115px;
  cursor: pointer;
  background: ${({ active }) => (active ? '#fdcd28' : '#fff')};
  color: #333;
  font-size: 16px;
  outline: 0;
  border: 1px solid #fdcd28;

  &:focus {
    outline: 0;
  }
`;

const InputWrapper = styled.div`
  margin-right: 16px;
  width: 100px;
`;

const AmountWrapper = styled.div`
  margin-top: 8px;
  @media (min-width: ${lgMin}) {
    margin-top: 0;
  }
`;

const Small = styled.small`
  font-size: 12px;
  margin-top: 24px;
  display: inline-block;
  color: #5b6d7a;
`;

const ClearIcon = styled.span`
  color: ${({ theme }) => theme.secondaryColor};
  font-size: 34px;
  cursor: pointer;
`;

const TermsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* grid-template-columns: 1fr 1fr; */
`;

const TermsFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TermsTripleRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr 156px;
  gap: 8px;
`;

export {
  Row,
  FormGroup,
  HeaderWrapper,
  TextLabel,
  Divider,
  ButtonGroup,
  TransparentButton,
  SwitchButton,
  InputWrapper,
  AmountWrapper,
  Small,
  ClearIcon,
  TermsRow,
  TermsFormContainer,
  TermsTripleRow,
};
