import styled from 'styled-components';

import { xlMin } from '../../utils/constants';

const Container = styled.div`
  padding-left: 2%;
  padding-right: 2%;
  margin-bottom: 2%;

  @media (min-width: ${xlMin}) {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    width: ${xlMin};
  }
`;

export default Container;
