import {
  TABLE_DATA_REQUEST,
  TABLE_DATA_SUCCESS,
  TABLE_DATA_FAILURE,
  GET_CONTRACT_REQUEST,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_FAILURE,
  SET_START_DATE,
  SET_END_DATE,
  SEND_TO_SIGNING_REQUEST,
  SEND_TO_SIGNING_SUCCESS,
  SEND_TO_SIGNING_FAILURE,
  CLEAR_SIGNING_STATUS,
  SET_SHOW_DIGITAL_SIGN_MODAL,
  SET_APPLICATION_TO_SIGN,
  SET_SHOW_DISPOSAL_MODAL,
  SET_SHOW_DOCUMENTS_MODAL,
  GET_CREDIT_INSTITUTIONS_REQUEST,
  GET_CREDIT_INSTITUTIONS_SUCCESS,
  GET_CREDIT_INSTITUTIONS_FAILURE,
  GET_DISPOSAL_INFORMATION_REQUEST,
  GET_DISPOSAL_INFORMATION_SUCCESS,
  GET_DISPOSAL_INFORMATION_FAILURE,
  POST_DISPOSAL_INFORMATION_REQUEST,
  POST_DISPOSAL_INFORMATION_SUCCESS,
  POST_DISPOSAL_INFORMATION_FAILURE,
  UPLOAD_DOCUMENTS_REQUEST,
  UPLOAD_DOCUMENTS_SUCCESS,
  UPLOAD_DOCUMENTS_FAILURE,
  INITIALIZE_DISPOSAL_STATE,
  INITIALIZE_DOCUMENTS_STATE,
  SET_SHOW_MERGER_MESSAGE_MODAL,
} from './constants';
import { Api, notifications } from '../../utils';
import { createAndShowBlobFile } from '../../utils/helpers';

export const tableDataRequest = () => ({
  type: TABLE_DATA_REQUEST,
});

export const tableDataSuccess = (tableData) => ({
  type: TABLE_DATA_SUCCESS,
  tableData,
});

export const tableDataFailure = (error) => ({
  type: TABLE_DATA_FAILURE,
  error,
});

export const getApplications = (from, to, isRefresh = false) => (dispatch) => {
  if (!isRefresh) {
    dispatch(tableDataRequest());
  }

  Api.getApplications(from, to)
    .then((res) => dispatch(tableDataSuccess(res.data)))
    .catch((err) => dispatch(tableDataFailure(err)));
};

const getContractRequest = (request) => ({
  type: GET_CONTRACT_REQUEST,
  request,
});

const getContractSuccess = () => ({
  type: GET_CONTRACT_SUCCESS,
});

const getContractFailure = (error) => ({
  type: GET_CONTRACT_FAILURE,
  error,
});

export const getContract = (data) => (dispatch) => {
  dispatch(getContractRequest(data));
  return Api.contract(data)
    .then((res) => {
      dispatch(getContractSuccess());
      createAndShowBlobFile(res.data.contractPDF);
    })
    .catch((err) => {
      dispatch(getContractFailure(err));
    });
};

export const setStartDate = (startDate) => ({
  type: SET_START_DATE,
  startDate,
});

export const setEndDate = (endDate) => ({
  type: SET_END_DATE,
  endDate,
});

const sendToSigningRequest = (req) => ({
  type: SEND_TO_SIGNING_REQUEST,
  req,
});

const sendToSigningSuccess = (status) => ({
  type: SEND_TO_SIGNING_SUCCESS,
  status,
});

const sendToSigningFailure = (error) => ({
  type: SEND_TO_SIGNING_FAILURE,
  error,
  displayNotification: false,
});

export const clearSigningStatus = () => ({
  type: CLEAR_SIGNING_STATUS,
});

export const sendToSigning = (data) => (dispatch, getState) => {
  dispatch(sendToSigningRequest(data));
  const { startDate, endDate } = getState().applications;
  const payload = {
    serialNumber: data.serialNumber,
    year: data.year,
  };

  return new Promise((resolve, reject) => {
    Api.sendToSigning(payload)
      .then((res) => {
        // dispatch(notifications.successNotification('Sign.Successful'));
        dispatch(sendToSigningSuccess(res.data));
        dispatch(
          getApplications(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), true),
        );
        resolve(res.data);
      })
      .catch((err) => {
        dispatch(sendToSigningFailure(err));
        reject(err);
      });
  });
};

export const setShowDigitalSignModal = (showModal) => ({
  type: SET_SHOW_DIGITAL_SIGN_MODAL,
  showModal,
});

export const setShowDisposalModal = (showModal) => ({
  type: SET_SHOW_DISPOSAL_MODAL,
  showModal,
});

export const setShowDocumentsModal = (showModal) => ({
  type: SET_SHOW_DOCUMENTS_MODAL,
  showModal,
});

export const setShowMergerMessageModal = (showModal) => ({
  type: SET_SHOW_MERGER_MESSAGE_MODAL,
  showModal,
});

export const setApplicationToSign = (applicationToSign) => ({
  type: SET_APPLICATION_TO_SIGN,
  applicationToSign,
});

const getCreditInstitutionRequest = () => ({
  type: GET_CREDIT_INSTITUTIONS_REQUEST,
});

const getCreditInstitutionSuccess = (creditInstitutions) => ({
  type: GET_CREDIT_INSTITUTIONS_SUCCESS,
  creditInstitutions,
});

const getCreditInstitutionFailure = ({ error }) => ({
  type: GET_CREDIT_INSTITUTIONS_FAILURE,
  error,
});

export const getCreditInstitution = (data) => (dispatch, getState) => {
  const { status } = getState().applications.creditInstitutions;
  if (status !== 'initial') {
    return null;
  }
  dispatch(getCreditInstitutionRequest(data));
  return Api.disposal
    .getCreditInstitution()
    .then((res) => {
      dispatch(getCreditInstitutionSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getCreditInstitutionFailure(err));
    });
};

const getDisposalInformationRequest = () => ({
  type: GET_DISPOSAL_INFORMATION_REQUEST,
});

const getDisposalInformationSuccess = (data) => ({
  type: GET_DISPOSAL_INFORMATION_SUCCESS,
  data,
});

const getDisposalInformationFailure = (disposalErrorMessage) => ({
  type: GET_DISPOSAL_INFORMATION_FAILURE,
  disposalErrorMessage,
});

const MOCK = {
  serialNumber: '10000200',
  year: '2021',
  payments: [
    {
      registryId: '6302110500',
      paymentCurr: 'ISK',
      paymentAmnt: 4141.0,
      bankKey: '6546',
      ctrlKey: '12',
      bankAcct: '000123',
    },
  ],
  payoffs: [
    {
      partnerId: '106107',
      paymentNote: '12 3',
      paymentCurr: 'ISK',
      paymentAmnt: 123.0,
    },
  ],
};

export const getDisposalInformation = (data, displayErrorMessage = false) => (dispatch) => {
  dispatch(getDisposalInformationRequest(data));
  // return dispatch(getDisposalInformationSuccess(MOCK));

  return Api.disposal
    .get(data)
    .then((res) => {
      dispatch(getDisposalInformationSuccess(res.data));
    })
    .catch((err) => {
      dispatch(getDisposalInformationFailure(err));
    });
};

const postDisposalInformationRequest = () => ({
  type: POST_DISPOSAL_INFORMATION_REQUEST,
});

const postDisposalInformationSuccess = () => ({
  type: POST_DISPOSAL_INFORMATION_SUCCESS,
});

const postDisposalInformationFailure = (disposalErrorMessage) => ({
  type: POST_DISPOSAL_INFORMATION_FAILURE,
  disposalErrorMessage,
});

export const postDisposalInformation = (data) => (dispatch) => {
  dispatch(postDisposalInformationRequest(data));
  return new Promise((resolve, reject) => Api.disposal
    .post(data)
    .then((res) => {
      dispatch(notifications.successNotification('Disposal.Notification.Success'));
      dispatch(postDisposalInformationSuccess(res.data));
      resolve(res.data);
    })
    .catch((err) => {
      dispatch(postDisposalInformationFailure(err));
      reject(err);
    }));
};

const uploadDocumentsRequest = () => ({
  type: UPLOAD_DOCUMENTS_REQUEST,
});

const uploadDocumentsSuccess = () => ({
  type: UPLOAD_DOCUMENTS_SUCCESS,
});

const uploadDocumentsFailure = (uploadError) => ({
  type: UPLOAD_DOCUMENTS_FAILURE,
  uploadError,
});

export const uploadDocuments = (data) => async (dispatch) => {
  dispatch(uploadDocumentsRequest(data));

  try {
    const res = await Api.documents.upload(data);
    dispatch(notifications.successNotification('Documents.Notification.Success'));
    dispatch(uploadDocumentsSuccess(res.data));
    return res;
  } catch (err) {
    dispatch(uploadDocumentsFailure(err));
    return err;
  }
};

export const initializeDisposalState = () => ({
  type: INITIALIZE_DISPOSAL_STATE,
});

export const initializeDocumentsState = () => ({
  type: INITIALIZE_DOCUMENTS_STATE,
});
