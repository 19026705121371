import React from 'react';

const ListArrow = props => (
  <svg width={18} height={15} viewBox="0 0 18 15" fill="none" {...props}>
    <path d="M0 7.4h16" stroke="#FDCD28" strokeWidth={2} strokeMiterlimit={10} />
    <path
      d="M10.4 1.8L16 7.4 10.4 13"
      stroke="#FDCD28"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </svg>
);

export default ListArrow;
