import { get, padStart } from 'lodash';

export default (e, padBy, setFieldValue, setTouched, touched) => {
  const oldValue = get(e, 'target.value', '0');
  const newValue = padStart(oldValue, padBy, '0');
  const inputDetails = e.target.name.split('.');
  const formId = inputDetails[0];
  const inputId = inputDetails[1];
  if (touched) {
    setTouched({ ...touched, [formId]: { ...touched[formId], [inputId]: true } }, false);
  }

  if (newValue === '00' || newValue === '0000' || newValue === '000000') {
    return setFieldValue(e.target.name, '');
  }
  return setFieldValue(e.target.name, newValue);
};
