import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Tooltip } from '@origo_vl_banking/ibanking-react';
import { format } from '@origo_vl_banking/utils';
import Info from '../icons/Info';

const Container = styled.div``;

const Discount = ({ discountPct, paymentToSales, discountAmount }) => (
  <Container>
    <Tooltip
      message={
        <FormattedMessage
          id="Discount.Tooltip"
          values={{
            discountPct: new Intl.NumberFormat('de-DE').format(discountPct),
            newline: <br />,
            paymentToSales: new Intl.NumberFormat('de-DE').format(paymentToSales),
            discountAmount: format.amount(discountAmount),
          }}
        />
      }
    >
      <Info />
    </Tooltip>
  </Container>
);

Discount.propTypes = {
  discountPct: PropTypes.number.isRequired,
  paymentToSales: PropTypes.number.isRequired,
  discountAmount: PropTypes.number.isRequired,
};

export default Discount;
