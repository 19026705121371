import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import Button from '../../button/Button';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ConfirmExitContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ConfirmExitButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const ConfirmHeading = styled.h4`
  text-align: center;
`;

const ButtonRow = ({
  formProps, closeModal, disabled, status,
}) => {
  const [isExiting, setExiting] = useState(false);
  const { touched, errors, dirty, validateForm } = formProps;

  useEffect(() => {
    validateForm();
  }, [validateForm]);


  const formDisabled = disabled
                      // || Object.keys(touched).length === 0
                      || Object.keys(errors).length > 0;

  return (
    <Container center={disabled}>
      {isExiting ? (
        <ConfirmExitContainer>
          <ConfirmHeading>
            <FormattedMessage id="Disposal.Heading.ConfirmClose" />
          </ConfirmHeading>
          <ConfirmExitButtons>
            <Button
              content={<FormattedMessage id="Disposal.Button.Exit.Cancel" />}
              primary={false}
              background="#bfbfbf"
              color="white"
              border="1px solid transparent"
              hoverColor="#b3b3b3"
              onClick={() => setExiting(false)}
            />
            <Button
              content={<FormattedMessage id="Disposal.Button.Exit.Confirm" />}
              background="hsl(46, 98%, 57%)"
              loading={status === 'posting'}
              onClick={() => closeModal()}
            />
          </ConfirmExitButtons>
        </ConfirmExitContainer>
      ) : (
        <>
          <Button
            content={(
              <FormattedMessage
                id={disabled ? 'Disposal.Button.Close' : 'Disposal.Button.Cancel'}
              />
            )}
            primary={false}
            background="#bfbfbf"
            color="white"
            border="1px solid transparent"
            hoverColor="#b3b3b3"
            onClick={() => {
              if (dirty) {
                return setExiting(true);
              }
              return closeModal();
            }}
          />
          {!disabled && (
            <Button
              content={<FormattedMessage id="Disposal.Button.Submit" />}
              disabled={formDisabled}
              loading={status === 'posting'}
              type="submit"
            />
          )}
        </>
      )}
    </Container>
  );
};

ButtonRow.propTypes = {
  formProps: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    dirty: PropTypes.bool,
    validateForm: PropTypes.func,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default ButtonRow;
