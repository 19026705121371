import { appType } from '../../utils/constants';
import { hasRequired, prepareData } from '../../utils/helpers';
import {
  setValidationError,
  postApplication,
  resetGuarantorFields,
  resetSecondGuarantorFields,
  resetSecondApplicantField,
  invalidCalculationMessage,
} from '../app/actions';

export const handleApplication = status => (dispatch, getState) => {
  const { app } = getState();

  if (!app.secondApplicant.ssn || !app.secondApplicant) {
    resetSecondApplicantField();
  }

  if (!app.guarantor.ssn || !app.guarantor) {
    resetGuarantorFields();
  }

  if (!app.secondGuarantor.ssn || !app.secondGuarantor) {
    resetSecondGuarantorFields();
  }

  if (app.terms.cashDeposit >= app.terms.price) {
    return;
  }

  if (!app.isCalculationValid) {
    return dispatch(invalidCalculationMessage());
  }

  const data = prepareData(app, status, appType.carLeasing);

  if (!hasRequired(data) || app.applicant.telephone.length < 7) {
    return dispatch(setValidationError('Form.Error.required-field'));
  }

  if (app.secondApplicant.telephone && app.secondApplicant.telephone.length < 7) {
    return null;
  }
  if (app.guarantor.telephone && app.guarantor.telephone.length < 7) {
    return null;
  }
  if (app.secondGuarantor.telephone && app.secondGuarantor.telephone.length < 7) {
    return null;
  }

  dispatch(postApplication(data, data.serialNumber));
};
