import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { Button } from '../../../components/button';
import { Input, FormErrorMessage, Label } from '../styled-components/ElectronicLoginForm';

const EletronicAppLoginForm = ({ formSubmitting, handleLogin, validators }) => (
  <React.Fragment>
    <Formik
      validationSchema={yup.object().shape(validators)}
      initialValues={{ mobile: '' }}
      onSubmit={values => handleLogin(values)}
      render={({
 handleSubmit, handleChange, handleBlur, touched, errors,
}) => (
  <Form onSubmit={handleSubmit}>
    <Label htmlFor="mobile">
      <FormattedMessage id="Application.SSN" />
    </Label>
    <Input
      type="text"
      id="snn"
      maxLength={10}
      label="Field.ssn"
      pattern="\d*"
      name="ssn"
      // autoComplete="ssn"
      onBlur={handleBlur}
      onChange={handleChange}
      disabled={formSubmitting}
    />
    {touched.mobile && errors.mobile && (
    <FormErrorMessage>
      <FormattedMessage id={errors.mobile} />
    </FormErrorMessage>
          )}
    <Button
      type="submit"
      marginTop="24px"
      loading={formSubmitting}
      content={<FormattedMessage id="Login.LoginButton" />}
    />
  </Form>
      )}
    />
  </React.Fragment>
);

EletronicAppLoginForm.defaultProps = {
  formSubmitting: false,
};

EletronicAppLoginForm.propTypes = {
  handleLogin: PropTypes.func.isRequired,
  formSubmitting: PropTypes.bool,
  validators: PropTypes.object.isRequired,
};

export default EletronicAppLoginForm;
