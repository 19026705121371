import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { TextInput } from '@origo_vl_banking/ibanking-react';
import { EqualColumns, TextName } from '../grid/styled-components';
import { validate } from '../../utils/validate';

const validators = yup.object().shape({
  email: yup.string().email('Form.Error.invalid-email'),
});

const SecondApplicant = ({
  app,
  secondApplicant,
  getSecondApplicantName,
  handleSecondApplicantChange,
  disabled,
  validationError,
}) => (
  <Formik
    enableReinitialize
    validationSchema={validators}
    initialValues={{
      ssn: secondApplicant.ssn || '',
      telephone: secondApplicant.telephone || '',
      email: secondApplicant.email || '',
    }}
    validate={(values) => {
      const error = {};

      if (values.ssn && !validate.ssn(values.ssn)) {
        error.ssn = 'Form.Error.invalid-ssn';
      }

      if (values.ssn && values.telephone.length < 7) {
        error.telephone = 'Form.Error.invalid-phone';
      }

      if (values.telephone && !validate.phoneNumber(values.telephone)) {
        error.telephone = 'Form.Error.invalid-phone';
      }

      if (values.ssn && !values.telephone) {
        error.telephone = 'Form.Error.required-field';
      }

      return error;
    }}
    render={({
 touched, errors, handleBlur, handleChange,
}) => {
      const handleSecondApplicantSsn = (e) => {
        handleBlur(e);
        getSecondApplicantName(e);
      };

      const handleSecondApplicantField = (e) => {
        handleChange(e);
        handleSecondApplicantChange(e.target.name, e);
      };

      return (
        <EqualColumns style={{ paddingBottom: '20px' }}>
          <>
            <TextInput
              label="Application.SSN"
              normalize="ssn"
              maxLength={11}
              name="ssn"
              height="34px"
              onBlur={handleSecondApplicantSsn}
              onChange={handleChange}
              value={secondApplicant.ssn || ''}
              error={touched.ssn && errors.ssn}
              disabled={disabled}
              width="100%"
              tabIndex="9"
              justifyErrorMessage="flex-end"
              errorInLabel
            />
            <TextName>{secondApplicant.name}</TextName>
            <TextInput
              type="tel"
              maxLength={7}
              normalize="number"
              label="Application.Telephone"
              name="telephone"
              height="34px"
              onBlur={handleBlur}
              onChange={handleSecondApplicantField}
              value={secondApplicant.telephone || ''}
              // error={touched.telephone && errors.telephone}
              error={
                (touched.telephone && errors.telephone) ||
                (!touched.telephone && secondApplicant.ssn && validationError)
              }
              disabled={disabled}
              star="red"
              tabIndex="10"
              justifyErrorMessage="flex-end"
              errorInLabel
            />
            <TextInput
              label="Application.Email"
              name="email"
              height="34px"
              onBlur={handleBlur}
              onChange={handleSecondApplicantField}
              value={secondApplicant.email || ''}
              error={touched.email && errors.email}
              disabled={disabled}
              tabIndex="11"
              justifyErrorMessage="flex-end"
              errorInLabel
            />
            {
            ((app.type !== 1 && app.type !== 2 && app.type !== 8) && (
              <>
                <TextInput
                  label="Application.Occupation"
                  name="occupation"
                  height="34px"
                  onBlur={handleBlur}
                  onChange={handleSecondApplicantField}
                  value={secondApplicant.occupation || ''}
                  disabled={disabled}
                  tabIndex="12"
                />
                <TextInput
                  label="Application.Company"
                  name="companyName"
                  height="34px"
                  onBlur={handleBlur}
                  onChange={handleSecondApplicantField}
                  value={secondApplicant.companyName || ''}
                  disabled={disabled}
                  tabIndex="13"
                />
              </>
            ))}
          </>
        </EqualColumns>
      );
    }}
  />
);

SecondApplicant.propTypes = {
  app: PropTypes.object.isRequired,
  secondApplicant: PropTypes.object,
  handleSecondApplicantChange: PropTypes.func,
  getSecondApplicantName: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SecondApplicant.defaultProps = {
  secondApplicant: {},
  handleSecondApplicantChange: () => {},
  disabled: false,
};

export default SecondApplicant;
