import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const Input = styled.input`
  /* width: 25px;
  height: 25px; */
  line-height: 20px;
  cursor: pointer;
  display: inline;
  && {
    margin: unset;
    margin-right: 8px;
  }

  @media screen and (max-width: 768px) {
    && {
      margin: unset;
      margin-right: 8px;
    }
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Checkbox = ({
  name, id, checked, label, onChange, onBlur, disabled, formatted,
}) => {
  if (!name || !label) {
    return null;
  }
  return (
    <Label>
      <Input
        type="checkbox"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        id={id || name}
        checked={checked}
        disabled={disabled}
      />
      {formatted ? <FormattedMessage id={label} /> : label}
    </Label>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  formatted: PropTypes.bool,
};

Checkbox.defaultProps = {
  id: undefined,
  formatted: true,
};

export default Checkbox;
