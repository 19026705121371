import React from 'react';
import { PropTypes } from 'prop-types';

import { TextInput as IBankingTextInput } from '@origo_vl_banking/ibanking-react';

const TextInput = ({ rightSideText, ...rest }) => (
  <IBankingTextInput
    height="34px"
    rightSideText={
      rightSideText && {
        text: rightSideText,
        inInput: true,
        style: { fontWeight: 'bold', color: 'hsl(209,21%,45%)' },
      }
    }
    {...rest}
  />
);

TextInput.propTypes = {
  rightSideText: PropTypes.string,
};

TextInput.defaultProps = {
  rightSideText: undefined,
};

export default TextInput;
