import { push } from 'connected-react-router';

import {
  MORTGAGE_INFO_REQUEST,
  MORTGAGE_INFO_SUCCESS,
  MORTGAGE_INFO_FAILURE,
  SET_MORTGAGE_ITEM,
  SET_SSN,
  CAR_INFO_REQUEST,
  CAR_INFO_SUCCESS,
  CAR_INFO_FAILURE,
  SET_MORTGAGE_AMOUNT,
  SET_MILAGE,
  SET_CAR_INSURANCE_COMPANY,
  SET_CAR_INSURANCE_COMPANIES,
  SAVE_MORTGAGE_REQUEST,
  SAVE_MORTGAGE_SUCCESS,
  SAVE_MORTGAGE_FAILURE,
  SEND_MORTGAGE_REQUEST,
  SEND_MORTGAGE_SUCCESS,
  SEND_MORTGAGE_FAILURE,
  RESET_MORTGAGE_STATE,
  GET_MORTGAGE_DETAILS_REQUEST,
  GET_MORTGAGE_DETAILS_SUCCESS,
  GET_MORTGAGE_DETAILS_FAILURE,
  SET_COMMUNICATION,
  SET_VALIDATION_ERROR,
} from './constants';
import { Api, notifications } from '../../utils';

const mortgageInfoRequest = request => ({
  type: MORTGAGE_INFO_REQUEST,
  request,
});

const mortgageInfoSuccess = data => ({
  type: MORTGAGE_INFO_SUCCESS,
  data,
});

const mortgageInfoFailure = error => ({
  type: MORTGAGE_INFO_FAILURE,
  error,
});

export const getMortgageInfo = data => (dispatch) => {
  dispatch(mortgageInfoRequest(data));
  return Api.mortgageSwitch
    .getItemInfo(data)
    .then((res) => {
      dispatch(mortgageInfoSuccess(res.data));
    })
    .catch((err) => {
      dispatch(mortgageInfoFailure(err));
    });
};

export const setMortgageItem = mortgageItem => ({
  type: SET_MORTGAGE_ITEM,
  mortgageItem,
});

export const setSsn = ssn => ({
  type: SET_SSN,
  ssn,
});

export const setMortgageAmount = amount => ({
  type: SET_MORTGAGE_AMOUNT,
  amount,
});

export const setMilage = mortgageMilage => ({
  type: SET_MILAGE,
  mortgageMilage,
});

const carInfoRequest = () => ({
  type: CAR_INFO_REQUEST,
});

const carInfoSuccess = car => ({
  type: CAR_INFO_SUCCESS,
  car,
});

const carInfoFailure = error => ({
  type: CAR_INFO_FAILURE,
  error,
});

export const getCarInfo = id => (dispatch, getState) => {
  dispatch(carInfoRequest());
  const {
    year,
    serialNumber,
  } = getState().app;

  return Api.getVehicle(id, year, serialNumber)
    .then((res) => {
      dispatch(carInfoSuccess(res.data));
    })
    .catch((err) => {
      dispatch(carInfoFailure(err));
    });
};

export const setCarInsuranceCompany = insuranceCompanyId => ({
  type: SET_CAR_INSURANCE_COMPANY,
  insuranceCompanyId,
});

const setCarInsuranceCompanies = carInsuranceCompanies => ({
  type: SET_CAR_INSURANCE_COMPANIES,
  carInsuranceCompanies,
});

export const getCarInsuranceCompanies = () => (dispatch) => {
  Api.getInsuranceCompanies().then((res) => {
    const companies = res.data.map(x => ({
      key: x.insuranceCompanyId,
      value: x.name,
    }));
    dispatch(setCarInsuranceCompanies(companies));
  });
};

export const setCommunication = communication => ({
  type: SET_COMMUNICATION,
  communication,
});

export const setValidationError = validationError => ({
  type: SET_VALIDATION_ERROR,
  validationError,
});

const saveMortgageSwitchRequest = request => ({
  type: SAVE_MORTGAGE_REQUEST,
  request,
});

const saveMortgageSwitchSuccess = data => ({
  type: SAVE_MORTGAGE_SUCCESS,
  data,
});

const saveMortgageSwitchFailure = error => ({
  type: SAVE_MORTGAGE_FAILURE,
  error,
});

export const saveMortgageSwitch = data => (dispatch) => {
  const payload = {
    ...data,
    seller: sessionStorage.sellerSSN,
    contactPerson: sessionStorage.username,
  };

  let apiMethod = Api.mortgageSwitch.save;
  if (payload.year !== '0000' && payload.serialNumber !== '00000000') {
    apiMethod = Api.mortgageSwitch.update;
  }

  if (!data.newMortgage.insuranceCompanyId || !data.newMortgage.mortgageItem) {
    return dispatch(setValidationError('Form.Error.required-field'));
  }

  dispatch(saveMortgageSwitchRequest(payload));
  apiMethod(payload)
    .then((res) => {
      dispatch(saveMortgageSwitchSuccess(res.data));
      if (data.status === '5') {
        dispatch(notifications.successNotification('Application.Saved'));
      }
      if (data.status === '10') {
        dispatch(notifications.successNotification('Application.Sent'));
      }
    })
    .catch((err) => {
      dispatch(saveMortgageSwitchFailure(err));
    });
};

const sendMortgageSwitchRequest = request => ({
  type: SEND_MORTGAGE_REQUEST,
  request,
});

const sendMortgageSwitchSuccess = data => ({
  type: SEND_MORTGAGE_SUCCESS,
  data,
});

const sendMortgageSwitchFailure = error => ({
  type: SEND_MORTGAGE_FAILURE,
  error,
});

export const sendMortgageSwitch = data => (dispatch) => {
  let apiCall = Api.mortgageSwitch.send;
  const payload = {
    ...data,
    seller: sessionStorage.sellerSSN,
    contactPerson: sessionStorage.username,
  };

  if (payload.serialNumber !== '0000000' && payload.year !== '0000') {
    // Used when finalizing a saved application.
    apiCall = Api.mortgageSwitch.update;
  }

  if (!data.newMortgage.insuranceCompanyId || !data.newMortgage.mortgageItem) {
    return dispatch(setValidationError('Form.Error.required-field'));
  }

  dispatch(sendMortgageSwitchRequest());
  apiCall(payload)
    .then((res) => {
      dispatch(notifications.successNotification('Application.Sent'));
      dispatch(sendMortgageSwitchSuccess(res.data));
      dispatch(push('/'));
    })
    .catch((err) => {
      dispatch(sendMortgageSwitchFailure(err));
    });
};

export const resetMortageState = () => ({
  type: RESET_MORTGAGE_STATE,
});

const getMortgageDetailsRequest = request => ({
  type: GET_MORTGAGE_DETAILS_REQUEST,
  request,
});

const getMortgageDetailsSuccess = mortgageDetails => ({
  type: GET_MORTGAGE_DETAILS_SUCCESS,
  mortgageDetails,
});

const getMortgageDetailsFailure = error => ({
  type: GET_MORTGAGE_DETAILS_FAILURE,
  error,
});

export const getMortgageDetails = data => async (dispatch) => {
  dispatch(getMortgageDetailsRequest(data));
  try {
    const res = await Api.mortgageSwitch.getDetails(data);
    dispatch(getMortgageDetailsSuccess(res.data));
  } catch (err) {
    dispatch(getMortgageDetailsFailure(err));
  }
};
