import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { uniq, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import styled, { css } from 'styled-components';

import { SelectDropdown } from '@origo_vl_banking/ibanking-react';
import { strip } from '@origo_vl_banking/utils';
import TextInput from '../../../components/text-input/TextInput';

import { validate } from '../../../utils/validate';

import { getCalculate, setCarTypeId, setAccessory, setMaxMilage } from '../../app/actions';
import { getCarType, clearSelectedCarType } from '../actions';
import { Label } from '../styled-components';
import CarTypeDropdown from './CarTypeDropdown';
import { mdMin } from '../../../utils/constants';

const TermsContainer = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: minmax(1fr, 2fr);
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  grid-template-areas:
    'makeDropdown typeDropdown'
    'subType subType'
    /* 'subTypeText subTypeText' */
    'price accessoriesPrice'
    'estimatedMilage estimatedMilage';
  transition: 500ms;

  @media all and (max-width: ${mdMin}) {
    grid-template-areas:
      'makeDropdown'
      'typeDropdown'
      'subType'
      'price'
      'accessoriesPrice'
      'estimatedMilage';
  }
`;

const CarTypeWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'dropdown';

  ${({ show }) => {
    if (show) {
      return css`
        grid-row-gap: 8px;
        grid-template-rows: 1fr min-content;
        grid-template-areas: 'dropdown' 'type';
      `;
    }

    return null;
  }}
`;

const InputWrapper = styled.div`
  grid-area: ${({ gridArea }) => gridArea};
`;

const VehicleMake = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: space-between;
`;

const validators = {
  price: validate.formik.amount,
};

const Terms = ({
  app,
  car,
  carInfo,
  carMainTypes,
  carTypeList,
  handleCalculation,
  handleSetCarTypeId,
  handleAccessory,
  handleMaxMilage,
  disabled,
  handleClearSelectedCarType,
}) => {
  // const [expand, setExpand] = useState(false);
  const [carMainType, setCarMainType] = useState('');
  const [carSubType, setCarSubType] = useState('');
  const [carModelSelected, setCarModelSelected] = useState({});
  const [subTypeListData, setSubTypesData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const {
    calculation, terms, fees, mortgage, validationError,
  } = app;

  const setSubTypeDropdown = (make) => {
    const subTypeList = uniq(carTypeList.filter(item => item.carMainType === make));
    const subTypes = uniq(subTypeList.map(item => item.carSubType));
    const carSubTypes = subTypes.map((item, index) => ({
      key: index,
      value: item,
    }));
    return setSubTypesData(carSubTypes);
  };

  const setModelDropdownData = (model) => {
    setCarModelSelected({});
    setCarSubType(model);
    const carModelList = uniq(carTypeList.filter(item => item.carSubType === model));
    return setModelData(carModelList);
  };

  const updateFields = () => {
    setCarMainType(carInfo.carMainType || '');
    setCarSubType(carInfo.carSubType || '');
    if (carInfo.carMainType && carInfo.carSubType) {
      setSubTypeDropdown(carInfo.carMainType);
      setModelDropdownData(carInfo.carSubType);
    } else {
      setSubTypesData([]);
      setModelData([]);
    }
    setCarModelSelected(carInfo || []);
  };

  useEffect(() => {
    updateFields();
  }, [carMainTypes, carTypeList, updateFields]);

  const handleSelectMainType = (value) => {
    const selected = carMainTypes.find(x => x.key === value);
    setCarMainType(selected.value);
    setCarSubType('');
    setCarModelSelected({});
    setModelData([]);
    setSubTypeDropdown(selected.value);
    handleSetCarTypeId(0);
    handleClearSelectedCarType();
  };

  const handleSelectSubType = (value) => {
    const selected = subTypeListData.find(x => x.key === value);
    setModelDropdownData(selected.value);
    handleSetCarTypeId(0);
  };

  const handleSelectCarModel = (value, setExpand, expand) => {
    if (!carModelSelected) return;
    setCarModelSelected(value);
    setExpand(!expand);
    handleSetCarTypeId(value.typeId);
    if (terms.price) {
      const data = {
        type: 9,
        terms,
        fees,
        calculation,
        car: {
          ...car,
          typeId: value.typeId,
        },
        mortgage,
      };
      handleCalculation(data, false);
    }
  };

  const handlePriceOnBlur = (e, handleBlur) => {
    handleBlur(e);
    if (isEmpty(e.target.value)) return;
    const price = parseInt(strip.amount(e.target.value), 10);
    const parsedPrice = price;
    if (parsedPrice < 0 || parsedPrice === terms.price) return;
    const data = {
      type: 9,
      terms: {
        ...terms,
        price: parsedPrice,
      },
      fees,
      calculation,
      car,
      mortgage,
    };

    if (parsedPrice === terms.price || parsedPrice === 0) return;
    handleCalculation(data, false);
  };

  const handlecarAccessoryItemOnBlur = (e) => {
    const value = parseInt(strip.amount(e.target.value), 10);
    if (!value || value === car.accessory) return;
    handleAccessory(value);
    const data = {
      type: 9,
      terms: {
        ...terms,
      },
      fees,
      calculation,
      car: {
        ...car,
        accessory: value,
      },
      mortgage,
    };
    if (!terms.price) return;
    handleCalculation(data, false);
  };

  const handleCarMaxMilageOnBlur = (e) => {
    const value = parseInt(strip.amount(e.target.value), 10);
    if (value < 0 || value === car.maxMilage) return;
    handleMaxMilage(value);
    const data = {
      type: 9,
      terms: {
        ...terms,
      },
      fees,
      calculation,
      car: {
        ...car,
        maxMilage: value,
      },
      mortgage,
    };
    if (!terms.price) return;
    handleCalculation(data, false);
  };

  return (
    <TermsContainer>
      <InputWrapper gridArea="makeDropdown">
        <SelectDropdown
          label="Application.CarType"
          headerHeight="34px"
          width="100%"
          data={carMainTypes}
          onClicked={handleSelectMainType}
          displayValue={carMainType || ''}
          tabIndex="1"
          disabled={disabled}
        />
      </InputWrapper>
      <InputWrapper gridArea="typeDropdown">
        <SelectDropdown
          label="Application.CarSubType"
          headerHeight="34px"
          width="100%"
          data={subTypeListData}
          onClicked={handleSelectSubType}
          displayValue={carSubType || ''}
          tabIndex="1"
          disabled={disabled}
        />
      </InputWrapper>
      <InputWrapper gridArea="subType">
        <CarTypeWrapper show={carInfo || carModelSelected}>
          <InputWrapper gridArea="dropdown">
            <Label>
              <FormattedMessage id="Application.CarModel" />
            </Label>
            <CarTypeDropdown
              handleSelectCarModel={handleSelectCarModel}
              carModelSelected={carModelSelected}
              modelData={modelData}
              disabled={disabled}
            />
          </InputWrapper>
          <InputWrapper gridArea="type">
            <VehicleMake show={carInfo || carModelSelected}>
              {(carInfo || carModelSelected) && (
                <React.Fragment>
                  <div>{carModelSelected.carTransmission || carInfo.carTransmission}</div>
                  <div>{carModelSelected.carDoors || carInfo.carDoors}</div>
                  <div>{carModelSelected.carFuel || carInfo.carFuel}</div>
                </React.Fragment>
              )}
            </VehicleMake>
          </InputWrapper>
        </CarTypeWrapper>
      </InputWrapper>
      <Formik
        validationSchema={yup.object().shape(validators)}
        initialValues={{
          price: terms.price || '',
          accessory: '',
          maxMilage: '',
        }}
        render={({
 touched, errors, handleBlur, handleChange,
}) => (
  <React.Fragment>
    <InputWrapper gridArea="price">
      <TextInput
        label="Application.ListPrice"
        name="price"
        normalize="amount"
        height="32px"
        value={terms.price || ''}
        onBlur={e => handlePriceOnBlur(e, handleBlur)}
        onChange={handleChange}
        error={
                  (touched.price && errors.price) ||
                  (!touched.price && validationError && !terms.price)
                }
        rightSideText="kr."
        align="right"
        disabled={disabled}
        star="red"
        tabIndex="1"
      />
    </InputWrapper>

    <InputWrapper gridArea="accessoriesPrice">
      <TextInput
        label="Application.CarAccessoryItem"
        name="carAccessoryItem"
        normalize="amount"
        onBlur={handlecarAccessoryItemOnBlur}
        height="32px"
        rightSideText="kr."
        align="right"
        value={car.accessory || ''}
        disabled={disabled}
        tabIndex="1"
      />
    </InputWrapper>
    <InputWrapper gridArea="estimatedMilage">
      <TextInput
        label="Application.CarMaxMilage"
        name="carMaxMilage"
        normalize="amount"
        height="32px"
        onBlur={handleCarMaxMilageOnBlur}
        value={car.maxMilage || ''}
        rightSideText="km"
        align="right"
        disabled={disabled}
        tabIndex="1"
      />
    </InputWrapper>
  </React.Fragment>
        )}
      />
    </TermsContainer>
  );
};

Terms.propTypes = {
  app: PropTypes.object.isRequired,
  car: PropTypes.object,
  carInfo: PropTypes.object,
  carMainTypes: PropTypes.array.isRequired,
  carTypeList: PropTypes.array.isRequired,
  handleCalculation: PropTypes.func.isRequired,
  handleSetCarTypeId: PropTypes.func.isRequired,
  handleAccessory: PropTypes.func.isRequired,
  handleMaxMilage: PropTypes.func.isRequired,
  handleClearSelectedCarType: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

Terms.defaultProps = {
  car: {},
  carInfo: {},
};

const mapStateToProps = ({ app, carrental }) => ({
  app,
  car: app.car,
  carMainTypes: carrental.carMainTypes,
  carTypeList: carrental.carTypeList,
  disabled: !app.editable,
  carInfo: carrental.car,
});

const mapDispatchToProps = dispatch => ({
  getCarType: () => dispatch(getCarType()),
  handleCalculation: (data, value) => dispatch(getCalculate(data, value)),
  handleSetCarTypeId: id => dispatch(setCarTypeId(id)),
  handleAccessory: value => dispatch(setAccessory(value)),
  handleMaxMilage: value => dispatch(setMaxMilage(value)),
  handleClearSelectedCarType: () => dispatch(clearSelectedCarType()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
