import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Button from '../button/Button';

const Container = styled.div``;
const SubHeading = styled.h3`
  margin: 16px 0;
`;
const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 128px 128px;
  justify-content: space-between;
  gap: 8px;

  @media all and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

const ConfirmUpload = ({ setConfirmUpload, modal }) => (
  <Container>
    <SubHeading>
      <FormattedMessage id="Documents.Heading.AlreadySubmitted" />
    </SubHeading>
    <ButtonContainer>
      <Button
        primary={false}
        background="#bfbfbf"
        color="white"
        border="1px solid transparent"
        hoverColor="#b3b3b3"
        content="Nei"
        onClick={() => modal.onClose()}
      />
      <Button content="Já" onClick={() => setConfirmUpload({ status: 'confirmed' })} />
    </ButtonContainer>
  </Container>
);

ConfirmUpload.propTypes = {
  modal: PropTypes.shape({
    onClose: PropTypes.func,
  }).isRequired,
  setConfirmUpload: PropTypes.func.isRequired,
};

export default ConfirmUpload;
