export const NAME = 'carrental';

export const GET_CAR_TYPE_REQUEST = `${NAME}/GET_CAR_TYPE_REQUEST`;
export const GET_CAR_TYPE_SUCCESS = `${NAME}/GET_CAR_TYPE_SUCCESS`;
export const GET_CAR_TYPE_FAILURE = `${NAME}/GET_CAR_TYPE_FAILURE`;

export const GET_CAR_TYPE_INFO_REQUEST = `${NAME}/GET_CAR_TYPE_INFO_REQUEST`;
export const GET_CAR_TYPE_INFO_SUCCESS = `${NAME}/GET_CAR_TYPE_INFO_SUCCESS`;
export const GET_CAR_TYPE_INFO_FAILURE = `${NAME}/GET_CAR_TYPE_INFO_FAILURE`;

export const SET_MAX_MILEAGE = `${NAME}/SET_MAX_MILEAGE`;
export const SET_CAR_TYPE_ID = `${NAME}/SET_CAR_TYPE_ID`;

export const SET_CAR_MAIN_TYPE = `${NAME}/SET_CAR_MAIN_TYPE`;
export const CLEAR_SELECTED_CAR_TYPE = `${NAME}/CLEAR_SELECTED_CAR_TYPE`;
