import styled, { css } from 'styled-components';
import { darken, readableColor, saturate } from 'polished';

const StyledButton = styled.button`
  -webkit-appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;

  height: ${({ fullHeight }) => (fullHeight ? '100%' : '40px')};
  width: ${({ fullWidth }) => fullWidth && '100%'};
  /* box-sizing: content-box; */
  min-width: 121px;
  width: ${({ width }) => width || 'auto'};
  margin-top: ${({ noTopMargin, marginTop }) => noTopMargin || marginTop};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  background: #fdcd28;
  border: ${({ border, theme }) => border || `2px solid ${theme.buttonBorder || '#333'}`};
  margin-left: ${({ marginLeft }) => marginLeft || '0px'};
  text-decoration-line: ${({ underline }) => (underline ? 'underline' : 'none')};
  padding: 0 8px;
  color: ${({ color }) => color || '#333'};

  ${({
    background, color, border, fontSize, theme,
  }) =>
    background ||
    color ||
    border ||
    (fontSize &&
      css`
        background: ${background};
        color: ${color || '#333'};
        border: ${border || `2px solid ${theme.buttonBorder || '#333'}`};
        font-size: ${fontSize || '16px'};
      `)};

  ${({ primary, theme, color }) =>
    primary &&
    css`
      background: ${theme.buttonPrimary};
      /* color: #000; */
      border: 0;
    `};

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background: ${theme.disabledColor};
      color: #000;
      border: 0;
    `};

  background: ${({ background }) => background || background};

  font-size: ${({ fontSize, theme }) => fontSize || theme.buttonFontSize || '16px'};
  font-weight: bold;
  line-height: 23px;
  outline: 0;

  @media only screen and (max-width: 768px) {
    width: ${({ width }) => width || '100%'};
    margin-left: 0;
    margin-top: ${({ marginTop }) => marginTop || '0px'};
    margin-bottom: 8px;
  }

  &:hover {
    background-color: ${({
    theme, background, disabled, hoverColor,
  }) =>
    !disabled && (hoverColor || saturate(0.25, background || theme.buttonPrimary))};
    color: ${({ theme, background, disabled }) =>
    !disabled && readableColor(saturate(0.25, background || theme.buttonPrimary))};
  }

  &:focus {
    outline: 1px solid ${({ theme, background }) => darken(0.05, background || theme.buttonPrimary)};
    color: ${({ theme, background }) => readableColor(background || theme.buttonPrimary)};
    border: ${({ primary }) => (primary ? '1px solid white' : null)};
    background: ${({ theme, background, hoverColor }) =>
    hoverColor || saturate(0.15, background || theme.buttonPrimary)};
  }

  &:active {
    background-color: ${({ theme, background }) => darken(0.01, background || theme.buttonPrimary)};
    box-shadow: none;
    color: ${({ theme, background }) =>
    readableColor(darken(0.01, background || theme.buttonPrimary))};
  }

  transition: outline 0.1s linear, color 150ms linear;
`;

export default StyledButton;
