import { handleActions } from 'redux-actions';

import * as carrental from './constants';
import * as app from '../app/constants';

const initialState = {
  car: {},
  carTypeList: [],
  carMainTypes: [],
  carSubTypes: [],
  isFetchingCarInfo: false,
  hasFetchedCarInfo: false,
};

export default handleActions(
  {
    [app.RESET_STATE]: () => initialState,
    // [app.CALCULATION_SUCCESS]: (state, { data }) => ({
    //   ...state,
    //   mortgage: data.mortgage,
    //   car: {
    //     ...state.car,
    //     ...data.car,
    //   },
    // }),
    [carrental.GET_CAR_TYPE_REQUEST]: state => ({
      ...state,
      hasFetchedCarInfo: false,
      isFetchingCarInfo: true,
    }),
    [carrental.GET_CAR_TYPE_SUCCESS]: (state, action) => ({
      ...state,
      carTypeList: action.carList,
      carMainTypes: action.carMainTypes,
      carSubTypes: action.carSubTypes,
      hasFetchedCarInfo: true,
      isFetchingCarInfo: false,
    }),
    [carrental.GET_CAR_TYPE_FAILURE]: (state, action) => ({
      ...state,
      error: action.error,
      hasFetchedCarInfo: false,
      isFetchingCarInfo: false,
    }),
    [carrental.GET_CAR_TYPE_INFO_REQUEST]: state => ({
      ...state,
      isFetchingCarInfo: true,
      hasFetchedCarInfo: false,
    }),
    [carrental.GET_CAR_TYPE_INFO_SUCCESS]: (state, action) => ({
      ...state,
      isFetchingCarInfo: false,
      hasFetchedCarInfo: true,
      car: {
        ...state.car,
        ...action.data,
      },
    }),
    [carrental.GET_CAR_TYPE_INFO_FAILURE]: (state, { error }) => ({
      ...state,
      error,
    }),
    [carrental.CLEAR_SELECTED_CAR_TYPE]: state => ({
      ...state,
      car: {},
    }),
    // [carrental.SET_MAX_MILEAGE]: (state, { maxMilage }) => ({
    //   ...state,
    //   car: {
    //     ...state.car,
    //     maxMilage,
    //   },
    // }),
    // [carrental.SET_CAR_TYPE_ID]: (state, { typeId }) => ({
    //   ...state,
    //   car: {
    //     ...state.car,
    //     typeId,
    //   },
    // }),
  },
  initialState,
);
