import Mortgage from './Mortgage';
import { NAME } from './constants';
import reducer from './reducer';
import * as actions from './actions';

export default {
  Mortgage,
  NAME,
  reducer,
  actions,
};
