import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import { format, strip } from '@origo_vl_banking/utils';
import { DetailsButton } from '@origo_vl_banking/ibanking-react';
import * as yup from 'yup';

import { validate } from '../../../utils/validate';

import {
  resetPriceDepositItem,
  resetAccruedDepositItem,
  getCalculate,
  setCashDeposit,
  setDepositItem as setStoreDepositItem,
} from '../../app/actions';
import {
  Label,
  TwoColumns,
  TwoColumnsLg,
  RowWithBorders,
  TextRight,
  Row,
  Line,
} from '../../../components/grid/styled-components';
import { preventNumError, normalizeMortgageItem } from '../../../utils/helpers';
import TextInput from '../../../components/text-input/TextInput';

const validators = {
  price: validate.formik.amount,
  priceDepositItem: validate.formik.amount,
  cashDeposit: validate.formik.amount,
  accruedDepositItem: validate.formik.amount,
};
const DetailsButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  * {
    padding-left: 0px;
    padding-top: 0px;
  }
`;

// const MortgageHeading = styled.h4``;

// const SwitchWrapper = styled.div` // ! Keep this for posterity, as it'll be used again
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   padding-top: 4px;
//   padding-left: 16px;

//   .react-switch-bg {
//     border: 1px solid rgb(150, 150, 150);
//   }
// `;

const Terms = ({
  app,
  handleCalculation,
  handleDepositItem,
  handleCashDeposit,
  resetPriceDepositItemHandler,
  resetAccruedDepositItemHandler,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [error, setError] = useState();
  const [itemError, setItemError] = useState();
  const [depositItemError, setDepositItemError] = useState();
  const [totalDeposit, setTotalDeposit] = useState();
  const [payoutAmount, setPayoutAmount] = useState(0);
  const [priceDepositItem, setDepositItemAmount] = useState(0);
  const [mortgageAmount, setMortgageAmount] = useState(0);
  const [cashDepositPercent, setCashDepositPercent] = useState(0);

  const [accruedDepositItem, setAccruedDepositItem] = useState(0);

  const [depositItem, setDepositItem] = useState('');
  const [total, setTotal] = useState(0);

  const {
    calculation, terms, car, fees, validationError,
  } = app;
  const disabled = !app.editable;

  const updateFields = () => {
    setDepositItemAmount(terms.priceDepositItem);
    setAccruedDepositItem(terms.accruedDepositItem);
    setDepositItem(terms.depositItem);
    setMortgageAmount(terms.priceDepositItem - terms.accruedDepositItem);

    const depositPercent = Math.round((terms.cashDeposit / terms.price) * 100);
    setCashDepositPercent(depositPercent);

    const loanPayout =
      terms.price - terms.cashDeposit - (terms.priceDepositItem - terms.accruedDepositItem);
    setPayoutAmount(loanPayout);
    const totalAmount =
      loanPayout + fees.administrationFee + fees.registrationFee + fees.commitmentFee;
    setTotal(totalAmount);
    const totalDepositAmount =
      terms.cashDeposit + (terms.priceDepositItem - terms.accruedDepositItem);
    setTotalDeposit(totalDepositAmount);
    if (terms.price > terms.cashDeposit || (terms.price === 0 && terms.cashDeposit === 0)) {
      setError('');
    }
    setItemError('');
    setDepositItemError('');
  };

  useEffect(() => {
    updateFields();
  }, [
    terms.price,
    terms.cashDeposit,
    terms.priceDepositItem,
    terms.accruedDepositItem,
    terms.depositItem,
    terms.dueDateCount,
    updateFields,
    error,
  ]);

  const handleBlurPrice = (e, handleBlur) => {
    handleBlur(e);
    if (isEmpty(e.target.value)) return;

    // price
    const price = parseInt(strip.amount(e.target.value), 10);
    const parsedPrice = price;
    if (parsedPrice < 0 || parsedPrice === terms.price) return;

    // deposit
    const depositValue = parsedPrice * 0.2;
    const parsedDepositValue = Math.round(depositValue);

    // loan payout
    const loanPayout = parsedPrice - parsedDepositValue - priceDepositItem;
    setPayoutAmount(loanPayout);
    setError('');

    // total amount
    const totalAmount =
      loanPayout + fees.administrationFee + fees.registrationFee + fees.commitmentFee;
    setTotal(totalAmount);

    if (parsedPrice < accruedDepositItem && priceDepositItem < accruedDepositItem) {
      return;
    }

    const data = {
      type: 1,
      terms: {
        ...terms,
        price: parsedPrice,
        cashDeposit: parsedDepositValue,
        priceDepositItem,
        accruedDepositItem,
        depositItem,
      },
      fees,
      calculation,
      car,
    };

    handleCalculation(data, false);
  };

  const setDeposit = (deposit) => {
    const newDepositValue = Math.round(parseInt(strip.amount(deposit), 10));
    const loanPayout = terms.price - newDepositValue - priceDepositItem;

    setPayoutAmount(loanPayout);
    setError('');

    // eslint-disable-next-line max-len
    const totalAmount = loanPayout + fees.administrationFee + fees.registrationFee + fees.commitmentFee;
    setTotal(totalAmount);

    if (newDepositValue === terms.cashDeposit) return;

    const data = {
      type: 1,
      terms: {
        ...terms,
        cashDeposit: newDepositValue,
        priceDepositItem,
        accruedDepositItem,
        depositItem,
      },
      fees,
      calculation,
      car,
    };

    const createPdf = false;
    handleCalculation(data, createPdf);
  };

  const handleCashDepositPercentOnBlur = (e) => {
    if (e.target.value && terms.price) {
      const newCashDepositValue = (e.target.value / 100) * terms.price;
      if (newCashDepositValue <= terms.price) {
        setDeposit(newCashDepositValue);
      } else {
        const errorField = 'Application.Deposit.TooHigh';
        setError(errorField);
      }
    }
  };

  const handleBlurDeposit = (e) => {
    setDeposit(e.target.value);
  };

  const handleMortgageValue = (e, handleBlur) => {
    handleBlur(e);
    const value = parseInt(strip.amount(e.target.value), 10);
    if (value === priceDepositItem) return;
    if (!value) {
      resetPriceDepositItemHandler();
      setItemError('');
    }

    // calc difference for mortgage amount = priceDepositItem - accruedDepositItem
    const newMortgageAmount = value - accruedDepositItem;

    // calculate total deposit -> deposit + mortgageAmount
    const totalDepositAmount = terms.cashDeposit + newMortgageAmount;

    // set priceDepositItem
    setDepositItemAmount(value);

    setMortgageAmount(newMortgageAmount);

    setTotalDeposit(totalDepositAmount);
    const loanPayout = terms.price - terms.cashDeposit - newMortgageAmount;
    setPayoutAmount(loanPayout);
    const totalAmount =
      loanPayout + fees.administrationFee + fees.registrationFee + fees.commitmentFee;
    setTotal(totalAmount);

    const data = {
      type: 1,
      terms: {
        ...terms,
        priceDepositItem: value,
        accruedDepositItem,
        depositItem,
      },
      fees,
      calculation,
      car,
    };

    setDepositItemError('');
    setItemError('');
    const createPdf = false;
    handleCalculation(data, createPdf);
  };

  const handleMortgageAcrrued = (e, handleBlur) => {
    handleBlur(e);
    const value = parseInt(strip.amount(e.target.value), 10);

    if (!value) {
      resetAccruedDepositItemHandler();
      setDepositItemError('');
    }

    const newMortgageAmount = priceDepositItem - value;

    setAccruedDepositItem(value);

    const loanPayout = terms.price - terms.cashDeposit - newMortgageAmount;
    setPayoutAmount(loanPayout);
    const totalAmount =
      loanPayout + fees.administrationFee + fees.registrationFee + fees.commitmentFee;
    setTotal(totalAmount);

    const totalDepositAmount = terms.cashDeposit + newMortgageAmount;

    const data = {
      type: 1,
      terms: {
        ...terms,
        priceDepositItem,
        accruedDepositItem: value,
        depositItem,
      },
      fees,
      calculation,
      car,
    };

    setTotalDeposit(totalDepositAmount);
    setMortgageAmount(newMortgageAmount);

    setDepositItemError('');
    setItemError('');

    const createPdf = false;
    handleCalculation(data, createPdf);
  };

  const handleItemNumber = (e) => {
    setDepositItem(e.target.value);
    handleDepositItem(e.target.value);
  };

  const toggle = () => {
    setExpanded(!expanded);
  };

  const calcDepositPercentage = () => {
    const totalDepositPercent = (totalDeposit / terms.price) * 100;
    return `${totalDepositPercent.toFixed(2)}%`;
  };

  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={yup.object().shape(validators)}
        initialValues={{
          price: terms.price || '',
          cashDeposit: terms.cashDeposit || '',
          priceDepositItem: terms.priceDepositItem || '',
          accruedDepositItem: terms.accruedDepositItem || '',
          depositItem: terms.depositItem || '',
        }}
        render={({
 touched, errors, handleBlur, handleChange, values, setFieldValue,
}) => (
  <>
    <TwoColumnsLg>
      <TextInput
        label="Application.Price"
        normalize="amount"
        id="price"
        type="text"
        height="34px"
        name="price"
        onBlur={e => handleBlurPrice(e, handleBlur)}
        onChange={e => preventNumError(e, handleChange)}
        error={
                  (touched.price && errors.price) ||
                  (!touched.price && validationError && !terms.price)
                }
        value={terms.price || ''}
        rightSideText="kr."
        align="right"
        disabled={disabled}
        star="red"
        autoFocus
        tabIndex="1"
      />
    </TwoColumnsLg>
    <div style={{ paddingTop: 10 }}>
      <TwoColumnsLg>
        <TextInput
          label="Application.PaidDepositItem"
          name="cashDeposit"
          normalize="amount"
          type="text"
          height="34px"
          value={terms.cashDeposit || ''}
          onBlur={handleBlurDeposit}
          error={error}
          rightSideText="kr."
          align="right"
          disabled={disabled}
          tabIndex="2"
        />
        <div style={{ paddingTop: 24, width: 80 }}>
          <TextInput
            height="34px"
            name="cashDepositPercent"
            onBlur={handleCashDepositPercentOnBlur}
            value={cashDepositPercent || ''}
            rightSideText="%"
            align="right"
            disabled={disabled}
            tabIndex="1"
          />
        </div>
        <Row style={{ padding: '10px 0' }}>
          <DetailsButtonWrapper>
            <DetailsButton
              color="#5b6d7a"
              tabIndex="15"
              content={<FormattedMessage id="Mortgage.Label" />}
              showDetails={expanded}
              onClick={toggle}
            />
          </DetailsButtonWrapper>
        </Row>
      </TwoColumnsLg>
    </div>
    {expanded && (
    <TwoColumnsLg style={{ gridRowGap: itemError ? 36 : 12 }}>
      <TextInput
        label="Application.PriceDepositItem"
        name="priceDepositItem"
        normalize="amount"
        type="text"
        height="34px"
        onBlur={e => handleMortgageValue(e, handleBlur)}
        onChange={e => preventNumError(e, handleChange)}
        error={itemError}
        value={priceDepositItem || ''}
        rightSideText="kr."
        align="right"
        disabled={disabled}
        tabIndex="16"
      />
      <TextInput
        label="Application.AccruedDepositItem"
        name="accruedDepositItem"
        height="34px"
        normalize="amount"
        type="text"
        onBlur={e => handleMortgageAcrrued(e, handleBlur)}
        onChange={e => preventNumError(e, handleChange)}
        value={accruedDepositItem || ''}
        rightSideText="kr."
        align="right"
        disabled={disabled}
        error={depositItemError}
        tabIndex="16"
      />
      <TextInput
        label="AccruedDeposit.ItemNumber"
        height="34px"
        name="depositItem"
        onChange={e =>
                    normalizeMortgageItem(e.target.value, setFieldValue, 'depositItem')
                  }
        onBlur={handleItemNumber}
                  // value={!depositItem ? '' : depositItem}
        value={values.depositItem.toUpperCase() || ''}
        disabled={disabled}
        tabIndex="16"
        maxLength={6}
        align="right"
      />
    </TwoColumnsLg>
            )}
  </>
        )}
      />
      <TwoColumns>
        <Line />
        <Label>
          <FormattedMessage id="Application.PaidWithMortgage" />
        </Label>
        <TextRight>{format.amount(mortgageAmount)} kr.</TextRight>
        <Label>
          <FormattedMessage id="Application.TotalDeposit" />
          &nbsp;
          <span>{totalDeposit ? calcDepositPercentage() : ''}</span>
        </Label>
        <TextRight>{format.amount(totalDeposit)} kr.</TextRight>
        <Label>
          <FormattedMessage id="Application.AdministrationFee" />
        </Label>
        <TextRight>{format.amount(fees.administrationFee) || 0} kr.</TextRight>
        <Label>
          <FormattedMessage id="Application.BorrowingFee" />
          <span style={{ marginLeft: 4 }}>
            &nbsp;
            {fees.commitmentFeePercent
              ? `${parseFloat(fees.commitmentFeePercent * 100).toFixed(2)}%`
              : null}{' '}
          </span>
        </Label>
        <TextRight>{format.amount(fees.commitmentFee) || 0} kr.</TextRight>
        <Label>
          <FormattedMessage id="Application.RegistrationFee" />
        </Label>
        <TextRight>{format.amount(fees.registrationFee) || 0} kr.</TextRight>
        <RowWithBorders>
          <TwoColumns style={{ fontWeight: 'bold', height: '34px' }}>
            <FormattedMessage id="Application.TotalLoanAmount" />
            <TextRight>{format.amount(total) || 0} kr.</TextRight>
          </TwoColumns>
        </RowWithBorders>
        <Label>
          <FormattedMessage id="Application.PayoutAmount" />
        </Label>
        <TextRight>{format.amount(payoutAmount) || 0} kr.</TextRight>
      </TwoColumns>
    </>
  );
};

Terms.propTypes = {
  app: PropTypes.object.isRequired,
  handleCalculation: PropTypes.func.isRequired,
  handleDepositItem: PropTypes.func.isRequired,
  handleCashDeposit: PropTypes.func.isRequired,
  resetPriceDepositItemHandler: PropTypes.func.isRequired,
  resetAccruedDepositItemHandler: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app }) => ({ app });

const mapDispatchToProps = dispatch => ({
  handleCalculation: (data, value) => dispatch(getCalculate(data, value)),
  handleDepositItem: item => dispatch(setStoreDepositItem(item)),
  handleCashDeposit: value => dispatch(setCashDeposit(value)),
  resetPriceDepositItemHandler: () => dispatch(resetPriceDepositItem()),
  resetAccruedDepositItemHandler: () => dispatch(resetAccruedDepositItem()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
