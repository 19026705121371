import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { isEmpty, find } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import { TextInput } from '@origo_vl_banking/ibanking-react';
import * as yup from 'yup';

import { TextRight, Label, TwoColumnsLg, Line } from '../../../components/grid/styled-components';
import { getMortgageInfo, setMortgageItem, setSsn } from '../actions';
import StandardLoader from '../../../components/loader/StandardLoader';
import { normalizeMortgageItem } from '../../../utils/helpers';
import { xlMin } from '../../../utils/constants';
import { validate } from '../../../utils/validate';

const ExpandingContainer = styled.div`
  max-height: ${({ show }) => (show ? '109px' : '0px')};
  height: 100%;
  overflow: hidden;
  transition: max-height 0.25s ease-in;
  margin-top: 8px;

  @media all and (max-width: ${xlMin}) {
    max-height: ${({ show }) => (show ? '250px' : '0px')};
  }
`;

const InfoRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'label info';
`;

const InfoItem = styled.div`
  grid-area: ${({ gridArea }) => gridArea};
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const validators = {
  mortgageItem: validate.formik.mortgageItem,
  ssn: validate.formik.ssn,
};

const CurrentInfo = ({
  applicants,
  companies,
  contractNumber,
  disabled,
  handleMortgageInfo,
  handleMortgageItem,
  handleSsnNumber,
  isFetchingMortgageInfo,
  mortgageItem,
  oldMortgage,
  ssn,
  typeText,
}) => {
  const company = companies && find(companies, { key: oldMortgage.insuranceCompanyId });

  const handleMortgageItemOnBlur = (e) => {
    const { value } = e.target;
    if (isEmpty(value)) return;
    handleMortgageItem(value);
    if (isEmpty(ssn)) return;

    const data = {
      ssn,
      mortgageItem: value,
    };

    if (mortgageItem === value) return;

    handleMortgageInfo(data);
  };

  const handleSsnOnBlur = (e) => {
    const { value } = e.target;
    if (isEmpty(value)) return;
    const parsedValue = value.replace('-', '');

    handleSsnNumber(parsedValue);
    if (!mortgageItem) return;

    const data = {
      ssn: parsedValue,
      mortgageItem,
    };

    handleMortgageInfo(data);
  };

  let applicantsOutput = null;

  if (applicants) {
    applicantsOutput = applicants.map(applicant => applicant.name);
  }

  return (
    <React.Fragment>
      <Formik
        validationSchema={yup.object().shape(validators)}
        enableReinitialize
        initialValues={{
          mortgageItem: (oldMortgage && oldMortgage.mortgageItem) || '',
          ssn: (applicants && applicants[0] && applicants[0].ssn) || '',
        }}
        render={({
 touched, values, errors, setFieldValue,
}) => (
  <React.Fragment>
    <TwoColumnsLg>
      <TextInput
        label="Mortgage.Item"
        name="mortgageItem"
        height="34px"
        onBlur={handleMortgageItemOnBlur}
        value={
                  values.mortgageItem.toUpperCase() ||
                  (mortgageItem && mortgageItem.toUpperCase()) ||
                  ''
                }
        maxLength={6}
        onChange={e => normalizeMortgageItem(e.target.value, setFieldValue, 'mortgageItem')}
        align="right"
        autoFocus
        error={touched.mortgageItem && errors.mortgageItem}
        disabled={disabled}
      />
      <TextInput
        label="Application.SSN"
        name="ssn"
        normalize="ssn"
        maxLength={11}
        height="34px"
        align="right"
        onBlur={handleSsnOnBlur}
        value={values.ssn}
        error={touched.ssn && errors.ssn}
        disabled={disabled}
      />
    </TwoColumnsLg>
  </React.Fragment>
        )}
      />

      <Line />
      {isFetchingMortgageInfo && <StandardLoader />}
      {
        <ExpandingContainer show={!isEmpty(oldMortgage)}>
          <InfoContainer>
            <InfoRow>
              <InfoItem style={{ gridColumn: '1 / 3' }}>
                <h4>
                  {typeText} - {contractNumber}
                </h4>
              </InfoItem>
            </InfoRow>
            <InfoRow>
              <InfoItem gridArea="label">
                <Label>
                  <FormattedMessage id="Mortgage.Vehicle" />
                </Label>
              </InfoItem>
              <InfoItem gridArea="info">
                <TextRight>{oldMortgage.mortgageType}</TextRight>
              </InfoItem>
            </InfoRow>

            <InfoRow>
              <InfoItem gridArea="label">
                <Label>
                  <FormattedMessage id="Loan.Borrower" />
                </Label>
              </InfoItem>
              <InfoItem gridArea="info">
                <TextRight>{applicantsOutput}</TextRight>
              </InfoItem>
            </InfoRow>

            {oldMortgage.insuranceCompanyId && (
              <InfoRow>
                <InfoItem gridArea="label">
                  <Label>
                    <FormattedMessage id="InsuranceCompany" />
                  </Label>
                </InfoItem>
                <InfoItem gridArea="info">
                  <TextRight>{companies && company && company.value}</TextRight>
                </InfoItem>
              </InfoRow>
            )}
          </InfoContainer>
        </ExpandingContainer>
      }
    </React.Fragment>
  );
};

CurrentInfo.propTypes = {
  oldMortgage: PropTypes.object,
  applicants: PropTypes.array,
  handleMortgageInfo: PropTypes.func.isRequired,
  handleMortgageItem: PropTypes.func.isRequired,
  handleSsnNumber: PropTypes.func.isRequired,
  ssn: PropTypes.string,
  typeText: PropTypes.string,
  contractNumber: PropTypes.string,
  mortgageItem: PropTypes.string,
  isFetchingMortgageInfo: PropTypes.bool.isRequired,
  companies: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
};

CurrentInfo.defaultProps = {
  ssn: '',
  mortgageItem: '',
  typeText: '',
  contractNumber: '',
  oldMortgage: {},
  applicants: [],
};

function mapStateToProps(state) {
  return {
    applicants: state.mortgage.applicants,
    ssn: state.mortgage.ssn,
    mortgageItem: state.mortgage.mortgageItem,
    contractNumber: state.mortgage.contractNumber,
    oldMortgage: state.mortgage.oldMortgage,
    typeText: state.mortgage.typeText,
    companies: state.mortgage.carInsuranceCompanies,
    isFetchingMortgageInfo: state.mortgage.isFetchingMortgageInfo,
    disabled: !state.mortgage.editable,
  };
}

const mapDispatchToProps = dispatch => ({
  handleMortgageInfo: data => dispatch(getMortgageInfo(data)),
  handleMortgageItem: value => dispatch(setMortgageItem(value)),
  handleSsnNumber: value => dispatch(setSsn(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentInfo);
