import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { Label } from '../grid/styled-components';
import { normalizeMortgageItem } from '../../utils/helpers';

import TextInput from '../text-input/TextInput';
import { lgMin } from '../../utils/constants';
import InsuranceCompany from './InsuranceCompany';
import Green from '../icons/Green';
import Seller from './Seller';

const MortgageContainer = styled.div`
  display: grid;
  grid-template-columns: 112px auto;
  grid-column-gap: 30px;
  grid-row-gap: 16px;
  grid-template-areas:
    'licensePlate mortgageItem'
    'seller sellerName'
    ${({ greenLoan }) => greenLoan && '"mortgageGreen mortgageGreen"'}
    'mortgageMilage mortageRegistration'
    ${({ hideInsurance }) => !hideInsurance && '"insurance insurance"'};

  @media all and (max-width: ${lgMin}) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'licensePlate',
      'mortgageItem'      
      'seller',
      ${({ greenLoan }) => greenLoan && 'mortgageGreen'},
      'mortgageMilage',
      'mortageRegistration',
      ${({ hideInsurance }) => !hideInsurance && 'insurance'};
  }
`;

const InputWrapper = styled.div`
  grid-area: ${({ gridArea }) => gridArea};
`;

const VehicleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const VehicleType = styled.div`
  margin-bottom: 10px;
`;

const Mortgage = ({
  mortgage,
  disabled,
  editable,
  setCarInsuranceCompany,
  setInsuranceCompanyName,
  showInsuranceCompany,
  getCarData,
  handleMileageOnBlur,
  companyName,
  app,
  isLeasing,
  hideInsurance,
  handleSeller,
}) => {
  const handleSelect = (id) => {
    setCarInsuranceCompany(id);
    const selected = app.companies.find((item) => item.key === id);
    setInsuranceCompanyName(selected.value);
  };

  const greenLoan = mortgage.mortgageGreen === '1' || mortgage.mortgageGreen === '2';

  const greenLoanText = () => {
    if (app.type === 2) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 8 }}>
          <Green />
          <Label style={{ paddingTop: 4, paddingLeft: 6 }}>
            <FormattedMessage id="Mortgage.GreenVehicle.Leasing" />
          </Label>
        </div>
      );
    }
    return (
      <>
        <Label>
          <FormattedMessage id="Mortgage.GreenVehicle" />
        </Label>
        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 8 }}>
          <Green />
          <Label style={{ paddingTop: 4, paddingLeft: 6 }}>
            <FormattedMessage id="Mortgage.GreenVehicle.BorrowDiscount" />
          </Label>
        </div>
        {mortgage.mortgageGreen === '1'
        && (
        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 6, paddingBottom: 6 }}>
          <Green />
          <Label style={{ paddingTop: 4, paddingLeft: 6 }}>
            <FormattedMessage id="Mortgage.GreenVehicle.IntDiscount" />
          </Label>
        </div>
        )}
      </>
    );
  };

  return (
    <>
      <Formik
        enableReinitialize
        validate={(val) => {
          const errors = {};

          if (!val.mortgageItem) {
            errors.mortgageItem = 'Form.Error.required-field';
          }
          if (!val.insuranceCompany) {
            errors.insuranceCompany = 'Form.Error.required-field';
          }
          return errors;
        }}
        initialValues={{
          mortgageItem: mortgage.mortgageItem || '',
          mortgageMilage: mortgage.mortgageMilage || '',
        }}
        render={({
          handleBlur, setFieldValue, values, errors, touched,
        }) => (
          <MortgageContainer
            hideInsurance={hideInsurance}
            greenLoan={greenLoan}
          >
            <InputWrapper gridArea="licensePlate">
              <TextInput
                label="Mortgage.Item"
                name="mortgageItem"
                height="34px"
                maxLength={isLeasing ? 8 : 6}
                value={values.mortgageItem.toUpperCase() || ''}
                onBlur={(e) => getCarData(e, handleBlur)}
                onChange={(e) => normalizeMortgageItem(e.target.value, setFieldValue, 'mortgageItem')}
                align="right"
                disabled={disabled}
                tabIndex="14"
                // error={touched.mortgageItem && errors.mortgageItem}
                error={
                  (touched.mortgageItem && errors.mortgageItem)
                  || (!touched.mortgageItem && app.validationError && !mortgage.mortgageItem)
                }
              />
            </InputWrapper>
            <InputWrapper gridArea="mortgageItem">
              <VehicleWrapper>
                <Label>
                  <FormattedMessage id="Mortgage.Vehicle" />
                </Label>
                <VehicleType>
                  {mortgage.mortgageType}
                  {' '}
                  {mortgage.mortgageSubType}
                </VehicleType>
              </VehicleWrapper>
            </InputWrapper>
            {greenLoan && (
              <div gridArea="mortgageGreen" style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                {greenLoanText()}
              </div>
            )}
            <InputWrapper gridArea="mortgageMilage">
              <TextInput
                label="Mortgage.Milage"
                name="mortgageMilage"
                height="34px"
                normalize="amount"
                maxLength={7}
                align="right"
                // rightSideText="km"
                value={mortgage.mortgageMilage || ''}
                onBlur={(e) => handleMileageOnBlur(e, handleBlur)}
                disabled={disabled}
                tabIndex="14"
              />
            </InputWrapper>
            <InputWrapper gridArea="mortageRegistration">
              <div>
                <div>
                  <p style={{ marginTop: 0 }}>
                    <Label>
                      <FormattedMessage id="Mortgage.Model" />
                    </Label>
                  </p>
                  <span>
                    {mortgage.mortgageType && moment(mortgage.mortgageModelYear).format('DD.MM.YYYY')}
                  </span>
                </div>
              </div>
            </InputWrapper>
            {!hideInsurance && (
            <InputWrapper gridArea="insurance">
              <InsuranceCompany
                editable={editable}
                showInsuranceCompany={showInsuranceCompany}
                app={app}
                handleSelect={handleSelect}
                companyName={companyName}
                disabled={disabled}
                error={
                    (touched.insuranceCompany && errors.insuranceCompany)
                    || (!touched.insuranceCompany
                      && app.validationError
                      && !mortgage.insuranceCompanyId)
                  }
              />
            </InputWrapper>
            )}
            {(app.type === 1 || app.type === 2 || app.type === 8) && (
              // <InputWrapper gridArea="seller">
              <Seller
                app={app}
                seller={get(app, 'seller')}
                handleSeller={handleSeller}
                validationError={app.validationError}
                disabled={disabled}
                label="Seller.SSN"
              />
              // </InputWrapper>
            )}
          </MortgageContainer>
        )}
      />
    </>
  );
};

Mortgage.propTypes = {
  app: PropTypes.object.isRequired,
  getCarData: PropTypes.func.isRequired,
  handleMileageOnBlur: PropTypes.func.isRequired,
  setInsuranceCompanyName: PropTypes.func.isRequired,
  mortgage: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  validationError: PropTypes.string,
  setCarInsuranceCompany: PropTypes.func,
  editable: PropTypes.bool,
  companyName: PropTypes.string,
  showInsuranceCompany: PropTypes.bool.isRequired,
  isLeasing: PropTypes.bool,
  hideInsurance: PropTypes.bool,
  seller: PropTypes.object,
  handleSeller: PropTypes.func.isRequired,
};

Mortgage.defaultProps = {
  isLeasing: false,
  disabled: false,
  hideInsurance: false,
  validationError: '',
  editable: true,
  companyName: '',
  setCarInsuranceCompany: () => {},
  seller: {},
};

export default Mortgage;
