import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { TextInput } from '@origo_vl_banking/ibanking-react';

import { EqualColumns, TextName } from '../grid/styled-components';
import { validate } from '../../utils/validate';

const validators = yup.object().shape({
  telephone: validate.formik.telephone,
  email: yup.string().email('Form.Error.invalid-email'),
});

const Guarantor = ({
  app,
  guarantor,
  handleGuarantorName,
  handleGuarantorChange,
  disabled,
  validationError,
}) => (
  <Formik
    enableReinitialize
    validationSchema={validators}
    initialValues={{
      ssn: guarantor.ssn || '',
      telephone: guarantor.telephone || '',
      email: guarantor.email || '',
    }}
    validate={(values) => {
      const error = {};

      if (values.ssn && !validate.ssn(values.ssn)) {
        error.ssn = 'Form.Error.invalid-ssn';
      }

      if (values.ssn && values.telephone.length < 7) {
        error.telephone = 'Form.Error.invalid-phone';
      }

      if (values.ssn && !values.telephone) {
        error.telephone = 'Form.Error.required-field';
      }

      return error;
    }}
    render={({
 touched, errors, handleBlur, handleChange,
}) => {
      const handleGuarantorSsn = (e) => {
        handleBlur(e);
        handleGuarantorName(e);
      };

      const handleGuarantorField = (e) => {
        handleChange(e);
        handleGuarantorChange(e.target.name, e);
      };

      return (
        <EqualColumns style={{ paddingBottom: '20px' }}>
          <TextInput
            label="Application.SSN"
            normalize="ssn"
            maxLength={11}
            name="ssn"
            height="34px"
            onBlur={handleGuarantorSsn}
            onChange={handleChange}
            value={guarantor.ssn || ''}
            error={touched.ssn && errors.ssn}
            disabled={disabled}
            justifyErrorMessage="flex-end"
            width="100%"
            tabIndex="7"
          />
          <TextName>{guarantor.name}</TextName>
          <TextInput
            type="tel"
            maxLength={7}
            normalize="number"
            label="Application.Telephone"
            name="telephone"
            height="34px"
            onBlur={handleBlur}
            onChange={handleGuarantorField}
            value={guarantor.telephone || ''}
            error={
              (touched.telephone && errors.telephone) ||
              (!touched.telephone && guarantor.ssn && !guarantor.telephone && validationError)
            }
            disabled={disabled}
            justifyErrorMessage="flex-end"
            star="red"
            pattern="\d*"
            tabIndex="7"
          />
          <TextInput
            label="Application.Email"
            name="email"
            height="34px"
            onBlur={handleBlur}
            onChange={handleGuarantorField}
            value={guarantor.email || ''}
            error={touched.email && errors.email}
            disabled={disabled}
            tabIndex="7"
            justifyErrorMessage="flex-end"
          />
        </EqualColumns>
      );
    }}
  />
);

Guarantor.propTypes = {
  guarantor: PropTypes.object,
  handleGuarantorName: PropTypes.func,
  handleGuarantorChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  validationError: PropTypes.string,
};

Guarantor.defaultProps = {
  guarantor: {},
  handleGuarantorName: () => {},
  disabled: false,
  validationError: '',
};

export default Guarantor;
