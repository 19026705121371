export const NAME = 'mortgage';

export const MORTGAGE_INFO_REQUEST = `${NAME}/MORTGAGE_INFO_REQUEST`;
export const MORTGAGE_INFO_SUCCESS = `${NAME}/MORTGAGE_INFO_SUCCESS`;
export const MORTGAGE_INFO_FAILURE = `${NAME}/MORTGAGE_INFO_FAILURE`;

export const SET_MORTGAGE_ITEM = `${NAME}/SET_MORTGAGE_ITEM`;
export const SET_SSN = `${NAME}/SET_SSN`;
export const SET_MORTGAGE_AMOUNT = `${NAME}/SET_MORTGAGE_AMOUNT`;
export const SET_MILAGE = `${NAME}/SET_MILAGE`;

export const CAR_INFO_REQUEST = `${NAME}/CAR_INFO_REQUEST`;
export const CAR_INFO_SUCCESS = `${NAME}/CAR_INFO_SUCCESS`;
export const CAR_INFO_FAILURE = `${NAME}/CAR_INFO_FAILURE`;

export const SAVE_MORTGAGE_REQUEST = `${NAME}/SAVE_MORTGAGE_REQUEST`;
export const SAVE_MORTGAGE_SUCCESS = `${NAME}/SAVE_MORTGAGE_SUCCESS`;
export const SAVE_MORTGAGE_FAILURE = `${NAME}/SAVE_MORTGAGE_FAILURE`;

export const SEND_MORTGAGE_REQUEST = `${NAME}/SEND_MORTGAGE_REQUEST`;
export const SEND_MORTGAGE_SUCCESS = `${NAME}/SEND_MORTGAGE_SUCCESS`;
export const SEND_MORTGAGE_FAILURE = `${NAME}/SEND_MORTGAGE_FAILURE`;

export const SET_CAR_INSURANCE_COMPANY = `${NAME}/SET_CAR_INSURANCE_COMPANY`;
export const SET_CAR_INSURANCE_COMPANIES = `${NAME}/SET_CAR_INSURANCE_COMPANIES`;

export const RESET_MORTGAGE_STATE = `${NAME}/RESET_MORTGAGE_STATE`;

export const GET_MORTGAGE_DETAILS_REQUEST = `${NAME}/GET_MORTGAGE_DETAILS_REQUEST`;
export const GET_MORTGAGE_DETAILS_SUCCESS = `${NAME}/GET_MORTGAGE_DETAILS_SUCCESS`;
export const GET_MORTGAGE_DETAILS_FAILURE = `${NAME}/GET_MORTGAGE_DETAILS_FAILURE`;

export const SET_COMMUNICATION = `${NAME}/SET_COMMUNICATION`;
export const SET_VALIDATION_ERROR = `${NAME}/SET_VALIDATION_ERROR`;
