import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import GridView from '../../components/grid/GridView';
import { SmartMessage, SmartHeader, SmartApplicants } from '../../components/shared';
import { Terms, Mortgage, Calculator } from './components';
import {
  getApplication,
  getPreConditions,
  setValidationError,
  postApplication,
  resetGuarantorFields,
  resetSecondGuarantorFields,
} from '../app/actions';
import SmartLoader from '../../components/loader/SmartLoader';
import { appType, appStatus } from '../../utils/constants';
import { hasRequired, prepareData } from '../../utils/helpers';

class CarLoan extends Component {
  componentDidMount() {
    const { state } = this.props.location;
    if (state) {
      this.loadApplicationHandler(state.year, state.serialNumber);
    } else {
      this.handlePreConditions();
    }
  }

  loadApplicationHandler = (year, serialNumber) => {
    this.props.loadApplication(year, serialNumber);
  };

  handleApplication = (status) => {
    const { app, guarantor, secondGuarantor } = this.props;
    const data = prepareData(app, status, appType.leasing);

    if (!guarantor.ssn || !guarantor) {
      this.props.resetGuarantorFields();
    }

    if (!secondGuarantor.ssn || !secondGuarantor) {
      this.props.resetSecondGuarantorFields();
    }

    if (!hasRequired(data) && app.applicant.telephone.length < 7) {
      this.props.setValidationError('Form.Error.required-field');
      return;
    }

    if (app.guarantor.telephone && app.guarantor.telephone.length < 7) {
      return;
    }
    if (app.secondGuarantor.telephone && app.secondGuarantor.telephone.length < 7) {
      return;
    }

    if (app.ssnInfo && !app.ssnInfo.business) {
      return;
    }

    this.props.handlePostApplication(data, this.props.app.serialNumber);
  };

  handlePreConditions = () => {
    const type = 2;
    this.props.getPreconditions(type);
  };

  render() {
    const { statusText, message1, message2 } = this.props.app;
    return (
      <React.Fragment>
        <SmartLoader />
        <SmartHeader
          title="sitemap.Leasing"
          subtitle={statusText || 'sitemap.Application'}
          message1={message1}
          message2={message2}
        />

        <GridView
          grid1={<Terms title="Application.Terms" />}
          grid2={<Mortgage title="LeasingItem.HeaderItem" showInsuranceCompany />}
          grid3={
            <SmartApplicants
              restrictApplicant="person"
              title="Application.PersonsHeader"
              showSecondApplicant={false}
            />
          }
          calculator={<Calculator />}
          message={<SmartMessage />}
          displayButtons={this.props.app.editable}
          handleSaveApplication={() => this.handleApplication(appStatus.save)}
          handleSendApplication={() => this.handleApplication(appStatus.send)}
          ssnInfo={this.props.app.ssnInfo}
          type={this.props.app.type}
          editable={this.props.app.editable}
        />
      </React.Fragment>
    );
  }
}

CarLoan.propTypes = {
  app: PropTypes.object.isRequired,
  guarantor: PropTypes.object,
  secondGuarantor: PropTypes.object,
  location: PropTypes.object.isRequired,
  getPreconditions: PropTypes.func.isRequired,
  handlePostApplication: PropTypes.func.isRequired,
  resetGuarantorFields: PropTypes.func.isRequired,
  resetSecondGuarantorFields: PropTypes.func.isRequired,
  setValidationError: PropTypes.func.isRequired,
  loadApplication: PropTypes.func.isRequired,
};

CarLoan.defaultProps = {
  guarantor: {},
  secondGuarantor: {},
};

const mapStateToProps = ({ app }) => ({
  app,
  guarantor: app.guarantor,
  secondGuarantor: app.secondGuarantor,
});

const mapDispatchToProps = {
  resetGuarantorFields,
  resetSecondGuarantorFields,
  setValidationError,
  handlePostApplication: postApplication,
  getPreconditions: getPreConditions,
  loadApplication: getApplication,
};

export default connect(mapStateToProps, mapDispatchToProps)(CarLoan);
