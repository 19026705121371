import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { SelectDropdown } from '@origo_vl_banking/ibanking-react';
import { Formik } from 'formik';
import { strip } from '@origo_vl_banking/utils';
import * as yup from 'yup';

import { normalizeMortgageItem } from '../../../utils/helpers';
import TextInput from '../../../components/text-input/TextInput';

import { validate } from '../../../utils/validate';

// import { getCarInfo } from '../../app/actions';
import {
  setMortgageAmount,
  setMilage,
  getCarInsuranceCompanies,
  setCarInsuranceCompany,
  getCarInfo,
} from '../actions';

import { Label } from '../../../components/grid/styled-components';
import { lgMin } from '../../../utils/constants';

const Form = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-areas:
    'licensePlate mortgageItem'
    'mortgageAmount mortgageMilage'
    'insurance insurance';
  grid-column-gap: 24px;
  grid-row-gap: 8px;

  @media all and (max-width: ${lgMin}) {
    grid-template-areas:
      'licensePlate'
      'mortgageItem'
      'mortgageAmount'
      'mortgageMilage'
      'insurance';
  }
`;

const InputWrapper = styled.div`
  grid-area: ${({ gridArea }) => gridArea};
`;

const VehicleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const VehicleType = styled.div`
  margin-bottom: 10px;
`;

const validators = {
  mortgageItem: validate.formik.mortgageItem,
  mortgageAmount: validate.formik.mortgageAmount,
  mortgageMilage: validate.formik.mortgageMilage,
  insuranceCompany: validate.formik.required,
};

const NewMortgage = ({
  handleCarInfoOnBlur,
  handleMortgageAmount,
  handleMortgageMilage,
  handleGetCarInsuranceCompanies,
  carInsuranceCompanies,
  handleSetCarInsuranceCompany,
  newMortgage,
  mortgageItem,
  disabled,
  validationError,
}) => {
  useEffect(() => {
    handleGetCarInsuranceCompanies();
  }, []);

  const getDisplayValue = () => {
    const id = newMortgage.insuranceCompanyId;
    const selected = carInsuranceCompanies.find(x => x.key === id);
    return selected ? selected.value : '';
  };

  const handleSelect = (value) => {
    const selected = carInsuranceCompanies.find(x => x.key === value);
    handleSetCarInsuranceCompany(selected.key);
  };

  const handleMortgageItemOnBlur = (e, handleBlur) => {
    if (e.target.value) {
      handleCarInfoOnBlur(e.target.value);
    }
    handleBlur(e);
  };

  const handleMortgageAmountOnBlur = (e, setFieldValue) => {
    const value = parseInt(strip.amount(e.target.value), 10);
    handleMortgageAmount(value);
    return setFieldValue('mortgageAmount', value);
  };

  const handleMilageOnBlur = (e, setFieldValue) => {
    const value = parseInt(strip.amount(e.target.value), 10);
    handleMortgageMilage(value);
    return setFieldValue('mortgageMilage', value);
  };

  return (
    <Formik
      validationSchema={yup.object().shape(validators)}
      validate={(values) => {
        const errors = {};

        if (!values.mortgageItem) {
          errors.mortgageItem = 'Form.Error.required-field';
        }
        if (!values.insuranceCompany) {
          errors.insuranceCompany = 'Form.Error.required-field';
        }
        return errors;
      }}
      enableReinitialize
      initialValues={{
        ...newMortgage,
        mortgageItem: mortgageItem || '',
        mortgageAmount: newMortgage.mortgageItem ? newMortgage.mortgageAmount : undefined,
        mortgageMilage: newMortgage.mortgageItem ? newMortgage.mortgageMilage : undefined,
      }}
      render={({
 values, setFieldValue, errors, touched, handleBlur,
}) => (
  <Form>
    <InputWrapper gridArea="licensePlate">
      <TextInput
        disabled={disabled}
        label="Mortgage.Item"
        height="34px"
        name="mortgageItem"
        id="mortgageItem"
        onBlur={e => handleMortgageItemOnBlur(e, handleBlur)}
        value={(values.mortgageItem && values.mortgageItem.toUpperCase()) || ''}
        maxLength={6}
        onChange={e => normalizeMortgageItem(e.target.value, setFieldValue, 'mortgageItem')}
        align="right"
        error={
                (touched.mortgageItem && errors.mortgageItem) ||
                (validationError && !newMortgage.mortgageItem)
              }
      />
    </InputWrapper>
    <InputWrapper gridArea="mortgageItem">
      <VehicleWrapper>
        <Label>
          <FormattedMessage id="Mortgage.Vehicle" />
        </Label>
        <VehicleType>
          {newMortgage.mortgageType} {newMortgage.mortgageSubType}
        </VehicleType>
      </VehicleWrapper>
    </InputWrapper>
    <InputWrapper gridArea="mortgageAmount">
      <TextInput
        disabled={disabled}
        label="Mortgage.Amount"
        height="34px"
        name="mortgageAmount"
        onBlur={val => handleMortgageAmountOnBlur(val, setFieldValue)}
        normalize="amount"
        value={values.mortgageAmount}
        rightSideText="kr."
        align="right"
      />
    </InputWrapper>
    <InputWrapper gridArea="mortgageMilage">
      <TextInput
        disabled={disabled}
        label="Mortgage.Milage"
        height="34px"
        name="mortgageMilage"
        maxLength={7}
        value={values.mortgageMilage}
        onBlur={val => handleMilageOnBlur(val, setFieldValue)}
        normalize="amount"
        rightSideText="km"
        align="right"
      />
    </InputWrapper>
    <InputWrapper gridArea="insurance">
      <SelectDropdown
        disabled={disabled}
        label="Mortgage.InsuranceCompany"
        headerHeight="34px"
        name="insuranceCompanyId"
        data={carInsuranceCompanies}
        onClicked={handleSelect}
        displayValue={getDisplayValue()}
        error={
                (touched.insuranceCompanyId && errors.insuranceCompanyId) ||
                (validationError && !newMortgage.insuranceCompanyId)
              }
      />
    </InputWrapper>
  </Form>
      )}
    />
  );
};

NewMortgage.propTypes = {
  handleCarInfoOnBlur: PropTypes.func.isRequired,
  handleMortgageAmount: PropTypes.func.isRequired,
  handleMortgageMilage: PropTypes.func.isRequired,
  handleGetCarInsuranceCompanies: PropTypes.func.isRequired,
  handleSetCarInsuranceCompany: PropTypes.func.isRequired,
  newMortgage: PropTypes.object,
  carInsuranceCompanies: PropTypes.array,
  mortgageItem: PropTypes.string,
  validationError: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

NewMortgage.defaultProps = {
  carInsuranceCompanies: [],
  newMortgage: {},
  mortgageItem: '',
};

function mapStateToProps({ mortgage }) {
  return {
    mortgage: mortgage.mortgage,
    newMortgage: mortgage.newMortgage,
    mortgageItem: mortgage.newMortgage.mortgageItem,
    car: mortgage.car,
    carInsuranceCompanies: mortgage.carInsuranceCompanies,
    disabled: !mortgage.editable,
    validationError: mortgage.validationError,
  };
}

const mapDispatchToProps = dispatch => ({
  handleCarInfoOnBlur: id => dispatch(getCarInfo(id)),
  handleMortgageAmount: data => dispatch(setMortgageAmount(data)),
  handleMortgageMilage: data => dispatch(setMilage(data)),
  handleGetCarInsuranceCompanies: () => dispatch(getCarInsuranceCompanies()),
  handleSetCarInsuranceCompany: data => dispatch(setCarInsuranceCompany(data)),
  setCarInsuranceCompany,
});

export default connect(mapStateToProps, mapDispatchToProps)(NewMortgage);
