import React, { Component } from 'react';
import { strip } from '@origo_vl_banking/utils';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get, replace } from 'lodash';
import { validate } from '../../../utils/validate';
import {
  getCarInfo,
  setMilage,
  setCarInsuranceCompany,
  setInsuranceCompanyName,
  resetCarInfo,
  prepareAndCalculate,
  getSellerName,
  setSellerField,
} from '../../app/actions';
import { Mortgage } from '../../../components/shared';
import { appType } from '../../../utils/constants';

class MortgageContainer extends Component {
  getCarData = (e, handleBlur) => {
    handleBlur(e);
    const { value } = e.target;
    const { mortgage } = this.props;
    if (!value) {
      return this.props.resetCarInfo();
    }
    if (!value || mortgage.mortgageItem === value) return;
    this.props.getCarInfo(value).then(() => this.props.prepareAndCalculate(appType.carLoan));
  };

  handleMileageOnBlur = (e, handleBlur) => {
    handleBlur(e);
    const value = parseInt(strip.amount(e.target.value), 10);
    this.props.setMilage(value);
  };

  parseValue = (e) => {
    const { value } = e.target;
    if (!value) return null;
    const parsedValue = replace(value, '-', '');
    if (!parsedValue || !validate.ssn(parsedValue)) return null;
    return parsedValue;
  };

  handleSellerSsn = (e) => {
    const parsedValue = this.parseValue(e);

    if (parsedValue === null) {
      this.props.setSellerField('ssn', '');
      this.props.setSellerField('name', '');
      return;
    }

    if (parsedValue === this.props.app.seller.ssn) {
      return;
    }

    this.props.getSellerName(parsedValue);
    this.props.setSellerField('ssn', parsedValue);
  };

  render() {
    const {
      mortgage,
      app,
      showInsuranceCompany,
      setCarInsuranceCompany,
      setInsuranceCompanyName,
    } = this.props;
    return (
      <Mortgage
        app={app}
        companyName={get(mortgage, 'companyName', '')}
        editable={get(app, 'editable')}
        serialNumber={get(app, 'serialNumber')}
        mortgage={get(app, 'mortgage', {})}
        showInsuranceCompany={showInsuranceCompany}
        disabled={!get(app, 'editable')}
        validationError={get(app, 'validationError')}
        setCarInsuranceCompany={setCarInsuranceCompany}
        setInsuranceCompanyName={setInsuranceCompanyName}
        getCarData={this.getCarData}
        handleMileageOnBlur={this.handleMileageOnBlur}
        handleSeller={this.handleSellerSsn}
      />
    );
  }
}

MortgageContainer.propTypes = {
  app: PropTypes.object.isRequired,
  mortgage: PropTypes.object.isRequired,
  getCarInfo: PropTypes.func.isRequired,
  setCarInsuranceCompany: PropTypes.func.isRequired,
  setInsuranceCompanyName: PropTypes.func.isRequired,
  showInsuranceCompany: PropTypes.bool.isRequired,
  setMilage: PropTypes.func.isRequired,
  getSellerName: PropTypes.func.isRequired,
  setSellerField: PropTypes.func.isRequired,
};

function mapStateToProps({ app }) {
  return {
    app,
    mortgage: app.mortgage,
  };
}

const mapDispatchToProps = {
  getCarInfo,
  setMilage,
  resetCarInfo,
  setCarInsuranceCompany,
  setInsuranceCompanyName,
  prepareAndCalculate,
  getSellerName,
  setSellerField,
};

export default connect(mapStateToProps, mapDispatchToProps)(MortgageContainer);
