import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  text-align: center;
  z-index: 500;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 300px;
  justify-content: space-between;
`;

const Heading = styled.h3`
  margin-top: 0;
`;

const Text = styled.span`
  margin-bottom: 24px;
`;

export { Container, Row, Heading, Text };
