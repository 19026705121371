import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { TransparentButton } from '../../../../components/shared/styled-components';
import EditApplication from '../../../../components/icons/ElectronicSignature';
import Popover from '../../../../components/popover/Popover';

const SignContract = ({ onClick, size, color }) => {
  const [showPopover, setShowPopover] = useState(false);
  return (
    <Popover isOpen={showPopover} content={<FormattedMessage id="Applications.Popover.Sign" />}>
      <TransparentButton
        onClick={onClick}
        onMouseEnter={() => setShowPopover(true)}
        onMouseLeave={() => setShowPopover(false)}
      >
        <EditApplication title="Undirrita" color={color} size={size} />
      </TransparentButton>
    </Popover>
  );
};

SignContract.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
};

SignContract.defaultProps = {
  color: '#3f3f3f',
  size: '26px',
};

export default SignContract;
