import { get } from 'lodash';
import { handleActions } from 'redux-actions';
import * as A from './constants';

export const initialState = {
  isAuthenticated: false,
  isSubmitting: false,
  changePasswordSuccess: false,
  user: {
    dealershipName: null,
  },
  error: {},
  showLogOutWarning: false,
  gotSMSToken: false,
  isCacheingSecurities: false,
  hasCachedSecurities: false,
  isFetching: false,
  passwordResetLinkSent: false,
  lastActivity: Date.now(),
  hasStartedAuthCheck: false,
  isWatingForDigitalLogin: false,
  verificationCode: '',
  authType: '0',
};

if (sessionStorage.name && sessionStorage.login) {
  initialState.isAuthenticated = true;
  initialState.user.name = sessionStorage.name;
  initialState.user.sellerSSN = sessionStorage.sellerSSN;
  initialState.user.username = sessionStorage.username;
  initialState.user.lastLogin = sessionStorage.lastLogin;
  initialState.user.dealershipName = sessionStorage.dealershipName;
  initialState.user.roles = get(sessionStorage, 'roles', []).split(',');
}

export default handleActions(
  {
    [A.SEND_SMS_TOKEN_REQUEST]: state => ({
      ...state,
      isFetching: true,
      hasFetched: false,
      gotSMSToken: false,
      isSubmitting: true,
      error: {},
    }),
    [A.SEND_SMS_TOKEN_SUCCESS]: (state, { menu }) => ({
      ...state,
      menu,
      isSubmitting: false,
      gotSMSToken: true,
      isFetching: false,
      hasFetched: true,
      error: {},
    }),
    [A.SEND_SMS_TOKEN_FAILURE]: (state, { error }) => ({
      ...state,
      error,
      isSubmitting: false,
      isFetching: false,
      hasFetched: false,
      gotSMSToken: false,
    }),
    [A.SHOW_LOGOUT_WARNING]: state => ({
      ...state,
      showLogOutWarning: true,
    }),
    [A.STAY_LOGGED_IN]: state => ({
      ...state,
      showLogOutWarning: false,
    }),

    [A.START_DIGITAL_LOGIN_REQUEST]: (state) => ({
      ...state,
      isSubmitting: true,
      isWatingForDigitalLogin: true,
      error: {},
    }),
    [A.START_DIGITAL_LOGIN_SUCCESS]: (state, { verificationCode, authType }) => ({
      ...state,
      error: {},
      verificationCode,
      authType,
    }),
    [A.START_DIGITAL_LOGIN_FAILURE]: (state, { error }) => ({
      ...state,
      error,
      isSubmitting: false,
      isWatingForDigitalLogin: false,
    }),

    [A.FINISH_DIGITAL_LOGIN_REQUEST]: (state) => ({
      ...state,
      isSubmitting: true,
      isWatingForDigitalLogin: true,
      error: {},
    }),
    [A.FINISH_DIGITAL_LOGIN_SUCCESS]: (state, { token }) => ({
      ...state,
      error: {},
      token,
      isSubmitting: false,
      isAuthenticated: true,
      isWatingForDigitalLogin: false,
    }),
    [A.FINISH_DIGITAL_LOGIN_FAILURE]: (state, { error }) => ({
      ...state,
      error,
      isSubmitting: false,
      isWatingForDigitalLogin: false,
    }),
    [A.LOGIN_REQUEST]: (state) => ({
      ...state,
      isSubmitting: true,
      error: {},
    }),
    [A.LOGIN_SUCCESS]: (state, { token }) => ({
      ...state,
      token,
      isSubmitting: false,
      isAuthenticated: true,
      isWatingForDigitalLogin: false,
      error: {},
    }),
    [A.LOGIN_FAILURE]: (state, { error }) => ({
      ...state,
      error,
      isSubmitting: false,
    }),
    [A.SET_AUTH]: (state, { isAuthenticated }) => ({ ...state, isAuthenticated }),
    [A.SET_USER]: (state, { user }) => ({ ...state, user }),
    [A.LOGOUT_REQUEST]: state => ({
      ...state,
      isFetching: true,
      hasFetched: false,
      hasStartedAuthCheck: false,
    }),
    [A.LOGOUT_SUCCESS]: state => ({
      ...initialState,
      isAuthenticated: false,
    }),
    [A.LOGOUT_FAILURE]: (state, { error }) => ({
      ...state,
      error,
    }),
    [A.CHANGE_PASSWORD_REQUEST]: state => ({
      ...state,
      isSubmitting: true,
      error: {},
    }),
    [A.CHANGE_PASSWORD_SUCCESS]: state => ({
      ...state,
      isSubmitting: false,
      error: {},
    }),
    [A.CHANGE_PASSWORD_FAILURE]: (state, { error }) => ({
      ...state,
      error,
      isSubmitting: false,
    }),
    [A.RESET_PASSWORD_REQUEST]: state => ({
      ...state,
      isSubmitting: true,
      error: {},
    }),
    [A.RESET_PASSWORD_SUCCESS]: state => ({
      ...state,
      isSubmitting: false,
      passwordResetLinkSent: true,
      error: {},
    }),
    [A.RESET_PASSWORD_FAILURE]: (state, { error }) => ({
      ...state,
      error,
      isSubmitting: false,
    }),
    [A.START_CHECK_AUTH]: state => ({
      ...state,
      hasStartedAuthCheck: true,
    }),
    [A.GET_DEALERSHIP_NAME_REQUEST]: state => ({
      ...state,
    }),
    [A.GET_DEALERSHIP_NAME_SUCCESS]: (state, { dealershipName }) => ({
      ...state,
      user: {
        ...state.user,
        dealershipName,
      },
    }),
    [A.GET_DEALERSHIP_NAME_FAILURE]: (state, { error }) => ({
      ...state,
      error,
    }),
  },
  initialState,
);
