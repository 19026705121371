import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { DropdownNext } from '@origo_vl_banking/ibanking-react';
import { get, map } from 'lodash';
import { FieldArray } from 'formik';
import { useSelector } from 'react-redux';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';

import TextInput from '../../text-input/TextInput';
import { CREDIT_INSTITUTION_INITIAL_VALUES } from '../DisposalModalContent';
import { normalizeMortgageItem } from '../../../utils/helpers';
import { Button } from '../../button';

const Container = styled.div`
  margin-top: 16px;
`;

const CreditInstitutionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
`;

const Inputs = styled.div`
  display: grid;
  grid-template-areas: 'dropdown text' 'button text';
  grid-template-columns: 3fr 4fr;
  column-gap: 8px;
`;

const Dropdown = styled.div`
  grid-area: dropdown;
`;
const TextInputs = styled.div`
  grid-area: text;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 8px;
`;

const ClearButtonContainer = styled.div`
  grid-area: button;
  display: grid;
  grid-template-columns: ${({ singleColumn }) => (singleColumn ? '1fr' : '2fr 1fr')};
  gap: 8px;
  height: 42px;
  align-self: end;
  justify-content: flex-end;
`;

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Splitter = styled.hr`
  margin-top: 16px;
  width: 100%;
`;

const AddRowButton = styled.button`
  background: ${({ theme }) => theme.primaryColor};
  color: ${({ theme }) => theme.primaryTextColor};
  margin: 0;
  border: 0;
  padding: 0;
  font-size: 16px;
  font-weight: bold;
  outline: 0;
  line-height: 23px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const FinanceDetailsForm = ({
  disabled,
  formId,
  formProps: {
    handleChange, handleBlur, errors, touched, values, setFieldValue,
  },
}) => {
  const { creditInstitutions } = useSelector(state => state.applications);
  const creditInstitutionsValues = get(values, 'financeDetails.creditInstitutions', []);

  const clearFields = (fieldNamePrefix, arrayHelpers, index, isDirty) => {
    setFieldValue(fieldNamePrefix, CREDIT_INSTITUTION_INITIAL_VALUES);
    setFieldValue(`${fieldNamePrefix}.amount`, '');
    if (index !== 0 && !isDirty) {
      return arrayHelpers.remove(index);
    }

    return null;
  };

  return (
    <Container>
      <CreditInstitutionWrapper>
        <FieldArray
          name="financeDetails.creditInstitutions"
          render={arrayHelpers =>
            (creditInstitutionsValues.length > 0 ? (
              map(creditInstitutionsValues, (creditInstitution, index) => {
                const fieldNamePrefix = `${formId}.creditInstitutions.${index}`;
                const fieldsErrors = get(errors, `${fieldNamePrefix}`);
                const fieldsTouched = get(touched, `${fieldNamePrefix}`);

                /* Variables that control how the form buttons render */
                const isFirst = index === 0;
                const isLast = index + 1 === creditInstitutionsValues.length;
                const isDirty = // Also helps with controlling to check if a new row should be added
                  !!creditInstitution.licensePlate ||
                  !!creditInstitution.amount ||
                  !!creditInstitution.creditInstitution.value;
                const singleColumn = !isFirst && isLast;
                const showAddButton = isFirst && isLast && isDirty;
                const showClearRowButton = (isFirst && isLast && isDirty) || (!isFirst && isDirty);
                const showRemoveRowButton = !isFirst && !isDirty;

                // const isClean = creditInstitution.licensePlate
                return (
                  <FieldsWrapper>
                    <Inputs key="creditInstitution-">
                      <Dropdown>
                        <DropdownNext
                          disabled={disabled}
                          label="Disposal.Label.CreditInstitution"
                          name={`${fieldNamePrefix}.creditInstitution`}
                          id={`${fieldNamePrefix}.creditInstitution`}
                          width="100%"
                          data={creditInstitutions.data}
                          onChange={(val) => {
                            setFieldValue(`${fieldNamePrefix}.creditInstitution`, val);
                          }}
                          displayValue={get(creditInstitution, 'creditInstitution.text', '')}
                          value={creditInstitution.creditInstitution}
                          config={{
                            valueKey: 'value',
                            labelKey: 'text',
                            menuPortalTarget: document.body,
                          }}
                        />
                      </Dropdown>
                      <TextInputs>
                        <TextInput
                          disabled={disabled}
                          id={`${fieldNamePrefix}.licensePlate`}
                          name={`${fieldNamePrefix}.licensePlate`}
                          onBlur={handleBlur}
                          onChange={e =>
                            normalizeMortgageItem(
                              e.target.value,
                              setFieldValue,
                              `${fieldNamePrefix}.licensePlate`,
                            )
                          }
                          value={creditInstitution.licensePlate}
                          maxLength={6}
                          error={
                            get(fieldsTouched, 'licensePlate', false) &&
                            get(fieldsErrors, 'licensePlate', false)
                          }
                          label="Disposal.Label.LicensePlate"
                          height="40px"
                        />
                        <TextInput
                          disabled={disabled}
                          id={`${fieldNamePrefix}.amount`}
                          name={`${fieldNamePrefix}.amount`}
                          onBlur={handleChange}
                          value={creditInstitution.amount}
                          label="Disposal.Label.Amount"
                          normalize="amount"
                          rightSideText="kr."
                          align="right"
                          height="40px"
                          error={
                            get(fieldsTouched, 'amount', false) &&
                            get(fieldsErrors, 'amount', false)
                          }
                        />
                      </TextInputs>
                      <ClearButtonContainer singleColumn={singleColumn}>
                        {(showRemoveRowButton || showClearRowButton) && (
                          <Button
                            primary={false}
                            border="3px solid hsl(46, 98%, 57%)"
                            background="white"
                            width="100%"
                            fullHeight
                            onClick={() =>
                              clearFields(fieldNamePrefix, arrayHelpers, index, isDirty)
                            }
                            content={showClearRowButton ? 'Hreinsa' : 'Fjarlægja'}
                          />
                        )}
                        {showAddButton && (
                          <AddRowButton
                            onClick={() => arrayHelpers.push(CREDIT_INSTITUTION_INITIAL_VALUES)}
                          >
                            <AiOutlinePlus size={24} />
                          </AddRowButton>
                        )}
                      </ClearButtonContainer>
                    </Inputs>
                    {creditInstitutionsValues.length > 1 && !isLast && <Splitter />}
                  </FieldsWrapper>
                );
              })
            ) : (
              <p>-</p>
            ))
          }
        />
      </CreditInstitutionWrapper>
    </Container>
  );
};

FinanceDetailsForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  formId: PropTypes.string.isRequired,
  formProps: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
  }).isRequired,
};

export default FinanceDetailsForm;
