import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal/Modal';
import Content from './DisposalModalContent';
import useDisposalModal from '../../hooks/use-disposal-modal';

const DisposalModal = () => {
  const { modal, showModal } = useDisposalModal();

  return (
    <Modal
      customContentStyle={{ overflow: 'inherit' }}
      content={<Content modal={modal} />}
      showModal={showModal}
      onClose={modal.onClose}
      modalProps={{ shouldCloseOnOverlayClick: false, shouldCloseOnEsc: false }}
    />
  );
};

DisposalModal.propTypes = {};

export default DisposalModal;
