import React from 'react';
import styled from 'styled-components';
import AppLoader from './AppLoader';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
`;

const AppLoaderWrapper = () => (
  <Wrapper>
    <AppLoader />
  </Wrapper>
);

AppLoaderWrapper.propTypes = {};

export default AppLoaderWrapper;
