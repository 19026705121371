import PropTypes from 'prop-types';
import * as React from 'react';

const ManualSignature = ({
  title, color, size, documentLineStroke, documentLinesWidth,
}) => (
  <svg width={size} height={size} viewBox="0 0 20 22">
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(3 3)" stroke={documentLineStroke}>
        <rect x={0.5} y={10.5} width={1} height={documentLinesWidth} rx={0.5} />
        <rect x={7.5} y={10.5} width={1} height={documentLinesWidth} rx={0.5} />
        <rect x={0.5} y={8.5} width={2} height={documentLinesWidth} rx={0.5} />
        <rect x={0.5} y={6.5} width={4} height={documentLinesWidth} rx={0.5} />
        <rect x={0.5} y={4.5} width={5} height={documentLinesWidth} rx={0.5} />
        <rect x={0.5} y={2.5} width={7} height={documentLinesWidth} rx={0.5} />
        <rect x={0.5} y={0.5} width={8} height={documentLinesWidth} rx={0.5} />
        <rect x={0.5} y={12.5} width={8} height={documentLinesWidth} rx={0.5} />
        <rect x={0.5} y={14.5} width={4} height={documentLinesWidth} rx={0.5} />
      </g>
      <path
        d="M11.111 1H2.444C1.647 1 1 1.995 1 3.222v15.556C1 20.005 1.647 21 2.444 21h10.112c.797 0 1.444-.995 1.444-2.222V14"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.41 1.616a2.103 2.103 0 012.974 2.974L8.966 14.01 5 15l.991-3.966 9.419-9.418z"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

ManualSignature.propTypes = {
  color: PropTypes.string,
  documentLineStroke: PropTypes.string,
  documentLinesWidth: PropTypes.number,
  size: PropTypes.string,
  title: PropTypes.string,
};

ManualSignature.defaultProps = {
  color: '#3f3f3f',
  size: '26px',
  title: 'Skjöl',
  documentLineStroke: 'hsl(0, 0%, 65%)',
  documentLinesWidth: 0.1,
};

export default ManualSignature;
