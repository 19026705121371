export const NAME = 'auth';

export const AUTHENTICATE_USERNAME_REQUEST = `${NAME}/AUTHENTICATE_USERNAME_REQUEST`;
export const AUTHENTICATE_USERNAME_SUCCESS = `${NAME}/AUTHENTICATE_USERNAME_SUCCESS`;
export const AUTHENTICATE_USERNAME_FAILURE = `${NAME}/AUTHENTICATE_USERNAME_FAILURE`;

export const LOGIN_REQUEST = `${NAME}/LOGIN_REQUEST`;
export const LOGIN_SUCCESS = `${NAME}/LOGIN_SUCCESS`;
export const LOGIN_FAILURE = `${NAME}/LOGIN_FAILURE`;

export const SET_AUTH = `${NAME}/SET_AUTH`;
export const SET_USER = `${NAME}/SET_USER`;
export const START_CHECK_AUTH = `${NAME}/START_CHECK_AUTH`;

export const SEND_SMS_TOKEN_REQUEST = `${NAME}/SEND_SMS_TOKEN_REQUEST`;
export const SEND_SMS_TOKEN_SUCCESS = `${NAME}/SEND_SMS_TOKEN_SUCCESS`;
export const SEND_SMS_TOKEN_FAILURE = `${NAME}/SEND_SMS_TOKEN_FAILURE`;

export const LOGOUT_REQUEST = `${NAME}/LOGOUT_REQUEST`;
export const LOGOUT_SUCCESS = `${NAME}/LOGOUT_SUCCESS`;
export const LOGOUT_FAILURE = `${NAME}/LOGOUT_FAILURE`;

export const CHANGE_PASSWORD_REQUEST = `${NAME}/CHANGE_PASSWORD_REQUEST`;
export const CHANGE_PASSWORD_SUCCESS = `${NAME}/CHANGE_PASSWORD_SUCCESS`;
export const CHANGE_PASSWORD_FAILURE = `${NAME}/CHANGE_PASSWORD_FAILURE`;

export const RESET_PASSWORD_REQUEST = `${NAME}/RESET_PASSWORD_REQUEST`;
export const RESET_PASSWORD_SUCCESS = `${NAME}/RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_FAILURE = `${NAME}/RESET_PASSWORD_FAILURE`;

export const REFRESH_TOKEN_REQUEST = `${NAME}/REFRESH_TOKEN_REQUEST`;
export const REFRESH_TOKEN_SUCCESS = `${NAME}/REFRESH_TOKEN_SUCCESS`;
export const REFRESH_TOKEN_FAILURE = `${NAME}/REFRESH_TOKEN_FAILURE`;
export const SHOW_LOGOUT_WARNING = `${NAME}/SHOW_LOGOUT_WARNING`;

export const STAY_LOGGED_IN = `${NAME}/STAY_LOGGED_IN`;

export const GET_CONSULTANTS_SUCCESS = `${NAME}/GET_CONSULTANTS_SUCCESS`;
export const GET_CONSULTANTS_FAILURE = `${NAME}/GET_CONSULTANTS_FAILURE`;

export const GET_DEALERSHIP_NAME_REQUEST = `${NAME}/GET_DEALERSHIP_NAME_REQUEST`;
export const GET_DEALERSHIP_NAME_SUCCESS = `${NAME}/GET_DEALERSHIP_NAME_SUCCESS`;
export const GET_DEALERSHIP_NAME_FAILURE = `${NAME}/GET_DEALERSHIP_NAME_FAILURE`;

export const START_DIGITAL_LOGIN_REQUEST = `${NAME}/START_DIGITAL_LOGIN_REQUEST`;
export const START_DIGITAL_LOGIN_SUCCESS = `${NAME}/START_DIGITAL_LOGIN_SUCCESS`;
export const START_DIGITAL_LOGIN_FAILURE = `${NAME}/START_DIGITAL_LOGIN_FAILURE`;

export const FINISH_DIGITAL_LOGIN_REQUEST = `${NAME}/FINISH_DIGITAL_LOGIN_REQUEST`;
export const FINISH_DIGITAL_LOGIN_SUCCESS = `${NAME}/FINISH_DIGITAL_LOGIN_SUCCESS`;
export const FINISH_DIGITAL_LOGIN_FAILURE = `${NAME}/FINISH_DIGITAL_LOGIN_FAILURE`;
