import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  position: ${props => (props.position ? props.position : 'fixed')};
  background: #00000047;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;
