import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Loader } from '@origo_vl_banking/ibanking-react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Button } from '../../../components/button';

import {
  RowWrapper,
  Input,
  FormErrorMessage,
  Label,
  LabelWrapper,
} from '../styled-components/ChangePasswordForm';

const ChangePasswordForm = ({ formSubmitting, validators, handleChangePassword }) => (
  <React.Fragment>
    <Formik
      validationSchema={yup.object().shape(validators)}
      validate={(values) => {
        const errors = {};

        if (
          values.oldPassword === values.newPassword &&
          values.oldPassword !== '' &&
          values.newPassword !== ''
        ) {
          errors.newPassword = 'Same password as current one.';
        }

        return errors;
      }}
      initialValues={{
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      }}
      onSubmit={(values) => {
        handleChangePassword(values);
      }}
      render={({
 handleSubmit, handleChange, handleBlur, touched, errors,
}) => (
  <Form onSubmit={handleSubmit}>
    <React.Fragment>
      <RowWrapper>
        <LabelWrapper>
          <Label htmlFor="oldPassword">
            <FormattedMessage id="Login.OldPassword" />
          </Label>
        </LabelWrapper>
        <Input
          type="password"
          id="oldPassword"
          label="oldPassword"
          name="oldPassword"
                // placeholder="Username"
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {touched.oldPassword && errors.oldPassword && (
        <FormErrorMessage>{errors.oldPassword}</FormErrorMessage>
              )}
      </RowWrapper>
      <RowWrapper>
        <LabelWrapper>
          <Label htmlFor="password">
            <FormattedMessage id="Login.NewPassword" />
          </Label>
        </LabelWrapper>
        <Input
          type="password"
          id="newPassword"
          label="newPassword"
          name="newPassword"
                // placeholder="Password"
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {touched.newPassword && errors.newPassword && (
        <FormErrorMessage>{errors.newPassword}</FormErrorMessage>
              )}
      </RowWrapper>
      <RowWrapper>
        <LabelWrapper>
          <Label htmlFor="password">
            <FormattedMessage id="Login.ConfirmPassword" />
          </Label>
        </LabelWrapper>
        <Input
          type="password"
          id="confirmPassword"
          label="confirmPassword"
          name="confirmPassword"
                // placeholder="Password"
          onBlur={handleBlur}
          onChange={handleChange}
        />
        {touched.confirmPassword && errors.confirmPassword && (
        <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
              )}
      </RowWrapper>
    </React.Fragment>

    <Button
      marginTop="24px"
      type="submit"
      formSubmitting={formSubmitting}
      disabled={formSubmitting}
      content={
              formSubmitting ? (
                <Loader color="black" />
              ) : (
                <FormattedMessage id="Login.ChangePassword" />
              )
            }
    />
    {/* <Button
              formSubmitting={formSubmitting}
              type="submit"
              marginTop="24px"
              content={<FormattedMessage id="Change.Password.Button" />}
            /> */}
  </Form>
      )}
    />
  </React.Fragment>
);

ChangePasswordForm.defaultProps = {
  formSubmitting: false,
  submissionError: {},
};

ChangePasswordForm.propTypes = {
  handleChangePassword: PropTypes.func.isRequired,
  formSubmitting: PropTypes.bool,
  submissionError: PropTypes.object,
  validators: PropTypes.object.isRequired,
};

export default ChangePasswordForm;
