import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { isEmpty } from 'lodash';
import { Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Loader } from '@origo_vl_banking/ibanking-react';
import { connect } from 'react-redux';

import { Content } from '../styled-components/Login';
import { Button } from '../../../components/button';
import { Label } from '../styled-components/LoginForm';
import { changePassword } from '../actions';
import { Input } from '../../settings/styled-components/ChangePasswordForm';
import { Api } from '../../../utils';
import { validate } from '../../../utils/validate';

const ResetPasswordRequest = ({
  isSubmitting, submissionError, dispatch, match,
}) => {
  const [isValidToken, setValidToken] = useState(false);

  useEffect(() => {
    const { token } = match.params;
    Api.verifyPasswordToken(token)
      .then((res) => {
        if (res.data === true) setValidToken(true);
      })
      .catch((err) => {
        setValidToken(false);
      });
  }, []);

  const submitForm = (values) => {
    const data = {
      token: match.params.token,
      password: values.password,
    };
    dispatch(changePassword(data));
  };

  return (
    <Content>
      <h3>
        <FormattedMessage id="Reset.Password.Heading" />
      </h3>
      <p>
        <FormattedMessage id="Reset.Password.MessageNew" />
      </p>

      <Formik
        onSubmit={submitForm}
        initialValues={{ password: '' }}
        validationSchema={yup.object().shape({ password: validate.formik.password })}
        render={({ handleSubmit, errors, touched }) => (
          <Form onSubmit={handleSubmit}>
            {!isEmpty(submissionError) && <FormattedMessage id={submissionError.errorKey} />}
            {isSubmitting && (
              <p>
                <FormattedMessage id="Reset.Password.ChangeRequested.Sent" />
              </p>
            )}

            <Label htmlFor="password">
              <FormattedMessage id="Password" />
            </Label>
            <Input type="password" name="password" height="34px" />
            {touched.password && errors.password && errors.password !== '' && (
              <FormattedMessage id={errors.password} />
            )}
            <Button
              marginTop="24px"
              type="submit"
              content={isSubmitting ? <Loader /> : <FormattedMessage id="Reset.Password.Submit" />}
              disabled={!isValidToken || isSubmitting}
            />
          </Form>
        )}
      />
    </Content>
  );
};

ResetPasswordRequest.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  submissionError: PropTypes.object,
};

ResetPasswordRequest.defaultProps = {
  submissionError: {},
};

function mapStateToProps(state) {
  return {
    isSubmitting: state.auth.isSubmitting,
    submissionError: state.auth.error,
  };
}

export default connect(mapStateToProps)(ResetPasswordRequest);
