import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { SpaceBetween, Label } from '../../../components/grid/styled-components';
import { Small } from '../../../components/shared/styled-components';
import { appType } from '../../../utils/constants';
import { SmartMonthCalculator, SmartPdfButton } from '../../../components/shared';

const Calculator = ({ app }) => {
  const { terms } = app;

  return (
    <React.Fragment>
      <SmartMonthCalculator type={appType.carRental} />
      <SpaceBetween>
        <Label><FormattedMessage id="Application.StartDate" /></Label>
        <Label>{moment(terms.startDate).format('DD.MM.YYYY')}</Label>
      </SpaceBetween>
      <SpaceBetween>
        <Label><FormattedMessage id="Application.FirstDueDate" /></Label>
        <Label>{moment(terms.firstDueDate).format('DD.MM.YYYY')}</Label>
      </SpaceBetween>

      <Small><FormattedMessage id="Application.GuaranteeFee" /></Small>

      <SmartPdfButton type={appType.carRental} />
    </React.Fragment>
  );
};

Calculator.propTypes = {
  app: PropTypes.object.isRequired,
};

const mapStateToProps = ({ app }) => ({ app });

export default connect(mapStateToProps)(Calculator);
