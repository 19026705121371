import React from 'react';
import PropTypes from 'prop-types';
import { Content } from './styled-component/Backdrop';

const Backdrop = ({ handleClose, isClosed }) => (
  <Content onClick={handleClose} isClosed={!isClosed} />
);

Backdrop.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isClosed: PropTypes.bool,
};

Backdrop.defaultProps = {
  isClosed: false,
};

export { Backdrop };
