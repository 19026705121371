import React from 'react';
import { FormattedMessage } from 'react-intl';

import Container from '../../components/container';
import {
  StyledFooter,
  Content,
  InnerColumn,
  Column,
  Image,
  Item,
  ItemTitle,
  ItemTextEnvelope,
  ItemTextMobile,
  ItemImage,
  ItemColumnTitle,
  RightImage,
} from './styled-components/StyledFooter';

const Footer = () => (
  <StyledFooter>
    <Container>
      <Content>
        <InnerColumn>
          <Column>
            <Item>
              <ItemTitle>
                <FormattedMessage id="footer.Company" />
              </ItemTitle>
            </Item>
            <Item>
              <FormattedMessage id="footer.PartOf" />
            </Item>
            <Item>
              <FormattedMessage id="footer.SSN" />
            </Item>
            <Item>
              <FormattedMessage id="footer.BankInfo" />
            </Item>
            <ItemImage>
              <Image src="/images/icons/mobile.png" />
              <ItemTextMobile>
                <FormattedMessage id="footer.Telephone" />
              </ItemTextMobile>
            </ItemImage>
          </Column>
          <Column>
            <Item>
              <ItemColumnTitle>
                <FormattedMessage
                  id="footer.OpeningHoursHeading"
                  defaultMessage="Opnunartími skrifstofu"
                />
              </ItemColumnTitle>
            </Item>
            <Item>
              <FormattedMessage
                id="footer.OpeningHours"
                defaultMessage="Virka daga milli kl. 9:00 og 17:00"
              />
            </Item>
            <Item>
              <FormattedMessage id="footer.Address" />
            </Item>
            <Item>
              <FormattedMessage id="footer.Postal" />
            </Item>
            <ItemImage>
              <Image src="/images/icons/envelope.png" />
              <ItemTextEnvelope>
                <FormattedMessage id="footer.Contact" />
              </ItemTextEnvelope>
            </ItemImage>
          </Column>
        </InnerColumn>
        <RightImage src="/images/framurskarandi_fyrirtaeki.png" />
      </Content>
    </Container>
  </StyledFooter>
);

export default Footer;
