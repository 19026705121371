import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Item } from '../styled-component/Dropdown';

const MenuItem = ({ onClick, messageId }) => (
  <Item onClick={onClick}>
    <FormattedMessage id={messageId} />
  </Item>
);

MenuItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  messageId: PropTypes.string.isRequired,
};

export default MenuItem;
