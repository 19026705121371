import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import ChangePassword from './components/ChangePassword';
import Route from './Route';
import { NAME } from './constants';
import reducer from './reducer';
import * as actions from './actions';

export default {
  NAME,
  Login,
  ResetPassword,
  ChangePassword,
  Route,
  reducer,
  actions,
};
