import { useDispatch, useSelector } from 'react-redux';
import MergerMessageModal from './MergerMessageModal';
import { setShowMergerMessageModal } from '../../modules/applications/actions';

export default () => {
  const { username } = useSelector(state => state.auth.user);
  const { mergerMessageModal } = useSelector(state => state.applications);
  const dispatch = useDispatch();
  const modal = {
    onClose: () => {
      const dismissedUsers = localStorage.getItem('dismissedUsers');
      const parsed = dismissedUsers ? JSON.parse(dismissedUsers) : [];
      const newDismissedUsers = [...parsed, username];
      localStorage.setItem('dismissedUsers', JSON.stringify(newDismissedUsers));
      dispatch(setShowMergerMessageModal(false));
    },
    onOpen: () => {
      dispatch(setShowMergerMessageModal(true));
    },
  };

  const hasUserDismissedMessage = (ssn) => {
    const dismissedUsers = localStorage.getItem('dismissedUsers');
    const parsed = dismissedUsers ? JSON.parse(dismissedUsers) : [];
    if (typeof parsed === 'object' && parsed.length > 0 && parsed.includes(ssn)) {
      return true;
    }

    return false;
  };

  return {
    modal,
    showModal: mergerMessageModal.show,
    setShowMergerMessageModal: show => dispatch(setShowMergerMessageModal(show)),
    MergerMessageModal,
    hasUserDismissedMessage,
  };
};
