import React from 'react';

import { LoaderWrapper } from './LoaderWrapper';
import AppLoader from './AppLoader';

export default function () {
  return (
    <LoaderWrapper position="fixed">
      <AppLoader size="64px" borderSize="6px" />
    </LoaderWrapper>
  );
}
