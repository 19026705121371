import styled from 'styled-components';
import { Field } from 'formik';

const RowWrapper = styled.div`
  margin-top: 24px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  &:input {
    padding-top: 24px;
    background-color: rgb(244, 244, 244);  
  }
`;

const ButtonGroup = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Input = styled(Field)`
  background-color: rgb(244, 244, 244);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0px;
  box-shadow: none;
  color: rgb(62, 62, 62);
  font-size: 16px;
  height: 42px;
  padding-left: 10px;
  width: 100%;
  margin-bottom: 2px;
  box-sizing: border-box;

  &:focus {
    background-color: #fff;
  }

  ${(props) => {
    if (props.error && props.touched) {
      return `
        background-color: ${props.theme.form.formErrorColor};
        border: ${props.theme.form.formErrorBorder};
    `;
    }
    return '';
  }};
`;

const FormErrorMessage = styled.span`
  color: red;
  float: left;

  &.hide {
    display: none;
  }
`;

const LabelWrapper = styled.div``;
const Label = styled.div`
  margin-bottom: 5px;
`;

export {
  RowWrapper,
  Row,
  Input,
  ButtonGroup,
  FormErrorMessage,
  Label,
  LabelWrapper,
};
