import React from 'react';
import styled from 'styled-components';
import { Title } from '../../components/grid/styled-components';

const Link = styled.a`
  color: #007fe0;
  text-decoration: none;
`;

const Documents = () => (
  <div>
    <h4>
      Hér má nálgast eyðublöð sem hugsanlega gætu nýst þér í umsýslu þinni við
      ökutækja- eða vinnuvélafjármögnun.
    </h4>

    <Title>Tilkynningar, kaupsamningur og afsal</Title>

    <Link href="https://www.lykill.is/um-lykil/eydublod/" rel="noopener noreferrer" target="_blank">
    Eyðublöð
    </Link>
  </div>
);

export default Documents;
