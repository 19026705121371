import { get } from 'lodash';
import { handleActions } from 'redux-actions';
import moment from 'moment';

import {
  SET_SHOW_DISPOSAL_MODAL,
  TABLE_DATA_REQUEST,
  TABLE_DATA_SUCCESS,
  TABLE_DATA_FAILURE,
  GET_CONTRACT_REQUEST,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_FAILURE,
  SET_START_DATE,
  SET_END_DATE,
  SEND_TO_SIGNING_REQUEST,
  SEND_TO_SIGNING_SUCCESS,
  SEND_TO_SIGNING_FAILURE,
  CLEAR_SIGNING_STATUS,
  SET_SHOW_DIGITAL_SIGN_MODAL,
  SET_SHOW_DOCUMENTS_MODAL,
  SET_SHOW_MERGER_MESSAGE_MODAL,
  SET_APPLICATION_TO_SIGN,
  GET_CREDIT_INSTITUTIONS_REQUEST,
  GET_CREDIT_INSTITUTIONS_SUCCESS,
  GET_CREDIT_INSTITUTIONS_FAILURE,
  GET_DISPOSAL_INFORMATION_REQUEST,
  GET_DISPOSAL_INFORMATION_SUCCESS,
  GET_DISPOSAL_INFORMATION_FAILURE,
  POST_DISPOSAL_INFORMATION_REQUEST,
  POST_DISPOSAL_INFORMATION_SUCCESS,
  POST_DISPOSAL_INFORMATION_FAILURE,
  UPLOAD_DOCUMENTS_REQUEST,
  UPLOAD_DOCUMENTS_SUCCESS,
  UPLOAD_DOCUMENTS_FAILURE,
  INITIALIZE_DISPOSAL_STATE,
  INITIALIZE_DOCUMENTS_STATE,
} from './constants';

const INITIAL_DATA_STATE = {
  status: 'initial',
  data: [],
};

const initialState = {
  tableData: [],
  tableDataLoaded: false,
  startDate: moment().subtract(2, 'weeks'),
  endDate: moment(),
  isFetchingTableData: false,
  isFetchingContract: false,
  hasFetchedContract: false,
  isSigning: false,
  hasSigned: false,
  signingStatus: '',
  digitalSignModal: { show: false },
  applicationToSign: {},
  disposalModal: { show: false },
  documentsModal: { show: false },
  mergerMessageModal: { show: false },
  creditInstitutions: INITIAL_DATA_STATE,
  documents: INITIAL_DATA_STATE,
  disposalInformation: INITIAL_DATA_STATE,
};

export default handleActions(
  {
    [TABLE_DATA_REQUEST]: (state, action) => ({
      ...state,
      ...action,
      tableDataLoaded: false,
      isFetchingTableData: true,
    }),
    [TABLE_DATA_SUCCESS]: (state, action) => ({
      ...state,
      tableData: action.tableData,
      tableDataLoaded: true,
      isFetchingTableData: false,
    }),
    [TABLE_DATA_FAILURE]: (state, action) => ({
      ...state,
      error: action.error,
      tableDataLoaded: false,
      isFetchingTableData: false,
    }),
    [GET_CONTRACT_REQUEST]: (state) => ({
      ...state,
      isFetchingContract: true,
      hasFetchedContract: false,
    }),
    [GET_CONTRACT_SUCCESS]: (state) => ({
      ...state,
      isFetchingContract: false,
      hasFetchedContract: true,
    }),
    [GET_CONTRACT_FAILURE]: (state, { error }) => ({
      ...state,
      error,
      isFetchingContract: false,
      hasFetchedContract: false,
    }),
    [SET_START_DATE]: (state, { startDate }) => ({
      ...state,
      startDate,
    }),
    [SET_END_DATE]: (state, { endDate }) => ({
      ...state,
      endDate,
    }),
    [SEND_TO_SIGNING_REQUEST]: (state) => ({
      ...state,
      signingStatus: '',
      isSigning: true,
      hasSigned: false,
    }),
    [SEND_TO_SIGNING_SUCCESS]: (state, { status }) => ({
      ...state,
      signingStatus: status,
      isSigning: false,
      hasSigned: true,
    }),
    [SEND_TO_SIGNING_FAILURE]: (state, { error }) => ({
      ...state,
      error,
      signingStatus: get(error, 'errorData.SapErrorMessage', ''),
      isSigning: false,
      hasSigned: false,
    }),
    [CLEAR_SIGNING_STATUS]: (state) => ({
      ...state,
      signingStatus: '',
    }),
    [SET_SHOW_DIGITAL_SIGN_MODAL]: (state, { showModal }) => ({
      ...state,
      digitalSignModal: {
        show: showModal,
      },
    }),
    [SET_SHOW_DISPOSAL_MODAL]: (state, { showModal }) => ({
      ...state,
      disposalModal: {
        show: showModal,
      },
    }),
    [SET_SHOW_DOCUMENTS_MODAL]: (state, { showModal }) => ({
      ...state,
      documentsModal: {
        show: showModal,
      },
    }),
    [SET_SHOW_MERGER_MESSAGE_MODAL]: (state, { showModal }) => ({
      ...state,
      mergerMessageModal: {
        show: showModal,
      },
    }),
    [SET_APPLICATION_TO_SIGN]: (state, { applicationToSign }) => ({
      ...state,
      applicationToSign,
    }),
    [GET_CREDIT_INSTITUTIONS_REQUEST]: (state) => ({
      ...state,
      creditInstitutions: {
        ...state.creditInstitutions,
        status: 'loading',
      },
    }),
    [GET_CREDIT_INSTITUTIONS_SUCCESS]: (state, { creditInstitutions }) => ({
      ...state,
      creditInstitutions: {
        ...state.creditInstitutions,
        status: 'loaded',
        data: creditInstitutions,
      },
    }),
    [GET_CREDIT_INSTITUTIONS_FAILURE]: (state, { error }) => ({
      ...state,
      creditInstitutions: {
        ...state.creditInstitutions,
        status: 'error',
      },
      error,
    }),
    [GET_DISPOSAL_INFORMATION_REQUEST]: (state) => ({
      ...state,
      disposalInformation: {
        ...state.disposalInformation,
        status: 'loading',
      },
    }),
    [GET_DISPOSAL_INFORMATION_SUCCESS]: (state, { data }) => ({
      ...state,
      disposalInformation: {
        ...state.disposalInformation,
        status: 'loaded',
        data,
      },
    }),
    [GET_DISPOSAL_INFORMATION_FAILURE]: (state, { disposalErrorMessage }) => ({
      ...state,
      disposalInformation: {
        ...state.disposalInformation,
        status: 'error',
        error: disposalErrorMessage,
      },
    }),
    [UPLOAD_DOCUMENTS_REQUEST]: (state) => ({
      ...state,
      documents: {
        status: 'uploading',
      },
    }),
    [UPLOAD_DOCUMENTS_SUCCESS]: (state) => ({
      ...state,
      documents: {
        status: 'uploaded',
      },
    }),
    [UPLOAD_DOCUMENTS_FAILURE]: (state, { uploadError }) => ({
      ...state,
      documents: {
        status: 'error',
        error: uploadError,
      },
    }),
    [POST_DISPOSAL_INFORMATION_REQUEST]: (state) => ({
      ...state,
      disposalInformation: {
        ...state.disposalInformation,
        status: 'posting',
      },
    }),
    [POST_DISPOSAL_INFORMATION_SUCCESS]: (state) => ({
      ...state,
      disposalInformation: {
        ...state.disposalInformation,
        status: 'posted',
      },
    }),
    [POST_DISPOSAL_INFORMATION_FAILURE]: (state, { disposalErrorMessage }) => ({
      ...state,
      disposalInformation: {
        ...state.disposalInformation,
        status: 'error',
        error: disposalErrorMessage,
      },
    }),
    [INITIALIZE_DISPOSAL_STATE]: (state) => ({
      ...state,
      disposalInformation: {
        ...state.disposalInformation,
        ...INITIAL_DATA_STATE,
      },
      disposalModal: { ...state.disposalModal, show: false },
    }),
    [INITIALIZE_DOCUMENTS_STATE]: (state) => ({
      ...state,
      documents: {
        ...state.documents,
        ...INITIAL_DATA_STATE,
      },
      documentModal: { ...state.documentModal, show: false },
    }),
  },
  initialState,
);
