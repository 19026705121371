import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 48px;
  width: 48px;

  border-radius: 10px;
  background: ${({ theme: { secondaryColor } }) => secondaryColor};
`;

const Type = styled.span`
  color: white;
`;

const FileType = ({ file }) => {
  const { fileName } = file;
  const extension = fileName && fileName.split('.').pop();
  const truncatedExtension =
    extension && extension.length > 3 ? `${extension.substring(0, 3)}` : extension;

  return (
    <Container>
      <Type>{typeof truncatedExtension === 'string' && truncatedExtension.toUpperCase()}</Type>
    </Container>
  );
};

FileType.propTypes = {
  file: PropTypes.shape({
    fileName: PropTypes.shape({
      split: PropTypes.func,
    }).isRequired,
  }).isRequired,
};

export default FileType;
