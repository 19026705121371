import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { format } from '@origo_vl_banking/utils';

import { SpaceBetween, Label } from '../../../components/grid/styled-components';
import { Small } from '../../../components/shared/styled-components';
import { SmartMonthCalculator, SmartPdfButton } from '../../../components/shared';
import { appType } from '../../../utils/constants';

const Calculator = ({ app }) => {
  const { terms, calculation, fees } = app;

  // const commitmentFeePercent = (fees.commitmentFee * 100) / terms.price;
  return (
    <>
      <SmartMonthCalculator type={appType.leasing} />

      <SpaceBetween>
        <Label>
          <FormattedMessage id="Application.PlannedFirstPayment" />
        </Label>
        <Label>
          {format.amount(Math.round(calculation.plannedFirstPayment))}
          {' '}
          kr.
        </Label>
      </SpaceBetween>
      <SpaceBetween>
        <Label>
          <FormattedMessage id="Leasing.BorrowingFee" />
          {' '}
          *
          <span style={{ paddingLeft: 5 }}>
            {fees.commitmentFeePercent ? `${(fees.commitmentFeePercent * 100).toFixed(2)} %` : ''}
          </span>
        </Label>
        <Label>
          <span style={{ paddingLeft: 8 }}>
            {`${format.amount(fees.commitmentFee)} kr.`}
          </span>
        </Label>
      </SpaceBetween>
      <SpaceBetween>
        <Label>
          <FormattedMessage id="Application.InterestPercent" />
        </Label>
        <Label>
          {(calculation.interestPercent * 100).toFixed(2) || 0}
          %
        </Label>
      </SpaceBetween>
      <SpaceBetween>
        <Label>
          <FormattedMessage id="Application.CashDepositActual" />
        </Label>
        <Label>
          {(calculation.funding * 100).toFixed(2) || 0}
          %
        </Label>
      </SpaceBetween>
      <SpaceBetween>
        <Label>
          <FormattedMessage id="Application.FirstDueDate" />
        </Label>
        <Label>{moment(terms.firstDueDate).format('DD.MM.YYYY')}</Label>
      </SpaceBetween>
      <SpaceBetween>
        <Label>
          <FormattedMessage id="Leasing.FinalPayment" />
        </Label>
        <Label>
          {format.amount(fees.finalFee) || 0}
          {' '}
          kr.
        </Label>
      </SpaceBetween>
      <Small>
        *
        <FormattedMessage id="Leasing.OnFirstPayment" />
        <br />
        <br />
        <FormattedMessage id="Leasing.FirstPaymentNote" />
      </Small>

      <SmartPdfButton type={appType.leasing} />
    </>
  );
};

Calculator.propTypes = {
  app: PropTypes.object.isRequired,
};

const mapStateToProps = ({ app }) => ({ app });

export default connect(mapStateToProps)(Calculator);
