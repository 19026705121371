import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import GridView from '../../components/grid/GridView';
import {
  SmartHeader,
  SmartMessage,
  SmartApplicants,
  SmartCalculator,
} from '../../components/shared';
import { Terms, Mortgage } from './components';
import { appType, appStatus } from '../../utils/constants';
import { handleApplication } from './actions';
import { getApplication, getPreConditions } from '../app/actions';
import SmartLoader from '../../components/loader/SmartLoader';

class CarLeasing extends Component {
  componentDidMount() {
    const { state } = this.props.location;
    // this.props.getApplication('2021', '10000267');
    if (state) {
      this.props.getApplication(state.year, state.serialNumber);
    } else {
      this.props.getPreConditions(appType.carLeasing);
    }
  }

  render() {
    const { editable, statusText, message1, message2 } = this.props.app;
    return (
      <React.Fragment>
        <SmartLoader />
        <SmartHeader
          title="sitemap.CarLeasing"
          subtitle={statusText || 'Application.StatusText.Offer'}
          message1={message1}
          message2={message2}
        />

        <GridView
          grid1={<Terms title="Application.Terms" />}
          grid2={<Mortgage title="Mortgage.LoanHeader" showInsuranceCompany />}
          grid3={<SmartApplicants title="Application.PersonsHeader" />}
          calculator={<SmartCalculator type={appType.carLeasing} />}
          message={<SmartMessage />}
          displayButtons={editable}
          editable={editable}
          handleSaveApplication={() => this.props.handleApplication(appStatus.save)}
          handleSendApplication={() => this.props.handleApplication(appStatus.send)}
        />
      </React.Fragment>
    );
  }
}

CarLeasing.propTypes = {
  location: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  getPreConditions: PropTypes.func.isRequired,
  getApplication: PropTypes.func.isRequired,
  handleApplication: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app }) => ({ app });

const mapDispatchToProps = {
  handleApplication,
  getPreConditions,
  getApplication,
};

export default connect(mapStateToProps, mapDispatchToProps)(CarLeasing);
