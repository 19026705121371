import axios from 'axios';
import es6promise from 'es6-promise';

es6promise.polyfill();

const main = axios.create({
  /* default config for the API */
  // baseURL: 'https://dev-fjarmognunapi.lykill.is/',
  // baseURL: 'https://test-fjarmognunapi.lykill.is/',
  // baseURL: 'https://fjarmognunapi.lykill.is/',
  // baseURL: 'https://localhost:6001/',
  // baseURL: 'http://localhost:5000/',
  baseURL: process.env.REACT_APP_API_URL,
});

let xsrfToken = null;

main.interceptors.response.use(
  (response) => {
    if (response.headers['x-xsrf-token']) {
      xsrfToken = response.headers['x-xsrf-token'];
    }
    return response;
  },
  (error) => {
    if (error.response) {
      /* Server not happy with request */
      const { data } = error.response;
      const errorKey = data.error_description || data.Message || data.ErrorMessageKey;
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        errorKey,
        errorData: data,
        severity: data.error || data.Severity,
        status: error.response.status,
      });
    }
    if (error.request) {
      /* Something in the request went wrong no response from server */
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        errorKey: 'Error.Network.InvalidRequest',
        error,
      });
    }
    /* Something went wrong with the config of the request, no network call made */
    return Promise.reject({ errorKey: 'Error.Network.InvalidConfig', error }); // eslint-disable-line
  },
);

main.interceptors.request.use((config) => {
  if (
    config.method.toUpperCase() === 'POST' ||
    config.method.toUpperCase() === 'PUT' ||
    config.method.toUpperCase() === 'DELETE'
  ) {
    config.headers['X-XSRF-TOKEN'] = xsrfToken; // eslint-disable-line no-param-reassign
  }

  return {
    ...config,
    headers: {
      ...config.headers,
    },
    withCredentials: true,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
  };
});

const headers = {
  'Content-Type': 'application/json',
};

const usernameLogin = data =>
  main({
    headers,
    method: 'POST',
    url: '/api/Auth/Username',
    data,
  });

const electronicLogin = data =>
  main({
    headers,
    method: 'POST',
    url: '/api/Auth/Digital',
    data,
  });

 const startElectronicLogin = data =>
  main({
    headers,
    method: 'POST',
    url: '/api/Auth/StartDigital',
    data,
  });

  const finishElectronicLogin = data =>
  main({
    headers,
    method: 'POST',
    url: '/api/Auth/FinishDigital',
    data,
  });

const changePassword = data =>
  main({
    headers,
    method: 'POST',
    url: '/api/Auth/ChangePassword',
    data,
  });

const ChangePasswordToken = data =>
  main({
    headers,
    method: 'POST',
    url: '/api/Auth/ChangePasswordToken',
    data,
  });

const userProperties = data =>
  main({
    headers,
    method: 'GET',
    url: '/api/Auth/UserProperties',
    data,
  });

const logout = () =>
  main({
    headers,
    method: 'POST',
    url: '/api/Auth/Logout',
  });

const getConsultants = () =>
  main({
    headers,
    method: 'GET',
    url: '/api/Consultant',
  });

const getConsultantInfo = id =>
  main({
    headers,
    method: 'GET',
    url: `/consultant/${id}`,
    id,
  });

const getApplications = (from, to) =>
  main({
    headers,
    method: 'GET',
    url: `/api/Application/Search?from=${from}&to=${to}`,
  });

const getApplication = (year, serialNumber) =>
  main({
    headers,
    method: 'GET',
    url: `/api/Application?year=${year}&serialNumber=${serialNumber}`,
  });

const getApplicationDetails = (year, serialNumber) =>
  main({
    headers,
    method: 'GET',
    url: `/api/Application/Detail?year=${year}&serialNumber=${serialNumber}`,
  });

const getLanguage = lang =>
  main({
    headers,
    method: 'GET',
    url: `/api/Language/${lang}`,
  });

const resetPassword = data =>
  main({
    headers,
    method: 'POST',
    url: '/api/Auth/SendChangePasswordLink',
    data,
  });

const verifyPasswordToken = token =>
  main({
    headers,
    method: 'GET',
    url: `/api/Auth/VerifyPasswordToken?token=${token}`,
  });

const getPreConditions = type =>
  main({
    headers,
    method: 'GET',
    url: `/api/Application/PreConditions?type=${type}`,
    type,
  });

const contract = ({ year, serialNumber, type }) =>
  main({
    headers,
    method: 'GET',
    url: `/api/Contract?type=${type}&serialNumber=${serialNumber}&year=${year}`,
    type,
  });

const getVehicle = (id, year, serialNumber) =>
  main({
    headers,
    method: 'GET',
    url: (year && serialNumber) ? `/api/Vehicle?id=${id}&year=${year}&serial=${serialNumber}` : `/api/Vehicle?id=${id}`,
  });

const getInsuranceCompanies = () =>
  main({
    headers,
    method: 'GET',
    url: '/api/InsuranceCompany',
  });

const getCarType = () =>
  main({
    headers,
    method: 'GET',
    url: '/api/CarType',
  });

const getCarTypeInfo = id =>
  main({
    headers,
    method: 'GET',
    url: `/api/CarType/${id}`,
  });

const getCalculation = (data, bool) =>
  main({
    headers,
    method: 'POST',
    url: `/api/Application/Calculate?createPdf=${bool}`,
    data,
  });

const getPersonName = id =>
  main({
    headers,
    method: 'GET',
    url: `/api/Person/?id=${id}`,
    id,
  });

const getLeasingPersonName = id =>
  main({
    headers,
    method: 'GET',
    url: `api/Person/Details?id=${id}`,
    id,
  });

const saveApplication = data =>
  main({
    headers,
    method: 'POST',
    url: '/api/Application',
    data,
  });

const updateApplication = data =>
  main({
    headers,
    method: 'PUT',
    url: '/api/Application',
    data,
  });

const sendToSigning = data =>
  main({
    headers,
    method: 'POST',
    url: '/api/Application/Sign',
    data,
  });

const mortgageSwitch = {
  getItemInfo: data =>
    main({
      headers,
      method: 'GET',
      url: `/api/MortgageSwitch?ssn=${data.ssn}&mortgageItem=${data.mortgageItem}`,
      data,
    }),
  getDetails: data =>
    main({
      headers,
      method: 'GET',
      url: `/api/MortgageSwitch/Details?year=${data.year}&serialNumber=${data.serialNumber}`,
      data,
    }),
  save: data =>
    main({
      headers,
      method: 'POST',
      url: '/api/MortgageSwitch',
      data,
    }),
  send: data =>
    main({
      headers,
      method: 'POST',
      url: '/api/MortgageSwitch',
      data,
    }),
  update: data =>
    main({
      headers,
      method: 'PUT',
      url: '/api/MortgageSwitch',
      data,
    }),
};

const disposal = {
  getCreditInstitution: () =>
    main({
      headers,
      method: 'GET',
      url: '/api/CreditInstitution',
    }),
  get: data =>
    main({
      headers,
      method: 'GET',
      url: `/api/Disposal?year=${data.year}&serialNumber=${data.serialNumber}`,
    }),
  post: data =>
    main({
      headers,
      method: 'POST',
      url: '/api/Disposal',
      data,
    }),
};

const documents = {
  upload: data =>
    main({
      headers: { 'Content-Type': 'multipart/form-data; charset=utf-8;' },
      method: 'POST',
      url: '/api/Application/UploadDocuments',
      // contentType: 'multipart/form-data',
      data,
    }),
};

export default {
  usernameLogin,
  electronicLogin,
  startElectronicLogin,
  finishElectronicLogin,
  changePassword,
  ChangePasswordToken,
  userProperties,
  logout,
  getPreConditions,
  getConsultants,
  getConsultantInfo,
  getLanguage,
  resetPassword,
  verifyPasswordToken,
  getApplications,
  getApplication,
  getApplicationDetails,
  getVehicle,
  getInsuranceCompanies,
  getCarType,
  getCarTypeInfo,
  getCalculation,
  saveApplication,
  updateApplication,
  mortgageSwitch,
  getPersonName,
  getLeasingPersonName,
  // getMortgageInfo,
  // saveMortgageSwitch,
  // sendMortgageSwitch,
  // getMortgageDetails,
  contract,
  sendToSigning,
  disposal,
  documents,
};
