import React from 'react';
import { PropTypes } from 'prop-types';
import { get } from 'lodash';
import { ActionButton } from '..';

const ApplicationButton = ({ buttonProps, changeRoute }) => {
  const typeCode = get(buttonProps, 'typeCode');
  const disabled = typeCode === 6 || typeCode === 7;

  return (
    <ActionButton
      disabled={disabled}
      changeLink={get(buttonProps, 'changeLink')}
      buttonProps={buttonProps}
      changeRoute={changeRoute}
    />
  );
};

ApplicationButton.propTypes = {
  buttonProps: PropTypes.object.isRequired,
  changeRoute: PropTypes.func.isRequired,
};

export default ApplicationButton;
