import kennitala from 'kennitala';
import { validate as origoValidate } from '@origo_vl_banking/utils';

const ssn = value => kennitala.isValid(value);

const phoneNumber = (value) => {
  if (value === undefined || value === '') {
    return 'Error.EmptyField';
  } else if (value.length !== 7) {
    return 'Error.InvalidPhoneNumber';
  } else if (!['6', '7', '8'].includes(phoneNumber[0])) {
    return { phoneNumber: 'Error.InvalidPhoneNumber' };
  }
  return undefined;
};

export const validate = {
  phoneNumber,
  formik: origoValidate.formik,
  ssn,
};
